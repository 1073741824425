import { Form, ProgressBar } from 'react-bootstrap';
import { toWordCase } from '../../../Utils/utils';

export const DealProgressBar = ({ formik, locale }) => {
  return (
    <Form.Group className='form-group'>
      <Form.Label>{locale('pages:deal.probability')}</Form.Label>
      <div className='meter-wrap'>
        {formik.values.probability > -1 && (
          <div
            className='progress-bar-tooltip'
            style={{
              width: formik.values.probability + '%',
              transition: 'width .5s ease-in-out',
            }}
          >
            <div className='progress-bar-tooltip-inner'>
              {formik && formik.values && formik.values.deal_stage_name
                ? toWordCase(formik.values.deal_stage_name)
                : ''}
            </div>
          </div>
        )}
        <ProgressBar
          variant='success'
          className='meter-item'
          min={0}
          max={100}
          now={formik.values.probability}
        />
        <ul className='meter-scale'>
          <li>
            <span>0</span>
          </li>
          <li>
            <span>10</span>
          </li>
          <li>
            <span>20</span>
          </li>
          <li>
            <span>30</span>
          </li>
          <li>
            <span>40</span>
          </li>
          <li>
            <span>50</span>
          </li>
          <li>
            <span>60</span>
          </li>
          <li>
            <span>70</span>
          </li>
          <li>
            <span>80</span>
          </li>
          <li>
            <span>90</span>
          </li>
          <li>
            <span>100</span>
          </li>
        </ul>
      </div>
    </Form.Group>
  );
};

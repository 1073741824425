import React from 'react';

class ReactLogin extends React.Component {
  constructor(props) {
    super(props);
    this.startOAuth = this.startOAuth.bind(this);
    this.getOAuthUrl = this.getOAuthUrl.bind(this);

    if (!this.props.clientId)
      throw new Error('invalid clientId provided for react-ms-login');
    if (!this.props.redirectUri)
      throw new Error('invalid redirectUri provided for react-ms-login');

    this.state = {
      clientId: this.props.clientId,
      redirectUri: this.props.redirectUri,
      scopes: this.props.scopes || ['user.read'],
      responseType: this.props.responseType || 'token',
    };

    this.oauthUrl =
      'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
  }

  getOAuthUrl() {
    // const code_verifier = generate_random_string((length = 100));
    // // session["code_verifier"] = code_verifier
    // const hash = sha256_hash(code_verifier);
    // const code_challenge = base64_url_encode(hash);
    const code_challenge =
      '8479234823djkfhsdjkfsdfksdjdskfjdsfksdj457834857dfkg897fg89dffg7df6hdf7h6df7h6dfh8dfh';

    let scopes = encodeURIComponent(this.state.scopes.join(' '));
    let oauthUri =
      `${this.oauthUrl}?client_id=${this.state.clientId}&response_type=${this.state.responseType}` +
      `&redirect_uri=${encodeURIComponent(this.state.redirectUri)}` +
      `&scope=${scopes}&response_mode=fragment&code_challenge=${code_challenge}&code_challenge_method=plain`;

    return oauthUri;
  }

  startOAuth() {
    let popup = (url, title, w, h) => {
      var left = window.screen.width / 2 - w / 2;
      var top = window.screen.height / 2 - h / 2;
      return window.open(
        url,
        title,
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
          w +
          ', height=' +
          h +
          ', top=' +
          top +
          ', left=' +
          left
      );
    };
    // eslint-disable-next-line
    let _authCompleteCb = function (data) {}.bind(this);

    window.authScope = {
      authCompletedCB: _authCompleteCb,
    };

    popup(this.getOAuthUrl(), 'Microsoft Sign In', 600, 750);
    window.addEventListener('message', this.receiveMicrosoftMessage, false);
  }

  receiveMicrosoftMessage = (data) => {
    window.removeEventListener('message', this.receiveLinkedInMessage, false);
    if (data.data.code) this.props.handleLogin(data.data);
    // popup.close();
  };

  render() {
    let cssClasses = `btn-microsoft-login ${this.props.cssClass}`;
    let btnContent = 'Sign in with Microsoft';

    if (this.props.btnContent) {
      if (typeof this.props.btnContent === 'function')
        btnContent = <this.props.btnContent />;
      else btnContent = this.props.btnContent;
    }

    return (
      <button
        type='button'
        className={cssClasses}
        onClick={this.startOAuth}
      >
        {btnContent}
      </button>
    );
  }
}

export default ReactLogin;

import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";

import { nonGroupColourStyles } from "../../../Components/Common/DropDown/Style";

export const SelectLanguage = ({
  label = "Set Language",
  showLabel = true,
  iconClass = "ri-translate",
  value = "",
  getLanguage = () => {},
  locale,
}) => {
  const [languages] = useState([
    { value: "en", label: "English" },
    { value: "fr", label: "French" },
    { value: "pt", label: "Portuguese" },
    { value: "es", label: "Español" },
  ]);

  /******/
  return (
    <Form.Group className="form-group">
      {showLabel ? (
        <Form.Label>{locale("pages:setting.common." + label)}</Form.Label>
      ) : (
        ""
      )}
      <InputGroup>
        <InputGroup.Text>
          <i className={iconClass}></i>
        </InputGroup.Text>
        <Select
          options={languages}
          placeholder={locale("pages:setting.common.language")}
          value={
            languages && languages.filter((item) => item.value === value)[0]
          }
          onChange={(e) => getLanguage(e)}
          styles={nonGroupColourStyles}
        />
      </InputGroup>
    </Form.Group>
  );
};

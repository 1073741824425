import React, { useState, useEffect } from "react";
/* Retrieve Data From Server */
import { useLazyQuery } from "@apollo/client";
import { Modal, Row, Col, Nav, Tab, Button } from "react-bootstrap";
import {
  currentTeamId,
  emailTemplateForPreview,
  toSnackCase,
} from "../../../../Utils/utils";
import { Link } from "react-router-dom";
import { EMAIL_BY_ID, SYSTEM_EMAIL_BY_ID } from "../../../../GraphApi/Emails";

export const EmailTemplatesListing = ({
  handleShowCreateEmail = () => {},
  handleClose,
  systemEmailTemplates,
  industry,
  previewEmailTemplates,
  handleClone,
  emailMode,
  page,
  setCreateFromScratch = () => {},
  locale,
  popupType = "default",
  templateType = "conversation",
}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [activeTab, setActiveTab] = useState("");
  const [emailTab, setEmailTab] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);

  const icons = {
    onboarding_nurture_emails: <i className="ri-mail-line"></i>,
    promotional_emails: <i className="ri-volume-vibrate-fill"></i>,
    informative_emails: <i className="ri-information-line"></i>,
  };
  const [getSystemEmailById, { loading }] = useLazyQuery(SYSTEM_EMAIL_BY_ID, {
    fetchPolicy: "network-only",
  });
  const [getEmailById] = useLazyQuery(EMAIL_BY_ID, {
    fetchPolicy: "network-only",
  });

  const fetchEmailDetails = (email, type = "preview") => {
    getSystemEmailById({
      variables: { id: parseInt(email.id) },
      fetchPolicy: "network-only",
    })
      .then((result) => {
        const temp = result.data.getSystemEmailTemplate;
        // console.log("result::", temp);
        if (type === "preview") {
          previewEmailTemplates(temp, "system");
        } else {
          handleClone(
            {
              id: parseInt(temp.id),
              name: temp.name,
              templateName: temp.name,
              subject: temp.subject,
              message: temp.message,
              imageSrc: temp.screenshot_url,
              // type: name,
              industry: industry,
              template_type: temp.template_type,
            },
            true
          );
        }
      })
      .catch((error) => {
        console.log("Unable to fetch", error);
      });
  };

  const handleConversationTemplate = (selectedEmail) => {
    getEmailById({
      variables: { id: selectedEmail.id, team_id: currentTeamId },
      fetchPolicy: "network-only",
    })
      .then((result) => {
        if (result.data.emailTemplate) {
          const { message, ...rest } = result.data.emailTemplate;
          const msg = emailTemplateForPreview(message);
          if (msg) {
            handleClone(
              {
                id: parseInt(rest.id),
                name: rest?.name || "",
                templateName: rest?.name || "",
                subject: rest?.subject || "",
                message: msg,
                imageSrc: rest?.screenshot_url || "",
                // type: name,
                industry: "service",
                template_type: rest.template_type,
                team_id: rest?.team_id || null,
              },
              true
            );
          }
        }
      })
      .catch((error) => {
        console.log("error", error.message);
      });
  };

  const handleCreateClone = () => {
    
    if (
      ["conversation"].includes(selectedEmail.template_type) &&
      !selectedEmail.team_id
    ) {
      fetchEmailDetails(selectedEmail, "clone");
    } else if (
      ["conversation", "transactional_email"].includes(
        selectedEmail.template_type
      )
    ) {
      handleConversationTemplate(selectedEmail);
    } else {
      fetchEmailDetails(selectedEmail, "clone");
    }
  };

  /******/
  useEffect(() => {
    if (systemEmailTemplates?.length) {
      let tabName = [];
      let allTemplateList = [];
      // console.log("systemEmailTemplates:", systemEmailTemplates);
      systemEmailTemplates.forEach((item, idx) => {
        const name = toSnackCase(item.label);
        if (idx === 0) {
          setActiveTab(name);
        }
        tabName.push({
          name: name,
          label: item.label,
          icon: icons[name],
        });

        const templateList = item.option.map(
          (temp) =>
            temp && {
              id: parseInt(temp.id),
              name: temp.name,
              templateName: temp.name,
              subject: temp.subject,
              message: temp.message,
              imageSrc: temp.screenshot_url,
              type: name,
              industry: industry,
              template_type: temp.template_type,
              team_id: temp?.team_id || null,
            }
        );
        allTemplateList.push(...templateList);
      });

      setEmailTemplates(allTemplateList);
      setEmailTab(tabName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemEmailTemplates]);
  /*****/

  /*****/
  return (
    <>
      <Modal.Header closeVariant="white" closeButton className={"bg-primary"}>
        <Modal.Title className={"text-white secondary-font text-uppercase"}>
          {locale("pages:setting.email.Email Templates")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {popupType === "default" && (
          <Row className="align-items-center">
            <Col xs={"auto"} className="ms-auto">
              {page === "campaign" ? (
                <Link
                  variant="btn-w-icon"
                  className="btn btn-primary btn-ms-icon"
                  to="/new-email-builder"
                  state={{
                    templateId: null,
                    templateName: null,
                    subject: null,
                    campaignName:
                      emailMode && emailMode.campaignName
                        ? emailMode.campaignName
                        : null,
                    campaignId:
                      emailMode && emailMode.campaignId
                        ? emailMode.campaignId
                        : null,
                    position:
                      emailMode && emailMode.position ? emailMode.position : 0,
                    level_id:
                      emailMode && emailMode.level_id
                        ? emailMode.level_id
                        : null,
                  }}
                >
                  <i className={"ri-history-line"}></i>{" "}
                  <span>
                    {locale("pages:setting.email.Start From Scratch")}
                  </span>
                </Link>
              ) : (
                ""
                // <Button
                //   onClick={() => {
                //     setCreateFromScratch(true);
                //     handleClose();
                //   }}
                //   className='btn-ms-icon'
                // >
                //   <i className={'ri-history-line'}></i>{' '}
                //   <span>
                //     {locale('pages:setting.email.Start From Scratch')}
                //   </span>
                // </Button>
              )}
            </Col>
          </Row>
        )}
        <Tab.Container
          activeKey={activeTab}
          onSelect={(e) => {
            setActiveTab(e);
          }}
        >
          <div className={"tab-header bg-white"}>
            <Nav>
              {emailTab.length
                ? emailTab.map((item, idx) => (
                    <Nav.Item key={item.name}>
                      <Nav.Link eventKey={item.name}>
                        {item.icon} {locale(item.label)}
                      </Nav.Link>
                    </Nav.Item>
                  ))
                : ""}
            </Nav>
          </div>

          <Tab.Content>
            {emailTab.length
              ? emailTab.map((itemTab, idx) => (
                  <Tab.Pane eventKey={itemTab.name} key={idx}>
                    <Row>
                      {emailTemplates.length
                        ? emailTemplates.map(
                            (item, idx) =>
                              item.type === itemTab.name &&
                              industry === item.industry && (
                                <Col md="4" lg="3" key={idx}>
                                  <div
                                    className={"view-email-templates ".concat(
                                      activeIndex === idx ? "active" : ""
                                    )}
                                    onClick={() => {
                                      setActiveIndex(idx);
                                      setSelectedEmail(item);
                                    }}
                                  >
                                    <figure>
                                      <img src={item.imageSrc} alt="" />
                                      <div className="template-preview">
                                        <span
                                          onClick={() => {
                                            !loading &&
                                              previewEmailTemplates(item);
                                          }}
                                        >
                                          <i className="ri-eye-fill"></i>
                                        </span>
                                      </div>
                                    </figure>

                                    <h4>{locale(item.name)}</h4>
                                  </div>
                                </Col>
                              )
                          )
                        : ""}
                    </Row>
                  </Tab.Pane>
                ))
              : ""}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={"btn btn-primary  btn-ms-icon ".concat(
            activeIndex !== null ? "" : "btn-disable"
          )}
          onClick={() => {
            activeIndex !== null && handleCreateClone();
          }}
          disabled={loading || activeIndex === null}
        >
          <span>{locale("next")}</span>{" "}
          <i className={"ri-arrow-right-line"}></i>
        </Button>
      </Modal.Footer>
    </>
  );
};

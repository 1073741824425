import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";

export const AutomateJobs = ({ fullSetup, setFullSetup = () => {} }) => {
  const [convertToJob, setConvertToJob] = useState({
    fromEstimate: false,
    fromBooking: false,
  });

  const handleNext = () => {
    setFullSetup({
      ...fullSetup,
      automateJobs: {
        ...fullSetup.automateJobs,
        status: "completed",
      },
    });
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Body className="px-3 py-3">
          <h4 className="text-primary">
            Now that you've set up the business flow to automate your business,
            lets automate jobs.
          </h4>
          <div>
            <Form.Group className="form-group mb-4">
              <Form.Label>
                Would you like to automatically convert an estimate to a job
                once it is approved by the client?
              </Form.Label>
              <div className="d-flex">
                <Form.Check
                  className="me-2"
                  type="radio"
                  label={"Yes"}
                  onClick={() =>
                    setConvertToJob({ ...convertToJob, fromEstimate: true })
                  }
                  checked={convertToJob?.fromEstimate}
                  onChange={() => {}}
                />
                <Form.Check
                  className="ms-2"
                  type="radio"
                  label={"No"}
                  onClick={() =>
                    setConvertToJob({ ...convertToJob, fromEstimate: false })
                  }
                  checked={!convertToJob?.fromEstimate}
                  onChange={() => {}}
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>
                Would you like to automatically convert online bookings to job?
              </Form.Label>
              <div className="d-flex">
                <Form.Check
                  className="me-2"
                  type="radio"
                  label={"Yes"}
                  onClick={() =>
                    setConvertToJob({ ...convertToJob, fromBooking: true })
                  }
                  checked={convertToJob?.fromBooking}
                  onChange={() => {}}
                />
                <Form.Check
                  className="ms-2"
                  type="radio"
                  label={"No"}
                  onClick={() =>
                    setConvertToJob({ ...convertToJob, fromBooking: false })
                  }
                  checked={!convertToJob?.fromBooking}
                  onChange={() => {}}
                />
              </div>
            </Form.Group>
          </div>
        </Card.Body>
        <Card.Footer className="bg-dark">
          <div className="text-end">
            <Button onClick={handleNext}>Next</Button>
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
};

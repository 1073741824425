import { gql } from "@apollo/client";

// /** Show Gallery Data **/
// export const IMAGE_GALLERY = gql`
//   query getGalleryFiles($team_id: Int) {
//     allGalleryFiles(team_id: $team_id) {
//       id
//       team_id
//       user_id
//       name
//       attachment {
//         id
//         name
//         path
//         size
//         url
//         source
//       }
//
//     }
//   }
// `;
/** Show Gallery Data **/
export const IMAGE_GALLERY = gql`
  query getGalleryFiles($team_id: Int) {
    allGalleryFiles(team_id: $team_id) {
      images
      videos
      audios
      #others
      attachment_images {
        path
        name
        size
        mime_type
        source
        disk
        attachment_id
        url
      }
      others
    }
  }
`;
/** Upload Gallery Data **/
export const IMAGE_UPLOAD = gql`
  mutation uploadImage(
    $team_id: Int
    $name: String
    $file: [Upload]
    $urls: [String]
  ) {
    saveEmailTemplateGallery(
      team_id: $team_id
      name: $name
      file: $file
      urls: $urls
    ) {
      attachment {
        id
        name
        url
      }
    }
  }
`;
/** Remove Gallery Data **/
export const IMAGE_REMOVE = gql`
  mutation removeGalleryImage($team_id: Int, $id: Int) {
    removeEmailTemplateGalleryImage(team_id: $team_id, id: $id) {
      status
    }
  }
`;

import React, { useState } from 'react';
import { Modal, Row, Col, Button, Form } from 'react-bootstrap';
import InLineError from '../../Common/AlertMessage/InLineError';
import { MailMerge } from '../../Common/Editor/Component/NewComponents/MailMerge';

export const CreateEmail = ({
  template,
  handleClose,
  industry,
  team_id,
  subject,
  setSubject,
  name,
  setName,
  handleCloseCreateEmail = null,
  emailDetails = null,
  setEmailDetails = () => {},
  setShowSubjectPopup = () => {},
  locale,
}) => {
  const [error, setError] = useState('');
  /*********************/

  const handleNext = () => {
    if (emailDetails !== null && emailDetails.mode === 'update') {
      if (subject && name) {
        setEmailDetails({
          ...emailDetails,
          templateName: name,
          subject: subject,
        });
        setShowSubjectPopup(false);
      } else if (!name) {
        setError({
          error: 'name',
          msg: locale('messages:formik.{{name}} is required', {
            name: locale('name'),
          }),
        });
      } else if (!subject) {
        setError({
          error: 'subject',
          msg: locale('messages:formik.{{name}} is required', {
            name: locale('subject'),
          }),
        });
      }
    } else {
      if (subject && name) {
        handleClose();
      } else if (!name) {
        setError({
          error: 'name',
          msg: locale('messages:formik.{{name}} is required', {
            name: locale('name'),
          }),
        });
      } else if (!subject) {
        setError({
          error: 'subject',
          msg: locale('messages:formik.{{name}} is required', {
            name: locale('subject'),
          }),
        });
      }
    }
  };

  const getMailMergeValue = (value) => {
    const input = document.getElementById('subject');
    const myValue = input.value;
    input.value = myValue.concat(value);
  };
  /*****/
  return (
    <React.Fragment>
      <Modal.Header className={'bg-primary'}>
        <Modal.Title className={'text-white secondary-font text-uppercase'}>
          {locale('pages:setting.email.Create Email')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h4 className={'text-primary font-weight-semibold mb-3'}>
              {locale('pages:setting.email.Create Email Template')}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col className='ms-auto'>
            <Form.Group className='form-group'>
              <Form.Label>{locale('Name')}</Form.Label>
              <Form.Control
                id={'name'}
                type='text'
                placeholder={locale('Enter name')}
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
              {error.error === 'name' && (
                <InLineError
                  type='error'
                  message={error.msg}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='form-group'>
              <Form.Label>{locale('Subject')}</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    id={'subject'}
                    type='text'
                    placeholder={locale('Enter Subject')}
                    defaultValue={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  {error.error === 'subject' && (
                    <InLineError
                      type='error'
                      message={error.msg}
                    />
                  )}
                </Col>
                <Col xs={'auto'}>
                  <MailMerge
                    mode='create-popup'
                    getMailMergeValue={getMailMergeValue}
                    locale={locale}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-4'></Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={'btn btn-primary btn-w-icon btn-ms-icon'}
          onClick={handleNext}
        >
          <span>{locale('next')}</span>{' '}
          <i className={'ri-arrow-right-line'}></i>
        </Button>
        {handleCloseCreateEmail !== null && (
          <Button
            className={'btn btn-primary btn-w-icon btn-ms-icon'}
            onClick={handleCloseCreateEmail}
          >
            <span>{locale('cancel')}</span>
          </Button>
        )}
      </Modal.Footer>
    </React.Fragment>
  );
};

import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { smilyAndPeople } from './EmojisList';

export const Emojis = ({ getEmoji }) => {
  const [emojis, setEmojis] = useState(null);
  const getSmily = () => {
    const emojiList = [...smilyAndPeople].map(
      (item, idx) =>
        item && (
          <Button
            variant={'outline'}
            className='p-2 cursor-pointer'
            style={{ fontSize: '1.5rem' }}
            key={idx}
            onClick={() => getEmoji(item)}
          >
            {item}
          </Button>
        )
    );
    return emojiList;
  };

  /*******/
  useEffect(() => {
    setEmojis(getSmily());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /*******/
  return (
    <Card style={{ height: '200px', overflow: 'auto' }}>
      <Card.Body>{emojis ? emojis : ''}</Card.Body>
    </Card>
  );
};

import React from "react";
import { Form, Col, Row } from "react-bootstrap";

const BetweenNumber = ({
  placeholder = "ie 123",
  label,
  labelVisibility = true,
  value,
  valueSecond,
  handleChange,
  handleChangeSecond,
  options,
  isError,
}) => {
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className={!labelVisibility && "invisible"}>
              From
            </Form.Label>
            <Form.Control
              className={isError.value ? "is-invalid" : ""}
              type="number"
              placeholder={placeholder}
              value={value && value}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </Form.Group>
        </Col>
        <Col xs="auto">
          <Form.Group className="form-group">
            <Form.Label className={!labelVisibility && "invisible"}>
              {" "}
            </Form.Label>
            <div>
              <Form.Label className={"mt-3"}>TO</Form.Label>
            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className={!labelVisibility && "invisible"}>
              To
            </Form.Label>
            <Form.Control
              className={isError.secondValue ? "is-invalid" : ""}
              type="number"
              placeholder={placeholder}
              value={valueSecond && valueSecond}
              onChange={(e) => {
                handleChangeSecond(e);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default BetweenNumber;

import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import Iframe from "react-iframe";
import { getFileExtensionFromUrl } from "../../../Utils/utils"; // Assuming this gives file extension or MIME type

const viewerStyle = {
  border: "0px solid #ccc",
  padding: "0px",
  margin: "0px",
};

export const DocumentViewer = ({
  showViewer,
  setShowViewer,
  docUrl,
  mimeType = null,
}) => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadDocument = async () => {
      setError(null);
      if (!docUrl || !docUrl.url) {  // Check that docUrl and docUrl.url exist
        setError("Document URL is not provided or is incorrectly structured.");
        setIsLoading(false);
        return;
      }

      try {
        if (mimeType) {
          handleMimeType(mimeType); // Use provided MIME type
        } else {
          const fileExtension = await getFileExtensionFromUrl(docUrl.url); // Ensure docUrl.url is being used correctly
          if (fileExtension?.type) {
            handleMimeType(fileExtension.type);
          } else {
            setError("Unable to determine file type.");
          }
        }
      } catch (error) {
        console.error("Error fetching file extension: ", error);
        setError("Failed to load the document.");
      } finally {
        setIsLoading(false);
      }
    };

    const handleMimeType = (type) => {
      const decodedUrl = decodeURIComponent(docUrl.url); // Ensure docUrl.url is correct

      if (type.startsWith("image")) {
        setUrl(decodedUrl); // Render image directly
      } else if (type.startsWith("video")) {
        setUrl(decodedUrl); // Render video directly
      } else if (type.startsWith("audio")) {
        setUrl(decodedUrl); // Render audio directly
      } else if (type === "application/pdf" || type === "pdf") {
        setUrl(decodedUrl); // Render PDF directly
      } else if (type.startsWith("text")) {
        setUrl(decodedUrl); // Render text files directly
      } else {
        // Fallback to Google Docs Viewer for unsupported formats
        setUrl(
          `https://docs.google.com/viewer?url=${decodedUrl}&embedded=true`
        );
      }
    };

    loadDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docUrl, mimeType]);

  return (
    <Modal
      show={showViewer}
      onHide={() => setShowViewer(false)}
      animation={false}
      className={"common modalXl"}
      style={{ zIndex: "9999" }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className={"bg-primary"}>
        <Modal.Title className={"text-white secondary-font text-uppercase"}>
          Document Viewer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
            <Spinner animation="border" variant="primary" />
            <span className="ms-2">Loading document...</span>
          </div>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : url ? (
          <Iframe
            url={url}
            width="100%"
            height={window.innerHeight - 100}
            display="initial"
            position="relative"
            frameBorder={0}
            style={viewerStyle}
          />
        ) : (
          <Alert variant="warning">Unable to load document.</Alert>
        )}
      </Modal.Body>
    </Modal>
  );
};

import { gql } from "@apollo/client";

/** Lead owner listing **/
export const ADD_CUSTOM_ATTRIBUTE = gql`
  mutation createCustomAttribute(
    $label: String
    $name: String
    $type: String
    $attributes: String
    $attributableType: String
    $required: Boolean
    $active: Boolean
    $tooltip: String
    $inMailmerge: Boolean
    $team_id: Int
  ) {
    createCustomAttribute(
      label: $label
      type: $type
      name: $name
      attributes: $attributes
      attributable_type: $attributableType
      required: $required
      active: $active
      tooltip: $tooltip
      in_mailmerge: $inMailmerge
      team_id: $team_id
    ) {
      id
      team_id
      label
      type
      name
      attributes
      required
      tooltip
      active
      attributable_type
      in_mailmerge
      created_at
      updated_at
    }
  }
`;

export const LIST_CUSTOM_ATTRIBUTE = gql`
  query customAttributesList($module: String, $team_id: Int, $search: String) {
    customAttributes(
      attributable_type: $module
      team_id: $team_id
      search: $search
    ) {
      id
      team_id
      label
      type
      name
      attributes
      required
      tooltip
      active
      attributable_type
      in_mailmerge
      created_at
      updated_at
      custom_attribute_id
    }
  }
`;

export const UPDATE_CUSTOM_ATTRIBUTE = gql`
  mutation updateCustomAttribute(
    $id: Int
    $label: String
    $attributes: String
    $required: Boolean
    $tooltip: String
    $inMailmerge: Boolean
    $active: Boolean
    # $type: String
    $team_id: Int
  ) {
    updateCustomAttribute(
      id: $id
      label: $label
      attributes: $attributes
      required: $required
      tooltip: $tooltip
      in_mailmerge: $inMailmerge
      active: $active
      team_id: $team_id
    ) {
      id
      team_id
      label
      type
      name
      attributes
      required
      tooltip
      active
      attributable_type
      in_mailmerge
      created_at
      updated_at
    }
  }
`;

export const MANAGE_LOCATION_API = gql`
  mutation manageLocationCredential(
    $team_id: Int
    $integrated_with: String
    $integration_api_key: String
    $status: Boolean
  ) {
    manageLocationCredential(
      team_id: $team_id
      integrated_with: $integrated_with
      integration_api_key: $integration_api_key
      status: $status
    ) {
      id
      team_id
      integrated_with
      integration_api_key
      status
      created_at
      updated_at
    }
  }
`;

export const GET_LOCATION_API = gql`
  query fetchLocationCredential($team_id: Int) {
    fetchLocationCredential(team_id: $team_id) {
      id
      team_id
      integrated_with
      integration_api_key
      status
      created_at
      updated_at
    }
  }
`;

export const GET_PUBLIC_LOCATION_API = gql`
  query fetchlocation($team_id: Int) {
    fetchlocation(team_id: $team_id) {
      integrated_with
      integration_api_key
      status
    }
  }
`;

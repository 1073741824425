import * as Yup from "yup";
import {
  isObjectEmpty,
  toWordCase,
  validateMyPhoneInput,
  validatePhoneNumber,
  validateUrl,
} from "./utils";

// const textRegex =
//   "^[a-zA-Z0-9-#*&^$+=|~?(){}%&@!\"<>/\\',`_.-:;\\sñáéíóúü /]*$";

// const numberRegex = "^[0-9]*$";
// const nameRegex = "^[a-zA-Z]*$";
// const nameRegex = "^[a-zA-Z0-9.-_\\s /]*$";
// const textArea = "^[a-zA-Z0-9-#*&^$+=|~?(){}%&@!\"<>/\\',`_.-:;\\sñáéíóúü /]*$";
// const password = "^[a-zA-Z0-9-#*&^$+=|~?(){}%&@!\"<>/\\',`_.-:;\\s /]*$";

// const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/im;

export const getValidationSchema = (
  fieldsToBeValidate,
  locale,
  convertedToYup = true
) => {
  let validationSchema = {};

  fieldsToBeValidate.forEach((field) => {
    const name = field.label
      ? field.label
      : toWordCase(
          ["product_id", "contact_id", "job_id"].indexOf(field.name) > -1
            ? field.name.replace("_id", "")
            : field.name
        ).toLowerCase();

    const maxLength = field.maxLength ? field.maxLength : 255;
    const minLength = field.minLength ? field.minLength : 3;
    let fieldSchema = null;

    /** FIELD TYPE **/
    if (field.type === "number") {
      fieldSchema = Yup.number();
    } else if (field.type === "duration") {
      fieldSchema = Yup.object();
    } else if (field.type === "object") {
      fieldSchema = Yup.object();
    } else if (field.type === "array") {
      fieldSchema = Yup.array();
    } else if (field.type === "email") {
      fieldSchema = Yup.string().email(locale("messages:formik.Invalid email"));
    } else {
      fieldSchema = Yup.string();
    }

    /** Is Field Required or Not **/
    if (field.isRequired) {
      fieldSchema = fieldSchema.required(
        locale("messages:formik.{{name}} is required", {
          name: locale(name),
        })
      );
    }

    /** Is Field Nullable or Not **/
    if (field.isNullable) {
      if (field.type === "object") {
        fieldSchema = fieldSchema.shape({
          value: Yup.string().nullable(),
        });
      } else {
        fieldSchema = fieldSchema.nullable();
      }
    }

    /** Has Field any maxLength or Not **/
    if (field.maxLength !== null) {
      if (field.type === "object") {
        fieldSchema = fieldSchema.shape({
          value: Yup.string().max(
            maxLength,
            locale(
              "messages:formik.{{name}} max {{maxLength}} character long!",
              {
                name: locale(name),
                maxLength,
              }
            )
          ),
        });
      } else {
        fieldSchema = fieldSchema.max(
          maxLength,
          locale("messages:formik.{{name}} max {{maxLength}} character long!", {
            name: locale(name),
            maxLength,
          })
        );
      }
    }

    /** Has Field any minLength or Not **/
    if (field.minLength !== null) {
      if (field.type === "object") {
        fieldSchema = fieldSchema.shape({
          value: Yup.string().min(
            minLength,
            locale(
              "messages:formik.{{name}} min {{minLength}} character long!",
              {
                name: locale(name),
                minLength,
              }
            )
          ),
        });
      } else {
        fieldSchema = fieldSchema.min(
          minLength,
          locale("messages:formik.{{name}} min {{minLength}} character long!", {
            name: locale(name),
            minLength,
          })
        );
      }
    }

    /** Check Format **/
    if (field.type === "text") {
      // fieldSchema = fieldSchema;
      // const msg = field.isCustomField
      //   ? "{{name}} can contains # % & @ ! \" ' , _ . { } characters only."
      //   : "{{name}} can contains # , - _  / . characters only.";
      // fieldSchema = fieldSchema.matches(
      //   field.isCustomField ? textArea : textRegex,
      //   locale("messages:formik." + msg, { name: locale(name) })
      // );
    } else if (field.type === "textarea") {
      // fieldSchema = fieldSchema;
      //   .matches(
      //   textArea,
      //   locale(
      //     "messages:formik.{{name}} can contains # % & @ ! \" ' , _ . { } characters only.",
      //     { name: locale(name) }
      //   )
      // );
    } else if (field.type === "password") {
      // fieldSchema = fieldSchema;
      //   .matches(
      //   password,
      //   locale(
      //     "messages:formik.{{name}} can contains ~ ? () {} % & @ ! \" <> / \\ ' , _ . - : ; characters only.",
      //     { name: locale(name) }
      //   )
      // );
    } else if (field.type === "name") {
      // fieldSchema = fieldSchema;
      //   .matches(
      //   nameRegex,
      //   locale(
      //     "messages:formik.{{name}} can contains alphanumeric and white-space - _ . characters only.",
      //     { name: locale(name) }
      //   )
      // );
    } else if (field.type === "textArea") {
      // fieldSchema = fieldSchema;
      //   .matches(
      //   textArea,
      //   locale(
      //     "messages:formik.{{name}} can contains # % & @ ! \" ' , _ . { } characters only.",
      //     { name: locale(name) }
      //   )
      // );
    } else if (field.type === "url") {
      fieldSchema = fieldSchema.test(
        "match", // this is used internally by yup
        locale("messages:formik.It does not look like a website url.", {
          name: locale(name),
        }),
        (value) => {
          if (
            (isObjectEmpty(field.isNullable) || field.isNullable === true) &&
            (isObjectEmpty(value) || ["http://", "https://"].includes(value))
          ) {
            return true;
          } else if (value && validateUrl(value)) {
            return true;
          } else {
            return false;
          }
        }
      );
    } else if (field.type === "phone") {
      fieldSchema = fieldSchema.test(
        "match", // this is used internally by yup
        locale("messages:formik.{{name}} has invalid format", {
          name: locale("phone"),
        }),
        (value) => {
          console.log(
            "validateMyPhoneInput(value, true)::",
            validateMyPhoneInput(value, true)
          );
          if (isObjectEmpty(value)) {
            return true;
          } else if (validateMyPhoneInput(value, true)) {
            return true;
          } else {
            return false;
          }
        }
      );
    } else if (field.type === "object") {
      fieldSchema = fieldSchema.shape({
        value: Yup.string(),
        //   .matches(
        //   textRegex,
        //   locale(
        //     "messages:formik.{{name}} can contains # , - _  / . characters only.",
        //     { name: locale(name) }
        //   )
        // ),
      });
    } else if (field.type === "array") {
      fieldSchema = fieldSchema.test(
        "match", // this is used internally by yup
        locale("messages:formik.{{name}} is required", { name: locale(name) }),
        (value) => {
          if (value && value.length > 0) {
            return true;
          } else {
            return field.isNullable ? true : false;
          }
        }
      );
    } else if (field.type === "duration") {
      fieldSchema = fieldSchema.test(
        "match", // this is used internally by yup
        locale("messages:formik.{{name}} is required", {
          name: locale(name),
        }),
        (value) => {
          if (
            (isObjectEmpty(field.isNullable) || field.isNullable === true) &&
            isObjectEmpty(value)
          ) {
            return false;
          } else if (
            !isObjectEmpty(value) &&
            !isObjectEmpty(value.unit) &&
            !isObjectEmpty(value.value)
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
    } else if (
      ["number", "integer", "decimal", "percentage"].indexOf(field.type) > -1
    ) {
      // fieldSchema = fieldSchema.matches(
      //   numberRegex,
      //   locale("messages:formik.{{name}} can contains numeric value only", {
      //     name: locale(name),
      //   })
      // );
    }

    validationSchema = { ...validationSchema, [field.name]: fieldSchema };
  });

  if (convertedToYup) {
    return Yup.object().shape({ ...validationSchema });
  } else {
    return validationSchema;
  }
};

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { WhatsappTemplateDropdown } from "../../../../../Components/WhatsAppManagement/WhatsappTemplateDropdown";
import { Button } from "react-bootstrap";
import {
  getMailMergeParamsForWhatsapp,
  isTemplateContainMedia,
} from "../../../../../Components/WhatsAppManagement/WaBaUtil";

export const SendWhatsappAction = ({
  handleAlertMessage = () => {},
  values = null,
  handleChange,
  handleClose,
}) => {
  const { t: locale } = useTranslation();
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [whatsappDetail, setWhatsappDetail] = useState({
    whatsapp_template_id: null,
  });
  const [errorBag, setErrorBag] = useState(null);

  useEffect(() => {
    if (values?.whatsapp_detail) {
      setWhatsappDetail({ ...values?.whatsapp_detail });
    }
  }, [values]);

  useEffect(() => {
    if (whatsappDetail?.whatsapp_template_id) {
      setSelectedTemplateId({
        template_id: whatsappDetail?.whatsapp_template_id,
      });
    }
  }, [whatsappDetail]);

  const handleChange1 = async (key, value) => {
    let componentParams = null;
    let hasMedia = false;
    if (key === "whatsapp_template_id" && value?.id) {
      componentParams = await getMailMergeParamsForWhatsapp(
        value ? value : null
      );
      hasMedia = isTemplateContainMedia(value ? value : null);
    }
    await setWhatsappDetail((prevState) => ({
      ...prevState,
      [key]:
        value && Array.isArray(value)
          ? value.map((item) => item.value)
          : value?.value || null,
      [key + "_label"]: value.label,
      ...(key === "whatsapp_template_id"
        ? {
            template_name: value?.name || "",
            component_params: componentParams,
            has_media: hasMedia,
            language: value && value.language ? value.language : null,
          }
        : {}),
    }));
  };

  const handleSaveData = () => {
    let newErrors = {};
    if (!whatsappDetail?.whatsapp_template_id) {
      newErrors = {
        ...newErrors,
        whatsapp_template_id: "Whatsapp template is required.",
      };
    }
    setErrorBag(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let str = [];
      str.push(<span key="1">Send </span>);
      str.push(
        <span key="2" className="text-primary">
          {whatsappDetail?.whatsapp_template_id_label || ""}
        </span>
      );
      str.push(<span key="3"> message</span>);
      handleChange("action", {
        action: "send_whatsapp_message",
        whatsapp_detail: whatsappDetail,
        string: str,
      });
      handleClose();
    }
  };
  const handelCancel = () => {
    handleClose();
  };
  /*****/
  return (
    <React.Fragment>
      <div className="mx-2 p-1">
        <WhatsappTemplateDropdown
          handleAlertMessage={() => {}}
          selectedTemplate={whatsappDetail?.whatsapp_template_id || null}
          setSelectedTemplate={(e) => handleChange1("whatsapp_template_id", e)}
          locale={locale}
          previewMode={"button"}
          selectedTemplateId={selectedTemplateId}
          setSelectedTemplateId={setSelectedTemplateId}
          error={null}
          label={"Select Template"}
        />
      </div>
      <div className="bg-dark text-end sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

import React, { useEffect, useState } from "react";
import { SelectDropdown } from "../../../../../Components/Common/Conditions/SelectDropdown";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { currentTeamId } from "../../../../../Utils/utils";
import useAgreementTemplateModal from "../../../../../Hooks/useAgreementTemplateModal";
import { DocumentViewer } from "../../../../../Components/Common/DocumentViewer/DocumentViewer";
import { AgreementTemplatesListing } from "../../../../../Components/Modals/AgreementTemplatesListing";
import { getTemplatesList } from "../../../../../Components/Agreement/AgreementHelper";
import { CreateAgreementPopup } from "../../../../../Components/Agreement/Agreements/CreateAgreementPopup";
import { ChooseReviewer } from "../../../../../Components/Agreement/Agreements/ChooseReviewer";
import { AgreementClone } from "../../../../../Components/Agreement/Agreements/AgreementClone";
import { useTranslation } from "react-i18next";

export const SendAgreementAction = ({
  handleAlertMessage = () => {},
  currentCompanySetting = null,
  values = null,
  handleChange,
  handleClose,
  openCreateAgreementPopup = false,
}) => {
  const { t: locale } = useTranslation();
  const [agreementDetails, setAgreementDetails] = useState(null);
  const [agreements, setAgreements] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [pageParams] = useState({
    team_id: currentTeamId,
    search: "",
    first: 100,
    page: 1,
    field: "created_at",
    order: "DESC",
  });

  const [showViewer, setShowViewer] = useState(false);
  const [docUrl, setDocUrl] = useState(null);
  const [showTemplateClone, setShowTemplateClone] = useState(false);
  const [cloneableAgreement, setCloneableAgreement] = useState(null);
  const [agreement, setAgreement] = useState(null);
  const [sendForReview, setSendForReview] = useState(null);
  const [errorBag, setErrorBag] = useState(null);
  const {
    agreementTemplateModalVisible,
    agreementTemplatesList,
    templateModalLoading,
    fetchTemplateData,
    showAgreementTemplateModal,
    hideAgreementTemplateModal,
  } = useAgreementTemplateModal();

  const handleClosePopup = () => setShowPopup(false);

  const fetchAgreements = () => {
    getTemplatesList({ team_id: currentTeamId, ...pageParams })
      .then((result) => {
        if (result.status) {
          const data = result.data;
          // setPageInfo({
          const templateData = data.data.map(
            ({ agreement_template_id, name, ...rest }) => ({
              value: agreement_template_id,
              label: name,
            })
          );
          setAgreements(templateData);
        }
      })
      .catch((error) => {
        console.log("error", error.message);
      });
  };

  const handleCloseClone = () => {
    setCloneableAgreement(null);
    setShowTemplateClone(false);
    fetchAgreements();
  };

  useEffect(() => {
    if (openCreateAgreementPopup) {
      showAgreementTemplateModal();
    }
  }, [openCreateAgreementPopup]);

  useEffect(() => {
    fetchAgreements();
  }, []);

  useEffect(() => {
    if (docUrl !== null) {
      setTimeout(() => {
        setShowViewer(true);
      }, 200);
    }
  }, [docUrl]);

  const handlePreview = (value) => {
    if (value?.agreement_versions?.length > 0) {
      value.agreement_versions.forEach((item) => {
        if (item.attachment && item.attachment.url) {
          if (
            item.type === "final_version" &&
            item.attachment.mime_type === "application/pdf"
          ) {
            setDocUrl({ url: item.attachment.url });
          }
        }
      });
    }
  };

  const closePreview = () => {
    setDocUrl(null);
    setShowViewer(false);
  };

  const handleSaveData = () => {
    if (!agreementDetails?.agreement_id) {
      setErrorBag({ agreement_id: "Agreement is required" });
      return;
    } else {
      setErrorBag(null);
      let str = [];
      str.push(<span key="1">Send </span>);
      str.push(
        <span key="2" className="text-primary">
          {agreementDetails.agreement_name}{" "}
        </span>
      );
      str.push(<span key="3"> agreement </span>);
      handleChange("action", {
        action: "send_agreement",
        agreement_details: agreementDetails,
        string: str,
      });
      handleClose();
    }
  };

  const handleDetail = (type, e) => {
    if (type === "name") {
      setAgreementDetails({
        agreement_id: e?.value || "",
        agreement_name: e?.label || "",
      });
      setErrorBag(null);
    }
  };

  useEffect(() => {
    if (values?.agreement_details) {
      setAgreementDetails(values.agreement_details);
    }
  }, [values]);

  const handelCancel = () => {
    handleClose();
  };
  /******/
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col className="mt-3">
            <SelectDropdown
              label="Select Agreement Template"
              options={agreements}
              value={agreementDetails?.agreement_id || null}
              handleChange={(e) => handleDetail("name", e)}
            />
            <InLineError
              extraStyle={{ marginTop: "-15px" }}
              type="error"
              name="agreement_id"
              message={errorBag?.agreement_id || ""}
            />
            <div className="text-end">
              <Button
                variant="outline-primary"
                onClick={() => setShowPopup(true)}
              >
                Create Agreement Template
              </Button>
            </div>
          </Col>
        </Row>
        <div className="text-end bg-dark sticky-footer">
          <Button
            variant="outline-primary"
            className="me-2"
            onClick={handleSaveData}
          >
            Save
          </Button>
          <Button variant="outline-secondary" onClick={handelCancel}>
            Cancel
          </Button>
        </div>
      </Container>
      {/* Document Viewer */}
      <DocumentViewer
        showViewer={showViewer}
        setShowViewer={(value) => closePreview()}
        docUrl={docUrl}
      />

      {/** Choose Contact **/}
      <AgreementClone
        show={showTemplateClone}
        handleCloseClone={handleCloseClone}
        locale={locale}
        type={{
          name: "agreement",
          label: "Agreement",
          icon: "ri-draft-line",
          type: "agreement",
        }}
        agreement={cloneableAgreement}
        contactId={null}
      />
      {/*** Select Template For Agreement ***/}
      <Modal
        className={"common modalXl fixed-height-with-scroll"}
        show={agreementTemplateModalVisible}
        onHide={hideAgreementTemplateModal}
        backdrop="static"
        keyboard={false}
      >
        {agreementTemplateModalVisible && (
          <AgreementTemplatesListing
            locale={locale}
            templateModalLoading={templateModalLoading}
            fetchTemplateData={fetchTemplateData}
            hideAgreementTemplateModal={hideAgreementTemplateModal}
            agreementTemplatesList={agreementTemplatesList}
            handlePreview={handlePreview}
            setCloneableAgreement={setCloneableAgreement}
            contactId={null}
            cId={null}
            cameFrom="agreement-listing"
          />
        )}
      </Modal>

      {/*** For Template Page ***/}
      <CreateAgreementPopup
        showPopup={showPopup}
        handleClosePopup={handleClosePopup}
        contactDetails={null}
        name={{
          name: "agreement",
          label: "Agreement",
          icon: "ri-draft-line",
          type: "agreement",
        }}
        cameFrom="agreement-listing"
      />
      <ChooseReviewer
        show={sendForReview}
        agreement={agreement}
        handleClose={handleClose}
        fetchData={fetchAgreements}
      />
    </React.Fragment>
  );
};

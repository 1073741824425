import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from "react-bootstrap";
import { getDateByUserFormat } from "../../Utils/CurrentSetting";
import {
  createMarkup,
  defaultImage,
  downloadFile,
  findAndReplaceUrl,
  findUrlInText,
  getFileExtension,
  isObjectEmpty,
} from "../../Utils/utils";
import { MetaDetailsInfo } from "../SocialMedia/Common/MetaDetailsInfo";
import "./whatsapp.scss";
import InLineError from "../Common/AlertMessage/InLineError";
import { MediaAndEmojis } from "../Common/MediaAndEmojis/MediaAndEmojis";

export const whatsappTemplateCategories = [
  "introduction",
  "booking_and_appointments",
  "payments",
  "promotional",
  "newsletter",
];
export const messageStatus = {
  error: <i className="ri-error-warning-line text-danger" />,
  sent: <i className="ri-check-fill whatsapp-footer-text-color" />,
  read: <i className="ri-check-double-fill text-info" />,
  delivered: <i className="ri-check-double-fill whatsapp-footer-text-color" />,
};
const uploadLimit = {
  images: 1,
  video: 1,
  gif: 1,
  audio: 1,
  document: 1,
};

export const ChatRoom = ({
  messages,
  currentCompanySetting,
  profilePicture,
  templateList,
  getReaction,
  contactProfilePic,
  saveToDocuments = () => {},
  setDocUrl = () => {},
}) => {
  const [myMessages, setMyMessages] = useState([]);
  const [reactions, setReactions] = useState([]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      const myReactions = [];
      const others = [];
      messages.forEach((item) => {
        if (item.type === "reaction") {
          myReactions.push(item);
        } else {
          others.push(item);
        }
      });
      setMyMessages(others);
      setReactions(myReactions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  return (
    <React.Fragment>
      {myMessages && myMessages.length > 0
        ? myMessages.map((message) => (
            <div
              key={message.id}
              className={
                message.direction === "in"
                  ? "message message-left"
                  : "message message-right"
              }
            >
              <div className="message-inner">
                <div className="avtar avtar-small">
                  <img
                    src={
                      message.direction === "in"
                        ? contactProfilePic
                        : profilePicture
                    }
                    alt=""
                  />
                </div>
                <div className="message-text">
                  {getMessageBody(
                    message,
                    templateList,
                    [],
                    saveToDocuments,
                    setDocUrl
                  )}
                  {getMessageFooter(message, currentCompanySetting)}
                  {getReactedMessage(message.message_id, reactions)}
                </div>
                <div className="action-on-message">
                  {message.direction === "in" && (
                    <Reaction
                      getEmoji={(emoji) => getReaction(emoji, message)}
                      message={message}
                      emoji={getReactedMessage(
                        message.message_id,
                        reactions,
                        "text"
                      )}
                    />
                  )}

                  {false && message.direction === "out" && (
                    <i className="ri-reply-fill ri-xl cursor-pointer"></i>
                  )}
                  {/* {message.direction === "in" && (
                    <i
                      className="ri-share-forward-fill ri-xl cursor-pointer"
                      onClick={() => console.log(message)}
                    ></i>
                  )} */}
                </div>
              </div>
            </div>
          ))
        : ""}
    </React.Fragment>
  );
};

/*** Show Message Description ***/
export const getMessageBody = (
  message,
  templateList = [],
  messages = [],
  saveToDocuments = () => {},
  setDocUrl = () => {}
) => {
  // const hasError = message.errors ? true : false;
  const messageValue = JSON.parse(message.type_value);
  const messageBody = [];
  /****/
  if (message.type === "audio") {
    let myFile = null;
    if (!isObjectEmpty(messageValue) && !isObjectEmpty(messageValue.link)) {
      myFile = (
        <audio controls autoPlay={false}>
          <source src={messageValue.link} type="audio/ogg" />
          <source src={messageValue.link} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      );
    } else if (
      !isObjectEmpty(message.attachment) &&
      !isObjectEmpty(message.attachment.url)
    ) {
      myFile = (
        <audio controls autoPlay={false}>
          <source src={message.attachment.url} type="audio/ogg" />
          <source src={message.attachment.url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      );
    }
    if (myFile !== null) {
      messageBody.push(
        <div className="m-2 text-end" key={message.type + "-" + message.id}>
          {message.direction === "in" && (
            <MessageAction
              saveToDocuments={(fileName) =>
                saveToDocuments(
                  message.attachment ? message.attachment.id : null,
                  fileName
                )
              }
              actions={["download"]}
              file={
                message.attachment && message.attachment.url
                  ? message.attachment.url
                  : messageValue.link
                    ? messageValue.link
                    : ""
              }
              filename={
                messageValue.filename ? messageValue.filename : "wa-video"
              }
              mimeType={
                messageValue && messageValue.mime_type
                  ? messageValue.mime_type
                  : null
              }
            />
          )}
          {myFile}
        </div>
      );
    }
  } else if (message.type === "video") {
    let myFile = null;
    if (!isObjectEmpty(messageValue) && !isObjectEmpty(messageValue.link)) {
      myFile = (
        <video controls autoPlay={false}>
          <source src={messageValue.link} type="video/mp4" />
          <source src={messageValue.link} type="video/ogg" />
          Your browser does not support the Video element.
        </video>
      );
    } else if (
      !isObjectEmpty(message.attachment) &&
      !isObjectEmpty(message.attachment.url)
    ) {
      myFile = (
        <video controls autoPlay={false}>
          <source src={message.attachment.url} type="video/mp4" />
          <source src={message.attachment.url} type="video/ogg" />
          Your browser does not support the Video element.
        </video>
      );
    }
    if (myFile !== null) {
      messageBody.push(
        <div className="m-2 text-end" key={message.type + "-" + message.id}>
          {message.direction === "in" && (
            <MessageAction
              saveToDocuments={(fileName) =>
                saveToDocuments(
                  message.attachment ? message.attachment.id : null,
                  fileName
                )
              }
              actions={["download"]}
              file={
                message.attachment && message.attachment.url
                  ? message.attachment.url
                  : messageValue.link
                    ? messageValue.link
                    : ""
              }
              filename={
                messageValue.filename ? messageValue.filename : "wa-video"
              }
              mimeType={
                messageValue && messageValue.mime_type
                  ? messageValue.mime_type
                  : null
              }
            />
          )}
          {myFile}
        </div>
      );
    }
  } else if (["image", "sticker"].indexOf(message.type) > -1) {
    let myFile = null;
    if (!isObjectEmpty(messageValue) && !isObjectEmpty(messageValue.link)) {
      myFile = (
        <img
          src={messageValue.link}
          alt=""
          onClick={() => setDocUrl(message.attachment.url)}
        />
      );
    } else if (
      !isObjectEmpty(message.attachment) &&
      !isObjectEmpty(message.attachment.url)
    ) {
      myFile = (
        <img
          src={message.attachment.url}
          alt=""
          onClick={() => setDocUrl({ url: message.attachment.url })}
        />
      );
    }

    if (myFile !== null) {
      messageBody.push(
        <div className="m-2 text-end" key={message.type + "-" + message.id}>
          {message.direction === "in" && (
            <MessageAction
              saveToDocuments={(fileName) =>
                saveToDocuments(
                  message.attachment ? message.attachment.id : null,
                  fileName
                )
              }
              actions={["download"]}
              file={
                message.attachment && message.attachment.url
                  ? message.attachment.url
                  : messageValue.link
                    ? messageValue.link
                    : ""
              }
              filename={
                messageValue.filename ? messageValue.filename : "wa-image"
              }
              mimeType={
                messageValue && messageValue.mime_type
                  ? messageValue.mime_type
                  : null
              }
            />
          )}
          {myFile}
        </div>
      );
    }
  } else if (message.type === "document") {
    let myDoc = null;
    const fileDetail = getFileExtension(
      messageValue.filename
        ? messageValue.filename
        : messageValue.link
          ? messageValue.link
          : ""
    );
    if (!isObjectEmpty(messageValue) && !isObjectEmpty(messageValue.link)) {
      myDoc =
        fileDetail.type === "image" ? (
          <img
            src={messageValue.link}
            className="img cursor-pointer"
            alt={messageValue.filename}
          />
        ) : (
          <i className={fileDetail.className + " ri-3x cursor-pointer"} />
        );
    } else if (
      !isObjectEmpty(message.attachment) &&
      !isObjectEmpty(message.attachment.url)
    ) {
      myDoc =
        fileDetail.type === "image" ? (
          <div className="m-2" key={message.type + "-" + message.id}>
            <img src={message.attachment.url} alt="" />
          </div>
        ) : (
          <i className={fileDetail.className + " ri-3x cursor-pointer"} />
        );
    }

    if (myDoc !== null) {
      messageBody.push(
        <div
          className="m-2 d-flex justify-content-between"
          key={message.type + "-" + message.id}
          // onClick={() => downloadFile(file, messageValue.filename)}
        >
          <div>{myDoc}</div>
          {message.direction === "in" && (
            <MessageAction
              saveToDocuments={(fileName) =>
                saveToDocuments(
                  message.attachment ? message.attachment.id : null,
                  fileName
                )
              }
              actions={["download", "save"]}
              file={
                messageValue.link
                  ? messageValue.link
                  : message.attachment && message.attachment.url
                    ? message.attachment.url
                    : ""
              }
              filename={messageValue.filename}
              mimeType={
                messageValue && messageValue.mime_type
                  ? messageValue.mime_type
                  : null
              }
            />
          )}
        </div>
      );
    }
  } else if (message.type === "template") {
    if (!isObjectEmpty(messageValue) && !isObjectEmpty(messageValue.name)) {
      const templateBody =
        message.whatsapp_message_data && message.whatsapp_message_data.data
          ? message.whatsapp_message_data.data
          : null;
      let temp = null;

      if (templateBody) {
        temp = buildTemplate(
          {
            components: JSON.stringify(JSON.parse(templateBody)),
          },
          messageValue.components
        );
      } else {
        temp = getTemplate(
          messageValue.name,
          templateList,
          messageValue.components
        );
      }

      return temp;
    }
  } else if (message.type === "button") {
    if (!isObjectEmpty(messageValue) && !isObjectEmpty(messageValue.text)) {
      messageBody.push(
        <React.Fragment key={message.type + "-" + message.id}>
          <i className="ri-share-forward-fill ri-xl cursor-pointer me-2" />
          {findUrlInText(messageValue.text, "whatsapp")}
        </React.Fragment>
      );
    }
  }

  if (message.type === "text") {
    if (!isObjectEmpty(messageValue) && !isObjectEmpty(messageValue.body)) {
      if (messageValue.preview_url && messageBody.length === 0) {
        messageBody.push(
          <MetaDetailsInfo
            message={messageValue.body}
            channel="facebook"
            key={message.type + "-meta-" + message.id}
          />
        );
      }
      messageBody.push(
        <React.Fragment key={message.type + "-" + message.id}>
          {findUrlInText(messageValue.body, "whatsapp")}
        </React.Fragment>
      );
    }
  }

  if (!isObjectEmpty(messageValue.caption)) {
    messageBody.push(
      <React.Fragment key={"caption-" + message.id}>
        {findUrlInText(messageValue.caption, "whatsapp")}
      </React.Fragment>
    );
  }
  return messageBody;
};

/*** GEt***/
export const getMessageFooter = (
  message,
  currentCompanySetting,
  onlyStatus = false
) => {
  const statuses = message.whatsapp_message_statuses;
  const error = message.errors ? JSON.parse(message.errors) : null;
  let msgStatus = null;
  if (statuses && statuses.length > 0) {
    statuses.forEach((item) => {
      if (item.status === "read") {
        msgStatus = item.status;
      } else if (msgStatus !== "read" && item.status === "delivered") {
        msgStatus = item.status;
      } else if (
        ["read", "delivered"].indexOf(msgStatus) === -1 &&
        item.status === "sent"
      ) {
        msgStatus = item.status;
      }
    });
  }

  if (onlyStatus) {
    return error === null ? message : "error";
  }

  return (
    <div>
      <span className="day-time">
        {!isObjectEmpty(error) && (
          <OverlayTrigger
            key={message.id}
            placement="top"
            overlay={<Tooltip id={`tooltip-${message.id}`}>{error}</Tooltip>}
          >
            {messageStatus["error"]}
          </OverlayTrigger>
        )}
        {!isObjectEmpty(msgStatus) &&
          (!isObjectEmpty(messageStatus[msgStatus])
            ? messageStatus[msgStatus]
            : "")}
        {getDateByUserFormat(currentCompanySetting, message.message_timestamp)}
      </span>
    </div>
  );
};

/**** CREATE TEMPLATE DESIGN ****/
export const getTemplate = (name, templateList, templateParams) => {
  let myTemplate = null;
  if (templateList && templateList.length > 0) {
    templateList.forEach((cat) => {
      cat.options.forEach((item) => {
        if (item.name === name) {
          myTemplate = buildTemplate(item, templateParams);
        }
      });
    });
  }
  return myTemplate;
};

export const buildTemplate = (
  template,
  templateParams = null,
  previewOnly = true
) => {
  let components = [];
  let exampleParams = {};
  if (
    template.components &&
    template.components.length > 0 &&
    isJson(template.components)
  ) {
    components = JSON.parse(template.components);
    if (templateParams !== null) {
      templateParams.forEach((item) => {
        if (item.type === "header") {
          if (
            item.parameters &&
            item.parameters.length > 0 &&
            ["image", "video", "document"].indexOf(item.parameters[0].type) > -1
          ) {
            const cType = item.parameters[0].type;
            const bodyParams = [
              item.parameters[0][cType] && item.parameters[0][cType].link
                ? item.parameters[0][cType].link
                : null,
              item.parameters[0][cType] && item.parameters[0][cType].filename
                ? item.parameters[0][cType].filename
                : null,
            ];
            exampleParams = { ...exampleParams, [cType]: bodyParams };
          } else {
            const bodyParams = item.parameters.map((paramItem) =>
              paramItem && paramItem.text ? paramItem.text : ""
            );
            exampleParams = { ...exampleParams, header_text: bodyParams };
          }
        } else if (item.type === "body") {
          const bodyParams = item.parameters.map((paramItem) =>
            paramItem && paramItem.text ? paramItem.text : ""
          );
          exampleParams = { ...exampleParams, body_text: bodyParams };
        }
      });
    } else {
      exampleParams =
        !isObjectEmpty(template.example_variables) &&
        isJson(template.example_variables)
          ? JSON.parse(template.example_variables)
          : null;
    }
  } else {
    components = [];
    return null;
  }

  const myTemplate = [];

  if (components.length > 0) {
    components.forEach((item) => {
      if (item.type === "HEADER") {
        if (item.format === "TEXT") {
          myTemplate.push(
            <div
              key={"header-" + template.id}
              className="font-weight-bold word-break"
            >
              {getHeaderTextWithParams(
                item.text,
                "header_text",
                exampleParams === null ? item.example : exampleParams,
                previewOnly
              )}
            </div>
          );
        } else if (item.format === "IMAGE") {
          let fileUrl = null;

          if (
            exampleParams &&
            exampleParams.image &&
            typeof exampleParams.image === "object"
          ) {
            fileUrl = exampleParams.image[0];
          } else if (
            exampleParams &&
            exampleParams.image &&
            typeof exampleParams.image === "string"
          ) {
            fileUrl = exampleParams.image;
          } else if (
            item.example &&
            item.example.header_handle &&
            item.example.header_handle.length > 0
          ) {
            fileUrl = item.example.header_handle[0];
          }

          myTemplate.push(
            <div
              key={"header-" + template.id}
              className="font-weight-bold text-center"
            >
              <img
                src={fileUrl ? fileUrl : defaultImage}
                alt="templateImage"
                style={{ width: "280px" }}
              />
            </div>
          );
        } else if (item.format === "VIDEO") {
          let fileUrl = null;

          if (
            exampleParams &&
            exampleParams.video &&
            typeof exampleParams.video === "object"
          ) {
            const videoObj = exampleParams.video
              ? exampleParams.video
              : exampleParams.image
                ? exampleParams.image
                : null;
            fileUrl = videoObj[0];
          } else if (
            exampleParams &&
            exampleParams.video &&
            typeof exampleParams.video === "string"
          ) {
            fileUrl = exampleParams.video;
          } else if (
            item.example &&
            item.example.header_handle &&
            item.example.header_handle.length > 0
          ) {
            fileUrl = item.example.header_handle[0];
          }

          myTemplate.push(
            <div key={"header-" + template.id} className="font-weight-bold">
              <div
                style={{
                  background: "#fff",
                  opacity: 0.5,
                  userSelect: "none",
                }}
              >
                {fileUrl ? (
                  <video
                    controls={false}
                    autoPlay={false}
                    style={{ maxWidth: "280px", userSelect: "none" }}
                  >
                    <source src={fileUrl} type="video/mp4" />
                    <source src={fileUrl} type="video/ogg" /> Your browser does
                    not support the Video element.
                  </video>
                ) : (
                  <React.Fragment>
                    <i className="ri-vidicon-line ri-lg me-2 text-primary " />{" "}
                    Upload Video
                  </React.Fragment>
                )}
              </div>
            </div>
          );
        } else if (item.format === "DOCUMENT") {
          let fileUrl = null;
          let fileDetails = null;
          let name = null;
          if (
            (exampleParams &&
              exampleParams.document &&
              typeof exampleParams.document === "object") ||
            (exampleParams &&
              exampleParams.image &&
              exampleParams.image.length > 0)
          ) {
            const docObj = exampleParams.document
              ? exampleParams.document
              : exampleParams.image
                ? exampleParams.image
                : null;

            fileUrl = docObj[0];
            fileDetails = getFileExtension(docObj[0].split("?")[0]);
            name = docObj[1] ? docObj[1] : "document." + fileDetails.ext;
          } else if (
            exampleParams &&
            exampleParams.document &&
            typeof exampleParams.document === "string"
          ) {
            fileUrl = exampleParams.document;
            fileDetails = getFileExtension(
              exampleParams.document.split("?")[0]
            );
            name = "document." + fileDetails.ext;
          } else if (
            item.example &&
            item.example.header_handle &&
            item.example.header_handle.length > 0
          ) {
            fileUrl = item.example.header_handle[0];
            fileDetails = getFileExtension(
              item.example.header_handle[0].split("?")[0]
            );
            name = "document." + fileDetails.ext;
          }

          const fileTypeIcon =
            fileDetails && fileDetails.className
              ? fileDetails.className
              : "ri-folder-5-line ri-lg";

          myTemplate.push(
            <div
              key={"header-" + template.id}
              className="font-weight-light bg-light d-flex justify-content-between cursor-pointer"
              onClick={() => downloadFile(fileUrl, name)}
            >
              <div className="p-2 text-uppercase d-flex">
                <i className={fileTypeIcon + "ri-3x mt-2"} />

                <label className="fs-6 mt-2 text-light">{name}</label>
              </div>
              <div className="text-right p-2">
                <i className="ri-download-2-line me-2 ri-2x" />
              </div>
            </div>
          );
        }
      } else if (item.type === "BODY") {
        myTemplate.push(
          <div className="mt-2 fs-6 word-break" key={"body-" + template.id}>
            {getHeaderTextWithParams(
              item.text,
              "body_text",
              exampleParams === null
                ? {
                    body_text:
                      item.example &&
                      item.example.body_text &&
                      item.example.body_text.length > 0
                        ? item.example.body_text[0]
                        : null,
                  }
                : exampleParams,
              previewOnly
            )}
          </div>
        );
      } else if (item.type === "FOOTER") {
        myTemplate.push(
          <div
            key={"footer-" + template.id}
            className="mt-2 text-muted word-break"
          >
            {item.text}
          </div>
        );
      } else if (item.type === "BUTTONS") {
        const templateButtons = [];
        if (item.buttons && item.buttons.length > 0) {
          templateButtons.push(
            <div
              key={"footer-hr"}
              className="mt-0 mb-3"
              style={{ borderBottom: "1px solid #ccc" }}
            ></div>
          );

          const quickButtons = [];
          const lastButton = [];
          item.buttons.forEach((button, indx) => {
            if (["PHONE_NUMBER"].indexOf(button.type) > -1) {
              if (!isObjectEmpty(button.footer_text)) {
                templateButtons.push(
                  <div
                    className="text-muted"
                    key={"button-footer_text" + indx + "-" + template.id}
                  >
                    {button.footer_text}
                  </div>
                );
              }
              templateButtons.push(
                <div key={"button-" + indx + "-" + template.id}>
                  <div
                    className="text-center mb-2"
                    key={"button-" + indx + "-" + template.id}
                  >
                    <a
                      title={button.text}
                      target="_new"
                      href={"#" + button.text}
                      className="text-primary text-decoration-none"
                      style={{
                        fontSize: "15px",
                        position: "relative",
                        paddingLeft: "15px",
                      }}
                    >
                      <i
                        className="ri-phone-fill ri-1x"
                        style={{
                          position: "absolute",
                          top: "-2px",
                          left: "-2px",
                        }}
                      />{" "}
                      {button.text}
                    </a>
                  </div>
                </div>
              );
            } else if (["URL"].indexOf(button.type) > -1) {
              if (!isObjectEmpty(button.footer_text)) {
                templateButtons.push(
                  <div
                    className="text-muted"
                    key={"button-footer_text" + indx + "-" + template.id}
                  >
                    {button.footer_text}
                  </div>
                );
              }

              templateButtons.push(
                <div
                  className="text-center"
                  key={"button-" + indx + "-" + template.id}
                >
                  <a
                    title={button.text}
                    target="_new"
                    href={
                      exampleParams &&
                      exampleParams.footer_text &&
                      exampleParams.footer_text.length > 0
                        ? exampleParams.footer_text[0]
                        : button.url
                    }
                    className="text-primary text-decoration-none "
                    style={{
                      fontSize: "15px",
                      position: "relative",
                      paddingLeft: "15px",
                    }}
                  >
                    <i
                      className="ri-external-link-line ri-1x"
                      style={{
                        position: "absolute",
                        top: "-2px",
                        left: "-2px",
                      }}
                    />
                    {button.text}
                  </a>
                </div>
              );
            } else if (["QUICK_REPLY", "CUSTOM"].indexOf(button.type) > -1) {
              if (!isObjectEmpty(button.footer_text)) {
                templateButtons.push(
                  <div
                    className="text-muted"
                    key={"button-footer-text" + indx + "-" + template.id}
                  >
                    {button.footer_text}
                  </div>
                );
              }

              if (button.text !== "Stop promotion") {
                quickButtons.push(
                  <Col key={"button-" + indx + "-" + template.id}>
                    <Button style={{ width: "100%" }}>{button.text}</Button>
                  </Col>
                );
              } else {
                lastButton.push(
                  <Row
                    className="mt-1"
                    key={"button-" + indx + "-" + template.id}
                  >
                    <Col>
                      <Button style={{ width: "100%" }}>{button.text}</Button>
                    </Col>
                  </Row>
                );
              }
            }
          });

          quickButtons.length > 0 &&
            templateButtons.push(
              <Row key={"button-row-" + template.id}>{quickButtons}</Row>
            );
          templateButtons.length > 0 &&
            myTemplate.push(
              <div key={"button-row-1"} className="whatsapp-footer-buttons">
                {templateButtons}
              </div>
            );
          lastButton.length > 0 &&
            myTemplate.push(
              <React.Fragment key={"button-row-2"}>{lastButton}</React.Fragment>
            );
        }
      }
    });
  }
  return <div className="templateParent">{[...myTemplate]}</div>;
};

export const Reaction = ({ getEmoji, emoji = null, message }) => {
  const [reaction] = useState(["👍", "😍", "😂", "😯", "😢", "🙏"]);

  const getSmily = () => {
    const emojiList = reaction.map(
      (item, idx) =>
        item && (
          <Button
            variant={"outline"}
            className={
              "p-1 cursor-pointer " +
              (emoji.length > 0 &&
              !isObjectEmpty(emoji[0]) &&
              emojiUnicode(emoji[0]) === emojiUnicode(item)
                ? "active-reaction"
                : "")
            }
            style={{ fontSize: "1.5rem" }}
            key={idx}
            onClick={() => getEmoji(item)}
          >
            {item}
          </Button>
        )
    );
    return emojiList;
  };

  const popover = (
    <Popover id="reaction-popover" className="reaction-popover">
      <Popover.Body>
        <div>{getSmily()}</div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose trigger="click" placement="top" overlay={popover}>
      <i
        className={
          "ri-emotion-fill ri-xl cursor-pointer" +
          (emoji.length > 0 ? " active" : "")
        }
      />
    </OverlayTrigger>
  );
};

export const getReactedMessage = (messageId, messages, format = "html") => {
  const reactionBody = [];
  if (messages && messages.length > 0) {
    messages.forEach((item, idx) => {
      const messageValue = JSON.parse(item.type_value);
      if (
        messageValue.message_id.includes(messageId) &&
        !isObjectEmpty(messageValue.emoji)
      ) {
        if (format === "html") {
          reactionBody.push(
            <span key={idx} className="message-reaction">
              {messageValue.emoji}
            </span>
          );
        } else {
          reactionBody.push(messageValue.emoji);
        }
      }
    });
  }
  return reactionBody;
};

export const emojiUnicode = (emoji) => {
  var comp;
  if (emoji.length === 1) {
    comp = emoji.charCodeAt(0);
  }
  comp =
    (emoji.charCodeAt(0) - 0xd800) * 0x400 +
    (emoji.charCodeAt(1) - 0xdc00) +
    0x10000;
  if (comp < 0) {
    comp = emoji.charCodeAt(0);
  }
  return comp.toString("16");
};

export const isJson = (str) => {
  if (str) {
    try {
      JSON.parse(str);
    } catch (e) {
      console.log("Component Error:", e.message);
      return false;
    }
    return true;
  } else {
    return false;
  }
};

export const searchAndGetMailMergeParams = (str, type) => {
  let params = [];
  if (!isObjectEmpty(str)) {
    let startIndex = -1;

    for (let i = 0; i <= str.length; i++) {
      if (str[i] === "{") {
        startIndex = i;
      }

      if (startIndex > -1 && str[i] === "}") {
        params.push(str.substr(startIndex, i + 1 - startIndex));
        startIndex = -1;
      }
    }
  }

  return params;
};

export const templateDynamicParam = (
  formik,
  type,
  mainType = null,
  callback,
  locale
) => {
  const typeLabel = {
    header_text: "Header",
    body_text: "Body",
    image: "Image",
    video: "Video",
    document: "Document",
  };

  const params = searchAndGetMailMergeParams(
    formik.values[mainType ? mainType : type]
  );
  const previousParams = formik.values[type + "_lebel"];

  if (previousParams && params && previousParams.length !== params.length) {
    let missingItemIndex = null;
    if (previousParams.length > params.length) {
      previousParams.forEach((item, idx) => {
        const indexValue = params.indexOf(item);
        if (indexValue === -1) {
          missingItemIndex = idx;
        }
      });

      formik.setFieldValue(type + "_lebel", params);
      const currentTypeValues = formik.values["example_variables"][type];
      currentTypeValues.splice(missingItemIndex, 1);
      formik.setFieldValue("example_variables", {
        ...formik.values.example_variables,
        [type]: currentTypeValues,
      });
    } else {
      params.forEach((item, idx) => {
        const indexValue = previousParams.indexOf(item);
        if (indexValue === -1) {
          missingItemIndex = idx;
        }
      });

      formik.setFieldValue(type + "_lebel", params);
      const currentTypeValues = formik.values["example_variables"][type];
      currentTypeValues.splice(missingItemIndex, 0, "");
      formik.setFieldValue("example_variables", {
        ...formik.values.example_variables,
        [type]: currentTypeValues,
      });
    }
  }

  return (
    ((params && params.length > 0) ||
      ["image", "video", "document"].indexOf(type) > -1) && (
      <Card className="ms-2 p-1">
        <Card.Body className="m-2 p-1  mb-3">
          {["header_text", "body_text"].indexOf(type) > -1 && (
            <Form.Group className="form-group">
              <Form.Label>
                Samples for {typeLabel[type].toLowerCase()} content
              </Form.Label>
              <p className="text-size-xs">
                To help us review your content, provide examples of the
                variables or media in the header. Do not include any customer
                information. Cloud API hosted by Meta reviews templates and
                variable parameters to protect the security and integrity of our
                services.
              </p>
            </Form.Group>
          )}

          {["image", "video", "document"].indexOf(type) > -1 && (
            <Form.Group className="form-group text-center" key={"media"}>
              <Form.Label>
                Upload {typeLabel[type].toLowerCase()} for template
              </Form.Label>
              <div>
                <MediaAndEmojis
                  isVisible={false}
                  uploadLimit={uploadLimit}
                  locale={locale}
                  formik={{ ...formik }}
                  messageRef={null}
                  contactDetails={null}
                  type={type}
                  callback={callback}
                />
              </div>
            </Form.Group>
          )}

          {params.map((item, idx) => (
            <Form.Group className="form-group my-1" key={item + "-" + idx}>
              <Form.Label className="pt-2 pe-1">
                {item} {/*mainType === null && " Value"*/}{" "}
              </Form.Label>
              <Form.Control
                className=""
                type="text"
                name={item}
                id={item}
                defaultValue={
                  formik.values["example_variables"] &&
                  formik.values["example_variables"][type] &&
                  formik.values["example_variables"][type][idx]
                    ? formik.values["example_variables"][type][idx]
                    : ""
                }
                onChange={(e) => {
                  const myValue = [
                    ...(formik.values.example_variables &&
                    formik.values.example_variables[type]
                      ? formik.values.example_variables[type]
                      : []),
                  ];

                  myValue[idx] = e ? e.target.value : "";

                  if (mainType === null) {
                    if (
                      formik.values.example_variables &&
                      formik.values.example_variables !== "{}"
                    ) {
                      formik.setFieldValue("example_variables", {
                        ...formik.values.example_variables,
                        [type]: [...myValue],
                      });
                    } else {
                      formik.setFieldValue("example_variables", {
                        [type]: [...myValue],
                      });
                    }
                  } else {
                    callback(idx, e.target.value, type);
                  }
                }}
              />
              <InLineError type="error" name={item} />
            </Form.Group>
          ))}
        </Card.Body>
      </Card>
    )
  );
};

export const getHeaderTextWithParams = (
  str,
  type,
  exampleParams,
  previewOnly
) => {
  if (
    str &&
    str.length > 0 &&
    exampleParams &&
    exampleParams[type] &&
    exampleParams[type].length > 0
  ) {
    exampleParams[type].forEach((item, idx) => {
      str = str.replace("{{" + (idx + 1) + "}}", item);
    });
  }
  if (previewOnly) {
    return createMarkup(findAndReplaceUrl(str, true));
  } else {
    return str;
  }
};

export const replaceParamWithText = (str, params, isCompile = true) => {
  if (str && str.length > 0 && params && params.length > 0) {
    if (isCompile) {
      params.forEach((item, idx) => {
        str = str.replace(item, "{{" + (idx + 1) + "}}");
      });
    } else {
      params.forEach((item, idx) => {
        str = str.replace("{{" + (idx + 1) + "}}", item);
      });
    }
  }
  return str;
};

/*** SHOW UPLOAD BUTTON TO UPLOAD MEDIA ***/
export const ShowFieldsToFillData = ({
  selectedTemplate,
  handleData,
  locale,
  showMedia = false,
}) => {
  const data = isJson(selectedTemplate.components)
    ? JSON.parse(selectedTemplate.components)
    : null;

  let variables = [{ header_text: [], body_text: [] }];
  if (selectedTemplate && !isObjectEmpty(selectedTemplate.example_variables)) {
    if (isJson(selectedTemplate.example_variables)) {
      variables = JSON.parse(selectedTemplate.example_variables);
    } else {
      variables = selectedTemplate.example_variables;
    }
  }

  const dataToBeFill = [];
  if (data && data.length > 0) {
    data.forEach((item, idx) => {
      if (item.type === "HEADER" && item.format === "TEXT" && false) {
        dataToBeFill.push(
          <React.Fragment key={idx}>
            {templateDynamicParam(
              {
                values: { ...item, example_variables: variables },
              },
              "header_text",
              "text",
              handleData,
              locale
            )}
          </React.Fragment>
        );
      } else if (item.type === "HEADER" && item.format === "IMAGE") {
        dataToBeFill.push(
          <React.Fragment key={idx}>
            {templateDynamicParam(
              { values: { ...item, example_variables: variables } },
              "image",
              "text",
              handleData,
              locale
            )}
          </React.Fragment>
        );
      } else if (item.type === "HEADER" && item.format === "VIDEO") {
        dataToBeFill.push(
          <React.Fragment key={idx}>
            {templateDynamicParam(
              { values: { ...item, example_variables: variables } },
              "video",
              "text",
              handleData,
              locale
            )}
          </React.Fragment>
        );
      } else if (item.type === "HEADER" && item.format === "DOCUMENT") {
        dataToBeFill.push(
          <React.Fragment key={idx}>
            {templateDynamicParam(
              { values: { ...item, example_variables: variables } },
              "document",
              "text",
              handleData,
              locale
            )}
          </React.Fragment>
        );
      } else if (item.type === "BODY" && false) {
        dataToBeFill.push(
          <React.Fragment key={idx}>
            {templateDynamicParam(
              { values: { ...item, example_variables: variables } },
              "body_text",
              "text",
              handleData,
              locale
            )}
          </React.Fragment>
        );
      }

      /*** IF MEDIA PREVIEW ENABLE ****/
      if (
        showMedia &&
        item.type === "HEADER" &&
        ["IMAGE", "VIDEO", "DOCUMENT"].indexOf(item.format) > -1 &&
        variables
      ) {
        if (
          item.format === "IMAGE" &&
          variables.image &&
          variables.image.length > 0
        ) {
          dataToBeFill.push(
            <React.Fragment key="media">
              <div className="text-center mt-1">
                <img
                  src={variables.image[0]}
                  alt=""
                  style={{ height: "60px" }}
                />
              </div>
            </React.Fragment>
          );
        } else if (
          item.format === "VIDEO" &&
          ((variables.video && variables.video.length > 0) ||
            (variables.image && variables.image.length > 0))
        ) {
          const videoObj = variables.video
            ? variables.video
            : variables.image
              ? variables.image
              : null;
          if (videoObj) {
            const fileUrl = videoObj[0];
            dataToBeFill.push(
              <React.Fragment key="media">
                <div className="text-center mt-1 border">
                  <div
                    style={{
                      background: "#fff",
                      opacity: 0.5,
                      userSelect: "none",
                    }}
                  >
                    <video
                      controls={false}
                      autoPlay={false}
                      style={{ maxWidth: "100px", userSelect: "none" }}
                    >
                      <source src={fileUrl} type="video/mp4" />
                      <source src={fileUrl} type="video/ogg" /> Your browser
                      does not support the Video element.
                    </video>
                  </div>
                </div>
              </React.Fragment>
            );
          }
        } else if (
          item.format === "DOCUMENT" &&
          ((variables.document && variables.document.length > 0) ||
            (variables.image && variables.image.length > 0))
        ) {
          const docObj = variables.document
            ? variables.document
            : variables.image
              ? variables.image
              : null;

          if (docObj) {
            const fileUrl = docObj[0];
            const fileDetails = getFileExtension(fileUrl.split("?")[0]);
            const name = docObj[1] ? docObj[1] : "document." + fileDetails.ext;

            const fileTypeIcon =
              fileDetails && fileDetails.className
                ? fileDetails.className
                : "ri-folder-5-line ri-lg";

            dataToBeFill.push(
              <React.Fragment key="media">
                <div
                  key={"header-doc"}
                  className="font-weight-light bg-light d-flex justify-content-between cursor-pointer"
                  onClick={() => {}}
                >
                  <div className="p-2 text-uppercase d-flex">
                    <i className={fileTypeIcon + "ri-3x mt-2"} />

                    <label className="fs-6 mt-2 text-light">{name}</label>
                  </div>
                  {/* <div className="text-right p-2">
                  <i className="ri-download-2-line me-2 ri-2x" />
                </div> */}
                </div>
              </React.Fragment>
            );
          }
        }
      }
    });
  }
  return dataToBeFill;
};

export const checkAndFillMailMergeValue = (
  variables,
  data,
  contactDetails,
  mailMergeItems,
  authData
) => {
  if (data && data.length > 0) {
    data.forEach((item) => {
      if (
        item &&
        item.type === "BODY" &&
        item.example &&
        item.example.body_text &&
        item.example.body_text.length > 0 &&
        item.example.body_text[0].length > 0
      ) {
        item.example.body_text[0].forEach((exampleItem, idx) => {
          if (!isObjectEmpty(mailMergeItems[exampleItem])) {
            const propertyArray = exampleItem.replace(/{|}/g, "").split(".");

            if (
              propertyArray &&
              propertyArray.length > 1 &&
              propertyArray[0] === "account"
            ) {
              const value = getFilterValue(propertyArray[1], authData);
              if (
                !isObjectEmpty(variables.body_text) &&
                variables.body_text.length > 0
              ) {
                variables.body_text[idx] = value;
              } else if (
                !isObjectEmpty(variables.body_text) &&
                Object.keys(variables).length > 0
              ) {
                variables = { ...variables, body_text: [value] };
              } else {
                variables = { body_text: [value] };
              }
            } else if (propertyArray && propertyArray.length > 1) {
              const value = getFilterValue(propertyArray[1], contactDetails);
              if (
                !isObjectEmpty(variables.body_text) &&
                variables.body_text.length > 0
              ) {
                variables.body_text[idx] = value;
              } else if (
                !isObjectEmpty(variables.body_text) &&
                Object.keys(variables).length > 0
              ) {
                variables = { ...variables, body_text: [value] };
              } else {
                variables = { body_text: [value] };
              }
            } else {
              contactDetails.custom_attributes.forEach((customField) => {
                const value = isJson(customField.value)
                  ? JSON.parse(customField.value)
                  : null;
                if (customField.name === propertyArray[0]) {
                  variables.body_text[idx] = value.toString();
                }
              });
            }
          }
        });
      } else if (
        item &&
        item.type === "HEADER" &&
        item.example &&
        item.example.header_text &&
        item.example.header_text.length > 0
      ) {
        item.example.header_text.forEach((exampleItem, idx) => {
          if (!isObjectEmpty(mailMergeItems[exampleItem])) {
            const propertyArray = exampleItem.replace(/{|}/g, "").split(".");
            if (propertyArray && propertyArray.length > 1) {
              const value = getFilterValue(propertyArray[1], contactDetails);
              if (
                !isObjectEmpty(variables.header_text) &&
                variables.header_text.length > 0
              ) {
                variables.header_text[idx] = value;
              } else if (
                !isObjectEmpty(variables.header_text) &&
                Object.keys(variables).length > 0
              ) {
                variables = { ...variables, header_text: [value] };
              } else {
                variables = { ...variables, header_text: [value] };
              }
            } else {
              contactDetails.custom_attributes.forEach((customField) => {
                const value = isJson(customField.value)
                  ? JSON.parse(customField.value)
                  : null;
                if (customField.name === propertyArray[0]) {
                  variables.header_text[idx] = value.toString();
                }
              });
            }
          }
        });
      }
    });
  }
  return variables ? JSON.stringify(variables) : null;
};

export const getFilterValue = (name, details) => {
  const objectTypeAttribute = [
    "contact_owner",
    "address",
    "country",
    "state",
    "city",
    "zip",
  ];
  let value = null;
  if (objectTypeAttribute.indexOf(name) > -1) {
    switch (name) {
      case "contact_owner":
        value = details[name].name;
        break;
      case "address":
        value =
          details.address && details.address[name] ? details.address[name] : "";
        break;
      case "country":
        value =
          details.address && details.address[name] ? details.address[name] : "";
        break;
      case "state":
        value =
          details.address && details.address[name] ? details.address[name] : "";
        break;
      case "city":
        value =
          details.address && details.address[name] ? details.address[name] : "";
        break;
      case "zip":
        value =
          details.address && details.address[name] ? details.address[name] : "";
        break;
      default:
        value = details[name];
    }
  } else {
    value = details[name];
  }
  return value;
};

/***Used In Campaign Builder [Send Email & Broadcast]***/
export const getMailMergeParamsForWhatsapp = async (selectedTemplate) => {
  let componentParams = {};
  const documentsType = [
    { value: "{quote.pdf}", name: "{quote.name}" },
    { value: "{project.pdf}", name: "{project.name}" },
    { value: "{invoice.pdf}", name: "{invoice.name}" },
  ];
  if (selectedTemplate && selectedTemplate.id) {
    if (
      selectedTemplate.components &&
      selectedTemplate.components.length > 0 &&
      isJson(selectedTemplate.components)
    ) {
      const components = JSON.parse(selectedTemplate.components);
      const exampleVariables = isJson(selectedTemplate.example_variables)
        ? JSON.parse(selectedTemplate.example_variables)
        : null;

      components.forEach((item) => {
        if (item.type === "HEADER") {
          if (item.format === "TEXT") {
            componentParams = {
              ...componentParams,
              [item.type]:
                item.example && item.example.header_text
                  ? item.example.header_text
                  : null,
            };
          } else if (["IMAGE", "VIDEO", "DOCUMENT"].indexOf(item.format) > -1) {
            let mediaPath = null;

            if (
              item.format === "IMAGE" &&
              !isObjectEmpty(exampleVariables) &&
              exampleVariables.image
            ) {
              mediaPath = exampleVariables.image;
            } else if (
              !isObjectEmpty(exampleVariables) &&
              exampleVariables.video
            ) {
              mediaPath = exampleVariables.video;
            } else if (
              !isObjectEmpty(exampleVariables) &&
              exampleVariables.document
            ) {
              mediaPath = exampleVariables.document;
            } else if (
              item.format === "DOCUMENT" &&
              !isObjectEmpty(exampleVariables) &&
              exampleVariables &&
              exampleVariables.image
            ) {
              const headerItem = exampleVariables.image
                ? exampleVariables.image[0]
                : null;
              const values = documentsType.filter(
                (item) => headerItem && headerItem === item.value
              );
              mediaPath =
                values.length > 0 ? [values[0].value, values[0].name] : null;
            } else if (item.example && item.example.header_image) {
              const headerItem =
                item.example && item.example.header_image
                  ? item.example.header_image[0]
                  : null;
              const values = documentsType.filter(
                (item) => headerItem && headerItem === item.value
              );
              mediaPath =
                values.length > 0 ? [values[0].value, values[0].name] : null;
            }

            componentParams = {
              ...componentParams,
              [item.type]: mediaPath,
            };
          }
        } else if (
          item.type === "BODY" &&
          item.example &&
          item.example.body_text &&
          item.example.body_text.length > 0
        ) {
          componentParams = {
            ...componentParams,
            [item.type]: item.example.body_text[0],
          };
        } else if (
          item.type === "BUTTONS" &&
          item.buttons &&
          item.buttons.length > 0
        ) {
          item.buttons.forEach((btn) => {
            if (btn.type === "URL") {
              componentParams = {
                ...componentParams,
                [item.type]: btn.example ? btn.example : null,
              };
            }
          });
        }
      });
    }
  }
  return componentParams;
};

export const WhatsappOptionalLabel = ({
  text = "Optional",
  style = {},
  bg = "light",
}) => {
  return (
    <Badge
      style={{
        fontSize: "10px",
        padding: "3px",
        opacity: 0.8,
        ...style,
      }}
      className="text-dark ms-2"
      pill
      bg={bg}
    >
      {text}
    </Badge>
  );
};

export const WhatsappTooltip = ({
  text = "Tooltip text goes here",
  placement = "auto",
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip>
          <span>{text}</span>
        </Tooltip>
      }
    >
      <i className="ri-information-line" />
    </OverlayTrigger>
  );
};

export const TextLength = ({ style = {}, textLength = 0, maxLength = 25 }) => {
  return (
    <span
      style={{
        position: "absolute",
        top: "35px",
        right: "10px",
        opacity: 0.5,
        userSelect: "none",
        ...style,
      }}
    >
      {textLength + " / " + maxLength}
    </span>
  );
};

/***Used In Campaign Builder [Send Email & Broadcast]***/
export const isTemplateContainMedia = (selectedTemplate) => {
  let componentParams = false;

  if (selectedTemplate && selectedTemplate.id) {
    if (
      selectedTemplate.components &&
      selectedTemplate.components.length > 0 &&
      isJson(selectedTemplate.components)
    ) {
      const components = JSON.parse(selectedTemplate.components);
      // const exampleVariables = isJson(selectedTemplate.example_variables)
      //   ? JSON.parse(selectedTemplate.example_variables)
      //   : null;

      components.forEach((item) => {
        if (item.type === "HEADER") {
          if (["IMAGE", "VIDEO", "DOCUMENT"].indexOf(item.format) > -1) {
            componentParams = true;
          }
        }
      });
    }
  }

  return componentParams;
};

export const MessageAction = ({
  actionType = "icon", //"menu"
  actions = [],
  file,
  filename,
  mimeType = null,
  saveToDocuments,
}) => {
  const icons = { download: "ri-download-2-fill", save: "ri-save-2-fill" };
  const tooltip = { download: "Download file", save: "Save to documents" };

  const handleFile = (type, file, name) => {
    if (type === "download") {
      downloadFile(file, name);
    } else {
      saveToDocuments(filename);
    }
  };
  /*******/
  return (
    <div className="fs-5">
      {actions &&
        actions.length > 0 &&
        (actionType === "icon" ? (
          <React.Fragment>
            {actions.map((item) => (
              <OverlayTrigger
                placement="auto"
                key={item}
                overlay={<Tooltip>{tooltip[item]}</Tooltip>}
              >
                <i
                  className={icons[item] + " text-secondary me-2"}
                  onClick={() => handleFile(item, file, filename)}
                />
              </OverlayTrigger>
            ))}
          </React.Fragment>
        ) : (
          <Dropdown className={"more-dropdown me-n2 "} align="end">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <i className={"ri-more-2-line"}></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {actions.map((item) => (
                <OverlayTrigger
                  placement="auto"
                  key={item}
                  overlay={<Tooltip>{tooltip[item]}</Tooltip>}
                >
                  <Dropdown.Item
                    onClick={() => handleFile(item, file, filename)}
                    className="text-capitalize"
                  >
                    <i className={icons[item]}></i>
                    {item}
                  </Dropdown.Item>
                </OverlayTrigger>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ))}
    </div>
  );
};

/* Retrieve Data From Server */
import { gql } from "@apollo/client";

/** Table predefine Column listing **/
export const COLUMN_LIST = gql`
  query getTableAttributes($page: ID) {
    tableAttributes(page: $page) {
      page
      columns
      import
    }
    preferred(page: $page) {
      id
      team_id
      page
      columns
      created_at
      updated_at
    }
  }
`;

/** Preferred Column listing **/
export const PREFERRED_COLUMN_LIST = gql`
  query getPreferredAttributes($page: ID) {
    preferred(page: $page) {
      id
      team_id
      page
      columns
      created_at
      updated_at
    }
  }
`;

/** Preferred Column listing save **/
export const PREFERRED_COLUMN_LIST_SAVE = gql`
  mutation updatePreferredColumn(
    $page: String!
    $columns: String!
    $team_id: Int
  ) {
    createUpdatePreferred(page: $page, columns: $columns, team_id: $team_id) {
      id
      team_id
      user_id
      page
      columns
      created_at
      updated_at
    }
  }
`;
/** Table predefine Column listing **/
export const PRE_DEFINED_COLUMN_LIST = gql`
  query getTableAttributes($page: ID, $team_id: Int) {
    tableAttributes(page: $page, team_id: $team_id) {
      page
      columns
      import
    }
  }
`;

/** Table predefine Column listing **/
export const PRE_DEFINED_COLUMNS = gql`
  query getTableAttributes($page: ID, $page2: ID, $team_id: Int) {
    contacts: tableAttributes(page: $page, team_id: $team_id) {
      page
      columns
      import
    }
    #deals: tableAttributes(page: $page1, team_id: $team_id) {
    #  page
    #  columns
    #  import
    #}
    company: tableAttributes(page: $page2, team_id: $team_id) {
      page
      columns
      import
    }
  }
`;
/** Table predefine Column listing **/
export const FETCH_COLUMNS = gql`
  query getTableAttributes($page: ID, $team_id: Int) {
    contacts: tableAttributes(page: $page, team_id: $team_id) {
      page
      columns
      import
    }
  }
`;

import { useLazyQuery } from "@apollo/client";
import { FETCH_COLUMNS } from "../GraphApi/TableColumn";
import { currentTeamId, jsonToArray } from "../Utils/utils";
import { useEffect, useState } from "react";

const useFetchColumnList = (module, useType = "listing") => {
  const [preDefinedColumns, setPreDefinedColumns] = useState([]);
  const [getColumnList, { data: columnData, loading, error }] = useLazyQuery(
    FETCH_COLUMNS,
    {
      fetchPolicy: "network-only",
    }
  );

  const fetchColumnList = () => {
    getColumnList({
      variables: {
        page: module,
        team_id: currentTeamId,
      },
      fetchPolicy: "network-only",
    });
  };

  useEffect(() => {
    if (columnData) {
      const allPredefinedFields = [];
      if (columnData && columnData.contacts) {
        const columns = jsonToArray(JSON.parse(columnData.contacts.columns));
        const myTpe = module === "estimate_listing" ? "estimates" : "contacts";
        allPredefinedFields.push(...addTypeName(columns, myTpe));
      }
      setPreDefinedColumns(allPredefinedFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnData]);

  const addTypeName = (data, type) => {
    if (data && data.predefined && data.predefined.length > 0) {
      let myData = [];
      data.predefined.forEach((item) => {
        if (item?.modules?.length > 0 && item.modules.includes(useType)) {
          myData.push({ ...item, value: item.name, fieldFor: type });
        }
      });
      return myData;
    }
    return [];
  };

  return { preDefinedColumns, fetchColumnList, loading, error };
};

export default useFetchColumnList;

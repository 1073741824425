import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CalendarSvg } from '../../../Components/Common/SvgFiles/CalendarSvg';
import { FailedSvg } from '../../../Components/Common/SvgFiles/FailedSvg';
import { MessageSvg } from '../../../Components/Common/SvgFiles/MessageSvg';
import { isObjectEmpty, toWordCase } from '../../../Utils/utils';

export const postPerRow = {
  facebook: { grid: 3, single: 1 },
  instagram: { grid: 4, single: 1 },
  twitter: { grid: 3, single: 1 },
  linkedin: { grid: 3, single: 1 },
};

export const showPostsInRow = (
  posts,
  users,
  getUserPosts,
  Component,
  view,
  channelProps
) => {
  const isColumnGrid = true;
  let postsInRow = [];
  let postsInCol = [];
  let count = 1;
  const {
    channel,
    currentCompanySetting,
    locale,
    selectedPage,
    handleInsight,
  } = channelProps;

  let postPerPage = 1;
  if (postPerRow[channel] && postPerRow[channel][view]) {
    postPerPage = postPerRow[channel][view];
  }
  if (posts && posts.length > 0) {
    /**In Case Of Twitter Add Condition for check Tweet Type either it is reply tweet or not. ***/
    posts.forEach((post, idx) => {
      if (
        post &&
        isObjectEmpty(post.in_reply_to_status_id) &&
        isObjectEmpty(post.in_reply_to_user_id)
      ) {
        if (isColumnGrid && view === 'grid') {
          postsInRow.push(
            <div
              className='post-grid4-col'
              key={post.id}
            >
              <Component
                currentCompanySetting={currentCompanySetting}
                locale={locale}
                users={users}
                post={post}
                getUserPosts={getUserPosts}
                postCount={posts.length}
                selectedPage={selectedPage}
                handleInsight={handleInsight}
              />
            </div>
          );
        } else {
          postsInCol.push(
            <Col
              md={12 / (postPerPage === 1 ? 2 : postPerPage)}
              key={post.id}
              className={'mt-1 '}
            >
              <Component
                currentCompanySetting={currentCompanySetting}
                locale={locale}
                users={users}
                post={post}
                getUserPosts={getUserPosts}
                postCount={posts.length}
                selectedPage={selectedPage}
                handleInsight={handleInsight}
              />
            </Col>
          );

          /******/

          if (count % postPerPage === 0) {
            postsInRow.push(
              <Row
                key={'row-' + idx}
                className={
                  'mt-1 align-items-stretch ' +
                  (postPerPage === 1 ? 'justify-content-md-center' : '')
                }
                // style={{ minHeight: "100%" }}
              >
                {postsInCol}
              </Row>
            );
            postsInCol = [];
          } else if (idx === posts.length - 1) {
            postsInRow.push(
              <Row
                key={'row-' + idx}
                className='mt-1 align-items-stretch'
                // style={{ minHeight: "100%" }}
              >
                {postsInCol}
              </Row>
            );

            postsInCol = [];
          }
          if (count === postPerPage) {
            count = 0;
          }
          count = count + 1;
        }
      }
    });
  }

  /*** Final Row Return ***/
  if (isColumnGrid && view === 'grid') {
    return <div className='post-grid4'>{postsInRow}</div>;
  } else {
    return postsInRow;
  }
};

export const getIconName = (item) => {
  const facebook = ['facebook', 'fb_group', 'fb_page', 'fb-group', 'fb-page'];
  const instagram = ['instagram', 'fb-instagram'];
  const twitter = ['twitter'];
  const linkedin = ['linkedin', 'in-user', 'in-organization'];

  if (facebook.indexOf(item.type) > -1) {
    return 'ri-facebook-circle-fill';
  } else if (instagram.indexOf(item.type) > -1) {
    return 'ri-instagram-line';
  } else if (twitter.indexOf(item.type) > -1) {
    return 'ri-twitter-fill';
  } else if (linkedin.indexOf(item.type) > -1) {
    return 'ri-linkedin-fill';
  } else {
    return 'ri-circle-line';
  }
};

export const getIconImage = (icon) => {
  if (['facebook', 'fb-page', 'fb-group'].indexOf(icon) > -1) {
    return (
      <span key={icon}>
        <img
          src={require('../../../Assets/Images/platform-facebook.png')}
          alt=''
        />
      </span>
    );
  } else if (['instagram', 'fb-instagram'].indexOf(icon) > -1) {
    return (
      <span key={icon}>
        <img
          src={require('../../../Assets/Images/platform-insta.png')}
          alt=''
        />
      </span>
    );
  } else if (icon === 'twitter') {
    return (
      <span key={icon}>
        <img
          src={require('../../../Assets/Images/platform-twitter.png')}
          alt=''
        />
      </span>
    );
  } else if (['linkedin', 'in-user', 'in-organization'].indexOf(icon) > -1) {
    return (
      <span key={icon}>
        <img
          src={require('../../../Assets/Images/platform-linkedin.png')}
          alt=''
        />
      </span>
    );
  }
};

export const getChannelName = (icon) => {
  if (['facebook', 'fb-page', 'fb-group'].indexOf(icon) > -1) {
    return 'facebook';
  } else if (['instagram', 'fb-instagram'].indexOf(icon) > -1) {
    return 'instagram';
  } else if (icon === 'twitter') {
    return 'twitter';
  } else if (['linkedin', 'in-user', 'in-organization'].indexOf(icon) > -1) {
    return 'linkedin';
  }
};
export const statusIcon = {
  pending: <CalendarSvg active={true} />,
  scheduled: <CalendarSvg active={true} />,
  published: <MessageSvg active={true} />,
  success: <MessageSvg active={true} />,
  fail: <FailedSvg />,
};

export const statusIconForGrid = {
  processing: <CalendarSvg active={false} />,
  pending: <CalendarSvg active={false} />,
  scheduled: <CalendarSvg active={false} />,
  published: <MessageSvg active={false} />,
  success: <MessageSvg active={false} />,
  fail: <FailedSvg />,
};

export const postStatus = (value, locale) => {
  const allStatus = {
    pending: 'Scheduled',
    scheduled: 'Scheduled',
    fail: 'Failed',
    published: 'Published',
    success: 'Published',
  };
  const statusClass = {
    pending: 'text-warning',
    scheduled: 'color-orange',
    fail: 'text-danger',
    published: 'text-success',
    success: 'text-success',
  };

  if (value) {
    const statusValue = allStatus[value];
    const IconComp = statusIcon[value];

    return (
      <label className={statusClass[value] + ' font-weight-bold'}>
        <>{IconComp}</>
        {' ' + locale(toWordCase(statusValue))}
      </label>
    );
  } else {
    return '';
  }
};

export const findUrlInMessage = (text) => {
  if (!text) return;
  let hyperlink = [];
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  /*** URL CONVERT TO HYPERLINK ****/

  text.replace(urlRegex, function (url) {
    let newHyperlink = url;
    if (!newHyperlink.match('^https?://')) {
      newHyperlink = 'http://' + newHyperlink;
    }
    hyperlink.push(newHyperlink);
  });

  return hyperlink;
};

import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ServiceCard } from "./ServiceCard";
import { PlanCard } from "./PlanCard";
import * as Yup from "yup";
import { OFFERING_CREATE } from "../../../GraphApi/OfferingList";
import { useMutation } from "@apollo/client";
import { currentTeamId } from "../../../Utils/utils";
import { EqupRoundLoaderWithOverlay } from "../../Common/Loader/RoundLoader";

export const AddServices = ({
  currentCompanySetting,
  handleAlertMessage = () => {},
  setSetup = null,
  setup = () => {},
}) => {
  const { t: locale } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selected, setSelected] = useState([]);
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState(null);

  const [createOffering] = useMutation(OFFERING_CREATE);

  const myRule = (locale, offeringType) => {
    const taskValidationSchema = Yup.object().shape({
      title: Yup.string()
        .required(
          locale("messages:formik.{{name}} is required", {
            name: locale("title"),
          })
        )
        .max(
          125,
          locale(
            "messages:formik.{{name}} max {{maxLength}} characters long!",
            {
              name: locale("title"),
              maxLength: 125,
            }
          )
        ),
      description: Yup.string()
        .nullable(true)
        .max(
          500,
          locale(
            "messages:formik.{{name}} max {{maxLength}} characters long!",
            {
              name: locale("description"),
              maxLength: 500,
            }
          )
        ),

      duration: Yup.number()
        .required(
          locale("messages:formik.{{name}} is required", {
            name: locale("duration"),
          })
        )
        .min(
          0,
          locale("messages:formik.{{name}} must be positive", {
            name: locale("duration"),
          })
        ),
    });

    return {
      price: Yup.number()
        .nullable(true)
        .required(
          locale("messages:formik.{{name}} is required", {
            name: locale("Price"),
          })
        )
        .min(
          0,
          locale("messages:formik.{{name}} must be positive", {
            name: locale("Price"),
          })
        ),
      cost: Yup.number()
        .nullable(true)
        .required(
          locale("messages:formik.{{name}} is required", {
            name: locale("Cost"),
          })
        )
        .min(
          0,
          locale("messages:formik.{{name}} must be positive", {
            name: locale("Cost"),
          })
        ),
      description: Yup.string()
        .nullable(true)
        .max(
          1000,
          locale(
            "messages:formik.{{name}} max {{maxLength}} characters long!",
            {
              name: locale("description"),
              maxLength: 1000,
            }
          )
        ),
      name: Yup.string()
        .required(
          locale("messages:formik.{{name}} is required", {
            name: locale("Name"),
          })
        )
        .max(
          125,
          locale(
            "messages:formik.{{name}} max {{maxLength}} characters long!",
            {
              name: locale("Name"),
              maxLength: 125,
            }
          )
        ),

      ...(offeringType === "service"
        ? {
            tasks: Yup.array().of(taskValidationSchema),
            type: Yup.string().required(
              locale("messages:formik.{{name}} is required", {
                name: locale("Type"),
              })
            ),
          }
        : {}),
    };
  };

  const handleSelect = (e) => {
    if (selected.includes(e.target.value)) {
      setSelected(selected.filter((item) => item !== e.target.value));
    } else {
      setSelected([...selected, e.target.value]);
    }
  };

  const handleSaveServices = async (type) => {
    const isValid = await handleValidate();
    if (isValid) {
      let errorLog = []; // Variable to store error messages
      setIsSubmitting(true);
      // Process each item one by one
      for (let data of [...services, ...products]) {
        let pType = "one_time";
        if (data.product_type === "service" && data.type !== "free") {
          pType = data.type;
        }

        const dataToBeProcessed = {
          team_id: currentTeamId,
          name: data.name,
          description: data.description,
          product_type: data.product_type,
          price:
            data.type !== "free" && data.price ? parseFloat(data.price) : 0,
          cost: data.cost ? parseFloat(data.cost) : 0,
          tax_applicable: data.tax_applicable === "true" ? true : false,
          type: pType,
          stock: data.product_type === "product" ? parseInt(data.stock) : 0,
          validity_type: data.product_type === "service" ? "hourly" : null,
          ...(data.product_type === "service"
            ? { service_task_items: data.tasks }
            : {}),
          images: [],
          defaultImage: 0,
          additional: JSON.stringify({}),
        };

        try {
          const result = await createOffering({
            variables: dataToBeProcessed,
          });
          console.log("Result:", result.data);
        } catch (error) {
          let { graphQLErrors } = error;
          errorLog.push({
            item: data.name,
            error: graphQLErrors ? graphQLErrors[0].message : "Unknown error",
          });
        }
      }

      // After all items have been processed, check if there are errors
      if (errorLog.length === 0) {
        setIsSubmitting(false);
        handleAlertMessage(
          "success",
          "All services or products have been saved successfully!"
        );
        setSetup({
          ...setup,
          addService: { ...setup.addService, status: "completed" },
          assignService: { ...setup.assignService, status: "active" },
        });
      } else {
        setIsSubmitting(false);
        console.error("Some items failed to save:", errorLog);
        alert(
          `Some items failed to save. See the error log for details. \n\n${errorLog
            .map((error) => `${error.item}: ${error.error}`)
            .join("\n")}`
        );
      }
    }
  };

  const handleValidate = async () => {
    let newErrors = { services: [], products: [] };
    let hasErrors = false;

    if (services?.length > 0) {
      const offeringType = "service";
      const schema = Yup.object().shape({ ...myRule(locale, offeringType) });

      for (let index = 0; index < services.length; index++) {
        try {
          await schema.validate(services[index], {
            context: { offeringType },
            abortEarly: false,
          });
          newErrors.services[index] = null;
        } catch (err) {
          newErrors.services[index] = transformYupErrors(err);
          hasErrors = true;
        }
      }
    }

    if (products?.length > 0) {
      const offeringType = "product";
      const schema = Yup.object().shape({ ...myRule(locale, offeringType) });

      for (let index = 0; index < products.length; index++) {
        try {
          await schema.validate(products[index], {
            context: { offeringType },
            abortEarly: false,
          });
          newErrors.products[index] = null;
        } catch (err) {
          newErrors.products[index] = transformYupErrors(err);
          hasErrors = true;
        }
      }
    }

    setErrors(newErrors);
    console.log("Errors:", newErrors);
    return !hasErrors;
  };

  // Helper function to transform Yup ValidationError into a nested object
  const transformYupErrors = (yupError) => {
    const errorObject = {};
    if (yupError.inner && yupError.inner.length > 0) {
      yupError.inner.forEach((error) => {
        if (error.path) {
          const path = error.path.split(/[.[\]]+/).filter(Boolean); // Split path into segments
          setNestedValue(errorObject, path, error.message); // Set nested value for error
        }
      });
    } else if (yupError.path) {
      errorObject[yupError.path] = yupError.message; // Handle single error case
    }
    return errorObject;
  };

  // Helper function to set a nested value in an object
  const setNestedValue = (obj, path, value) => {
    path.reduce((acc, key, idx) => {
      if (idx === path.length - 1) {
        acc[key] = value; // Set the final key's value
      } else {
        acc[key] = acc[key] || {}; // Ensure the key exists as an object
      }
      return acc[key];
    }, obj);
  };

  /*******/
  return (
    <Card>
      {isSubmitting && <EqupRoundLoaderWithOverlay />}
      <Card.Body className="px-3 py-3">
        <div>
          <h3 className="text-primary mb-1">
            {/* What services, products or subscription plan you provide? */}
            What services or products you provide?
          </h3>
          <span className="text-sm">
            {/* Add at-least one service, product or subscription plan. */}
            Add at-least one service or product.
          </span>
        </div>
        <Row className="my-2">
          <Col xs={"auto"}>
            <Form.Check
              type="checkbox"
              label="Add Service"
              name="service"
              value="service"
              onClick={handleSelect}
              checked={selected.includes("service")}
              onChange={() => {}}
            />
          </Col>
          <Col xs={"auto"}>
            <Form.Check
              type="checkbox"
              label="Add Product"
              name="product"
              value="product"
              onClick={handleSelect}
              checked={selected.includes("product")}
              onChange={() => {}}
            />
          </Col>
          {/* <Col xs={"auto"}>
            <Form.Check
              type="checkbox"
              label="Add Subscription Plan"
              name="plan"
              value="plan"
              onClick={handleSelect}
              checked={selected.includes("plan")}
              onChange={() => {}}
            />
          </Col> */}
        </Row>
        {selected.map((item, idx) =>
          item === "plan" ? (
            <PlanCard
              key={idx}
              type={item}
              index={idx}
              currentCompanySetting={currentCompanySetting}
            />
          ) : item === "service" ? (
            <ServiceCard
              key={idx}
              type={item}
              index={idx}
              currentCompanySetting={currentCompanySetting}
              services={services}
              setServices={setServices}
              errors={errors?.services || null}
              setErrors={setErrors}
              handleAlertMessage={handleAlertMessage}
            />
          ) : (
            item === "product" && (
              <ServiceCard
                key={idx}
                type={item}
                index={idx}
                currentCompanySetting={currentCompanySetting}
                services={products}
                setServices={setProducts}
                errors={errors?.products || null}
                setErrors={setErrors}
                handleAlertMessage={handleAlertMessage}
              />
            )
          )
        )}
      </Card.Body>
      <Card.Footer className="">
        <div className="d-flex justify-content-between">
          <Button
            variant="outline-secondary"
            disabled={isSubmitting}
            type="button"
            onClick={() =>
              setSetup({
                ...setup,
                addService: { ...setup.addService, status: "completed" },
                assignService: { ...setup.assignService, status: "active" },
              })
            }
          >
            <span>{locale("Skip")}</span>
          </Button>
          <Button onClick={handleSaveServices}>Next</Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

import { useLazyQuery } from "@apollo/client";
import { useEffect, useState, useContext } from "react";
import { FILTER_LIST } from "../GraphApi/Filters";
import {
  currentTeamId,
  getSupportedActionFromJson,
  isObjectEmpty,
  toWordCase,
} from "../Utils/utils";
import { AuthContext } from "../Context/AuthContext";
import { useTranslation } from "react-i18next";

export const useFilters = ({ industry = "service", pageId }) => {
  const { t: locale } = useTranslation(["common"]);
  const { userData } = useContext(AuthContext);
  const [attributes, setAttributes] = useState([]);
  const [getFilters, { data: filterList }] = useLazyQuery(FILTER_LIST, {
    fetchPolicy: "network-only",
  });

  const notInUse = [
    "plan_interested",
    "service_interested",
    "product_interested",
    "offering_interested",
    "subscription_plan",
  ];

  useEffect(() => {
    if (!isObjectEmpty(industry)) {
      getFilters({
        variables: {
          industry: industry,
          entity: "automation",
          team_id: currentTeamId,
        },
      });
    }
  }, [industry, getFilters, pageId]);

  useEffect(() => {
    if (filterList) {
      const adminFilterTrigger = []; // You can define this based on userData
      let filtersAttributes = [];
      let itemGroup = [];

      filterList.filters.forEach((filter) => {
        if (itemGroup.indexOf(filter.group) === -1) {
          itemGroup.push(filter.group);
          filtersAttributes.push({
            label: toWordCase(filter.group),
            options: [],
          });
        }
      });

      filtersAttributes.forEach((group) => {
        filterList.filters.forEach((filter) => {
          if (
            toWordCase(filter.group) === group.label &&
            notInUse.indexOf(filter.name) === -1 &&
            adminFilterTrigger.indexOf(filter.name) === -1
          ) {
            group.options.push({
              value: filter.name,
              label:
                filter.group !== "custom attribute"
                  ? toWordCase(filter.label)
                  : toWordCase(filter.label),
              type: filter.type,
              group: filter.group,
              supported_operators: getSupportedActionFromJson(
                filter.supported_operators
              ),
              sub_type: filter.sub_type,
            });
          }
        });
      });

      setAttributes(filtersAttributes);
    }
  }, [filterList, locale, userData]);

  return { attributes };
};

import React, { useEffect, useState } from "react";
import { CREATE_TAGS, TAGS_LIST } from "../../../../../GraphApi/Tags";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import {
  currentTeamId,
  getValidationMessage,
} from "../../../../../Utils/utils";
import { useTranslation } from "react-i18next";
import { autoHeightMultiValue } from "../../../../../Components/Common/DropDown/Style";

export const TagAction = ({
  handleAlertMessage,
  currentCompanySetting,
  values,
  handleChange,
  handleClose,
}) => {
  const { t: locale } = useTranslation();
  const [tags, setTags] = useState([]);
  const [applyTag, setApplyTag] = useState([]);
  const [removeTag, setRemoveTag] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [errorBag, setErrorBag] = useState(null);
  const [isCreateNew, setIsCreateNew] = useState(false);
  /** GET CAMPAIGN ELEMENT **/
  const [refetchTags, { data: tagsList }] = useLazyQuery(TAGS_LIST, {
    fetchPolicy: "network-only",
    variables: { team_id: currentTeamId },
  });
  const [createTag] = useMutation(CREATE_TAGS);

  useEffect(() => {
    refetchTags();
  }, []);

  useEffect(() => {
    if (tagsList) {
      const allTags = tagsList.allTag.map(
        (item) => item && { value: parseInt(item.id), label: item.name }
      );
      console.log("ALL TAGS:", allTags);
      setTags(allTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsList]);

  const createNewTag = (newTag, selectedTags) => {
    createTag({
      variables: { name: newTag, team_id: currentTeamId },
      // update: () => refetchTags(),
    })
      .then((result) => {
        const tags_list = JSON.parse(result.data.createTag.tags_list);
        selectedTags.push({
          value: parseInt(tags_list[0].id),
          label: tags_list[0].name,
        });
        setIsCreateNew(true);
        refetchTags();
        setApplyTag([...selectedTags]);
        setIsDisable(false);
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage("error", getValidationMessage(graphQLErrors));
        setIsDisable(false);
      });
  };

  const handleTags = (allTags) => {
    setIsDisable(true);
    let selectedTags = [];
    let newTag = [];
    allTags &&
      allTags.forEach((tag) => {
        if (tag.__isNew__) {
          newTag.push(tag.label);
        } else {
          selectedTags.push(tag);
        }
      });

    if (newTag.length) {
      createNewTag(newTag[0], selectedTags);
    } else {
      setApplyTag(selectedTags);
      setIsDisable(false);
    }
  };

  const handleSaveData = () => {
    let newErrors = {};
    if (applyTag.length === 0 && removeTag.length === 0) {
      newErrors = {
        ...newErrors,
        apply_tag: "Select at least one option: apply tag or remove tag.",
      };
      setErrorBag(newErrors);
    } else {
      setErrorBag(null);
      let str = [];
      if (applyTag.length > 0) {
        str.push(<span key="a-1">Apply tag(s) : </span>);
        str.push(
          <span key="a-2" className="text-primary">
            {applyTag.map((item) => item && item.label).join(", ")}
          </span>
        );
      }
      if (removeTag.length > 0) {
        str.push(<span key="r-1"> Removed tag(s) : </span>);
        str.push(
          <span key="r-2" className="text-primary">
            {removeTag.map((item) => item && item.label).join(", ")}
          </span>
        );
      }
      handleChange("action", {
        action: "tag",
        apply_tag:
          applyTag.length > 0 ? applyTag.map((item) => item && item.value) : [],
        remove_tag:
          removeTag.length > 0
            ? removeTag.map((item) => item && item.value)
            : [],
        string: str,
      });
      handleClose();
    }
  };

  useEffect(() => {
    const tagApply = values?.apply_tag || [];
    const tagRemove = values?.remove_tag || [];
    const preSelectedApplyTag = [];
    const preSelectedRemoveTag = [];
    tags.forEach((item) => {
      if (tagApply?.length && tagApply.indexOf(item.value) > -1) {
        preSelectedApplyTag.push(item);
      }
      if (tagRemove?.length && tagRemove.indexOf(item.value) > -1) {
        preSelectedRemoveTag.push(item);
      }
    });
    if (!isCreateNew) {
      setApplyTag(preSelectedApplyTag);
      setRemoveTag(preSelectedRemoveTag);
      setIsCreateNew(false);
    }
  }, [tags, values]);

  const handelCancel = () => {
    handleClose();
  };
  /******/
  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <Form.Group className="form-group">
            <Form.Label>{locale("apply tag")}</Form.Label>
            <CreatableSelect
              placeholder={locale("create or select tag")}
              isMulti={true}
              isSearchable={true}
              isClearable={false}
              isDisabled={isDisable}
              options={tags}
              id="apply_tag"
              name="apply_tag"
              value={applyTag}
              onChange={(e) => {
                handleTags(e);
              }}
              noOptionsMessage={() => locale("Create or select tag")}
              styles={autoHeightMultiValue}
            />
            <InLineError
              type="error"
              message={
                errorBag && errorBag.apply_tag ? errorBag.apply_tag : null
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label>{locale("remove tag")}</Form.Label>
            <Select
              isMulti={true}
              placeholder={locale("select tag")}
              isSearchable={true}
              isClearable={false}
              options={tags}
              id="remove_tag"
              name="remove_tag"
              value={removeTag}
              onChange={(e) => {
                setRemoveTag(e ? e : []);
              }}
              noOptionsMessage={() => locale("select tag")}
              styles={autoHeightMultiValue}
            />
            <InLineError
              type="error"
              message={
                errorBag && errorBag.apply_tag ? errorBag.apply_tag : null
              }
            />
          </Form.Group>
        </Col>
      </Row>{" "}
      <div className="bg-dark text-end sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </Container>
  );
};

import React, { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import XAlertMessage from "../../Common/AlertMessage/XAlertMessage";
import { currentTeamId, isObjectEmpty } from "../../../Utils/utils";
import { saveAgreement, saveTemplate } from "../AgreementHelper";
import { EqupRoundLoaderWithOverlay } from "../../Common/Loader/RoundLoader";
import { ContactDropDown } from "../../Common/Company/ContactDropDown";
import { Navigate } from "react-router";

export const AgreementClone = ({
  locale,
  show,
  handleCloseClone,
  type,
  agreement,
  contactId,
}) => {
  const [newName, setNewName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  /** Alert Message State **/
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const [contact, setContact] = useState(null);
  const [isRedirect, setRedirect] = useState(false);
  const [newAgreement, setNewAgreement] = useState(null);

  useEffect(() => {
    if (
      agreement &&
      agreement.name &&
      (agreement.agreement_id || agreement.agreement_template_id)
    ) {
      setNewName("Copy-" + agreement.name);
    } else {
      setNewName(null);
    }
  }, [agreement]);

  /** Handle Alert Message **/
  const handleAlertMessage = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    setAlertShow(true);
  };

  const handleClone = () => {
    setIsLoading(true);
    if (isObjectEmpty(newName)) {
      handleAlertMessage(
        "error",
        locale("messages:formik.{{name}} is required", {
          name: locale("name"),
        })
      );
      setIsLoading(false);
    } else if (newName.length < 2) {
      handleAlertMessage(
        "error",
        locale("messages:formik.{{name}} min {{minLength}} character long!", {
          name: locale("name"),
          minLength: "2",
        })
      );
    } else if (newName.length > 50) {
      handleAlertMessage(
        "error",
        locale("messages:formik.{{name}} max {{maxLength}} character long!", {
          name: locale("name"),
          maxLength: "50",
        })
      );
    } else if (!contact && contactId === null && type.type === "agreement") {
      handleAlertMessage("error", "Contact is required");
      setIsLoading(false);
    } else {
      if (type.type === "agreement" && agreement && agreement.agreement_id) {
        handleSave(agreement.agreement_id);
      } else if (
        type.type === "template" &&
        agreement &&
        agreement.agreement_template_id
      ) {
        handleSave(agreement.agreement_template_id);
      } else if (agreement && agreement.template_id) {
        /* Create New Agreement From Template */
        saveAgreementFromTemplate({
          name: newName,
          contact_id: contactId ? contactId : contact.id,
          template_id: agreement.template_id,
        });
      }
    }
  };

  const handleSave = (withCloneId = null) => {
    setIsLoading(true);
    let dataToBeProcessed = {
      team_id: currentTeamId,
      name: newName,
      clone_id: withCloneId,
    };
    if (type.type === "agreement") {
      dataToBeProcessed = {
        ...dataToBeProcessed,
        contact_id: contactId ? contactId : contact.id,
      };
    }
    console.log("dataToBeProcessed:", dataToBeProcessed);
    if (type.type === "template")
      saveTemplate(dataToBeProcessed)
        .then((result) => {
          handleAlertMessage(
            "success",
            locale("messages:common.cloned", {
              fieldName: locale("pages:setting.agreements." + type.label),
            })
          );
          setIsLoading(false);
          handleCloseClone();
        })
        .catch((error) => {
          handleAlertMessage("error", error.message);
          setIsLoading(false);
        });
    else if (type.type === "agreement")
      saveAgreement(dataToBeProcessed)
        .then((result) => {
          handleAlertMessage(
            "success",
            locale("messages:common.cloned", {
              fieldName: locale("pages:setting.agreements." + type.label),
            })
          );

          setNewAgreement(result.data);
          setIsLoading(false);
          setRedirect(true);
        })
        .catch((error) => {
          handleAlertMessage("error", error.message);
          setIsLoading(false);
        });
  };

  /** On Create New Form Template **/
  const saveAgreementFromTemplate = (dataToBeProcessed) => {
    setIsLoading(true);
    saveAgreement({ ...dataToBeProcessed, team_id: currentTeamId })
      .then((result) => {
        setNewAgreement(result.data);
        setIsLoading(false);
        setRedirect(true);
      })
      .catch((error) => {
        handleAlertMessage("error", error.message);
        setIsLoading(false);
      });
  };

  if (isRedirect && agreement !== null) {
    return (
      <Navigate
        to={"/agreement-builder"}
        state={{
          type: "agreement",
          mode: "edit",
          agreement_id: newAgreement.agreement_id,
          contact_id: contactId ? contactId : contact.id,
        }}
      />
    );
  }
  /****/
  return (
    <Modal
      className={"common modalMd"}
      show={show}
      onHide={handleCloseClone}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeVariant="white" closeButton className={"bg-primary"}>
        <Modal.Title className={"text-white secondary-font text-uppercase"}>
          {(agreement && agreement.agreement_id) ||
          (agreement && agreement.agreement_template_id)
            ? locale("Clone " + type.label)
            : locale("Create " + type.label)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <EqupRoundLoaderWithOverlay />}
        <Row>
          <Col>
            <XAlertMessage
              type={alertType}
              message={alertMessage}
              setAlertShow={setAlertShow}
              alertShow={alertShow}
            />
          </Col>
        </Row>
        <Form>
          <Row>
            <Col>
              <Form.Group className="form-group" controlId="campaignName">
                <Form.Label>{locale(type.label)}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Agreement Name"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          {type.type === "agreement" && contactId === null && (
            <Row>
              <Col md={12}>
                <ContactDropDown
                  locale={locale}
                  contact={contact}
                  isDisabled={false}
                  isMulti={false}
                  isClearable={true}
                  isSearchable={true}
                  handleContact={(e) => {
                    setContact(e);
                  }}
                />
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={"primary"}
          className={"text-uppercase"}
          onClick={handleClone}
        >
          {locale("create")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../Context/AuthContext";
import { TEAM_EMAIL_LISTING } from "../../../../../GraphApi/Campaigns/TeamEmails";
import {
  checkModPermission,
  currentTeamId,
  emailTemplateForPreview,
  isObjectEmpty,
} from "../../../../../Utils/utils";
import useQueryData from "../../../../../Hooks/useQueryData";
import { AddEmailForVerification } from "../../../../../Components/Pages/Campaign/Builder/Drawer/AddEmailForVerification";
import { GET_EMAIL_TEMPLATES } from "../../../../../GraphApi/Automations/RequiredData";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { SelectDropdown } from "../../../../../Components/Common/Conditions/SelectDropdown";
import { overTheNextElement } from "../../../../../Components/Common/DropDown/Style";
import { EyeSvg } from "../../../../../Components/Common/SvgFiles/EyeSvg";
import { Link, Navigate } from "react-router-dom";
import { EMAIL_BY_ID } from "../../../../../GraphApi/Emails";
import { useLazyQuery } from "@apollo/client";
import { PreviewEmail } from "../../../../../Components/Pages/EmailBuilder/PreviewEmail";
import { EqupRoundLoaderWithOverlay } from "../../../../../Components/Common/Loader/RoundLoader";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";

export const MarketingEmailAction = ({
  handleAlertMessage = () => {},
  automationId = null,
  values,
  handleSave = () => {},
  handleClose = () => {},
  nodeId = null,
}) => {
  const { t: locale } = useTranslation();
  const { userData } = useContext(AuthContext);
  const [emailDetails, setEmailDetails] = useState({
    email_id: null,
  });
  const [linkedEmail, setLinkedEmail] = useState(false);
  const [teamEmails, setTeamEmails] = useState(null);
  const [emailMode, setEmailMode] = useState(null);
  const [myErrors, setMyErrors] = useState(null);
  const [emails, setEmails] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [pageUrl, setPageUrl] = useState(null);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);
  const [errorBag, setErrorBag] = useState(null);
  /*****/
  const handleCloseEmailTemplate = () => setShowEmailTemplate(false);
  const handleShowEmailTemplate = () => setShowEmailTemplate(true);
  const {
    fetchData,
    data: emailTemplates,
    loading: fetchingEmails,
  } = useQueryData(GET_EMAIL_TEMPLATES);

  const {
    fetchData: fetchTeamEmails,
    data: teamEmail,
    loading: fetchingTeamEmails,
  } = useQueryData(TEAM_EMAIL_LISTING);

  const [getEmailById, { loading: fetchingEmail }] = useLazyQuery(EMAIL_BY_ID);

  useEffect(() => {
    fetchTeamEmails({
      variables: { team_id: currentTeamId },
      fetchPolicy: "no-cache",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values?.email_id) {
      setEmailDetails({ email_id: values?.email_id || null });
    }
  }, [values]);

  useEffect(() => {
    if (teamEmail?.teamUserEmailListing) {
      setTeamEmails(teamEmail.teamUserEmailListing);
      if (teamEmail.teamUserEmailListing?.email_verified_at) {
        setLinkedEmail(true);
        fetchEmailTemplate();
      }
    }
  }, [teamEmail]);

  useEffect(() => {
    if (emailTemplates?.getEmailTemplates?.length > 0) {
      setEmails(
        emailTemplates.getEmailTemplates.map(
          (item) =>
            item && {
              value: item.id,
              label: item.name,
              name: item.name,
              subject: item.subject,
            }
        )
      );
    }
  }, [emailTemplates]);
  /*****/
  const handleCloseEmailPreview = () => {
    setPageUrl(null);
    setShowEmailPreview(false);
  };

  const handleShowEmailPreview = () => setShowEmailPreview(true);
  const fetchEmailTemplate = () => {
    fetchData({
      variables: { team_id: currentTeamId, template_type: "campaign" },
      fetchPolicy: "no-cache",
    });
  };

  /** FETCH EMAIL TEMPLATE ***/
  const getEmailTemplate = (templateId) => {
    getEmailById({
      variables: { team_id: currentTeamId, id: parseInt(templateId) },
      fetchPolicy: "no-cache",
    }).then((result) => {
      if (result.data && !result.error) {
        if (result.data) {
          const msg = emailTemplateForPreview(
            result.data.emailTemplate.message,
            result.data.emailTemplate.template_type
          );
          setEmailTemplate(msg);
          handleShowEmailPreview();
        }
      }
    });
  };

  const handleChange = async (key, value) => {
    await setEmailDetails((prevState) => ({
      ...prevState,
      [key]:
        value && Array.isArray(value)
          ? value.map((item) => item.value)
          : value?.value || null,
      [key + "_label"]:
        value && Array.isArray(value)
          ? value.map((item) => item.label).join(", ")
          : value?.label || null,
      ...(key === "email_id"
        ? {
            [key + "_subject"]:
              value && Array.isArray(value)
                ? value.map((item) => item.subject).join(", ")
                : value?.subject || null,
          }
        : {}),
    }));
  };

  const handleCreateEditEmail = (type) => {
    setEmailMode({
      templateId: emailDetails?.email_id || "",
      templateName: emailDetails?.email_id_label || "",
      subject: emailDetails?.email_id_subject || "",
      mode: type,
      campaignId: automationId || null,
      position: nodeId || null,
    });

    if (type === "update") {
      setRedirect(true);
    } else {
      handleShowEmailTemplate();
    }
  };

  const getPreviewEmail = () => {
    if (isObjectEmpty(emailDetails?.email_id)) {
      setMyErrors({ ...myErrors, send_email: "Select Email for preview." });
    } else {
      getEmailTemplate(parseInt(emailDetails?.email_id));
    }
  };

  const handleSaveData = () => {
    if (!emailDetails?.email_id) {
      setErrorBag({ email_id: "Email is required" });
      return;
    } else {
      setErrorBag(null);
      let str = [];

      str.push(<span key="1">Send </span>);
      str.push(
        <span key="2" className="text-primary">
          {emailDetails.email_id_label}
        </span>
      );
      str.push(<span key="3"> Email</span>);

      handleChange("action", {
        action: "send_marketing_email",
        // email_detail: emailDetails,
        ...emailDetails,
        string: str,
      });
      handleClose();
    }
  };

  const handelCancel = () => {
    handleClose();
  };
  /********** RENDER HTML ***********/
  /*** FOR EMAIL BUILDER ***/
  if (redirect && emailMode !== "") {
    return (
      <Navigate
        to="/email-builder"
        state={{
          ...emailMode,
          campaignId: automationId || null,
          position: nodeId || null,
          from: "automation",
        }}
      />
    );
  }
  /*****/
  if (fetchingEmails || fetchingTeamEmails || fetchingEmail) {
    return <EqupRoundLoaderWithOverlay />;
  }

  return (
    <React.Fragment>
      <div>
        {userData?.authData?.currentTeam &&
        checkModPermission(
          userData?.authData?.currentTeam?.lt,
          "white_label"
        ) &&
        !linkedEmail ? (
          <React.Fragment>
            <div className="mx-2" style={{ position: "relative" }}>
              <Container>
                <AddEmailForVerification
                  teamEmails={teamEmails}
                  setTeamEmails={setTeamEmails}
                />
              </Container>
            </div>
            <div className="sticky-on-footer">
              <Button variant="outline-secondary" onClick={handelCancel}>
                Cancel
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <div style={{ position: "relative" }}>
            <Container>
              {userData?.authData?.currentTeam &&
                checkModPermission(
                  userData.authData.currentTeam.lt,
                  "white_label"
                ) && (
                  <Row className="mt-3">
                    <Col>
                      <Form.Group className="form-group">
                        <Form.Label>{locale("From")}</Form.Label>
                        <Form.Control
                          value={
                            teamEmails.name + " <" + teamEmails.email + ">"
                          }
                          readOnly={true}
                          onChange={() => {}}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              <Row>
                <Col>
                  <SelectDropdown
                    label="Select Email"
                    value={emailDetails?.email_id || null}
                    options={emails}
                    isClearable={true}
                    handleChange={(e) => {
                      handleChange("email_id", e);
                    }}
                    placeholder={locale("Select Email")}
                    noOptionsMessage={() => locale("no email")}
                    styles={overTheNextElement}
                  />
                  <InLineError
                    extraStyle={{ marginTop: "-15px" }}
                    type="error"
                    name="email"
                    message={errorBag?.email_id || ""}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                {!emailDetails?.email_id ? (
                  <Link
                    variant="btn-w-icon"
                    className="btn btn-primary btn-ms-icon"
                    to="/email-builder"
                    state={{
                      templateId: emailDetails?.email_id || "",
                      templateName: emailDetails?.email_id_label || "",
                      subject: null,
                      campaignId: automationId || null,
                      position: nodeId || null,
                    }}
                  >
                    <i className={"ri-edit-box-line"}></i>{" "}
                    <span>{locale("Create")}</span>
                  </Link>
                ) : (
                  <React.Fragment>
                    <Button
                      variant="outline-primary text-uppercase"
                      onClick={() => handleCreateEditEmail("update")}
                      size="sm"
                    >
                      <i className={"ri-edit-box-line"}></i>{" "}
                      <span>{locale("edit")}</span>
                    </Button>
                    <Button
                      variant="outline-primary text-uppercase ms-2"
                      onClick={getPreviewEmail}
                      size="sm"
                    >
                      <EyeSvg />
                      <span>{locale("preview")}</span>
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </Container>
          </div>
        )}
      </div>
      <div className="bg-dark text-end sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
      {/** EMAIL PREVIEW **/}
      {showEmailPreview && (
        <PreviewEmail
          showEmailPreview={showEmailPreview}
          data={emailTemplate}
          handleCloseEmailPreview={handleCloseEmailPreview}
          pageUrl={pageUrl}
          setPageUrl={setPageUrl}
          locale={locale}
        />
      )}
    </React.Fragment>
  );
};

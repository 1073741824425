import React from "react";
import { Modal } from "react-bootstrap";

const WhatsappTemplatePreview = ({
  show,
  locale,
  handleClose,
  templateHtml,
}) => {
  return (
    <React.Fragment>
      <Modal
        className={"common"}
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeVariant="white" closeButton className={"bg-primary"}>
          <Modal.Title className={"text-white secondary-font text-uppercase"}>
            Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{templateHtml}</Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default WhatsappTemplatePreview;

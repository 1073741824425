import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  ToggleButton,
} from "react-bootstrap";

export const ClientPortalAction = ({
  values = null,
  handleChange,
  handleClose,
}) => {
  const [checked, setChecked] = useState("0");
  const [radios] = useState([
    { name: "Enable", value: true },
    { name: "Disable", value: false },
  ]);

  const handleSaveData = () => {
    let str = [];
    str.push(
      <span key="1" className="text-primary">
        {checked ? "Enable" : "Disable"}
        {" Customer Portal"}
      </span>
    );

    handleChange("action", {
      action: "enable_customer_portal",
      contact_details: { enable_portal: checked },
      string: str,
    });
    handleClose();
  };

  useEffect(() => {
    if (values) {
      setChecked(values?.contact_details?.enable_portal || false);
    }
  }, [values]);

  const handelCancel = () => {
    handleClose();
  };

  /******/
  return (
    <React.Fragment>
      <Container>
        <Row className="mt-3">
          <Col>
            <Form.Group className="form-group">
              <Form.Label>Client Portal</Form.Label>
              <br />
              <ButtonGroup>
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant="outline-primary"
                    className={checked === radio.value ? "active" : ""}
                    name="radio"
                    value={radio.value}
                    checked={checked.toString() === radio.value.toString()}
                    onChange={(e) => setChecked(e.currentTarget.value)}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <div className="text-end bg-dark sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

import React, { useEffect, useState } from "react";
import { useSlate } from "slate-react";
import { Transforms } from "slate";
import { Button } from "./Toolbar"; // assuming you already have a custom Button
import { Dropdown } from "react-bootstrap"; // or any other dropdown library you prefer
import { getShortCodesList } from "./EditorHelper";
import { currentTeamId } from "../../../../Utils/utils";

// Example list of shortcodes
const SHORTCODES = [
  { label: "First Name", value: "{{first_name}}" },
  { label: "Last Name", value: "{{last_name}}" },
  { label: "Email", value: "{{email}}" }
];

const MailMergeDropdown = () => {
  const editor = useSlate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [shortCodes, setShortCodes] = useState(SHORTCODES);

  useEffect(() => {
    getShortCodesList(null, currentTeamId)
      .then((result) => {
        console.log("result", result);
        setShortCodes(result);
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }, []);

  const handleSelect = (shortcode) => {
    // Insert the chosen shortcode at the current selection
    Transforms.insertText(editor, shortcode);
    setShowDropdown(false);
    
    Transforms.select(editor, editor.selection);
  };

  return (
    <Dropdown
      show={showDropdown}
      onToggle={(isOpen) => setShowDropdown(isOpen)}
      className="toolbar-group"
      drop="down"
      align="end"
      autoClose={true}
    >
      <Dropdown.Toggle as={Button} variant="light" className="toolbar-button fw-normal">
        Mail Merge Fields
      </Dropdown.Toggle>

      <Dropdown.Menu style={{height: "200px", overflowY: "scroll", background: "white"}}>
        {shortCodes.map((item) => (
          <Dropdown.Item
            key={item.value}
            onClick={() => handleSelect(item.value)}
          >
            {item.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MailMergeDropdown;

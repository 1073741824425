import React from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { convertMinutesToHoursAndMinutes } from "../../../Utils/utils";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import InLineError from "../../Common/AlertMessage/InLineError";

const defaultLine = { title: "", description: "", duration: "" };

export const NewServiceTask = ({
  service,
  hasError = () => {},
  handleData = () => {},
  errors,
}) => {
  const { t: locale } = useTranslation();

  const handleDuration = (duration) => {
    return convertMinutesToHoursAndMinutes(duration);
  };

  const handleServiceTask = (index, name, value) => {
    let tasks = service?.tasks || [];
    let newTaskValues = {};
    tasks.forEach((item, idx) => {
      if (idx === index) {
        newTaskValues = { ...item, [name]: value };
      }
    });
    tasks[index] = newTaskValues;
    handleData("tasks", tasks);
  };
  /******/
  return (
    <React.Fragment>
      <h4 className="text-primary">{locale("Service Tasks")}</h4>
      {service?.tasks?.map((task, index) => {
        const duration = handleDuration(task.duration);

        return (
          <Row key={index} className="border mb-1 bg-light">
            <Col>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="form-group">
                    <Form.Label>{locale("Title")}</Form.Label>
                    <Form.Control
                      type="text"
                      name={`tasks[${index}].title`}
                      placeholder={locale("Service Task")}
                      value={task.title}
                      onChange={(e) => {
                        handleServiceTask(
                          index,
                          "title",
                          e?.target?.value || ""
                        );
                      }}
                    />
                    <InLineError
                      type="error"
                      message={errors?.[index]?.title || ""}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="form-group">
                    <Form.Label>{locale("Duration")}</Form.Label>
                    <div className="d-flex">
                      <InputGroup className="me-2">
                        <Form.Control
                          type="number"
                          name={`tasks[${index}].duration`}
                          min={0}
                          max={23}
                          placeholder="Hours"
                          value={duration?.hours || ""}
                          onChange={(e) => {
                            let myDuration = 0;
                            const minutes = duration?.minutes || 0;

                            if (e?.target?.value > 23) {
                              myDuration = 23 * 60 + minutes;
                            } else if (e?.target?.value <= 0) {
                              myDuration = minutes;
                            } else {
                              if (e?.target?.value) {
                                myDuration =
                                  parseInt(e.target.value) * 60 + minutes;
                              } else {
                                myDuration = minutes;
                              }
                            }

                            handleServiceTask(
                              index,
                              "duration",
                              myDuration ? parseInt(myDuration) : null
                            );
                          }}
                        />
                        <InputGroupText>Hrs</InputGroupText>
                      </InputGroup>
                      <InputGroup>
                        <Form.Control
                          className=""
                          type="number"
                          min={0}
                          max={59}
                          value={duration?.minutes || ""}
                          placeholder="Minutes"
                          onChange={(e) => {
                            let myDuration = 0;
                            const minutes = duration?.hours
                              ? parseInt(duration.hours) * 60
                              : 0;

                            if (e?.target?.value >= 59) {
                              myDuration = minutes + 59;
                            } else if (
                              e?.target?.value > 0 &&
                              e?.target?.value < 59
                            ) {
                              myDuration = minutes + parseInt(e.target.value);
                            } else {
                              myDuration = minutes;
                            }

                            handleServiceTask(
                              index,
                              "duration",
                              myDuration ? parseInt(myDuration) : null
                            );
                          }}
                        />
                        <InputGroupText>Mins</InputGroupText>
                      </InputGroup>
                    </div>
                    <InLineError
                      type="error"
                      message={
                        errors?.[index]?.duration
                          ? (() => {
                              const errorMessage = errors?.[index]?.duration;
                              if (
                                errorMessage.includes(
                                  "duration must be a `number`"
                                )
                              ) {
                                return "Duration is required";
                              }

                              const [, s] = errorMessage.split(".");

                              return s ? s.trim() : errorMessage;
                            })()
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className="form-group">
                    <Form.Label>{locale("Description")}</Form.Label>
                    <Form.Control
                      style={{ minHeight: "50px" }}
                      id={"description"}
                      className={hasError("description")}
                      as="textarea"
                      rows={2}
                      placeholder={locale("description") + "..."}
                      name={`tasks[${index}].description`}
                      value={task.description}
                      onChange={(e) => {
                        handleServiceTask(
                          index,
                          "description",
                          e?.target?.value || ""
                        );
                      }}
                    />
                  </Form.Group>
                  <InLineError
                    type="error"
                    message={errors?.[index]?.description || ""}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="auto" sm={1} md={1} style={{ position: "relative" }}>
              <div style={{ position: "relative", height: "100%" }}>
                {service.tasks.length > 1 && (
                  <Button
                    variant="transparent"
                    size="sm"
                    className="mx-0 px-0"
                    style={{ position: "absolute", top: "10px" }}
                    onClick={() => {
                      let allTask = [...service.tasks];
                      allTask.splice(index, 1);
                      handleData("tasks", allTask);
                    }}
                  >
                    <i className="ri-close-circle-line fs-5 text-danger pe-1" />
                  </Button>
                )}
                {service?.tasks?.length - 1 === index && (
                  <Button
                    variant="transparent"
                    className="mx-0 px-0"
                    style={{ position: "absolute", bottom: "10px" }}
                    size="sm"
                    onClick={() => {
                      let allTask = [...service.tasks];

                      allTask.push({
                        ...defaultLine,
                        title: service.name || "",
                      });

                      handleData("tasks", allTask);
                    }}
                  >
                    <i className="ri-add-circle-line fs-5 text-success pe-1" />
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        );
      })}
    </React.Fragment>
  );
};

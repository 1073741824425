import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useDropzone } from "react-dropzone";
import "./fileUpload.css";
import { acceptableType } from "../../../Utils/commonParams";
export const APP_FILE_SIZE = process.env.REACT_APP_FILE_SIZE;

const EditProfileImage = ({
  accept = "*",
  handleUpload,
  handleAlertMessage,
  isMulti = false,
  fileSize,
  extraStyle = {},
}) => {
  const [uploadFileSize, setUploadFileSize] = useState(APP_FILE_SIZE);

  useEffect(() => {
    if (fileSize && fileSize > 0) {
      setUploadFileSize(fileSize);
    }
  }, [fileSize]);
  const acceptedTypes = acceptableType(accept);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes,
    onDrop: (acceptedFile) => {
      if (acceptedFile.length) {
        const file =
          accept === "image"
            ? Object.assign(acceptedFile[0], {
                preview: URL.createObjectURL(acceptedFile[0]),
              })
            : acceptedFile[0];
        if (getFileSize(acceptedFile[0])) {
          handleAlertMessage(
            "error",
            "File size exceeds max upload file size of " +
              uploadFileSize +
              " MB"
          );
        } else {
          handleUpload([file]);
        }
      } else {
        handleAlertMessage(
          "error",
          "File format not supported. Upload a valid file!!"
        );
      }
    },
  });
  /** GEt FILE SIZE **/
  const getFileSize = (file) => {
    const requiredFileSizeInBytes = uploadFileSize * 1024 * 1024;
    if (file.size <= requiredFileSizeInBytes) {
      return false;
    } else {
      return true;
    }
  };
  /***** */
  return (
    <React.Fragment>
      <div className="edit-avtar">
        <div {...getRootProps()} style={extraStyle}>
          <div className="inputHidden">
            <input
              multiple={isMulti}
              accept={acceptedTypes}
              {...getInputProps()}
            />
          </div>
          <i className="ri-edit-box-line"></i>
        </div>
      </div>
    </React.Fragment>
  );
};
EditProfileImage.propTypes = {
  isMulti: PropTypes.bool,
  accept: PropTypes.string,
  handleUpload: PropTypes.func.isRequired,
  handleAlertMessage: PropTypes.func.isRequired,
  fileSize: PropTypes.number,
};

export default EditProfileImage;

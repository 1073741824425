import React from 'react';
import ReactLogin from './ReactLogin';
import { getValidationMessage } from '../../../Utils/utils';
import office365 from '../../../Assets/Images/icons/office-365-icon.svg';
// const tenant = "aa09d047-60ac-4ef2-87e5-530de59235cb";
const clientId = process.env.REACT_APP_OUTLOOK_CLIENT_ID;

// const url = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=http://localhost:3005&response_mode=query&scope=offline_access%20user.read%20mail.read&state=12345`;

export const OutlookAuthentication = ({
  setLinkedAccountState = () => {},
  createEmailCredential,
  handleAlertMessage,
  handleCloseAddEmail,
  updateSteps,
  updateGlobalUserDetail,
  team_id,
  locale,
}) => {
  const postDataToServer = (data) => {
    const dataToPost = {
      variables: {
        emailId: '',
        credentialFor: 'office360',
        code: window.btoa(data.code),
        accessToken: '',
        refreshToken: null,
        tokenExpireDate: null,
        firstTimeUpdate: null,
        team_id: team_id,
      },
    };

    createEmailCredential(dataToPost)
      .then((result) => {
        if (result.data.createEmailCredential) {
          setLinkedAccountState(
            result.data.createEmailCredential.email_id,
            'office360'
          );
          locale(
            'messages:emailConfig.Great now you can send Emails from <br /> {{email}}',
            { email: result.data.createEmailCredential.email_id }
          );

          updateSteps('integrateEmail');
          setTimeout(updateGlobalUserDetail, 5000);
        } else {
          handleAlertMessage(
            'error',
            locale('messages:backend.something went wrong')
          );
        }
        return result.data.createEmailCredential;
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage(
          'error',
          locale('messages:backend.' + getValidationMessage(graphQLErrors))
        );
      });
  };

  return (
    <ReactLogin
      clientId={clientId} // required: 'application id/client id' obtained from https://apps.dev.microsoft.com for your app
      redirectUri={process.env.REACT_APP_URL + '/login-microsoft'} // required: redirectUri registered in https://apps.dev.microsoft.com for your app
      scopes={[
        'openid',
        'profile',
        'user.read',
        'mail.readwrite',
        'mail.send',
        'offline_access',
      ]} //optional: defaults to "user.read" full list is present https://developer.microsoft.com/en-us/graph/docs/concepts/permissions_reference
      responseType='code' //optional: valid values are "token" for `Implicite OAuth flow` and "code" for `Authorization Code flow` defaults to "token"
      cssClass='btn btn-lg btn-w-icon btn-ms-icon me-auto ms-auto integrate-btn' // optional: space separated class names which are applied on the html Button element
      btnContent={ButtonContent(locale)} // optional: can be string or a valid react component which can be rendered inside html Button element
      handleLogin={(data) => postDataToServer(data)} // required: callback to receive token/code after successful login
      locale={locale}
    />
  );
};

const ButtonContent = (locale) => {
  return (
    <>
      <img
        src={office365}
        alt=''
      />{' '}
      <span>Sign in with Office 360</span>
    </>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import Select from "react-select";
import { currentTeamId, isObjectEmpty } from "../../../../../Utils/utils";
import { nonGroupColourStyles } from "../../../../Common/DropDown/Style";
import RoundLoaderWithOverlay from "../../../../Common/Loader/RoundLoader";
import { useLazyQuery } from "@apollo/client";
import { FORM_LIST } from "../../../../../GraphApi/Forms";
import InLineError from "../../../../Common/AlertMessage/InLineError";

export const FormSubmission = ({
  label = "Landing Page",
  value = null,
  handleChange,
  isClearable = false,
  isSearchable = false,
  isMulti = false,
  handleClose = () => {},
}) => {
  const [pages, setPages] = useState([{ value: "any", label: "Any Form" }]);
  const [loading, setLoading] = useState(false);
  const [errorBag, setErrorBag] = useState(null);
  const [form, setForm] = useState(null);

  const [getPages] = useLazyQuery(FORM_LIST);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (value) {
      setForm(value);
    }
  }, [value]);

  const fetchData = () => {
    setLoading(true);
    getPages({
      variables: { team_id: currentTeamId },
      fetchPolicy: "network-only",
    })
      .then((result) => {
        const data = result.data;
        const webForms =
          data?.getAllForms?.length > 0
            ? data.getAllForms.map(
                (item) =>
                  item && {
                    value: item.id,
                    label: item.name,
                  }
              )
            : [];
        setPages([{ value: "any", label: "Any Form" }, ...webForms]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error.message);
        setLoading(false);
      });
  };

  const getDropdownValue = (value) => {
    if (!value) {
      return "";
    } else if (isMulti && Array.isArray(value) && value.length > 0) {
      return pages.filter((item) => value.includes(item.value));
    } else {
      return pages.filter((item) => value === item.value);
    }
  };

  const validate = () => {
    let newErrors = {};
    if (isObjectEmpty(form)) {
      newErrors = {
        ...newErrors,
        form_id: "Form is required.",
      };
    }
    if (Object.keys(newErrors).length > 0) {
      setErrorBag(newErrors);
      return false;
    } else {
      setErrorBag(null);
      return true;
    }
  };

  const handleSave = () => {
    if (validate()) {
      handleChange("form_id", { value: form });
      handleClose();
    }
  };
  /*****/
  return (
    <React.Fragment>
      {loading && <RoundLoaderWithOverlay />}

      <h3 className="text-center mb-3">
        Select the form that will initiate this business flow.
      </h3>
      <div className="mb-4">
        <Form.Group className="form-group">
          <Form.Label>{label}</Form.Label>
          <Select
            isClearable={isClearable}
            isSearchable={isSearchable}
            isMulti={isMulti}
            options={pages}
            value={getDropdownValue(form)}
            onChange={(e) => {
              setForm(e?.value || null);
            }}
            styles={nonGroupColourStyles}
          />
          <InLineError type="error" message={errorBag?.form_id || ""} />
        </Form.Group>
        <div className="text-end">{!value && <Button>Create</Button>}</div>
      </div>

      {!isObjectEmpty(form) && form !== value && (
        <div className="text-end bg-dark sticky-footer">
          <Button
            variant="outline-primary"
            className="mx-2"
            onClick={handleSave}
          >
            Save
          </Button>
          {/* <Button variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button> */}
        </div>
      )}
    </React.Fragment>
  );
};

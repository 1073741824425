import { toSnackCase } from "./utils";

export const fetchUserRole = (userData) => {
  if (userData && userData !== null && userData !== undefined) {
    const { authenticated, authData } = userData;
    if (authenticated && authData.currentTeam) {
      const { id, name, permissions } = authData.currentTeam.pivot.role;
      const currentPermissions =
        permissions.length > 0 &&
        permissions.map(
          (item) =>
            item && {
              id: item.id,
              industry: JSON.parse(item.industry),
              label: item.label,
              name: item.name,
              access_type: item.pivot.access_type,
            }
        );
      return {
        error: null,
        role: { id: id, name: name },
        permissions: currentPermissions,
      };
    } else {
      return {
        error: "User is not authorised.",
        role: null,
        permissions: null,
      };
    }
  }
};

export const hasPermission = (userData, module) => {
  const userRole = fetchUserRole(userData);
  let permissions = null;
  if (
    userRole.error === null &&
    userRole.permissions &&
    userRole.permissions.length > 0
  ) {
    permissions = userRole.permissions.filter(
      (item) => item.name.indexOf(module) > -1
    );
    //console.log(permissions);
    return permissions;
  } else {
    return null;
  }
};

/**** Apply Permissions ****/
export const handlePermissions = (name, pagePermissions) => {
  const checkPermission =
    pagePermissions &&
    pagePermissions.filter((item) => item && item.name.indexOf(name) > -1);
  if (checkPermission && checkPermission.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const getPagePermissions = (userData, module, exactMatch = false) => {
  const currentUserPermission = userData.authData.currentUserPermission;
  let permissions = {};
  if (currentUserPermission && currentUserPermission.length > 0) {
    currentUserPermission.forEach((item) => {
      if (item.name.includes(module)) {
        if (exactMatch) {
          const name = item.name.split(":");
          if (name[0].length === module.length) {
            permissions = {
              ...permissions,
              [toSnackCase(item.label)]: { isAllow: true, value: item },
            };
          }
        } else {
          permissions = {
            ...permissions,
            [toSnackCase(item.label)]: { isAllow: true, value: item },
          };
        }
      }
    });
    return permissions;
  } else {
    return null;
  }
};

// export const getPagePermissions = (userData, module) => {
//   const userRole = fetchUserRole(userData);
//   let permissions = {};
//   if (
//     userRole.error === null &&
//     userRole.permissions &&
//     userRole.permissions.length > 0
//   ) {
//     userRole.permissions.forEach((item) => {
//       if (item.name.indexOf(module) > -1) {
//         permissions = {
//           ...permissions,
//           [toSnackCase(item.label)]: { isAllow: true, value: item },
//         };
//       }
//     });
//     //console.log(permissions);
//     return permissions;
//   } else {
//     return null;
//   }
// };
/***************************/

import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { TriggerRow } from "./TriggerRow";
import { BusinessActions } from "./BusinessActions";

export const BusinessAutomation = ({
  currentCompanySetting,
  handleAlertMessage = () => {},
  setSetup = null,
  setup = () => {},
  userData,
  setFullSetup,
  fullSetup,
  automationData,
  setAutomationData = () => {},
}) => {
  const [automationStep, setAutomationStep] = useState(0);

  const handleNext = () => {
    if (automationData?.triggers?.length === automationStep + 1) {
      setFullSetup({
        ...fullSetup,
        automateBusiness: {
          ...fullSetup.automateBusiness,
          status: "completed",
        },
        automateJobs: {
          ...fullSetup.automateJobs,
          status: "active",
        },
      });
    } else {
      setAutomationStep(automationStep + 1);
    }
  };
  /*****/
  return (
    <Card>
      <Card.Body>
        <h4 className="text-primary mb-1">
          Now that you've set up all channels through which clients reach out to
          you, let's create a business flow to automate your business.
        </h4>
        <TriggerRow
          userData={userData}
          currentCompanySetting={currentCompanySetting}
          handleAlertMessage={handleAlertMessage}
          setSetup={setSetup}
          setup={setup}
          automationData={automationData}
          setAutomationData={setAutomationData}
          automationStep={automationStep}
        />
        <BusinessActions
          userData={userData}
          currentCompanySetting={currentCompanySetting}
          handleAlertMessage={handleAlertMessage}
          setSetup={setSetup}
          setup={setup}
          automationData={automationData}
          setAutomationData={setAutomationData}
          automationStep={automationStep}
        />
      </Card.Body>
      <Card.Footer>
        <div className="text-end">
          <Button onClick={handleNext}>Next</Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

import { RestApi, getIdToken } from "../RestApi/RestApi";
// Booking  Preference listing...
export async function bookingPreference(data = null, operation = null) {
  await getIdToken();
  const url = "/api/booking-preferences";
  return await RestApi.get(url, {
    params: data,
  });
}
export async function saveBookingRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/booking-preferences";
  return await RestApi.post(url, data);
}

export async function updateBookingRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/booking-preferences" + (operation ? "/" + operation : "");
  return await RestApi.put(url, data);
}
export async function bookingPreferenceById(data = null, operation = null) {
  await getIdToken();
  const url = "/api/booking-preferences" + (operation ? "/" + operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}
export async function deleteBookingAvailability(data = null, operation = null) {
  await getIdToken();
  const url = "/api/booking-preferences" + (operation ? "/" + operation : "");
  return await RestApi.delete(url, {
    params: data,
  });
}
export async function clientBookingPage(data = null, operation = null) {
  await getIdToken();
  const url = "/api/get-booking-data" + (operation ? "/" + operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}
export async function offeringBySearch(data = null, operation = null) {
  await getIdToken();
  const url =
    "/api/get-offerings-for-booking-form" + (operation ? "/" + operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}

export async function crateBookingRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/create-booking";
  return await RestApi.post(url, data);
}

/** SERVICE REQUEST **/
export async function getServicesRequests(data = null, operation = null) {
  await getIdToken();
  const url = "/api/all-bookings";
  return await RestApi.get(url, {
    params: data,
  });
}

import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { MAIL_MERGE } from "../../../../../GraphApi/Emails";
import { useLazyQuery, useQuery } from "@apollo/client";
import { currentTeamId } from "../../../../../Utils/utils";
import "../../../../Common/Editor/Editor.scss";
import "../../../../Pages/EmailBuilder/email-builder.scss";
import { newMailMerge } from "../../../../../GraphApi/Automations/Automations";

export const MailMerge = (props) => {
  const [mailMergePopup, setMailMergePopup] = useState(false);
  const [mailMergeFields, setMailMergeFields] = useState([]);
  /*****/
  const [getMailMergeData, { data: mailMergeList }] = useLazyQuery(MAIL_MERGE, {
    variables: {
      team_id: currentTeamId,
      complete_list: props.completeList ? props.completeList : false,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (mailMergeList) {
      const fields = mailMergeList.getMailMergeList.fields;
      const codeList = Object.keys(fields).map(
        (item) => item && { value: item, label: fields[item] }
      );
      setMailMergeFields(codeList);
    }
  }, [mailMergeList]);

  const fetchData = () => {
    if (props.forAutomation) {
      fetchMailMergeData({
        variables: {
          team_id: currentTeamId,
          complete_list: props.completeList ? props.completeList : false,
        },
        fetchPolicy: "network-only",
      });
    } else {
      getMailMergeData();
    }
  };

  const fetchMailMergeData = () => {
    newMailMerge({ team_id: currentTeamId }).then((result) => {
      const fields = result.data.data;
      const codeList = Object.keys(fields).map(
        (item) => item && { value: item, label: fields[item] }
      );
      setMailMergeFields(codeList);
    });
  };
  /*****/
  const handleCode = (code) => {
    if (props.selectedObject) {
      const range = props.selection.getRangeAt(0);
      const textNode = document.createTextNode(code);
      range.deleteContents();
      range.insertNode(textNode);
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      range.collapse(true);
      props.selection.removeAllRanges();
      props.selection.addRange(range);
      handleClick();
    }
  };

  const handleClick = () => {
    setMailMergePopup(!mailMergePopup);
  };

  useEffect(() => {
    fetchData();
  }, []);
  /*******/
  const otherStyle = {
    top: "10px",
  };
  // const iconSize = props.iconSize ? props.iconSize : "sm";
  return (
    <React.Fragment>
      <OverlayTrigger
        key={"mail merge"}
        placement="top"
        overlay={
          <Tooltip id="tooltip-disabled">
            {props.tooltip
              ? props.locale(props.tooltip)
              : props.locale("pages:editorBar.Mail Merge")}
          </Tooltip>
        }
      >
        {props.mode === "iconOnly" ? (
          <Button
            variant="outline"
            size="sm"
            onClick={handleClick}
            disabled={props.isDisabled ? props.isDisabled : false}
          >
            <span>
              <i className="ri-mail-add-line text-size-xxl"></i>
            </span>
          </Button>
        ) : props.mode === "create-popup" ? (
          <Button
            variant="primary"
            className="btn-primary btn-primary-1 h-100 btn-ms-icon"
            onClick={handleClick}
            disabled={props.isDisabled ? props.isDisabled : false}
          >
            <i className="ri-mail-add-line text-size-xxl"></i>
          </Button>
        ) : (
          <button
            onClick={handleClick}
            disabled={props.isDisabled ? props.isDisabled : false}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M22 13h-2V7.238l-7.928 7.1L4 7.216V19h10v2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v9zM4.511 5l7.55 6.662L19.502 5H4.511zM21 18h3v2h-3v3h-2v-3h-3v-2h3v-3h2v3z"
                fill={props.isDisabled ? "#ccc" : ""}
              />
            </svg>
          </button>
        )}
      </OverlayTrigger>

      {mailMergePopup && (
        <div
          className="ed-popover"
          style={
            ["create-popup", "iconOnly"].indexOf(props.mode) > -1
              ? otherStyle
              : {}
          }
        >
          <div className="ed-cover" onClick={handleClick} />
          <div className="eb-popup">
            <div className="eb-popup-header">
              <h4> {props.locale("pages:editorBar.Mail Merge")}</h4>
              <div className="eb-popup-close" onClick={handleClick}>
                <i className="ri-close-fill"></i>
              </div>
            </div>
            <div className="eb-popup-body">
              <div className="eb-mail-merge-inner">
                <ul className="dropdown-list">
                  {mailMergeFields.map((code, index) => (
                    <li
                      key={index}
                      onClick={(e) => {
                        ["create-popup", "iconOnly"].indexOf(props.mode) > -1
                          ? props.getMailMergeValue(code.value)
                          : handleCode(code.value);
                      }}
                    >
                      {code.label}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

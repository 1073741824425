import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { useTranslation } from "react-i18next";
import { createMarkup } from "../../../../../Utils/utils";
import { deserialize } from "../../../../../Components/Common/SlateEditor/Utils/slateUtils";
import SlateWrapperA from "../../../../../Components/Common/SlateEditor/FixedSlateEditor";

export const CreateNoteAction = ({
  values = null,
  handleChange,
  handleClose,
}) => {
  const { t: locale } = useTranslation();
  const [noteDetails, setNoteDetails] = useState({
    title: "",
    description: "",
  });
  const [errorBag, setErrorBag] = useState(null);

  const handleSaveData = () => {
    let newError = {};
    if (!noteDetails?.title) {
      newError = { ...newError, title: "Title is required" };
    }

    if (!noteDetails?.description) {
      newError = { ...newError, description: "Description is required" };
    }

    setErrorBag(newError);

    if (Object.keys(newError).length === 0) {
      setErrorBag(null);
      let str = [];
      str.push(<span key="1">Create Note </span>);
      str.push(
        <span key="2" className="text-primary">
          {noteDetails.title}
        </span>
      );
      str.push(
        <span key="3" className="text-highlighter-dark">
          Where
        </span>
      );
      str.push(
        <span key="4">
          Description : {createMarkup(noteDetails?.description || "", "emoji")}
        </span>
      );
      handleChange("action", {
        action: "create_note",
        note_details: noteDetails,
        string: str,
      });
      handleClose();
    }
  };

  /** Handle Deal Values **/
  const handleNoteDetails = (type, value) => {
    setNoteDetails({ ...noteDetails, [type]: value });
  };

  useEffect(() => {
    if (values?.note_details) {
      setNoteDetails(values.note_details);
    }
  }, [values]);

  const handelCancel = () => {
    handleClose();
  };

  const defaultValue = [
    {
      type: "paragraph",
      children: [{ text: "Type something awesome" }],
    },
  ];

  const initialValue = useMemo(() => {
    const value = noteDetails?.description
      ? deserialize(noteDetails.description)
      : defaultValue;
    const valueArray = Array.isArray(value) ? value : [];
    return valueArray;
  }, [noteDetails.description]);

  const handleEditorChange = async (content, editor) => {
    const customContent = await content.replace(
      /<p>/g,
      '<p style="margin:0;padding:0">'
    );
    handleNoteDetails("description", customContent);
  };

  /******/
  return (
    <React.Fragment>
      <Container className="mt-3">
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>{locale("title")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={locale("Enter the title")}
                value={noteDetails?.title || ""}
                onChange={(e) => handleNoteDetails("title", e.target.value)}
              />
              <InLineError
                type="error"
                name="title"
                message={errorBag?.title || null}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Note Description</Form.Label>
              <SlateWrapperA
                initialValue={initialValue}
                onChange={handleEditorChange}
                style={{
                  minHeight: "150px",
                  alignContent: "flex-start",
                  lineHeight: 1.5,
                  borderRadius: "unset",
                  marginBottom: "60px",
                }}
                showMailMergeOption={false}
              />
              <InLineError
                type="error"
                name="description"
                message={errorBag?.description || null}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <div className="text-end bg-dark sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

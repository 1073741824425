import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { InventoryConsumption } from "../Context/InventoryConsumption";
import { CHECK_INVENTORY_FOR_STATUS } from "../GraphApi/Users/Inventory";
import { currentTeamId } from "../Utils/utils";

const defaultInventory = [
  "contact",
  "customized_dashboard",
  "pipeline",
  "deal",
  "workflow",
  "campaign",
  "landing_page",
  "user",
];

export default function useCheckInventoryConsumption(inventory_type) {
  // set state for show/hide modal...
  const [showModelForEntity, setShowModelForEntity] = useState(null);
  // set show/hide value of buy addon/plan model and set inventory type via context...
  const { setShowBuyAddonPlan, setInventoryType, setUpgradeAvailableFor } =
    useContext(InventoryConsumption);
  // check if user still have inventory or not...
  const [
    fetchCheckInventoryForStatus,
    { data: checkInventoryStatus, loading },
  ] = useLazyQuery(CHECK_INVENTORY_FOR_STATUS, { fetchPolicy: "no-cache" });
  // fetch inventory status from backend...
  const fetchInventoryStatus = () => {
    let mInventoryType = inventory_type;
    if (inventory_type === "document") {
      mInventoryType = "space";
    } else if (inventory_type === "plan") {
      mInventoryType = "product";
    }

    if (defaultInventory.indexOf(inventory_type) > -1) {
      setShowModelForEntity(true);
      setInventoryType(null);
      setShowBuyAddonPlan(false);
    } else {
      fetchCheckInventoryForStatus({
        variables: { team_id: currentTeamId, inventory_type: mInventoryType },
        fetchPolicy: "no-cache",
      });
    }
    // setShow(true);
  };
  // based of inventory status fetched from backend, show models required....
  useEffect(() => {
    if (
      checkInventoryStatus &&
      checkInventoryStatus.checkInventoryStatus &&
      !loading
    ) {
      if (checkInventoryStatus.checkInventoryStatus.status === true) {
        setShowModelForEntity(true);
        setInventoryType(null);
        setShowBuyAddonPlan(false);
      } else {
        setShowModelForEntity(false);
        setInventoryType(inventory_type);
        if (inventory_type !== "space" && inventory_type !== "plan") {
          setShowBuyAddonPlan(true);
        }
        setUpgradeAvailableFor(
          checkInventoryStatus.checkInventoryStatus.upgrade_available_for
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInventoryStatus]);

  return [
    showModelForEntity,
    setShowModelForEntity,
    fetchInventoryStatus,
    setShowBuyAddonPlan,
  ];
}

import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";

import { currencies } from "../../../Utils/CurrencyListWithSymbols";

import { nonGroupColourStyles } from "../../../Components/Common/DropDown/Style";

export const SelectCurrencyFormat = ({
  label = "Set Currency",
  showLabel = true,
  iconClass = "ri-money-dollar-circle-line",
  value = "",
  getCurrencyFormat = () => {},
  locale,
}) => {
  const [currenciesData, setCurrenciesData] = useState([]);

  useEffect(() => {
    let currenciesObj = [];

    currencies.forEach((item) => {
      currenciesObj.push({
        label: item.name + " ( " + item.code + " / " + item.symbol + " )",
        value: item.code,
      });
    });
    setCurrenciesData(currenciesObj);
  }, []);

  /******/
  return (
    <Form.Group className="form-group">
      {showLabel ? <Form.Label>{locale(label)}</Form.Label> : ""}
      <InputGroup>
        <InputGroup.Text>
          <i className={iconClass}></i>
        </InputGroup.Text>
        <Select
          options={currenciesData}
          placeholder={locale(label)}
          value={
            currenciesData &&
            currenciesData.filter(
              (item) => item.value.toLowerCase() === value
            )[0]
          }
          onChange={(e) => getCurrencyFormat(e)}
          styles={nonGroupColourStyles}
        />
      </InputGroup>
    </Form.Group>
  );
};

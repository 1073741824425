import React, { useEffect, useState } from "react";
/* Retrieve Data From Server */
import { useLazyQuery } from "@apollo/client";
import Select from "react-select";
/** QUERIES**/
import { PRODUCT_ALL } from "../../../GraphApi/ProductList";
import {
  nonGroupColourStyles,
  autoHeightMultiValue,
  nonGroupColourStylesPreview,
} from "../../Common/DropDown/Style";
import { currentTeamId } from "../../../Utils/utils";
import { useTranslation } from "react-i18next";

export const ProductDropdown = ({
  handleOnChange,
  isMulti = false,
  isSearchable = false,
  isClearable = false,
  name = "select-1",
  type = ["physical", "digital", "service"],
  noOptions = "Product",
  value = "",
  isDisabled = false,
  fromPage = "",
  isOnPreviewPage = false,
  styles = null,
}) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const [optionMessage, setOptionMessage] = useState("No " + noOptions);
  const [state, setState] = useState({
    typingTimeout: 0,
  });
  const [products, setProducts] = useState([]);
  /** Fetch Product List **/
  const [getProducts, { data: allProducts, loading }] = useLazyQuery(
    PRODUCT_ALL,
    { fetchPolicy: "no-cache" }
  );

  const fetchProducts = (search = "") => {
    getProducts({
      variables: { productType: type, search: search, team_id: currentTeamId },
      fetchPolicy: "no-cache",
    });
  };

  useEffect(() => {
    if (products && products.length === 0) {
      fetchProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (allProducts) {
      const data = allProducts.allProducts;

      let servicesList = [];
      let productsList = [];
      if (data && data.length > 0) {
        data.forEach((product) => {
          if (product && product.product_type === "service") {
            servicesList.push({
              value: product.id,
              label: product.name,
              price: product.price,
              type: product.type,
              product_type: product.product_type,
              description: product.description,
              image: product.image,
              tax_applicable: product.tax_applicable,
              tax: product.tax,
              cost: product.cost,
              product_id: product.product_id,
              service_task_items: product.service_task_items,
            });
          } else {
            productsList.push({
              value: product.id,
              label: product.name,
              price: product.price,
              type: product.type,
              product_type: product.product_type,
              description: product.description,
              image: product.image,
              tax_applicable: product.tax_applicable,
              tax: product.tax,
              cost: product.cost,
              product_id: product.product_id,
            });
          }
        });
      }
      setProducts([
        {
          label: (
            <label className="font-weight-bold p-1 text-primary">
              Services
            </label>
          ),
          options: servicesList,
        },
        {
          label: (
            <label className="font-weight-bold p-1 text-primary">
              Products
            </label>
          ),
          options: productsList,
        },
      ]);
      data.length === 0 && setOptionMessage("No " + noOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProducts]);

  /* Handle Search Product*/
  const getSearchValue = (searchedText) => {
    if (searchedText.length >= 3) {
      const inputValue = searchedText;
      clearTimeout(state.typingTimeout);
      setState({
        typingTimeout: setTimeout(function () {
          fetchProducts(inputValue);
        }, 500),
      });
      return inputValue;
    } else if (searchedText.length > 0 && searchedText.length < 3) {
      setOptionMessage("Type at least 3 character");
    } else {
      fetchProducts();
    }
  };

  return (
    <Select
      placeholder={locale(noOptions.toLowerCase())}
      isMulti={isMulti}
      isClearable={isClearable}
      isSearchable={isSearchable}
      options={products}
      name={name}
      value={value ? value : ""}
      onInputChange={getSearchValue}
      onChange={handleOnChange}
      noOptionsMessage={() =>
        loading ? locale("loading") : locale(optionMessage.toLowerCase())
      }
      isDisabled={isDisabled}
      styles={
        styles
          ? styles
          : fromPage === "workflow"
            ? autoHeightMultiValue
            : isOnPreviewPage
              ? nonGroupColourStylesPreview
              : nonGroupColourStyles
      }
    />
  );
};

import { gql } from "@apollo/client";
import { getIdToken, RestApi } from "../../RestApi/RestApi";

export const AUTOMATION_ELEMENTS = gql`
  query getAllElements($team_id: Int) {
    getAllElements(team_id: $team_id) {
      id
      name
      element_type
      element_options
    }
  }
`;

export const PHONE_NUMBER = gql`
  query getPhoneNumber($team_id: Int) {
    fetchCallingAccount(team_id: $team_id) {
      number {
        twilio_number_id
        address_requirements
        beta
        voice_capabilities
        sms_capabilities
        mms_capabilities
        fax_capabilities
        phone_number
        sms_application_sid
        voice_application_sid
        voice_receive_mode
        status
        created_at
        updated_at
      }
      incomingNumber {
        team_id
        number
        channel
        via_service_sid
        verified_at
      }
      friendly_name
      twilio_id
      auth_token
      status
      type
      created_at
      updated_at
    }
  }
`;

export const BROADCAST_CONTACTS_LIST = gql`
  query contactSearchFilter(
    $team_id: Int
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $filter: [CommonFilter!]
  ) {
    contactSearchFilter(
      team_id: $team_id
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      filter: $filter
    ) {
      data {
        id
        name
        email
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;

export const GET_AUTOMATION = gql`
  query ($id: Int, $team_id: Int) {
    campaign(id: $id, team_id: $team_id) {
      id
      content
      repeat_automation
      stop_on_response
      stop_on_appointment_scheduled
      campaign_meta {
        id
        communication_time_window
        created_at
        updated_at
      }
      campaign_trigger {
        id
        campaign_id
        campaign_item_detail {
          id
          element_details
        }
      }
      campaign_items {
        id
        campaign_id
        campaign_item_detail {
          id
          element_details
        }
        campaign_item_parent {
          id
          pivot {
            child_id
            campaign_item_id
          }
        }
        campaign_item_children {
          id
          pivot {
            child_id
            campaign_item_id
          }
        }
        condition
        delay_timer
      }
      name
      status
      tested
      has_invalid_trigger
    }
  }
`;
export async function removeAutomation(data, operation = null) {
  await getIdToken();
  const url = "/api/campaigns" + (operation ? "/" + operation : "");
  return await RestApi.delete(url, {
    params: data,
  });
}

export async function saveAutomation(data = null, operation = null) {
  await getIdToken();
  const url = "/api/campaigns";
  return await RestApi.post(url, data);
}

export async function updateAutomation(data = null, operation = null) {
  await getIdToken();
  const url = "/api/campaigns" + (operation ? "/" + operation : "");
  return await RestApi.put(url, data);
}

export async function newMailMerge(data = null, operation = null) {
  await getIdToken();
  const url = "/api/mail-merge-email-sms-whatsapp";
  return await RestApi.get(url, {
    params: data,
  });
}

export async function cloneAutomation(data = null, operation = null) {
  await getIdToken();
  const url = "/api/campaigns";
  return await RestApi.post(url, data);
}

/** Campaign listing **/
export const AUTOMATION_LIST = gql`
  query campaignListing(
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $team_id: Int
  ) {
    campaignListing(
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      team_id: $team_id
    ) {
      data {
        id
        content
        campaign_trigger {
          id
          campaign_id
          campaign_item_detail {
            id
            element_details
          }
        }
        campaign_items {
          id
          campaign_id
          campaign_item_detail {
            id
            element_details
          }
          campaign_item_parent {
            id
            pivot {
              child_id
              campaign_item_id
            }
          }
          campaign_item_children {
            id
            pivot {
              child_id
              campaign_item_id
            }
          }
          condition
          delay_timer
        }
        name
        status
        tested
        has_invalid_trigger
        sent_count
        opened_count
        clicked_count
        bounced_count
        delivered_count
        spammed_count
        leads_count
        visits_count
        unsubscribed_count
        active_contacts_count
        exited_contacts_count
        created_at
        updated_at
        sms_count
        sms_delivered_count
        sms_failed_count
        whatsapp_sent_count
        whatsapp_delivered_count
        whatsapp_failed_count
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

export const AUTOMATION_TEMPLATES = gql`
  query getAutomationTemplates($group: String, $team_id: Int) {
    getAllCampaignTemplates(group_name: $group, team_id: $team_id) {
      id
      name
      group_name
      description
      icon
      campaign_template_items {
        id
        campaign_template_id
        element_id
        element_type
        element_details
        trigger_mode
        trigger_date
        trigger_interval
        trigger_interval_unit
        parent_id
        decision_path
        created_at
        updated_at
      }
    }
  }
`;
export const ACTIVE_AUTOMATION_LIST = gql`
  query getAllCampaigns($team_id: Int) {
    getAllCampaigns(team_id: $team_id) {
      id
      name
      status
    }
  }
`;

import React, { useState } from "react";
import { OutlookAuthentication } from "../../Pages/Dashboard/OutlookAuthentication";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import XAlertMessage from "../../Common/AlertMessage/XAlertMessage";
import { EMAIL_CONNECT } from "../../../GraphApi/EmailConnect";

import "../../Common/SignInButton/google-css.scss";

export const EmailIntegration = ({
  setLinkedAccountState,
  setAddImapSmtpDrawer,
  setSmtpForGoogle = () => {},
  team_id,
  locale,
}) => {
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);

  const [createEmailCredential] = useMutation(EMAIL_CONNECT);

  const handleAlertMessage = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
  };

  return (
    <>
      <div className="mb-4">
        <XAlertMessage
          type={alertType}
          message={alertMessage}
          setAlertShow={setAlertShow}
          alertShow={alertShow}
        />
      </div>
      <Button
        variant="btn-lg btn-w-icon btn-ms-icon me-auto ms-auto integrate-btn integrate-mail-btn"
        onClick={() => {
          setAddImapSmtpDrawer(true);
          setSmtpForGoogle(true);
        }}
      >
        <i className="ri-google-fill ri-xl"></i>
        <span>{"Sign in with Google"}</span>
      </Button>

      <span className={"or-divider text-uppercase"}>{locale("OR")}</span>
      <OutlookAuthentication
        setLinkedAccountState={setLinkedAccountState}
        createEmailCredential={createEmailCredential}
        handleAlertMessage={handleAlertMessage}
        handleCloseAddEmail={() => {}}
        updateSteps={() => {}}
        updateGlobalUserDetail={() => {}}
        team_id={team_id}
        locale={locale}
      />
      <span className={"or-divider text-uppercase"}>{locale("OR")}</span>
      <Button
        variant="btn-lg btn-w-icon btn-ms-icon me-auto ms-auto integrate-btn integrate-mail-btn"
        onClick={() => {
          setAddImapSmtpDrawer(true);
        }}
      >
        <i className={"ri-mail-fill ri-xl"}></i>
        <span>{"Sign in with Others"}</span>
      </Button>
    </>
  );
};

import { useState, useEffect, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import { PHONE_NUMBER } from "../GraphApi/Automations/Automations";

import { currentTeamId } from "../Utils/utils";
import { getSMSWithoutPaginate } from "../RestApi/SMSApi";

export const useSMSData = () => {
  const [messages, setMessages] = useState([]);
  const [phoneNumberData, setPhoneNumberData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasEnable, setHasEnable] = useState(null);

  const [getPhoneNumber] = useLazyQuery(PHONE_NUMBER, {
    fetchPolicy: "network-only",
  });

  const fetchPhoneNumberData = useCallback(() => {
    setLoading(true);
    getPhoneNumber({ variables: { team_id: currentTeamId } })
      .then((result) => {
        setPhoneNumberData(result?.data?.fetchCallingAccount || null);
        setHasEnable(
          result?.data?.fetchCallingAccount?.number?.sms_application_sid || true
        );
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [getPhoneNumber]);

  const fetchSMSMessages = useCallback(() => {
    setLoading(true);
    getSMSWithoutPaginate({
      team_id: currentTeamId,
      template_type: "campaign",
    })
      .then((result) => {
        const data = result?.data?.data || [];

        setMessages(
          data.map(
            (item) =>
              item && {
                value: item.id,
                label: item.name,
                message: item.message,
              }
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  // Refetch messages manually
  const refetchMessages = useCallback(() => {
    fetchSMSMessages();
  }, [fetchSMSMessages]);

  useEffect(() => {
    fetchPhoneNumberData();
  }, [fetchPhoneNumberData]);

  // Automatically fetch messages when hasEnable changes to a truthy value
  useEffect(() => {
    if (hasEnable) {
      fetchSMSMessages();
    }
  }, [hasEnable, fetchSMSMessages]);

  return {
    phoneNumberData,
    messages,
    loading,
    error,
    hasEnable,
    refetchMessages,
  };
};

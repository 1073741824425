import { RestApi, getIdToken } from '../RestApi/RestApi';

export async function postEmailAction(data, emailOperation = null) {
  await getIdToken();
  const url = emailOperation ? '/api/email/' + emailOperation : '/api/email/';
  return await RestApi.post(url, data);
}

export async function generateImageViaAI(data, emailOperation = null) {
  await getIdToken();
  const url = emailOperation
    ? '/api/generate-image' + emailOperation
    : '/api/generate-image';
  return await RestApi.post(url, data);
}

import { gql } from "@apollo/client";

/** Lead owner listing **/
export const LEAD_OWNER_LIST = gql`
  query getAllLeadOwner($team_id: Int, $is_setup_calendar_scheduling: Boolean) {
    allUsers(
      team_id: $team_id
      is_setup_calendar_scheduling: $is_setup_calendar_scheduling
    ) {
      id
      team_user_id
      first_name
      name
      booking_availability {
        booking_availability_id
      }
    }
  }
`;

/** Assign Selected Contacts**/
export const ASSIGN_LEAD_OWNER = gql`
  mutation contactAssigned(
    $leadOwner: Int!
    $type: String
    $unSelected: [ContactIds!]
    $selected: [ContactIds!]
    $search: String
    $filter: [CommonFilter!]
    $team_id: Int
  ) {
    assignLeadOwner(
      team_id: $team_id
      type: $type
      unchecked_contact_ids: $unSelected
      checked_contact_ids: $selected
      search: $search
      filter: $filter
      assign_lead_owner: $leadOwner
    ) {
      status
    }
  }
`;

/** Get all Contacts Source**/
export const GET_ALL_CONTACT_SOURCE = gql`
  query getAllSource($team_id: Int) {
    allSource(team_id: $team_id) {
      id
      name
    }
  }
`;
/**  Create Source **/
export const CREATE_CONTACT_SOURCE = gql`
  mutation addNewSource($name: String, $team_id: Int) {
    createSource(name: $name, team_id: $team_id) {
      id
      name
    }
  }
`;

import React from 'react';

const Summary = ({
  importId,
  handleAlertMessage,
  getSummary,
  summary,
  importInProgress,
  importError,
  module,
  locale,
}) => {
  return (
    <h3 className='text-center'>
      {locale('pages:import.import_summary_' + module)}
      <br />
      {locale('we will notify you once done.')}
    </h3>
  );
};

export default Summary;

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { LEAD_OWNER_LIST } from "../../../../../GraphApi/LeadOwner";

import { useTranslation } from "react-i18next";
import {
  autoHeightMultiValue,
  nonGroupColourStyles,
} from "../../../../../Components/Common/DropDown/Style";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { currentTeamId, toWordCase } from "../../../../../Utils/utils";

export const AssignContactOwnerAction = ({
  handleAlertMessage,
  currentCompanySetting,
  values,
  handleChange,
  handleClose,
}) => {
  const { t: locale } = useTranslation();
  const [triggers] = useState([
    { value: "assign_contact_owner", label: "Assign Contact Owner" },
    { value: "assign_via_round_robin", label: "Assign Round Robin" },
  ]);
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [contactOwners, setContactOwners] = useState([]);
  const [contactOwner, setContactOwner] = useState(null);
  const [notifyUser, setNotifyUser] = useState("no");
  const [errorBag, setErrorBag] = useState(null);
  /** GET LEAD OWNER **/
  const { data: ownerList } = useQuery(LEAD_OWNER_LIST, {
    variables: { team_id: currentTeamId },
    fetchPolicy: "network-only",
  });

  /**Get Data and Put into Page**/
  useEffect(() => {
    if (contactOwners.length && values) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, contactOwners]);

  /** ON Component Update**/
  useEffect(() => {
    if (ownerList) {
      const allLeadOwner = ownerList.allUsers.map(
        (item) => item && { value: item.id, label: item.name }
      );
      setContactOwners(allLeadOwner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerList]);

  /**Get Data and Put into Page**/
  const loadData = () => {
    setNotifyUser(values?.notify || "no");
    const preSelectedTrigger = values?.owner_type || "assign_contact_owner";
    setSelectedTrigger(preSelectedTrigger);
    /*****/
    const userApplied = contactOwners.filter(
      (item) => item.value === values?.contact_owner
    );
    setContactOwner(userApplied.length > 0 ? userApplied[0] : null);
  };

  const handleSaveData = () => {
    let newErrors = {};
    if (!selectedTrigger) {
      newErrors = {
        ...newErrors,
        action: "Action is required",
      };
    }
    if (selectedTrigger === "assign_contact_owner" && !contactOwner) {
      newErrors = {
        ...newErrors,
        contact_owner: "Contact owner is required",
      };
    }
    setErrorBag(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setErrorBag(null);
      let str = [];
      if (selectedTrigger) {
        if (selectedTrigger !== "assign_contact_owner") {
          str.push(
            <span key={2} className="text-primary">
              {toWordCase(selectedTrigger)}
            </span>
          );
        } else if (contactOwner !== "") {
          str.push(<span key={3}>{"Assign "}</span>);
          str.push(
            <strong key={4} className="text-primary">
              {contactOwner.label}
            </strong>
          );
          str.push(<span key={5}>{" as contact owner "}</span>);
        }
      }
      if (notifyUser === "yes") {
        str.push(
          <span key={6} className={"text-highlighter-dark"}>
            {locale("AND")}
          </span>
        );
        str.push(<span key={7}>{" Notify "}</span>);
        if (contactOwner === "") {
          str.push(<span key={8}>{" contact owner "}</span>);
        } else {
          str.push(
            <span key={8} className="text-primary">
              {contactOwner?.label || ""}
            </span>
          );
        }
        str.push(
          <span key={9}>{" " + locale("when a contact assigned")}</span>
        );
      }

      handleChange("action", {
        action: "assign_contact_owner",
        contact_owner: contactOwner.value,
        notify: notifyUser,
        owner_type: selectedTrigger,
        string: str,
      });
      handleClose();
    }
  };

  const handleData = (type, value) => {
    if (type === "trigger") {
      setSelectedTrigger(value);
      setContactOwner("");
    } else if (type === "owner") {
      setContactOwner(value);
    } else if (type === "notify") {
      setNotifyUser(value === notifyUser ? "no" : "yes");
    }
  };
  const handelCancel = () => {
    handleClose();
  };
  /**RENDER HTML**/
  return (
    <React.Fragment>
      <Container className="mt-3">
        <Row>
          <Col>
            {triggers.map((item, idx) => (
              <React.Fragment key={idx}>
                <div key={idx}>
                  <Form.Check
                    inline
                    type={"radio"}
                    label={locale(item.label)}
                    value={item.value}
                    checked={selectedTrigger === item.value}
                    onChange={(e) => handleData("trigger", e.target.value)}
                  />
                </div>

                {selectedTrigger === "assign_contact_owner" &&
                selectedTrigger === item.value ? (
                  <Form.Group className="form-group">
                    <Form.Label>{locale("contact owner")}</Form.Label>
                    <Select
                      placeholder={locale("contact owner")}
                      isMulti={false}
                      isSearchable={true}
                      isClearable={false}
                      options={contactOwners}
                      name="contact_owner"
                      value={contactOwner}
                      onChange={(e) => {
                        handleData("owner", e);
                        //setContactOwner(e);
                      }}
                      noOptionsMessage={() => {
                        locale("no contact owner");
                      }}
                      styles={{
                        ...nonGroupColourStyles,
                        ...autoHeightMultiValue,
                      }}
                    />
                    <InLineError
                      type="error"
                      message={errorBag?.action || null}
                    />
                    {errorBag?.contact_owner ? (
                      <InLineError
                        type="error"
                        message={errorBag?.contact_owner || null}
                      />
                    ) : (
                      ""
                    )}
                  </Form.Group>
                ) : (
                  ""
                )}
              </React.Fragment>
            ))}
          </Col>
        </Row>

        <Row>
          <Col>
            <div>
              <Form.Check
                inline
                type={"checkbox"}
                label={locale("notify contact owner")}
                value={"yes"}
                checked={notifyUser === "yes"}
                onChange={(e) => handleData("notify", e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bg-dark text-end sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

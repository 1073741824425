import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { isObjectEmpty } from "../../../../../Utils/utils";
import InLineError from "../../../../Common/AlertMessage/InLineError";

export const ChatBox = ({
  value = null,
  handleChange = () => {},
  handleClose = () => {},
}) => {
  const [errorBag, setErrorBag] = useState(null);
  const [chat, setChat] = useState(null);

  useEffect(() => {
    if (value) {
      setChat(value);
    }
  }, [value]);

  const validate = () => {
    let newErrors = {};
    if (isObjectEmpty(chat)) {
      newErrors = {
        ...newErrors,
        chat: "Trigger is required.",
      };
    }
    if (Object.keys(newErrors).length > 0) {
      setErrorBag(newErrors);
      return false;
    } else {
      setErrorBag(null);
      return true;
    }
  };

  const handleSave = () => {
    if (validate()) {
      handleChange("chat_box", { value: chat });
      handleClose();
    }
  };
  /*****/
  return (
    <React.Fragment>
      <h3 className="text-center mb-3">
        Select the trigger that will start this business flow
      </h3>
      <div className="mb-4 pb-4">
        <div className="d-flex justify-content-center">
          <div
            className="border mx-3 p-2 rounded-2"
            onClick={() => setChat("new_lead")}
          >
            <Form.Check
              name="chat_box"
              type="radio"
              label="New Lead Generated"
              value={"new_lead"}
              checked={chat === "new_lead"}
              onClick={() => setChat("new_lead")}
            />
            <InLineError
              type="error"
              message={errorBag?.chat || ""}
              extraStyle={{ marginTop: "10px" }}
            />
          </div>
          <div
            className="border mx-3 p-2 rounded-2"
            onClick={() => setChat("appointment_scheduled")}
          >
            <Form.Check
              name="chat_box"
              type="radio"
              label="Appointment Scheduled"
              value={"appointment_scheduled"}
              checked={value === "appointment_scheduled"}
              onClick={() => setChat("appointment_scheduled")}
            />
          </div>
        </div>
      </div>
      {!isObjectEmpty(chat) && chat !== value && (
        <div className="text-end bg-dark sticky-footer ">
          <Button
            variant="outline-primary"
            className="mx-2"
            onClick={handleSave}
          >
            Save
          </Button>
          {/* <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button> */}
        </div>
      )}
    </React.Fragment>
  );
};

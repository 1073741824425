import React, { useEffect, useState } from "react";
import { SelectDropdown } from "../../../../../Components/Common/Conditions/SelectDropdown";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { DOCUMENT_CATEGORY_LIST } from "../../../../../GraphApi/TaskAndDocuments";
import { currentTeamId } from "../../../../../Utils/utils";
import { useLazyQuery } from "@apollo/client";

export const RequestDocumentAction = ({
  handleAlertMessage = () => {},
  currentCompanySetting = null,
  values = null,
  handleChange,
  handleClose,
  defaultCategory = [],
}) => {
  const [documentDetails, setDocumentDetails] = useState(null);
  const [errorBag, setErrorBag] = useState(null);
  const [categories, setCategories] = useState([]);

  const [getCategory, { data }] = useLazyQuery(DOCUMENT_CATEGORY_LIST, {
    fetchPolicy: "network-only",
  });

  const fetchCategory = () => {
    getCategory({
      variables: { team_id: currentTeamId },
      fetchPolicy: "network-only",
    });
  };

  useEffect(() => {
    fetchCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && data.allDocumentCategory) {
      const uploadCat =
        data.allDocumentCategory.length > 0
          ? data.allDocumentCategory.map(
              (item) =>
                item && { ...item, value: parseInt(item.id), label: item.name }
            )
          : [];
      setCategories([...defaultCategory, ...uploadCat]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSaveData = () => {
    let newError = {};
    if (!documentDetails?.document_category_id) {
      newError = {
        ...newError,
        document_category_id: "Document category is required",
      };
    } else if (!documentDetails?.title) {
      newError = { ...newError, title: "Document name is required" };
    }
    setErrorBag(newError);

    if (Object.keys(newError).length === 0) {
      setErrorBag(null);
      let str = [];
      if (documentDetails?.title) {
        str.push(
          <span key="1" className="text-primary">
            {documentDetails.title}
          </span>
        );
        str.push(<span key="2"> document is requested to upload.</span>);
      }

      handleChange("action", {
        action: "request_document",
        request_document_details: documentDetails,
        string: str,
      });
      handleClose();
    }
  };

  const handleDetail = (type, e) => {
    if (type === "name") {
      setDocumentDetails({
        document_category_id: e.value,
        title: null,
      });
    } else if (type === "value") {
      setDocumentDetails({
        ...documentDetails,
        title: e?.target?.value || "",
      });
    }
  };

  useEffect(() => {
    if (values?.request_document_details) {
      setDocumentDetails(values.request_document_details);
    }
  }, [values]);

  const handelCancel = () => {
    handleClose();
  };
  /******/
  return (
    <Container>
      <Row>
        <Col className="mt-3">
          <SelectDropdown
            label="Select Document Category"
            options={categories}
            value={documentDetails?.document_category_id || null}
            handleChange={(e) => handleDetail("name", e)}
          />
          <InLineError
            extraStyle={{ marginTop: "-15px" }}
            type="error"
            name="document_category"
            message={errorBag?.document_category_id || ""}
          />

          <Form.Group className="form-group mt-3">
            <Form.Label>{`Enter Document Name To Be Requested`}</Form.Label>
            <Form.Control
              name="document_name"
              value={documentDetails?.title || ""}
              onChange={(e) => handleDetail("value", e)}
            />
            <InLineError
              type="error"
              name="document_name"
              message={errorBag?.title || ""}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="text-end bg-dark sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </Container>
  );
};

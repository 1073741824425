import React from "react";
import { Form } from "react-bootstrap";
import { createMarkup } from "../../../Utils/utils";

import XFileUpload from "../FileUpload/XFileUpload";
const contactSampleData = "./assets/sample-contact-data.csv";
const productSampleData = "./assets/sample-products-data.csv";
const serviceSampleData = "./assets/sample-services-data.csv";

const ImportContacts = ({
  files,
  setFiles,
  importFile,
  inActiveStatusAndClearData,
  isAgree,
  setIsAgree,
  handleAlertMessage,
  module,
  locale,
}) => {
  const sampleData =
    module === "contacts" ? contactSampleData : module === "products" ? productSampleData : serviceSampleData;

  return (
    <>
      <h4 className={"font-weight-semibold mb-2"}>
        {module === "contacts" && locale("import contacts through CSV/EXCEL")}
        {module === "products" && locale("import products through CSV/EXCEL")}
        {module === "services" && locale("import services through CSV/EXCEL")}
      </h4>

      {createMarkup(locale("pages:import.import_sub_text_non"))}
      {/* Supports excel(with single sheet) or csv file with comma separated
        values. CSV/Excel <br />
        header should contain only alphabets, numbers, under scores and spaces. */}

      <div className={"drop-file-sec"}>
        {files.length === 0 && (
          <div className={"text-end"}>
            <a
              className={"btn btn-link ps-0 pe-0"}
              href={sampleData}
              download="sample-data.csv"
            >
              <i className={"ri-download-2-line ri-lg"}></i>{" "}
              <span>{locale("pages:import.download_sample")}</span>
            </a>
          </div>
        )}
        <XFileUpload
          accept="excel"
          files={files}
          setFiles={setFiles}
          importFile={importFile}
          inActiveStatusAndClearData={inActiveStatusAndClearData}
          handleAlertMessage={handleAlertMessage}
          setIsAgree={setIsAgree}
        />
      </div>
      {module === "contacts" && (
        <div className="mt-2">
          <Form.Check
            className={"small d-flex"}
            type="checkbox"
            label={locale("pages:import.import_terms_condition")}
            checked={isAgree}
            onChange={(e) => {
              setIsAgree(!isAgree);
            }}
          />
        </div>
      )}
    </>
  );
};

export default ImportContacts;

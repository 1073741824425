import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useEmailCredentials } from "../../../../../Hooks/useEmailCredentials";
import { EmailIntegration } from "../../../../../Components/Common/Email/EmailIntegration";
import { currentTeamId, toWordCase } from "../../../../../Utils/utils";
import { useTranslation } from "react-i18next";
import { AddImapSmtp } from "../../../../../Components/Common/Email/AddImapSmtp";
import { EqupRoundLoaderWithOverlay } from "../../../../../Components/Common/Loader/RoundLoader";
import { LEAD_OWNER_LIST } from "../../../../../GraphApi/LeadOwner";
import { useLazyQuery } from "@apollo/client";
import { nonGroupColourStyles } from "../../../../../Components/Common/DropDown/Style";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { SelectDropdown } from "../../../../../Components/Common/Conditions/SelectDropdown";
import { Editor } from "@tinymce/tinymce-react";

export const TeamNotificationAction = ({
  handleAlertMessage = () => {},
  userData,
  currentCompanySetting,
  values,
  handleClose,
  handleChange,
}) => {
  const { t: locale } = useTranslation();
  const [emailDetails, setEmailDetails] = useState({
    from: null,
    from_name: null,
    from_user: null,
    action: null,
    user: null,
    subject: null,
    email_body: null,
  });
  const [linkedAccountEmail, setLinkedAccountEmail] = useState(null);
  const [addImapSmtpDrawer, setAddImapSmtpDrawer] = useState(false);
  const [contactOwners, setContactOwners] = useState([]);
  const [errorBag, setErrorBag] = useState(null);
  const [receiverTypes] = useState([
    { value: "contact_owner", label: "Contact Owner" },
    { value: "deal_owner", label: "Deal Owner" },
    { value: "team_member", label: "Team Member" },
  ]);
  const { linkedEmail, loading, fetchCredentials } = useEmailCredentials();

  const [fetchUser, { data: ownerList }] = useLazyQuery(LEAD_OWNER_LIST, {
    variables: { team_id: currentTeamId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (ownerList) {
      const allLeadOwner = ownerList.allUsers.map(
        (item) => item && { value: item.id, label: item.name }
      );
      setContactOwners(allLeadOwner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerList]);

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCredentials();
  }, [fetchCredentials, linkedAccountEmail]);

  useEffect(() => {
    if (linkedEmail) {
      setEmailDetails({
        ...emailDetails,
        from: linkedEmail,
        from_user: "<" + linkedEmail + ">",
        from_name: userData?.authData?.name || "",
      });
    }
  }, [linkedEmail]);

  const setLinkedAccountState = (vala, valb) => {
    setLinkedAccountEmail(vala);
  };

  const handleAddImapSmtpDrawer = () => {
    setAddImapSmtpDrawer(false);
  };

  /** Handle Deal Values **/
  const handleEmailDetails = (type, value) => {
    let updateDetails = { ...emailDetails };
    if (type === "user") {
      updateDetails = { ...updateDetails, user: value.value };
    } else if (type === "action") {
      updateDetails = { ...updateDetails, action: value.value, user: null };
    } else {
      updateDetails = { ...updateDetails, [type]: value };
    }

    setEmailDetails({ ...updateDetails });
  };

  const handleSaveData = () => {
    let newErrors = {};
    if (!emailDetails.action) {
      newErrors = {
        ...newErrors,
        action: "To Action is required",
      };
    }
    if (emailDetails?.action === "team_member" && !emailDetails.user) {
      newErrors = {
        ...newErrors,
        message: "Team Member is required",
      };
    }
    if (!emailDetails.subject) {
      newErrors = {
        ...newErrors,
        subject: "Subject is required",
      };
    }
    if (!emailDetails.email_body) {
      newErrors = {
        ...newErrors,
        email_body: "message is required",
      };
    }
    setErrorBag(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const actionString = [];
      actionString.push(
        <span key="1">{locale("Send Team Notification")}</span>
      );
      if (emailDetails?.action) {
        actionString.push(
          <span key={"to-action"} className="text-primary">
            {" " +
              locale("To") +
              " " +
              locale(toWordCase(emailDetails.action)) +
              " "}
          </span>
        );
      }

      actionString.push(
        <span key={"subject"} className="text-primary">
          {" " + emailDetails?.subject || ""}
        </span>
      );

      handleChange("action", {
        action: "team_notification",
        email_details: emailDetails,
        string: actionString,
      });
      handleClose();
    }
  };

  useEffect(() => {
    if (values?.email_details) {
      setEmailDetails(values.email_details);
    }
  }, [values]);

  const handelCancel = () => {
    handleClose();
  };
  if (loading) {
    return <EqupRoundLoaderWithOverlay />;
  }
  /*****/
  return (
    <React.Fragment>
      <div className="mx-2 p-1">
        {!linkedEmail ? (
          <Row>
            <Col>
              <div
                className={
                  "pb-4 me-auto ms-auto text-center integrate-via-wrap"
                }
              >
                <EmailIntegration
                  setLinkedAccountState={setLinkedAccountState}
                  setAddImapSmtpDrawer={setAddImapSmtpDrawer}
                  team_id={currentTeamId}
                  locale={locale}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <React.Fragment>
            <Row>
              <Col>
                <Form.Group className="form-group">
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={emailDetails?.from_user || ""}
                    readOnly
                  />
                </Form.Group>

                <React.Fragment>
                  <SelectDropdown
                    placeholder={locale("To")}
                    isMulti={false}
                    isSearchable={false}
                    isClearable={false}
                    options={receiverTypes}
                    name="action"
                    value={emailDetails.action}
                    handleChange={(e) => {
                      handleEmailDetails("action", e);
                    }}
                    styles={nonGroupColourStyles}
                  />
                  <InLineError
                    extraStyle={{ marginTop: "-15px" }}
                    type="error"
                    message={errorBag?.action || null}
                  />
                </React.Fragment>
                {emailDetails.action === "team_member" && (
                  <React.Fragment>
                    <SelectDropdown
                      placeholder={locale("Team Member")}
                      isMulti={false}
                      isSearchable={true}
                      isClearable={false}
                      options={contactOwners}
                      name="contact_owner"
                      value={emailDetails.user}
                      handleChange={(e) => {
                        handleEmailDetails("user", e);
                      }}
                      noOptionsMessage={() => {
                        locale("no team member");
                      }}
                      styles={nonGroupColourStyles}
                    />
                    <InLineError
                      type="error"
                      message={errorBag?.user || null}
                    />
                  </React.Fragment>
                )}

                <Form.Group className="form-group">
                  <Form.Label>{locale("Subject")}</Form.Label>
                  <Form.Control
                    id={"subject"}
                    type="text"
                    placeholder={locale("Enter Subject")}
                    value={emailDetails?.subject || ""}
                    onChange={(e) => {
                      handleEmailDetails("subject", e.target.value);
                    }}
                  />
                  <InLineError
                    type="error"
                    name="subject"
                    message={errorBag?.subject || null}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="form-group">
                  <Form.Label>{locale("Message")}</Form.Label>
                  <InLineError
                    extraStyle={{ right: 0 }}
                    type="error"
                    name="email_body"
                    message={errorBag?.email_body || null}
                  />
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_KEY}
                    value={emailDetails?.email_body || ""}
                    init={{
                      external_plugins: {
                        shortcode:
                          process.env.REACT_APP_BACKEND_URL +
                          "/editor/" +
                          currentTeamId +
                          "/shortCodes.js",
                      },
                      paste_as_text: true,
                      smart_paste: true,
                      content_style: "p {margin: 0; padding: 0;}",
                      min_height: 300,
                      height: 400,
                      placeholder: locale("Start writing your message here"),
                      menubar: false,
                      branding: false,
                      plugins: ["link"],
                      relative_urls: false,
                      remove_script_host: false,
                      toolbar: [
                        "bold italic underline | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignfull | outdent indent | shortcode | link",
                      ],
                    }}
                    onEditorChange={async (content, editor) => {
                      const customContent = content.replace(
                        /<p>/g,
                        '<p style="margin:0;padding:0">'
                      );
                      handleEmailDetails("email_body", customContent);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
      <div className="text-end bg-dark sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
      <Modal
        className={"right"}
        show={addImapSmtpDrawer}
        onHide={handleAddImapSmtpDrawer}
      >
        <AddImapSmtp
          handleCloseAddEmail={handleAddImapSmtpDrawer}
          setLinkedAccountState={setLinkedAccountState}
          team_id={currentTeamId}
          locale={locale}
        />
      </Modal>
    </React.Fragment>
  );
};

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { nonGroupColourStyles } from "../../../../../Components/Common/DropDown/Style";

const reminderUnitType = [
  { value: 1, label: "Minutes" },
  { value: 2, label: "Hours" },
  // { value: 3, label: "Days" },
];

export const SetupJobReminderAction = ({
  isDisabled = false,
  reminderConfig,
  setReminderConfig,
}) => {
  const handleReminder = (name, value, index = 0) => {
    let updatedConfig = { ...reminderConfig };

    if (name === "remind") {
      const remindIndex = updatedConfig.remind.indexOf(value);
      if (remindIndex !== -1) {
        updatedConfig.remind.splice(remindIndex, 1);
      } else {
        updatedConfig.remind.push(value);
      }
    } else if (name === "on_type") {
      updatedConfig.on_type = value;
      updatedConfig.value = [{ when: 1, type: "", unit: 1 }];
    } else if (name === "type" && updatedConfig.value) {
      updatedConfig.value[index] = {
        ...updatedConfig.value[index],
        type: value,
      };
    } else if (name === "unit" && updatedConfig.value) {
      updatedConfig.value[index] = {
        ...updatedConfig.value[index],
        unit: value,
      };
    } else if (name === "when") {
      if (updatedConfig.on_type === null) {
        const existingIndex = updatedConfig.value.findIndex(
          (item) => item.when === value
        );
        if (existingIndex !== -1) {
          updatedConfig.value = updatedConfig.value.filter(
            (item) => item.when !== value
          );
        } else {
          updatedConfig.value.push({ when: value, type: "", unit: 1 });
        }
      } else {
        updatedConfig.value[index] = {
          ...updatedConfig.value[index],
          when: value,
        };
      }
    }

    setReminderConfig(updatedConfig);
  };

  const getFieldValue = (when, key) => {
    const fieldItem = reminderConfig.value.find((item) => item.when === when);
    return fieldItem ? fieldItem[key] : null;
  };

  return (
    <div className="bg-medium p-3 border">
      <Form.Group className="form-group">
        <Form.Label>Setup Reminder</Form.Label>
        <div className="mb-0">
          <span className="me-3">Remind </span>
          <Form.Check
            inline
            label="Job Owner"
            name="job_owner"
            type="checkbox"
            checked={reminderConfig.remind.includes(1)}
            onChange={() => !isDisabled && handleReminder("remind", 1)}
            disabled={isDisabled}
          />
          <Form.Check
            inline
            label="Technician"
            name="technician"
            type="checkbox"
            checked={reminderConfig.remind.includes(2)}
            onChange={() => !isDisabled && handleReminder("remind", 2)}
            disabled={isDisabled}
          />
        </div>
      </Form.Group>

      <div className="mb-3">
        <Form.Check type="radio" id="setup_reminder2" inline className="mb-3">
          <Form.Check.Input
            type="radio"
            name="setup_reminder"
            onChange={() => !isDisabled && handleReminder("on_type", 1)}
            checked={reminderConfig.on_type === 1}
            disabled={isDisabled}
          />
          <Form.Check.Label>On service completion</Form.Check.Label>
          <RemindField
            reminderConfig={reminderConfig}
            Label="Remind after"
            afterFieldLabel="of service completion"
            handleReminder={handleReminder}
            index={0}
            isDisabled={isDisabled || reminderConfig.on_type !== 1}
          />
        </Form.Check>

        <Form.Check type="radio" id="setup_reminder3" inline>
          <Form.Check.Input
            type="radio"
            name="setup_reminder"
            onChange={() => !isDisabled && handleReminder("on_type", 2)}
            checked={reminderConfig.on_type === 2}
            disabled={isDisabled}
          />
          <Form.Check.Label>Job completion</Form.Check.Label>
          <RemindField
            reminderConfig={reminderConfig}
            Label="Remind after"
            afterFieldLabel="of job completion"
            handleReminder={handleReminder}
            index={0}
            isDisabled={isDisabled || reminderConfig.on_type !== 2}
          />
        </Form.Check>
      </div>
    </div>
  );
};

const RemindField = ({
  reminderConfig,
  handleReminder,
  index,
  Label,
  afterFieldLabel,
  isDisabled,
}) => {
  const value = reminderConfig.value[index];

  return (
    <div className="setup-reminder-unit">
      <span className="me-3">{Label}</span>
      <Form.Control
        style={{ height: "40px", marginTop: "10px" }}
        name="unit"
        className="me-2"
        type="number"
        value={value ? value.unit : ""}
        onChange={(e) =>
          !isDisabled && handleReminder("unit", e.target.value, index)
        }
        disabled={isDisabled}
      />
      <Select
        options={reminderUnitType}
        styles={nonGroupColourStyles}
        onChange={(e) => !isDisabled && handleReminder("type", e.value, index)}
        value={
          value
            ? reminderUnitType.find((option) => option.value === value.type)
            : null
        }
        isDisabled={isDisabled}
      />
      <span className="ms-3">{afterFieldLabel}</span>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import './fileUpload.css';
import { useTranslation } from 'react-i18next';
import { getFileExtension, isObjectEmpty } from '../../../Utils/utils';
import { acceptableType } from '../../../Utils/commonParams';

export const APP_FILE_SIZE = process.env.REACT_APP_FILE_SIZE;

const filesToBeAccepted = [
  'jpg',
  'jpeg',
  'svg',
  'bmp',
  'png',
  'xls',
  'csv',
  'txt',
  'xlsx',
  'doc',
  'docx',
  'pdf',
  'ppt',
  'pptx',
];

const acceptAbleFileName = {
  image: 'JPG, JPEG, PNG, BMP',
  excel: 'EXCEL, CSV',
  '*': 'JPG, JPEG, PNG, BMP, EXCEL, CSV, PDF, PPT, DOC, TXT',
  pdf: 'PDF',
  documents: 'PDF, DOC',
  document: 'JPG, JPEG, PNG, BMP, EXCEL, CSV, PDF, PPT, DOC, TXT',
  video: 'mp4, mpeg, ogg, webm, 3gpp',
  audio: 'mp3, mpeg, ogg',
};

const XFileUpload = ({
  accept,
  files,
  setFiles,
  inActiveStatusAndClearData,
  handleAlertMessage,
  setIsAgree,
  isMulti,
  fileSize,
  fileSelectMode = 'overwrite',
  fileCount = 5,
  label = null,
}) => {
  const { t: locale } = useTranslation(['common', 'pages']);
  const [uploadFile, setUploadFile] = useState([]);
  const [uploadFileSize, setUploadFileSize] = useState(APP_FILE_SIZE);

  useEffect(() => {
    if (fileSize && fileSize > 0) {
      setUploadFileSize(fileSize);
    }
  }, [fileSize]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptableType(accept),
    onDrop: (acceptedFile) => {
      const count = acceptedFile.length + files.length;
      let hasFileSizeError = [];
      if (count <= fileCount) {
        const uploadedFile =
          acceptedFile.length > 0
            ? getFileExtension(acceptedFile[0].path)
            : null;

        if (acceptedFile.length > 0) {
          hasFileSizeError = acceptedFile.filter(
            (item, idx) => getFileSize(item) === true
          );
        }

        if (hasFileSizeError.length > 0) {
          handleAlertMessage(
            'error',
            'File size exceeds max upload file size of ' +
              uploadFileSize +
              ' MB'
          );

          if (fileSelectMode === 'overwrite') {
            handleResetObjects();
            setFiles([]);
            setUploadFile([]);
          }
        } else if (
          uploadedFile &&
          filesToBeAccepted.indexOf(uploadedFile.ext) === -1
        ) {
          handleAlertMessage(
            'error',
            'File format not supported. Upload a valid file!!'
          );

          if (fileSelectMode === 'overwrite') {
            handleResetObjects();
            setFiles([]);
            setUploadFile([]);
          }
        } else {
          if (acceptedFile.length > 0) {
            let file = [];
            if (isMulti) {
              file = acceptedFile.map((item) =>
                Object.assign(item, {
                  src: window.URL.createObjectURL(item),
                })
              );
            } else {
              file = [
                Object.assign(acceptedFile[0], {
                  src: window.URL.createObjectURL(acceptedFile[0]),
                }),
              ];
            }
            if (fileSelectMode !== 'overwrite' && isMulti) {
              setFiles([...files, ...file]);
              setUploadFile([...files, ...file]);
            } else {
              setFiles(file);
              setUploadFile(file);
            }
            handleResetObjects();
          } else {
            if (fileSelectMode === 'overwrite') {
              handleResetObjects();
              setFiles([]);
              setUploadFile([]);
            }
            handleAlertMessage(
              'error',
              'File format not supported. Upload a valid file!!'
            );
          }
        }
      } else {
        handleAlertMessage('error', 'You can upload maximum 5 files at a time');
      }
    },
  });

  const handleResetObjects = () => {
    if (accept === 'excel') {
      setIsAgree(false);
      inActiveStatusAndClearData();
    }
  };

  useEffect(() => {
    uploadFile &&
      uploadFile.forEach((item) => {
        URL.revokeObjectURL(item.preview);
      });
  }, [uploadFile]);

  /** GEt FILE SIZE **/
  const getFileSize = (file) => {
    const requiredFileSizeInBytes = uploadFileSize * 1024 * 1024;
    if (file.size <= requiredFileSizeInBytes) {
      return false;
    } else {
      return true;
    }
  };
  /** Create Thumb Image **/
  const thumbs =
    files.length &&
    files.map((file) => (
      <div
        className='thumb'
        key={file.name}
      >
        <div className='thumbInner'>
          {getFileExtension(file.name) === 'image' ? (
            <img
              src={file.preview}
              className='img'
              alt={file.name}
            />
          ) : (
            <i
              className={getFileExtension(file.name).className}
              style={{ width: '32px', lineHeight: '1' }}
            />
          )}
        </div>
        <p className='fileDetails'>{file.name}</p>
      </div>
    ));

  const countFormat = (formats) => {
    if (!isObjectEmpty(formats)) {
      const formatCount = formats.split(',');
      return formatCount.length;
    } else {
      return '';
    }
  };
  /******/
  return (
    <React.Fragment>
      <div
        {...getRootProps()}
        className={'drop-file-box'}
      >
        <div className='inputHidden'>
          <input
            multiple={isMulti}
            accept={acceptableType(accept)}
            {...getInputProps()}
          />
        </div>
        <div className={'drop-file-inner'}>
          {!thumbs ? <i className={'ri-upload-2-line ri-2x'}></i> : ''}
          <h6 className={'secondary-font text-uppercase mb-2'}>
            {isDragActive ? (
              <> {locale('drop the files here')}</>
            ) : (
              <>
                {thumbs && !isMulti ? (
                  <aside className='thumb-container'>{thumbs}</aside>
                ) : thumbs && isMulti ? (
                  <aside className='thumb-container'>
                    {thumbs.length > 1
                      ? thumbs.length + ' files selected'
                      : thumbs}
                  </aside>
                ) : label === null ? (
                  <>
                    {locale('drag & drop file')}{' '}
                    <span className={'text-success'}>{locale('or')}</span>{' '}
                    {locale('click to select a file')}
                  </>
                ) : (
                  <>
                    {locale('drag & drop')} {locale(label)}
                  </>
                )}
              </>
            )}
          </h6>
          <p className={'text-size-xs'}>
            {isMulti &&
              locale(
                'You can upload up to 5 files, with each file having a maximum size of 5MB.'
              )}
            {isMulti && <br />}
            {locale('approximate time to upload 1MB file is 30 seconds.')}{' '}
            <br />
            {countFormat(acceptAbleFileName[accept]).length > 1
              ? locale('supported file formats are')
              : locale('supported file formats is')}{' '}
            : <React.Fragment>{acceptAbleFileName[accept]}</React.Fragment>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
XFileUpload.propTypes = {
  isMulti: PropTypes.bool,
  accept: PropTypes.string.isRequired,
  files: PropTypes.any.isRequired,
  setFiles: PropTypes.func.isRequired,
  showThumbnail: PropTypes.bool.isRequired,
  handleAlertMessage: PropTypes.func.isRequired,
};

export default XFileUpload;

import { useState } from 'react';
import { getTemplatesList } from '../Components/Agreement/AgreementHelper';
import { currentTeamId } from '../Utils/utils';

export default function useAgreementTemplateModal() {
  const [agreementTemplateModalVisible, setAgreementTemplateModalVisible] =
    useState(false);

  const showAgreementTemplateModal = () => {
    setAgreementTemplateModalVisible(true);
  };

  const [templateModalLoading, setTemplateModalLoading] = useState(false);
  const hideAgreementTemplateModal = () => {
    setAgreementTemplateModalVisible(false);
  };
  const [agreementTemplatesList, setAgreementTemplatesList] = useState([]);
  // const [pageInfo, setPageInfo] = useState(null);
  const [pageParams] = useState({
    team_id: currentTeamId,
    search: '',
    first: 100, //getNumberOfRecordToBeShow("templates"),
    page: 1,
    field: 'created_at',
    order: 'DESC',
    // filter: [],
  });

  const fetchTemplateData = () => {
    getTemplatesList({ team_id: currentTeamId, ...pageParams })
      .then((result) => {
        if (result.status) {
          const data = result.data;
          // setPageInfo({
          //   lastPage: data.last_page,
          //   currentPage: data.current_page,
          //   total: data.total,
          // });
          const templateData = data.data.map(
            ({ agreement_template_id, ...rest }) => ({
              template_id: agreement_template_id,
              ...rest,
            })
          );
          setAgreementTemplatesList(templateData);
        }
        setTemplateModalLoading(false);
      })
      .catch((error) => {
        setTemplateModalLoading(false);
      });
  };

  return {
    agreementTemplateModalVisible,
    agreementTemplatesList,
    templateModalLoading,
    fetchTemplateData,
    showAgreementTemplateModal,
    hideAgreementTemplateModal,
  };
}

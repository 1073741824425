import React, { useEffect, useState } from "react";
/* Retrieve Data From Server */
import { useLazyQuery } from "@apollo/client";
import { EMAIL_BY_ID, SYSTEM_EMAIL_BY_ID } from "../../../GraphApi/Emails";
import { PreviewEmail } from "./PreviewEmail";
import { useTranslation } from "react-i18next";
import { currentTeamId, emailTemplateForPreview } from "../../../Utils/utils";
import { EqupRoundLoaderWithOverlay } from "../../Common/Loader/RoundLoader";

export const GenerateEmailPreview = ({
  showEmailPreview,
  handleCloseEmailPreview,
  fromReferral,
  email = null,
}) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const [emailTemplate, setEmailTemplate] = useState(null);

  const [getEmailById, { data: templateDetails, loading: loading1 }] =
    useLazyQuery(EMAIL_BY_ID, {
      fetchPolicy: "network-only",
    });
  const [
    getSystemEmailById,
    { data: systemTemplateDetails, loading: loading2 },
  ] = useLazyQuery(SYSTEM_EMAIL_BY_ID, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (email && showEmailPreview) {
      let emailType = "system";

      if (["conversation"].includes(email.template_type) && !email.team_id) {
        emailType = "system";
      } else if (
        ["conversation", "transactional_email"].includes(email.template_type)
      ) {
        emailType = "user";
      }

      previewEmailTemplates(email, emailType);
    } else {
      setEmailTemplate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEmailPreview]);

  useEffect(() => {
    if (templateDetails && templateDetails.emailTemplate) {
      const msg = emailTemplateForPreview(
        templateDetails.emailTemplate.message
      );
      if (msg) {
        setEmailTemplate(msg);
      }
    }
  }, [templateDetails]);

  useEffect(() => {
    if (systemTemplateDetails && systemTemplateDetails.getSystemEmailTemplate) {
      const msg = emailTemplateForPreview(
        systemTemplateDetails.getSystemEmailTemplate.message
      );
      if (msg) {
        setEmailTemplate(msg);
      }
    }
  }, [systemTemplateDetails]);
  /**** Call Email Template List ****/
  const previewEmailTemplates = (email, type = "user") => {
    if (type === "user") {
      getEmailById({
        variables: { team_id: currentTeamId, id: parseInt(email.id) },
      });
    } else {
      getSystemEmailById({ variables: { id: parseInt(email.id) } });
    }
  };

  /*****/
  return (
    <React.Fragment>
      {(loading1 || loading2) && <EqupRoundLoaderWithOverlay />}
      <PreviewEmail
        showEmailPreview={showEmailPreview}
        data={emailTemplate}
        handleCloseEmailPreview={() => handleCloseEmailPreview()}
        fromReferral={fromReferral}
        locale={locale}
      />
    </React.Fragment>
  );
};

import React, { useState } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import {
  currentTeamId,
  getValidationMessage,
  isObjectEmpty,
} from '../../../../Utils/utils';
import InLineError from '../../../Common/AlertMessage/InLineError';
import copy from 'copy-to-clipboard';

export const GenerateKey = ({
  locale,
  handleClose,
  createApiKey,
  refetchKeyList,
}) => {
  const [apiKey, setApiKey] = useState('');
  const [keyName, setKeyName] = useState('');
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onCopyMessage = () => {
    setStatus(locale('messages:common.Copied to clipboard!'));
  };
  /*****/
  const handleGenerateApiKey = () => {
    setIsSubmitting(true);
    if (isObjectEmpty(keyName)) {
      setError(
        locale('messages:formik.{{name}} is required', {
          name: locale('pages:setting.api.Key Name'),
        })
      );
      setIsSubmitting(false);
    } else if (keyName.length < 3) {
      setError(
        locale('messages:formik.{{name}} min {{minLength}} character long!', {
          name: locale('pages:setting.api.Key Name'),
          minLength: 3,
        })
      );
      setIsSubmitting(false);
    } else if (keyName.length > 255) {
      setError(
        locale('messages:formik.{{name}} max {{maxLength}} character long!', {
          name: locale('pages:setting.api.Key Name'),
          maxLength: 255,
        })
      );
      setIsSubmitting(false);
    } else {
      createApiKey({
        variables: {
          team_id: currentTeamId,
          name: keyName,
        },
        update: () => refetchKeyList(),
      })
        .then((result) => {
          setApiKey(result.data.createApiKey.token);
          setError('');
          setStatus('');
          setIsSubmitting(false);
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          setError(
            locale('messages:backend.' + getValidationMessage(graphQLErrors))
          );
          setIsSubmitting(false);
        });
    }
  };

  /*** RENDER HTML ***/
  return (
    <React.Fragment>
      <Modal.Header
        closeVariant='white'
        closeButton
        className={'bg-primary'}
      >
        <Modal.Title className={'text-white secondary-font text-uppercase'}>
          {locale('pages:setting.api.Generate API Key')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className='font-weight-bold text-success'>{status}</Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='form-group'>
              <Form.Label>{locale('pages:setting.api.Key Name')}</Form.Label>
              <Row>
                <Col
                  xs={7}
                  md={9}
                >
                  <Form.Control
                    type='text'
                    placeholder={locale('pages:setting.api.Enter key name')}
                    onChange={(e) => setKeyName(e.target.value)}
                  />
                  <InLineError
                    type='error'
                    message={error}
                  />
                </Col>
                {isObjectEmpty(apiKey) && (
                  <Col
                    xs={5}
                    md={3}
                    className='d-flex'
                  >
                    <Button
                      variant='outline-primary btn-w-icon'
                      onClick={handleGenerateApiKey}
                      disabled={isSubmitting}
                    >
                      {locale('pages:setting.api.Generate Key')}
                    </Button>
                  </Col>
                )}
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='form-group'>
              <Form.Label>{locale('pages:setting.api.API Key')}</Form.Label>
              <Row>
                <Col
                  xs={10}
                  md={9}
                >
                  <Form.Control
                    type='text'
                    value={apiKey}
                    onChange={() => {}}
                    disabled
                  />
                </Col>
                <Col
                  xs={2}
                  md={3}
                  className='d-flex align-items-center'
                >
                  {!isObjectEmpty(apiKey) && (
                    <div className='copy-api-key'>
                      <i
                        className='ri-file-copy-line text-primary '
                        onClick={(e) => {
                          copy(apiKey, {
                            debug: true,
                            message: locale('Press #{key} to copy'),
                          });
                          onCopyMessage();
                        }}
                      ></i>
                    </div>
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
    </React.Fragment>
  );
};

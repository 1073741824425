import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { nonGroupColourStyles } from "../../../Components/Common/DropDown/Style";

export const SelectPhoneNumberFormat = ({
  label = "Set Phone Number Format",
  showLabel = true,
  iconClass = "ri-phone-fill",
  value = "",
  getPhoneNumberFormat = () => {},
  locale,
}) => {
  const [phoneNumberFormat] = useState([
    { value: "(123) 234-5689", label: "(123) 234-5689" },
    { value: "123-4567890", label: "123-4567890" },
    { value: "1234567890", label: "1234567890" },
  ]);

  return (
    <Form.Group className={"form-group"}>
      {showLabel ? (
        <Form.Label>{locale("pages:setting.common." + label)}</Form.Label>
      ) : (
        ""
      )}
      <InputGroup>
        <InputGroup.Text>
          <i className={iconClass}></i>
        </InputGroup.Text>
        <Select
          options={phoneNumberFormat}
          placeholder={locale("Phone Number Format")}
          value={
            phoneNumberFormat &&
            phoneNumberFormat.filter((item) => item.value === value)[0]
          }
          onChange={(e) => {
            getPhoneNumberFormat(e);
          }}
          styles={nonGroupColourStyles}
        />
      </InputGroup>
    </Form.Group>
  );
};

import React, { useEffect, useState } from "react";
import { SelectDropdown } from "../../../../../Components/Common/Conditions/SelectDropdown";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import useFetchColumnList from "../../../../../Hooks/useColumnList";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { toWordCase } from "../../../../../Utils/utils";
import DateFilter from "../../../../../Components/Common/Filters/SubFilters/DateFilter";

export const UpdateContactAction = ({
  handleAlertMessage = () => {},
  currentCompanySetting = null,
  values = null,
  handleChange,
  handleClose,
}) => {
  const [contactDetails, setContactDetails] = useState(null);
  const [errorBag, setErrorBag] = useState(null);

  const { preDefinedColumns, fetchColumnList } = useFetchColumnList(
    "contacts_listing",
    "form"
  );

  useEffect(() => {
    fetchColumnList();
  }, []);

  const handleSaveData = () => {
    let newErrors = {};
    if (!contactDetails?.field_name) {
      newErrors = { ...newErrors, field_name: "Field is required" };
    }
    if (!contactDetails?.field_value) {
      newErrors = { ...newErrors, field_value: "Field value is required" };
    }
    setErrorBag(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setErrorBag(null);
      let str = [];
      str.push(<span key="1">Update contact </span>);
      str.push(
        <span key="2" className="text-highlighter-dark">
          Where
        </span>
      );
      if (contactDetails?.field_name) {
        str.push(
          <span key="3">
            {" " + toWordCase(contactDetails.field_name) + " is "}{" "}
          </span>
        );
        str.push(
          <span key="3" className="text-primary">
            {contactDetails?.field_value || ""}{" "}
          </span>
        );
      }

      handleChange("action", {
        action: "update_contact",
        contact_details: {
          [contactDetails.field_name]: contactDetails.field_value,
        },
        string: str,
      });
      handleClose();
    }
  };

  const handleDetail = (type, e) => {
    if (type === "name") {
      setContactDetails({ field_name: e.value, field_value: null });
    } else if (type === "value") {
      setContactDetails({
        ...contactDetails,
        field_value: e?.target?.value || "",
      });
    }
  };

  useEffect(() => {
    if (values?.contact_details) {
      const data = Object.entries(values.contact_details);
      let details = {};
      if (data?.length > 0) {
        data.forEach((item) => {
          if (item.length > 0) {
            details = { field_name: item[0], field_value: item[1] };
          }
        });
      }
      setContactDetails(details);
    }
  }, [values]);

  const handelCancel = () => {
    handleClose();
  };
  /******/
  return (
    <Container>
      <Row>
        <Col className="mt-3">
          <SelectDropdown
            label="Select Field To Update"
            options={preDefinedColumns}
            value={contactDetails?.field_name || ""}
            handleChange={(e) => handleDetail("name", e)}
          />{" "}
          <InLineError
            extraStyle={{ marginTop: "-15px" }}
            type="error"
            name="field_name"
            message={errorBag?.field_name || ""}
          />
          {["date_of_birth", "date"].includes(contactDetails?.field_name) ? (
            <Form.Group className="form-group mt-3">
              <Form.Label>{`New Value Of The ${toWordCase(contactDetails?.field_name) || ""} Field`}</Form.Label>
              <DateFilter
                id={"dueDateValue"}
                position={"left"}
                placeholder="Date"
                label={""}
                labelVisibility={false}
                labelRemove={true}
                name={"date"}
                value={
                  contactDetails?.field_value
                    ? {
                        startDate: contactDetails?.field_value || "",
                      }
                    : ""
                }
                type={"single"}
                handleChange={(date) => {
                  handleDetail("value", { target: { value: date } });
                }}
                isError={{
                  value: false,
                }}
                allowFuture={true}
                allowPast={true}
                isDisabled={false}
              />
              <InLineError
                type="error"
                name="field_value"
                message={errorBag?.field_value || ""}
              />
            </Form.Group>
          ) : (
            <Form.Group className="form-group mt-3">
              <Form.Label>{`New Value Of The ${toWordCase(contactDetails?.field_name) || ""} Field`}</Form.Label>

              <Form.Control
                name="field_value"
                value={contactDetails?.field_value || ""}
                onChange={(e) => handleDetail("value", e)}
              />
              <InLineError
                type="error"
                name="field_value"
                message={errorBag?.field_value || ""}
              />
            </Form.Group>
          )}
        </Col>
      </Row>
      <div className="text-end bg-dark sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </Container>
  );
};

import React from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isObjectEmpty, toWordCase } from "../../../../../Utils/utils";

export const BusinessFlowBox = ({
  name,
  openDrawer = () => {},
  removeWorkflowItem,
  itemId,
  string,
  noString,
  appliedClasses,
  icon,
  indexNo,
  isRemovable = true,
}) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  return (
    <Card key={name + "-" + itemId}>
      <Card.Body className="p-2" onClick={() => openDrawer(name, itemId)}>
        <h4
          className={"text-primary font-weight-semibold mt-1 text-center"}
          style={{ position: "relative" }}
        >
          {locale(toWordCase(name))}
        </h4>
        {isRemovable && (
          <div style={{ position: "absolute", right: "0px", top: "0px" }}>
            <Button
              variant={"link"}
              onClick={(e) => {
                e.stopPropagation();
                removeWorkflowItem(itemId);
              }}
            >
              <i className={"ri-close-line"}></i>
            </Button>
          </div>
        )}

        <div
          className={"my-2 " + appliedClasses}
          onClick={() => openDrawer(name, itemId)}
        >
          {string && string !== "" ? (
            <React.Fragment>
              <div className={" text-center text-size-xl"}>{string || ""}</div>
            </React.Fragment>
          ) : !isObjectEmpty(noString) ? (
            <React.Fragment>
              <h4
                className={"text-primary font-weight-semibold mt-3 text-center"}
              >
                {noString}
              </h4>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

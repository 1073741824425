import { gql } from "@apollo/client";

/* *All Email Template Used in Campaign **/
export const EMAIL_LIST = gql`
  query ($team_id: Int, $template_type: String) {
    getEmailTemplates(team_id: $team_id, template_type: $template_type) {
      id
      name
      subject
      team_id
    }
  }
`;

export const EMAIL_PAGE_LIST = gql`
  query ($team_id: Int, $template_type: String) {
    getEmailTemplates(team_id: $team_id, template_type: $template_type) {
      id
      name
      subject
      message
    }
  }
`;

export const SAVE_EMAIL_TEMPLATE = gql`
  mutation saveEmailTemplate(
    $team_id: Int
    $template_id: Int
    $name: String
    $subject: String
    $message: String
    $template_type: String
  ) {
    saveEmailTemplate(
      team_id: $team_id
      template_id: $template_id
      name: $name
      subject: $subject
      message: $message
      template_type: $template_type
    ) {
      id
      name
      subject
      message
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation sendEmail(
    $to: String
    $team_id: Int
    $cc: String
    $bcc: String
    $sender: String
    $subject: String
    $thread_id: String
    $text_message: String
    $message: String
    $template_id: Int
    $has_attachments: Boolean
    $headers: String
    $in_reply_to: String
    $scheduled_at: DateTime
    $attachments: [Int]
  ) {
    sendEmail(
      to: $to
      team_id: $team_id
      cc: $cc
      bcc: $bcc
      sender: $sender
      subject: $subject
      thread_id: $thread_id
      text_message: $text_message
      message: $message
      template_id: $template_id
      has_attachments: $has_attachments
      headers: $headers
      in_reply_to: $in_reply_to
      scheduled_at: $scheduled_at
      attachments: $attachments
    ) {
      id
      message_id
      internet_message_id
      to
      cc
      sender
      mail_from
      bcc
      subject
      text_body
      html_body
      template_id
      headers
      has_attachments
      incoming
      status
      is_clicked
      in_reply_to
      scheduled_at
      e_id
      created_at
      attachment {
        id
        name
        path: url
        source
        file_url
      }
    }
  }
`;

export const DELETE_EMAIL = gql`
  mutation deleteEmail($team_id: Int, $id: Int) {
    deleteEmailTemplate(team_id: $team_id, id: $id) {
      status
    }
  }
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation updateEmailTemplate(
    $id: Int
    $team_id: Int
    $subject: String
    $message: String
    $json_data: String
    $name: String
    $template_type: String
  ) {
    updateEmailTemplate(
      id: $id
      team_id: $team_id
      name: $name
      subject: $subject
      message: $message
      json_data: $json_data
      template_type: $template_type
    ) {
      id
      name
      subject
      message
    }
  }
`;

export const EMAIL_BY_ID = gql`
  query getEmailById($team_id: Int, $id: Int) {
    emailTemplate(team_id: $team_id, id: $id) {
      id
      name
      json_content
      templateName: name
      subject
      message
      template_type
      team_id
    }
  }
`;

export const SYSTEM_EMAIL_BY_ID = gql`
  query getSystemEmailTemplate($id: Int) {
    getSystemEmailTemplate(id: $id) {
      id
      name
      templateName: name
      subject
      message
      template_type
    }
  }
`;

export const MAIL_MERGE = gql`
  query mailMerge($team_id: Int, $complete_list: Boolean) {
    getMailMergeList(team_id: $team_id, complete_list: $complete_list) {
      fields
    }
  }
`;
/* *All Email Template Used in WORKFLOW **/
export const EMAIL_TEMPLATES = gql`
  query emailTemplateListing(
    $team_id: Int
    $first: Int!
    $page: Int
    $search: String
    $interval: String
    $template_type: String
    $campaign_id: Int
    $order: SortOrder!
    $field: String!
  ) {
    emailTemplateListing(
      first: $first
      team_id: $team_id
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      template_type: $template_type
      campaign_id: $campaign_id
      interval: $interval
    ) {
      data {
        id
        name
        templateName: name
        subject
        message
        template_type
        updated_at

        #sent_count
        #opened_count
        #clicked_count
        #bounced_count
        #delivered_count
        #spammed_count
        created_by_name
        updated_by_name
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;
/* *All Email Template Used in Campaign **/
export const EMAIL_TEMPLATE_LIST = gql`
  query emailTemplateListing(
    $team_id: Int
    $first: Int!
    $page: Int
    $search: String
    $interval: String
    $template_type: String
    $campaign_id: Int
    $order: SortOrder!
    $field: String!
    $industry: String
    $group_name: String
  ) {
    emailTemplateListing(
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      team_id: $team_id
      template_type: $template_type
      campaign_id: $campaign_id
      interval: $interval
    ) {
      data {
        id
        name
        templateName: name
        subject
        #message
        template_type
        updated_at

        sent_count
        opened_count
        clicked_count
        #bounced_count
        #delivered_count
        #spammed_count
        created_by_name
        updated_by_name
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
    getAllSystemEmailTemplate(
      industry: $industry
      group_name: $group_name
      template_type: $template_type
    ) {
      id
      name
      subject
      #message
      template_type
      group_name
      description
      icon
      industry
      screenshot
      screenshot_url
    }
  }
`;

/** Delete Email Template which is not Used in Campaign or workflow **/
export const EMAIL_TEMPLATE_DELETE = gql`
  mutation deleteEmailTemplate($team_id: Int, $id: Int) {
    deleteEmailTemplate(team_id: $team_id, id: $id) {
      status
    }
  }
`;

/* *Clone Email Template  **/
export const EMAIL_TEMPLATE_CLONE = gql`
  mutation cloneEmailTemplate(
    $team_id: Int
    $subject: String
    $message: String
    $name: String
    $template_type: String
    $cloned_from: Int
    $template_id: Int
  ) {
    saveEmailTemplate(
      team_id: $team_id
      name: $name
      subject: $subject
      message: $message
      template_type: $template_type
      cloned_from: $cloned_from
      template_id: $template_id
    ) {
      id
      user_id
      team_id
      subject
      message
      template_type
      created_at
      updated_at
    }
  }
`;

/**Clone Email Template  **/
export const EMAIL_TEMPLATE_PREDEFINED = gql`
  query getAllSystemEmailTemplate(
    $industry: String
    $group_name: String
    $template_type: String
  ) {
    getAllSystemEmailTemplate(
      industry: $industry
      group_name: $group_name
      template_type: $template_type
    ) {
      id
      name
      templateName: name
      subject
      message
      template_type
      group_name
      description
      icon
      industry
      screenshot
      screenshot_url
    }
  }
`;

export const UNSUBSCRIBE_EMAIL_PAGE_DATA = gql`
  query unsubscribeUserData($contact_id: String) {
    unsubscribeUserData(contact_id: $contact_id) {
      logo
      company_name
      message
    }
  }
`;

export const UNSUBSCRIBE_EMAIL = gql`
  mutation unsubscribedAction($contact_id: String, $email_id: String) {
    unsubscribedAction(contact_id: $contact_id, email_id: $email_id) {
      status
    }
  }
`;

export const EMAILS_CONVERSATION = gql`
  query getAllConversationEmails(
    $team_id: Int
    $industry: String
    $group_name: String
    $template_type: String
  ) {
    getEmailTemplates(team_id: $team_id, template_type: $template_type) {
      id
      name
      subject
      #message
    }
    getAllSystemEmailTemplate(
      industry: $industry
      group_name: $group_name
      template_type: $template_type
    ) {
      id
      name
      subject
      #message
      template_type
      group_name
      description
      icon
      industry
      screenshot
      screenshot_url
    }
  }
`;

import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { TextLength } from "../../../../../Components/WhatsAppManagement/WaBaUtil";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { currentTeamId, isObjectEmpty } from "../../../../../Utils/utils";
import { useTranslation } from "react-i18next";
import {
  saveSMSRequest,
  updateSMSRequest,
} from "../../../../../RestApi/SMSApi";
import { SMSTemplates } from "../../../../../Pages/Automation/Elements/Settings/Actions/SMSTemplates";

const totalChar = 1520;

export const SendSMSAction = ({
  handleAlertMessage = () => {},
  values,
  handleChange = () => {},
  handleClose = () => {},
  authData,
}) => {
  const { t: locale } = useTranslation();
  const [show, setShow] = useState(false);
  const [smsDetail, setSmsDetail] = useState({
    message_type: "write",
    sms_id: null,
    sms_id_label: null,
    message: null,
    name: null,
  });
  const [errorBag, setErrorBag] = useState(null);

  const [showTemplateName, setShowTemplateName] = useState(false);

  const handleSaveData = () => {
    let newErrors = {};
    if (!smsDetail?.message) {
      newErrors = { ...newErrors, message: "SMS is required." };
    }
    setErrorBag(newErrors);

    if (Object.keys(newErrors).length === 0) {
      let str = [];
      str.push(<span key="1">Send </span>);
      str.push(
        <span key="2" className="text-primary">
          {smsDetail?.sms_id_label || ""}
        </span>
      );
      str.push(<span key="3"> SMS</span>);
      handleChange("action", {
        action: "send_sms",
        ...smsDetail,
        string: str,
      });
      handleClose();
    }
  };

  useEffect(() => {
    if (values?.message) {
      setSmsDetail({
        sms_id: values?.sms_id || "",
        message_type: "write",
        sms_id_label: values?.sms_id_label || "",
        message: values?.message || "",
        name: values?.name || "",
      });
    }
  }, [values]);

  const handelCancel = () => {
    handleClose();
  };

  const showSaveTemplateData = () => {
    setShowTemplateName(true);
  };

  const handleCancelShowTemplate = () => {
    setShowTemplateName(false);
  };

  const handleSaveTemplateData = () => {
    let newErrors = {};

    if (isObjectEmpty(smsDetail?.message)) {
      newErrors = { ...newErrors, message: "Message is required" };
    }

    setErrorBag(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (values?.sms_id) {
        updateSMSRequest(
          {
            team_id: currentTeamId,
            message: smsDetail?.message || "",
            name: smsDetail?.name || "",
          },
          values.sms_id
        )
          .then((result) => {
            const data = result.data;
            setSmsDetail({
              ...smsDetail,
              sms_id: data?.message?.id || null,
              name: data?.message?.name || null,
              message: data?.message?.message || null,
            });
            handleAlertMessage("success", "SMS content update successfully");
            handleCancelShowTemplate();
          })
          .catch((error) => {
            console.log("Error", error.message);
            handleAlertMessage("error", error.message);
          });
      } else
        saveSMSRequest({
          team_id: currentTeamId,
          template_type: "campaign",
          message: smsDetail?.message || "",
          name: smsDetail?.name || "",
        })
          .then((result) => {
            const data = result.data;
            setSmsDetail({
              ...smsDetail,
              sms_id: data?.data?.id || null,
              name: data?.data?.name || null,
              message: data?.data?.message || null,
            });
            handleAlertMessage("success", "SMS create successfully");
            handleCancelShowTemplate();
          })
          .catch((error) => {
            console.log("Error", error.message);
            handleAlertMessage("error", error.message);
          });
    }
  };
  /*****/
  return (
    <React.Fragment>
      <div className="mx-2 p-1">
        <div className="d-flex justify-content-between mt-2">
          <h4 className="text-primary text-start">Write SMS</h4>
          <Button
            variant="outline-primary"
            className="me-2"
            onClick={() => setShow(true)}
          >
            Choose Template
          </Button>
        </div>
        <Form.Group className="form-group mt-2">
          <Form.Label>Message</Form.Label>
          <Form.Control
            id="smsMessage"
            name="message"
            as={"textarea"}
            row="5"
            value={smsDetail?.message || ""}
            onChange={(e) =>
              setSmsDetail({ ...smsDetail, message: e?.target?.value })
            }
            // onChange={(e) => handleChange("message", { value: e?.target?.value })}
          />
          <TextLength
            textLength={smsDetail?.message?.length || 0}
            maxLength={totalChar}
          />
          <InLineError type="error" message={errorBag?.message || ""} />
          <div className="small mt-4">
            <b>Note: </b>If your text message after mail merge surpasses the
            character limit of 1520, then it will be trimmed to 1520 characters
            from start.
            <br /> However we recommends sending messages that are no more than
            306 characters to ensure the best deliverability and user
            experience.
          </div>
        </Form.Group>
      </div>
      <div className=" bg-dark text-end sticky-footer py-2">
        <div className="d-flex justify-content-between">
          <Button
            variant="outline-danger"
            className="me-2"
            onClick={showSaveTemplateData}
          >
            Save as Template
          </Button>
          <div className="text-end">
            <Button
              variant="outline-primary"
              className="me-2"
              onClick={handleSaveData}
            >
              Save
            </Button>
            <Button variant="outline-secondary" onClick={handelCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>

      <SMSTemplates
        show={show}
        setShow={setShow}
        handleChange={(value) => setSmsDetail({ ...smsDetail, ...value })}
      />

      <Modal
        className={"common"}
        centered
        show={showTemplateName}
        onHide={handleCancelShowTemplate}
      >
        <Modal.Header className="bg-primary">
          <Modal.Title className={"text-white secondary-font text-uppercase"}>
            Save as template to re-use in future
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="form-group">
            <Form.Label>{locale("Template Name")}</Form.Label>
            <Form.Control
              id={"name"}
              type="text"
              placeholder={locale("Enter Template Name")}
              value={smsDetail?.name || ""}
              onChange={(e) => {
                setSmsDetail({ ...smsDetail, name: e?.target?.value || "" });
              }}
            />
            <InLineError type="error" message={errorBag?.name || ""} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            className="me-2"
            onClick={handleCancelShowTemplate}
          >
            cancel
          </Button>
          <Button
            variant="outline-primary"
            className="me-2"
            onClick={handleSaveTemplateData}
          >
            Save as template
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { DEAL_STAGES_AND_OWNER } from "../../../../../GraphApi/DealList";
import { PipelineContext } from "../../../../../Context/PipelineContext";
import { currentTeamId, isObjectEmpty } from "../../../../../Utils/utils";
import { nonGroupColourStyles } from "../../../../../Components/Common/DropDown/Style";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { MailMerge } from "../../../../../Components/Common/Editor/Component/NewComponents/MailMerge";
import { DealProgressBar } from "../../../../../Components/Pages/Deals/DealProgressBar";
import { ProductDropdown } from "../../../../../Components/Pages/Products/ProductDropdown";

export const CreateDealAction = ({
  handleAlertMessage = () => {},
  currentCompanySetting = null,
  values = null,
  handleChange,
  handleClose,
}) => {
  const { t: locale } = useTranslation();
  const [dealOwners, setDealOwners] = useState([]);
  const [dealStages, setDealStages] = useState([]);
  const [dealDetails, setDealDetails] = useState({
    name: "",
    deal_type: "service",
    deal_owner: "",
    deal_stage: "",
    deal_products: "",
    amount: "",
  });

  const [typingTimeout, setTypingTimeout] = useState(0);
  const [pipelines, setPipelines] = useState([]);
  const [pipeline, setPipeline] = useState(null);
  const [errorBag, setErrorBag] = useState(null);

  /** Get Page Data **/
  const [fetchDealStage, { data: listingPageData }] = useLazyQuery(
    DEAL_STAGES_AND_OWNER,
    {
      variables: {
        module: "service",
        leadOwner: true,
        team_id: currentTeamId,
      },
      fetchPolicy: "network-only",
    }
  );

  const { pipelineData, getPipelines: fetchPipeline } =
    useContext(PipelineContext);

  useEffect(() => {
    fetchDealStage({
      variables: {
        module: "service",
        leadOwner: true,
        team_id: currentTeamId,
      },
      fetchPolicy: "network-only",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pipelineData?.pipelines !== null) {
      const allPipelines = [];
      pipelineData.pipelines.forEach((item) => {
        // if (!item.default_pipeline) {
        const formattedStages = item.stages
          .filter(
            (stage) =>
              stage &&
              ![
                "Completed",
                "Won",
                "Lost",
                "completed",
                "won",
                "lost",
              ].includes(stage.name)
          )
          .map((defaultStage) => ({
            label: defaultStage.label,
            value: defaultStage.id,
            pipelineId: item.id,
            category: defaultStage.category,
            created_at: defaultStage.created_at,
            id: defaultStage.id,
            name: defaultStage.name === "Completed" ? "Won" : defaultStage.name,
            probability: defaultStage.probability,
            team_id: defaultStage.team_id,
            updated_at: defaultStage.updated_at,
            sort_order: defaultStage.sort_order,
          }));

        if (formattedStages.length > 0) {
          allPipelines.push({
            ...item,
            stages: formattedStages,
            value: item.id,
            label: item.name,
          });
        }
        // }
      });
      setPipelines(allPipelines);
      setPipeline(allPipelines[0]);
      setDealStages(allPipelines[0].stages);
      dealDetails.deal_stage = allPipelines[0].stages[0];
    } else if (pipelineData?.pipelines === null && !pipelineData.isFetching) {
      fetchPipeline();
    }
  }, [pipelineData, fetchPipeline]);

  useEffect(() => {
    if (listingPageData?.allUsers?.length) {
      let options = [];
      listingPageData.allUsers.forEach((option) => {
        options.push({
          value: parseInt(option.id),
          label: option.name,
        });
      });
      setDealOwners(options);
      dealDetails.deal_owner = options[0];
    }
  }, [listingPageData]);
  /**Get Data and Put into Page**/
  useEffect(() => {
    if (!isObjectEmpty(values?.deal_details)) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, pipelines]);

  const loadData = () => {
    const preSelectedDealData = values.deal_details || "";
    setDealDetails({ ...preSelectedDealData, deal_type: "service" });
    pipelines.length > 0 &&
      pipelines.forEach((item) => {
        if (
          !isObjectEmpty(preSelectedDealData) &&
          parseInt(item.id) ===
            parseInt(preSelectedDealData.deal_stage.pipelineId)
        ) {
          setPipeline(item);
          setDealStages(item.stages);
        }
      });
  };

  const handleSaveData = () => {
    const errors = {};
    // Validation rules for required fields
    if (!dealDetails.name) {
      errors.name = "Deal name is required.";
    }

    if (!dealDetails.deal_owner) {
      errors.deal_owner = "Deal owner is required.";
    }
    if (!dealDetails.deal_stage) {
      errors.deal_stage = "Deal stage is required.";
    }

    setErrorBag(errors);
    if (Object.keys(errors).length === 0) {
      setErrorBag(null);
      let str = [];
      if (dealDetails?.name !== "") {
        str.push(<span key="1">Create deal</span>);
        str.push(
          <span key="2" className="text-primary">
            {" " + dealDetails.name + " "}
          </span>
        );
      }
      if (dealDetails?.amount !== "") {
        str.push(<span key="3">of</span>);
        str.push(
          <span key="4" className="text-primary">
            {" $" + dealDetails.amount + " "}
          </span>
        );
      }

      if (dealDetails?.deal_owner !== "") {
        str.push(<span key="5">for</span>);
        str.push(
          <span key="6" className="text-primary">
            {" " + dealDetails.deal_owner.label + " "}
          </span>
        );
      }

      if (dealDetails?.deal_stage !== "") {
        str.push(<span key="7">in</span>);
        str.push(
          <span key="8" className="text-primary">
            {" " + dealDetails.deal_stage.label + " "}
          </span>
        );
      }

      if (dealDetails?.deal_products.length > 0) {
        str.push(<span key="9">for</span>);
        str.push(
          <span key="10" className="text-primary">
            {" " +
              dealDetails.deal_products
                .map((item) => item && " " + item.label)
                .toString()}
          </span>
        );
      }
      handleChange("action", {
        action: "create_deal",
        deal_details: dealDetails,
        string: str,
      });
      handleClose();
    }
  };

  /** Handle Deal Values **/
  const handleDealDetails = (type, value) => {
    setDealDetails({ ...dealDetails, [type]: value });
  };

  /* Handle Type and Execute Save */
  const onStopTyping = (type, searchedText) => {
    if (searchedText.length >= 3) {
      clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(function () {
          handleDealDetails(type, searchedText);
        }, 600)
      );
    } else if (searchedText.length === 0 || !searchedText) {
      clearTimeout(typingTimeout);
      handleDealDetails(type, searchedText);
    }
  };
  /******/
  const getMailMergeValue = (value) => {
    const input = document.getElementById("name");
    const myValue = input.value;
    input.value = myValue.concat(value);
    handleDealDetails("name", input.value);
  };
  const handelCancel = () => {
    handleClose();
  };
  /******/
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label>{locale("pipeline")}</Form.Label>
              <Select
                isClearable={false}
                isSearchable={false}
                placeholder={locale("pipeline")}
                noOptionsMessage={() => locale("no pipeline")}
                options={pipelines}
                styles={nonGroupColourStyles}
                value={pipeline}
                onChange={(e) => {
                  setPipeline(e);
                  setDealStages(e.stages);
                }}
              />
              <InLineError
                type="error"
                message={
                  errorBag?.deal_stage && isObjectEmpty(pipeline)
                    ? "Select pipeline"
                    : null
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label>{locale("Deal name")}</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    id="name"
                    type="text"
                    placeholder={locale("Deal name")}
                    defaultValue={dealDetails?.name || ""}
                    onChange={(e) => {
                      onStopTyping("name", e.target.value);
                    }}
                  />
                  <InLineError type="error" message={errorBag?.name || null} />
                </Col>
                <Col xs={"auto"}>
                  <MailMerge
                    mode="create-popup"
                    getMailMergeValue={getMailMergeValue}
                    locale={locale}
                    forAutomation={true}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label>{locale("Deal amount")}</Form.Label>
              <Form.Control
                id="amount"
                type="number"
                placeholder={locale("Deal amount")}
                min={0}
                value={dealDetails?.amount || ""}
                onChange={(e) => {
                  handleDealDetails("amount", e.target.value);
                }}
              />
              <InLineError type="error" message={errorBag?.amount || null} />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="form-group" id="deal_owner">
              <Form.Label>{locale("Deal owner")}</Form.Label>
              <div>
                <Select
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  placeholder={locale("Deal owner")}
                  noOptionsMessage={() => locale("no deal owner")}
                  options={dealOwners}
                  styles={nonGroupColourStyles}
                  value={dealDetails?.deal_owner || ""}
                  onChange={(e) => {
                    handleDealDetails("deal_owner", e);
                  }}
                />
              </div>
              <InLineError
                type="error"
                message={errorBag?.deal_owner || null}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="form-group" id="deal_stage">
              <Form.Label>{locale("Deal stage")}</Form.Label>
              <div>
                <Select
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  placeholder={locale("Deal stage")}
                  noOptionsMessage={() => locale("no deal stage")}
                  options={dealStages}
                  styles={nonGroupColourStyles}
                  value={dealDetails?.deal_stage || ""}
                  onChange={(e) => {
                    handleDealDetails("deal_stage", e);
                  }}
                />
              </div>
              <InLineError
                type="error"
                message={errorBag?.deal_stage || null}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="form-group" id="deal_products">
              <Form.Label>{locale("associated offering")}</Form.Label>
              <ProductDropdown
                noOptions="Product"
                isMulti={true}
                isClearable={false}
                isSearchable={true}
                name="products"
                type={["physical", "digital", "service"]}
                value={dealDetails?.deal_products || ""}
                handleOnChange={(e) => {
                  handleDealDetails("deal_products", e);
                }}
                fromPage="workflow"
              />
              <InLineError
                type="error"
                message={errorBag?.deal_products || null}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <DealProgressBar
              formik={{ values: { ...dealDetails } }}
              locale={locale}
            />
          </Col>
        </Row>
      </Container>
      <div className="text-end bg-dark sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

import { gql } from "@apollo/client";

/** Lead owner listing **/
export const LIST_DOCUMENT = gql`
  query allDocuments($contact_id: Int, $team_id: Int) {
    allDocuments(contact_id: $contact_id, team_id: $team_id) {
      id
      contact_id
      title
      created_by
      description
      file_url
      attachment {
        id
        name
        path: url
        source
        created_at
        updated_at
      }
      document_category {
        id
        name
      }
      created_at
      updated_at
    }
  }
`;

/** Lead owner listing **/
export const DOCUMENT_LISTING = gql`
  query documentListing(
    $contact_id: Int
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $team_id: Int
    $category: Int
    $job_id: String
  ) {
    documentListing(
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      contact_id: $contact_id
      team_id: $team_id
      document_category_id: $category
      job_id: $job_id
    ) {
      data {
        id
        contact_id
        title
        created_by
        description
        file_url
        attachment {
          id
          name
          path: url
          source
          mime_type
          created_at
          updated_at
        }
        document_category {
          id
          name
        }
        contact {
          id
          contact_id
          first_name
          last_name
          name
          email
        }
        team_user_id
        created_at
        updated_at
        client_portal
        user {
          name
          role {
            role_type
          }
        }
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

/** Create Tags **/
export const UPLOAD_DOCUMENT = gql`
  mutation uploadDocument(
    $contact_id: Int
    $title: String
    $file: Upload
    $description: String
    $team_id: Int
    $category: Int
    $client_portal: Boolean
    $document_request_id: String
    $job_id: String
  ) {
    uploadDocument(
      contact_id: $contact_id
      title: $title
      description: $description
      file: $file
      team_id: $team_id
      document_category_id: $category
      client_portal: $client_portal
      document_request_id: $document_request_id
      job_id: $job_id
    ) {
      id
    }
  }
`;
export const UPDATE_DOCUMENT = gql`
  mutation updateUploadDocument(
    $team_id: Int
    $id: Int
    $contact_id: Int
    $title: String
    $description: String
    $category: Int
    $client_portal: Boolean
    $job_id: String
  ) {
    updateUploadDocument(
      team_id: $team_id
      id: $id
      contact_id: $contact_id
      title: $title
      description: $description
      document_category_id: $category
      client_portal: $client_portal
      job_id: $job_id
    ) {
      id
      title
      description
      created_at
      updated_at
    }
  }
`;

/** Remove Document **/
export const REMOVE_DOCUMENT = gql`
  mutation deleteDocument($id: Int, $team_id: Int) {
    deleteDocument(id: $id, team_id: $team_id) {
      status
    }
  }
`;

/*********** APPOINTMENTS ***************/

/** Create Appointment / Task **/
export const APPOINTMENT_TASK_CREATE = gql`
  mutation createAppointmentTask(
    $task_type: String
    $contact_id: Int
    $title: String
    $duration: Int
    $description: String
    $date: DateTime
    $attendees: [Int]
    $notify_user: Boolean
    $team_id: Int
    $contact_ids: [Int]
    $end_date: DateTime
    $tags: [Int]
    $meeting_type: String
    $product_id: Int
    $is_scheduling_enabled: Boolean
    $after_meeting_break: Int
    $job_id: String
  ) {
    createAppointmentTask(
      task_type: $task_type
      contact_id: $contact_id
      title: $title
      duration: $duration
      description: $description
      due_date: $date
      attendee: $attendees
      notify_user: $notify_user
      team_id: $team_id
      contact_ids: $contact_ids
      end_date: $end_date
      tags: $tags
      meeting_type: $meeting_type
      product_id: $product_id
      is_scheduling_enabled: $is_scheduling_enabled
      after_meeting_break: $after_meeting_break
      job_id: $job_id
    ) {
      id
    }
  }
`;
/** Update Appointment / Task **/
export const APPOINTMENT_TASK_UPDATE = gql`
  mutation updateAppointmentTask(
    $id: Int
    $task_type: String
    $title: String
    $duration: Int
    $description: String
    $date: DateTime
    $attendees: [Int]
    $status: String
    $notify_user: Boolean # $completed: Boolean
    $team_id: Int
    $remove_attendee: [Int]
    $contact_ids: [Int]
    $end_date: DateTime
    $tags: [Int]
    $meeting_type: String
    $product_id: Int
    $is_scheduling_enabled: Boolean
    $after_meeting_break: Int
  ) {
    updateAppointmentTask(
      id: $id
      task_type: $task_type
      title: $title
      duration: $duration
      description: $description
      due_date: $date
      status: $status
      attendee: $attendees
      notify_user: $notify_user # completed: $completed
      team_id: $team_id
      remove_attendee: $remove_attendee
      contact_ids: $contact_ids
      end_date: $end_date
      tags: $tags
      meeting_type: $meeting_type
      product_id: $product_id
      is_scheduling_enabled: $is_scheduling_enabled
      after_meeting_break: $after_meeting_break
    ) {
      id
    }
  }
`;

/** List Appointment / Task **/
export const APPOINTMENT_TASK_LIST = gql`
  query allAppointmentTask($type: String, $contact_id: Int, $team_id: Int) {
    allAppointmentTask(
      type: $type
      contact_id: $contact_id
      team_id: $team_id
    ) {
      id
      contact_id
      task_type
      assigned_to
      title
      description
      due_date
      duration
      notify_user
      completed_at
      attendee {
        id
        first_name
        last_name
        name
      }
      created_at
      updated_at
    }
  }
`;
/** USER LIST **/
export const USER_LIST = gql`
  query ($team_id: Int) {
    allUsers(team_id: $team_id) {
      id
      first_name
      last_name
      name
      email
      company
    }
  }
`;
/** Remove Task / Appointment **/
export const REMOVE_APPOINTMENT_TASK = gql`
  mutation deleteAppointmentTask($id: Int, $team_id: Int) {
    deleteAppointmentTask(id: $id, team_id: $team_id) {
      status
    }
  }
`;
/**** NOTES ****/
/** Notes LIST **/
export const NOTES_LIST = gql`
  query allNotes($contact_id: Int, $team_id: Int) {
    allNotes(contact_id: $contact_id, team_id: $team_id) {
      id
      contact_id
      created_by
      description
      created_at
      updated_at
      client_portal
    }
  }
`;
/** Remove Task / Appointment **/
export const NOTE_REMOVE = gql`
  mutation deleteNote($id: Int, $team_id: Int) {
    deleteNote(id: $id, team_id: $team_id) {
      status
    }
  }
`;
/** Update Note  **/
export const NOTE_CREATE = gql`
  mutation createNote(
    $contact_id: Int
    $title: String
    $description: String
    $team_id: Int
    $client_portal: Boolean
    $job_id: String
  ) {
    createNote(
      contact_id: $contact_id
      title: $title
      description: $description
      team_id: $team_id
      client_portal: $client_portal
      job_id: $job_id
    ) {
      id
    }
  }
`;
/** Update Note  **/
export const NOTE_UPDATE = gql`
  mutation updateNote(
    $id: Int
    $title: String
    $description: String
    $team_id: Int
    $client_portal: Boolean
    $job_id: String
  ) {
    updateNote(
      id: $id
      title: $title
      description: $description
      team_id: $team_id
      client_portal: $client_portal
      job_id: $job_id
    ) {
      id
    }
  }
`;

/** List with Pagination Appointment / Task **/
export const ALL_APPOINTMENT_TASK_LIST = gql`
  query appointmentTaskListing(
    $type: String
    $contact_id: Int
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $team_id: Int
    $deal_id: Int
    $is_deal_info: Boolean
    $job_id: String
  ) {
    appointmentTaskListing(
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      type: $type
      contact_id: $contact_id
      team_id: $team_id
      deal_id: $deal_id
      is_deal_info: $is_deal_info
      job_id: $job_id
    ) {
      data {
        id
        booking_invoice_id
        contact_id
        task_type
        assigned_to
        title
        description
        due_date
        duration
        notify_user
        completed_at
        status
        task_status
        attendee {
          id
          first_name
          last_name
          name
        }
        contacts {
          id
          email
          name
        }
        task_meta {
          end_date
          tags
          meeting_type
          product_id

          product {
            id
            name
          }
        }
        created_at
        updated_at
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

/** List with Pagination Appointment / Task **/
export const MEMBER_APPOINTMENT_TASK_LIST = gql`
  query memberAppointmentTaskListing(
    $type: String
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $user_id: Int
    $team_id: Int
  ) {
    appointmentTaskListing(
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      type: $type
      user_id: $user_id
      team_id: $team_id
    ) {
      data {
        id
        contact_id
        task_type
        assigned_to
        title
        description
        due_date
        duration
        notify_user
        completed_at
        status
        task_status
        attendee {
          id
          first_name
          last_name
          name
        }

        contacts {
          id
          name
          email
        }
        task_meta {
          end_date
          tags
          meeting_type
          product_id

          product {
            id
            name
          }
        }
        created_at
        updated_at
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

/** Notes LIST **/
export const NOTES_LISTING = gql`
  query notesListing(
    $contact_id: Int
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $team_id: Int
    $job_id: String
  ) {
    notesListing(
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      contact_id: $contact_id
      team_id: $team_id
      job_id: $job_id
    ) {
      data {
        id
        contact_id
        created_by
        title
        description
        created_at
        updated_at
        client_portal
        user {
          name
          role {
            role_type
          }
        }
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

/** RECENT WEBSITE INTERACTION LIST **/
export const RECENT_WEB_INTERACTION = gql`
  query websiteInteraction($contact_id: Int, $team_id: Int) {
    websiteInteraction(contact_id: $contact_id, team_id: $team_id) {
      created_date
      name
      url
    }
  }
`;

/** List with Pagination Appointment / Task **/
export const ALL_APPOINTMENTS_TASKS = gql`
  query allContactAppointmentTaskListing(
    $type: String
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $team_id: Int
    $filter: [CommonFilter!]
  ) {
    allContactAppointmentTaskListing(
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      type: $type
      team_id: $team_id
      filter: $filter
    ) {
      data {
        id
        contact_id
        task_type
        assigned_to
        assigned_to_name
        title
        description
        due_date
        duration
        notify_user
        completed_at
        task_status
        status
        contacts {
          id
          name
          company
          company_id
          deleted_at
          image {
            path: url
          }
        }
        attendee {
          id
          first_name
          last_name
          name
        }
        created_at
        updated_at
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

export const APPOINTMENT_CONTACT = gql`
  query getAppointmentAttendees(
    $appointment_id: String
    $team_id: Int
    $attendee_type: String
    $first: Int!
    $page: Int
    $search: String
    $status: Int
  ) {
    getAppointmentAttendees(
      appointment_id: $appointment_id
      team_id: $team_id
      attendee_type: $attendee_type
      first: $first
      page: $page
      q: $search
      status: $status
    ) {
      data {
        status
        meeting_type
        created_at: updated_at
        contact {
          id
          name
          email
          company
          company_id
        }
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

export const APPOINTMENT_CONTACT_STATS = gql`
  query getAppointmentAttendeeStat($team_id: Int, $appointment_id: String) {
    getAppointmentAttendeeStat(
      team_id: $team_id
      appointment_id: $appointment_id
    ) {
      message
    }
  }
`;

export const CHECK_APPOINTMENT_SLOT_BOOKED = gql`
  query checkAppointmentSlotBooked(
    $team_id: Int
    $search: String
    $due_date: DateTime
    $end_date: DateTime
  ) {
    checkAppointmentSlotBooked(
      team_id: $team_id
      search: $search
      due_date: $due_date
      end_date: $end_date
    ) {
      id
      #product_id
      name
      image
      price
      type
      product_type
      description
      tax_applicable
      tax
    }
  }
`;

//create category
export const DOCUMENT_CATEGORY_CREATE = gql`
  mutation createDocumentCategory($team_id: Int, $category: String) {
    createDocumentCategory(team_id: $team_id, name: $category) {
      id
      name
    }
  }
`;
//Update category
export const DOCUMENT_CATEGORY_UPDATE = gql`
  mutation updateDocumentCategory($id: Int, $team_id: Int, $category: String) {
    updateDocumentCategory(id: $id, team_id: $team_id, name: $category) {
      id
      name
    }
  }
`;

//delete category
export const DOCUMENT_CATEGORY_DELETE = gql`
  mutation deleteDocumentCategory($team_id: Int, $id: Int, $category: Int) {
    deleteDocumentCategory(
      team_id: $team_id
      id: $id
      document_category_id: $category
    ) {
      status
      message
    }
  }
`;

//list all document categories
export const DOCUMENT_CATEGORY_LIST = gql`
  query allDocumentCategory($team_id: Int) {
    allDocumentCategory(team_id: $team_id) {
      id
      name
      team_id
      created_at
      updated_at
      documents_count
    }
  }
`;
export const DOCUMENT_REQUEST_LIST = gql`
  query requestDocumentListing(
    $team_id: Int
    $first: Int!
    $page: Int
    $contact_id: Int
  ) {
    requestDocumentListing(
      team_id: $team_id
      first: $first
      page: $page
      contact_id: $contact_id
    ) {
      data {
        id
        document_request_id
        team_id
        contact_id
        title
        team_user {
          id
          name
        }
        created_at
        updated_at
        document_category {
          id
          name
        }
        user {
          name
          role {
            role_type
            id
          }
        }
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

export const DOCUMENT_REQUEST = gql`
  mutation requestDocumentFromClient(
    $team_id: Int
    $contact_id: Int
    $title: String
    $category: Int
  ) {
    requestDocumentFromClient(
      team_id: $team_id
      contact_id: $contact_id
      title: $title
      document_category_id: $category
    ) {
      id
    }
  }
`;

export const DOCUMENT_REQUEST_CANCEL = gql`
  mutation cancelRequestDocument($id: String, $team_id: Int) {
    cancelRequestDocument(id: $id, team_id: $team_id) {
      status
    }
  }
`;

export const DOCUMENT_REQUEST_UPDATE = gql`
  mutation updateRequestDocument(
    $id: String
    $team_id: Int
    $contact_id: Int
    $title: String
    $category: Int
  ) {
    updateRequestDocument(
      id: $id
      team_id: $team_id
      contact_id: $contact_id
      title: $title
      document_category_id: $category
    ) {
      id
    }
  }
`;

import React from 'react';
import { Form } from 'react-bootstrap';

const FONT_SIZES = [
  { label: '8px', value: '8px' },
  { label: '9px', value: '9px' },
  { label: '10px', value: '10px' },
  { label: '11px', value: '11px' },
  { label: '12px', value: '12px' },
  { label: '14px', value: '14px' },
  { label: '16px', value: '16px' },
  { label: '18px', value: '18px' },
  { label: '20px', value: '20px' },
  { label: '24px', value: '24px' },
  { label: '28px', value: '28px' },
  { label: '32px', value: '32px' },
  { label: '36px', value: '36px' },
  { label: '48px', value: '48px' },
  { label: '60px', value: '60px' },
  { label: '72px', value: '72px' },
  { label: '96px', value: '96px' },
];

const FontSizeSelector = ({ onChange, value }) => (
  <div className="me-1 d-flex align-items-center">
    <label className="form-label mb-0 me-2" style={{fontSize: "14px", color: "#000"}}>Size</label>
    <Form.Select
      onChange={(e) => onChange(e.target.value)}
      value={value}
      className="form-select"
      style={{ width: '80px',  fontSize: "small" }}
    >
      {FONT_SIZES.map((size) => (
        <option key={size.value} value={size.value}>
          {size.label}
        </option>
      ))}
    </Form.Select>
  </div>
);

export default FontSizeSelector;
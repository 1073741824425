import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./agreement-popup.scss";

export const CreateAgreementPopup = ({
  contactDetails,
  showPopup,
  handleClosePopup,
  name,
  cameFrom = "",
}) => {
  const { t: locale } = useTranslation();
  /*** For Template Page ***/
  return (
    <Modal
      show={showPopup}
      className="common modalXl fixed-height-with-scroll"
      centered
      onHide={handleClosePopup}
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white" className="bg-primary">
        <Modal.Title className="text-white">
          Create Agreement Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateAgreementOption
          contactDetails={contactDetails}
          cameFrom={cameFrom}
          name={name}
          locale={locale}
        />
      </Modal.Body>
    </Modal>
  );
};

export const CreateAgreementOption = ({
  contactDetails,
  cameFrom,
  name,
  locale,
}) => {
  return (
    <Row className="align-items-center justify-content-center setting-main-wrapper my-4">
      <Col>
        <div className="setting-add-payment-method">
          <div className="payment-icon mb-4 text-primary">
            <span>
              <i className="ri-pages-fill"></i>
            </span>
          </div>
          <h4 className="fs-6">
            {locale("Paste from existing agreement or create from scratch.")}
          </h4>
          <p className="fs-6" style={{ letterSpacing: "1px" }}>
            <span className="text-success">Recommended</span>
            {locale(": More customization options")}
          </p>
          <Button
            variant="outline-warning"
            as={Link}
            to={"/agreement-builder"}
            state={{
              type: name.type,
              mode: "create",
              step: 2,
              contact_id:
                contactDetails && contactDetails.contact_id
                  ? contactDetails.contact_id
                  : null,
              contactId:
                contactDetails && contactDetails.id ? contactDetails.id : null,
              cameFrom: cameFrom,
            }}
          >
            Create
          </Button>
        </div>
      </Col>
      <Col>
        <div className="setting-add-payment-method">
          <div className="payment-icon mb-4">
            <span>
              <i className="ri-pages-fill"></i>
            </span>
          </div>
          <h4 className="fs-6">
            {locale("Upload your existing agreement as Doc or PDF.")}
          </h4>
          <p className="fs-6" style={{ letterSpacing: "1px" }}>
            <span className="text-success">Quick</span>{" "}
            {locale("To add agreement, with less customizations")}
          </p>
          <Button
            variant="outline-warning"
            as={Link}
            to={"/agreement-builder"}
            state={{
              type: name.type,
              mode: "create",
              step: 1,
              contact_id:
                contactDetails && contactDetails.contact_id
                  ? contactDetails.contact_id
                  : null,
              contactId:
                contactDetails && contactDetails.id ? contactDetails.id : null,
              cameFrom: cameFrom,
            }}
          >
            Upload
          </Button>
        </div>
      </Col>
    </Row>
  );
};

import { getShortCodeRequest } from "../../../../RestApi/EditorApi";

export const getShortCodesList = (data, id) => {
    return new Promise((resolve, reject) => {
        getShortCodeRequest(data, id)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          return reject({
            status: false,
            message: getResponseError(error),
          });
        });
    });
  };

  
export const getResponseError = (error) => {
    let responseError = error.response.statusText && error.response.statusText;
    if (error.response.data && error.response.data.message) {
      responseError = error.response.data.message;
    } else if (error.response.data.message) {
      responseError = error.message;
    }
    return responseError;
  };
  
import React from "react";
import { Form } from "react-bootstrap";

const NumberField = ({
  placeholder = "ie 123",
  label,
  labelVisibility = true,
  value,
  handleChange,
  options,
  isError,
}) => {
  const onHandleFloatingNumberChange = (e) => {
    if (e.target.value) {
      const decimalA = /^[0-9.\-/]+$/;
      if (decimalA.test(e.target.value)) handleChange(e.target.value);
    } else {
      handleChange("");
    }
  };

  const onBlurFloatingNumberChange = (e) => {
    const decimalB = /^[0-9]\d{0,9}(\.\d{1,9})?%?$/;
    if (!decimalB.test(e.target.value))
      handleChange(parseFloat(e.target.value));
  };

  return (
    <Form.Group className="form-group">
      <Form.Label className={!labelVisibility && "invisible"}>From</Form.Label>
      <Form.Control
        className={isError ? "is-invalid" : ""}
        // type="number"
        placeholder={placeholder}
        value={value ? value : ""}
        onChange={(e) => {
          onHandleFloatingNumberChange(e, "change");
        }}
        onBlur={(e) => {
          onBlurFloatingNumberChange(e, "blur");
        }}
      />
    </Form.Group>
  );
};

export default NumberField;

import { useState } from "react";
import ImportWizard from "../../Common/Import/ImportWizard";
import { IMPORT_CONTACT_STATUS } from "../../../GraphApi/Import";
import { useLazyQuery } from "@apollo/client";
import { currentTeamId } from "../../../Utils/utils";
import { customAttribute } from "../../../DummyData";

const ImportWrapper = ({module = "contacts", showImports = false, mappingFields, setMappingFields, setShowImports = () => {}}) => {

  const [step, setStep] = useState(1);
  const [isConfirm, setIsConfirm] = useState(false);
  const [importVia, setImportVia] = useState("Excel/CSV");
  const [leadOwners, setLeadOwners] = useState([]);
  const [tagsList, setTagsList] = useState([]);

  const [summary, SetSummary] = useState({
    total: 0,
    ignored: 0,
    inserted: 0,
    duplicate: 0,
    overwrite: 0,
    error: "",
    status: 0,
  });

  const [
    getSummary,
    { loading: importInProgress, error: importError, data: contactImport },
  ] = useLazyQuery(IMPORT_CONTACT_STATUS);

  const handleCloseImports = () => {
    if (step >= 2 && step !== 4) {
      setIsConfirm(true);
      setStep(1);
      setImportVia("Excel/CSV");
    } else {
      setImportVia("Excel/CSV");
      setShowImports(false);
    }
  };

  const refetchData = () => {
    console.log("fetch data after import started here...");
  };

  const refetchModuleData = () => {
    console.log("refetch page listing data after import started here...");
  }

  const preDefinedColumnUpdate = () => {
    console.log("fetch pre-defined columns");
  }

  return (
    <ImportWizard
      step={step}
      setStep={setStep}
      handleClose={handleCloseImports}
      mappingField={mappingFields}
      customAttribute={customAttribute}
      refetchData={refetchModuleData}
      leadOwners={leadOwners}
      module={module}
      preDefinedColumnUpdate={() => refetchData()}
      tagsList={tagsList}
      getSummary={getSummary}
      summary={summary}
      importInProgress={importInProgress}
      importError={importError}
      isConfirm={isConfirm}
      setIsConfirm={setIsConfirm}
      setShowImports={setShowImports}
      team_id={currentTeamId}
      importVia={importVia}
    />
  );
};

export default ImportWrapper;
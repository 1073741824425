import { gql } from "@apollo/client";

export const FETCH_WABA = gql`
  query fetchWaba($team_id: Int) {
    fetchWaba(team_id: $team_id) {
      id
      team_id
      name
      on_behalf_business_info
      account_review_status
      owner_business_info
      created_at
      updated_at
      whatsappNumbers {
        id
        number_id
        waba_id
        verified_name
        code_verification_status
        display_phone_number
        quality_rating
        created_at
        updated_at
      }
    }
  }
`;

/** Contact listing **/
export const FETCH_MESSAGES = gql`
  query fetchMessages($team_id: Int, $contact_id: Int) {
    fetchMessages(team_id: $team_id, contact_id: $contact_id) {
      id
      message_id
      recipient_type
      from
      to
      direction
      type
      type_value
      message_timestamp
      referral
      errors
      created_at
      updated_at
      component_params
      whatsapp_message_data {
        data
      }
      attachment {
        id
        url
        path
        file_url
      }
      whatsapp_message_statuses {
        status
        created_at
      }
    }
  }
`;

export const SEND_WHATSAPP_MESSAGE = gql`
  mutation sendWhatsappMessage(
    $teamId: Int
    $contactId: Int
    $message: String
    $type: String
    $preview_url: Boolean
    $message_id: String
    $emoji: String
    $asset: Upload
    $asset_link: String
    $asset_caption: String
    $asset_file_name: String
    $template_name: String
    $template_language_code: String
    $component_params: String
  ) {
    sendWhatsappMessage(
      team_id: $teamId
      contact_id: $contactId
      message: $message
      type: $type
      preview_url: $preview_url
      message_id: $message_id
      emoji: $emoji
      asset: $asset
      asset_link: $asset_link
      asset_caption: $asset_caption
      asset_file_name: $asset_file_name
      template_name: $template_name
      template_language_code: $template_language_code
      component_params: $component_params
    ) {
      id
      message_id
      recipient_type
      from
      to
      direction
      type
      type_value
      message_timestamp
      referral
      errors
      created_at
      updated_at
      whatsapp_message_statuses {
        status
        created_at
      }
    }
  }
`;

export const REQUEST_OTP_NUMBER_VERIFY = gql`
  mutation requstVerifyWhatsappNumber(
    $team_id: Int
    $number_id: String
    $code_method: String
  ) {
    requstVerifyWhatsappNumber(
      team_id: $team_id
      number_id: $number_id
      code_method: $code_method
    ) {
      status
    }
  }
`;

export const REQUEST_NUMBER_VERIFY = gql`
  mutation completeVerifyWhatsappNumber(
    $team_id: Int
    $number_id: String
    $code: String
  ) {
    completeVerifyWhatsappNumber(
      team_id: $team_id
      number_id: $number_id
      code: $code
    ) {
      status
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation markMessageAsRead($team_id: Int, $message_id: String) {
    markMessageAsRead(team_id: $team_id, message_id: $message_id) {
      status
    }
  }
`;

export const WHATSAPP_TEMPLATE_LIST = gql`
  query fetchWhatsappMessageTemplates(
    $team_id: Int
    $status: String #$industry: String
  ) {
    fetchWhatsappMessageTemplates(
      team_id: $team_id
      status: $status #industry: $industry
    ) {
      id
      waba_id
      template_id
      name
      language
      status
      category
      whatsapp_category
      components
      example_variables
      created_at
      updated_at
      reason
    }
  }
`;

export const WHATSAPP_TEMPLATE_LIST_PAGINATE = gql`
  query fetchPaginatedWhatsappMessageTemplates(
    $team_id: Int
    $first: Int!
    $page: Int
  ) {
    fetchPaginatedWhatsappMessageTemplates(
      team_id: $team_id
      first: $first
      page: $page
    ) {
      data {
        id
        waba_id
        template_id
        name
        language
        status
        category
        whatsapp_category
        components
        example_variables
        created_at
        updated_at
        reason
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

export const WHATSAPP_TEMPLATE_CREATE = gql`
  mutation createWhatsappMessageTemplate(
    $team_id: Int
    $name: String
    $language: String
    $category: String
    $whatsapp_category: String
    $components: String
    $sent_for_approval: Boolean
    $example_variables: String
  ) {
    createWhatsappMessageTemplate(
      team_id: $team_id
      name: $name
      language: $language
      category: $category
      whatsapp_category: $whatsapp_category
      components: $components
      sent_for_approval: $sent_for_approval
      example_variables: $example_variables
    ) {
      id
      waba_id
      template_id
      name
      language
      sent_for_approval
      status
      category
      whatsapp_category
      components
      created_at
      updated_at
    }
  }
`;

export const WHATSAPP_TEMPLATE_UPDATE = gql`
  mutation updateWhatsappMessageTemplate(
    $team_id: Int
    $id: Int
    $name: String
    $language: String
    $whatsapp_category: String
    $category: String
    $components: String
    $sent_for_approval: Boolean
    $example_variables: String
  ) {
    updateWhatsappMessageTemplate(
      team_id: $team_id
      id: $id
      name: $name
      language: $language
      category: $category
      whatsapp_category: $whatsapp_category
      components: $components
      sent_for_approval: $sent_for_approval
      example_variables: $example_variables
    ) {
      id
      waba_id
      template_id
      name
      language
      status
      sent_for_approval
      category
      whatsapp_category
      components
      created_at
      updated_at
      example_variables
    }
  }
`;

export const WHATSAPP_TEMPLATE_DELETE = gql`
  mutation deleteWhatsappMessageTemplate($team_id: Int, $id: Int) {
    deleteWhatsappMessageTemplate(team_id: $team_id, id: $id) {
      status
    }
  }
`;

export const WHATSAPP_MEDIA_UPLOAD = gql`
  mutation uploadMediaToWhatsapp($team_id: Int, $file: Upload) {
    uploadMediaToWhatsapp(team_id: $team_id, file: $file) {
      message
    }
  }
`;

export const WHATSAPP_TEMP_DATA = gql`
  query fetchWhatsappTempData($team_id: Int) {
    fetchWhatsappTempData(team_id: $team_id) {
      id
      description
      created_at
      updated_at
    }
  }
`;

export const WHATSAPP_ACCOUNT_SELECT = gql`
  mutation finalizeWhatsapp($team_id: Int, $waba_id: String) {
    finalizeWhatsapp(team_id: $team_id, waba_id: $waba_id) {
      message
    }
  }
`;

export const TEMPLATE_CATEGORY = gql`
  query fetchWhatsappSystemTemplateCategories($team_id: Int) {
    fetchWhatsappSystemTemplateCategories(team_id: $team_id) {
      message
    }
  }
`;

export const TEMPLATE_LISTING = gql`
  query fetchAllSystemWhatsappTemplates($team_id: Int, $industry: String) {
    fetchAllSystemWhatsappTemplates(team_id: $team_id, industry: $industry) {
      id
      name
      language
      category
      whatsapp_category
      components
      industry
      created_at
      updated_at
    }
    fetchWhatsappSystemTemplateCategories(team_id: $team_id) {
      message
    }
  }
`;
export const TEMPLATE_CLONE = gql`
  mutation copySystemWhatsappMessageTemplate(
    $team_id: Int
    $template_id: Int
    $send_for_approval: Boolean
  ) {
    copySystemWhatsappMessageTemplate(
      team_id: $team_id
      template_id: $template_id
      send_for_approval: $send_for_approval
    ) {
      id
      waba_id
      template_id
      name
      language
      status
      sent_for_approval
      category
      whatsapp_category
      components
      created_at
      updated_at
      example_variables
    }
  }
`;

export const SAVE_TO_DOCUMENT = gql`
  mutation addToDocuments(
    $team_id: Int
    $attachment_id: Int
    $contact_id: Int
    $name: String
  ) {
    addToDocuments(
      team_id: $team_id
      attachment_id: $attachment_id
      contact_id: $contact_id
      name: $name
    ) {
      status
    }
  }
`;

import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ImageGalleryWithModal } from "../ImageGallery/ImageGalleryWithModal";
import { Emojis } from "../../SocialMedia/Common/Emojis";
import {
  currentTeamId,
  getFileExtension,
  getValidationMessage,
  handleCursorPosition,
  setCaretPosition,
} from "../../../Utils/utils";
import "../../SocialMedia/Common/slider.css";
import { LIST_DOCUMENT } from "../../../GraphApi/TaskAndDocuments";
import { useLazyQuery } from "@apollo/client";
import InLineError from "../AlertMessage/InLineError";
import { WhatsappTemplateDropdown } from "../../WhatsAppManagement/WhatsappTemplateDropdown";
import { WhatsappSticker } from "./WhatsappSticker";

export const MediaAndEmojis = ({
  formik,
  uploadLimit,
  locale,
  messageRef = null,
  setShowViewer = () => {},
  setDocUrl = () => {},
  contactDetails = {},
  isVisible = true,
  type = null,
  callback = () => {},
  hasWhatsappTemplate = false,
}) => {
  const [myErrors, setMyErrors] = useState(false);
  const [showImageList, setShowImageList] = useState(false);
  const [showVideoList, setShowVideoList] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [showFileManager, setShowFileManager] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showAttachments, setShowAttachments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [showAudioList, setShowAudioList] = useState(false);
  const [showTemplateList, setShowTemplateList] = useState(false);
  const [template, setTemplate] = useState(null);
  const [showStickerList, setShowStickerList] = useState(false);
  /******/
  const [fetchDocuments] = useLazyQuery(LIST_DOCUMENT, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (documents && documents.length > 0) {
      const myAttachments = documents.filter(
        (document) => selectedItems.indexOf(document.id) !== -1
      );
      setShowAttachments([...myAttachments]);
      formik.setFieldValue("attachments", myAttachments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const getEmoji = useCallback((code) => {
    if (messageRef && messageRef.current) {
      const input = messageRef.current;
      let prvPosition = handleCursorPosition(input);

      let myValue = input.value;

      const beforeCurserText = myValue.substring(0, prvPosition.start);
      const afterCurserText = myValue.substring(
        prvPosition.end,
        myValue.length
      );

      const newPosition = {
        start: prvPosition.start + code.length,
        end: prvPosition.end + code.length,
      };

      myValue = beforeCurserText.concat(code, afterCurserText);
      formik.setFieldValue("message", myValue);
      input.value = myValue;
      setCaretPosition(input, newPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMedia = () => {
    let mediaFiles = [];
    const videoIcon = require("../../../Assets/Images/media.png");

    if (formik.values.images && formik.values.images.length > 0) {
      formik.values.images.forEach((item, idx) => {
        mediaFiles.push(
          <div className="img-thumb border p1 m-1" key={"img-" + idx}>
            <i
              className="ri-close-fill ri-xl cursor-pointer"
              onClick={() => removeMedia("image", idx)}
            />
            <img src={item.src} alt="" />
          </div>
        );
      });
    }
    if (false && formik.values.stickers && formik.values.stickers.length > 0) {
      formik.values.stickers.forEach((item, idx) => {
        mediaFiles.push(
          <div className="img-thumb border p1 m-1" key={"img-" + idx}>
            <i
              className="ri-close-fill ri-xl cursor-pointer"
              onClick={() => removeMedia("stickers", idx)}
            />
            <img src={item.src} alt="" />
          </div>
        );
      });
    }

    if (formik.values.videos && formik.values.videos.length > 0) {
      formik.values.videos.forEach((item, idx) => {
        mediaFiles.push(
          <div className="img-thumb border p1 m-1" key={"video-" + idx}>
            <i
              className="ri-close-fill ri-xl cursor-pointer"
              onClick={() => removeMedia("video", idx)}
            />
            <img src={videoIcon} alt="" />
          </div>
        );
      });
    }

    if (formik.values.audios && formik.values.audios.length > 0) {
      formik.values.audios.forEach((item, idx) => {
        mediaFiles.push(
          <div className="img-thumb border p1 m-1" key={"audio-" + idx}>
            <i
              className="ri-close-fill ri-xl cursor-pointer"
              onClick={() => removeMedia("audio", idx)}
            />
            <img src={videoIcon} alt="" />
          </div>
        );
      });
    }
    if (
      formik.values.attachment_images &&
      formik.values.attachment_images.length > 0
    ) {
      formik.values.attachment_images.forEach((item, idx) => {
        mediaFiles.push(
          <div className="img-thumb border p1 m-1" key={"audio-" + idx}>
            <i
              className="ri-close-fill ri-xl cursor-pointer"
              // onClick={() => removeMedia('audio', idx)}
            />
            <img src={item.url} alt="" />
          </div>
        );
      });
    }
    return mediaFiles;
  };

  const removeMedia = (type, index) => {
    if (type === "image") {
      const myImageMedia = [];
      if (formik.values.images.length > 0) {
        formik.values.images.forEach((item, idx) => {
          if (idx !== index) {
            myImageMedia.push(item);
          }
        });
      }
      formik.setFieldValue("images", myImageMedia);
      setMyErrors(false);
    } else if (type === "stickers") {
      const myImageMedia = [];
      if (formik.values.stickers.length > 0) {
        formik.values.stickers.forEach((item, idx) => {
          if (idx !== index) {
            myImageMedia.push(item);
          }
        });
      }
      formik.setFieldValue("stickers", myImageMedia);
      setMyErrors(false);
    } else if (type === "video") {
      const myVideoMedia = [];
      if (formik.values.videos.length > 0) {
        formik.values.videos.forEach((item, idx) => {
          if (idx !== index) {
            myVideoMedia.push(item);
          }
        });
      }
      formik.setFieldValue("videos", myVideoMedia);
      setMyErrors(false);
    } else if (type === "audio") {
      const myAudioMedia = [];
      if (formik.values.audios.length > 0) {
        formik.values.audios.forEach((item, idx) => {
          if (idx !== index) {
            myAudioMedia.push(item);
          }
        });
      }
      formik.setFieldValue("audios", myAudioMedia);
      setMyErrors(false);
    }
  };

  const handleImageChange = async (src) => {
    let myImageMedia =
      formik.values.images && formik.values.images.length > 0
        ? formik.values.images
        : [];
    if (myImageMedia.length >= uploadLimit.images) {
      setMyErrors({
        ...myErrors,
        media: "You can upload max " + uploadLimit.images + " images",
      });
    } else {
      myImageMedia.push({ src: src });
      formik.setFieldValue("images", myImageMedia);
    }
    setShowImageList(false);
  };

  const handleStickerChange = async (src) => {
    let myImageMedia =
      formik.values.stickers && formik.values.stickers.length > 0
        ? formik.values.stickers
        : [];
    if (myImageMedia.length >= uploadLimit.images) {
      setMyErrors({
        ...myErrors,
        media: "You can upload max " + uploadLimit.images + " images",
      });
    } else {
      myImageMedia.push({ src: src });
      formik.setFieldValue("stickers", myImageMedia);
    }
    setShowStickerList(false);
  };

  const handleVideoChange = (videoSrc) => {
    let myVideoMedia =
      formik.values.videos && formik.values.videos.length > 0
        ? formik.values.videos
        : [];
    if (myVideoMedia.length >= uploadLimit.video) {
      setMyErrors({
        ...myErrors,
        media: "You can upload max " + uploadLimit.video + " videos",
      });
    } else if (
      videoSrc &&
      videoSrc.path &&
      videoSrc.size &&
      videoSrc.mime_type
    ) {
      myVideoMedia.push({
        url: videoSrc.path,
        size: parseInt(videoSrc.size),
        mime_type: videoSrc.mime_type,
      });
      formik.setFieldValue("videos", myVideoMedia);
    }
    handleCloseVideoPopup();
  };

  const handleAudioChange = (audioSrc) => {
    let myAudioMedia =
      formik.values.audios && formik.values.audios.length > 0
        ? formik.values.audios
        : [];
    if (myAudioMedia.length >= uploadLimit.video) {
      setMyErrors({
        ...myErrors,
        media: "You can upload max " + uploadLimit.audio + " audios",
      });
    } else if (
      audioSrc &&
      audioSrc.path &&
      audioSrc.size &&
      audioSrc.mime_type
    ) {
      myAudioMedia.push({
        url: audioSrc.path,
        size: parseInt(audioSrc.size),
        mime_type: audioSrc.mime_type,
      });
      formik.setFieldValue("audios", myAudioMedia);
    }
    handleCloseAudioPopup();
  };

  const handleCloseVideoPopup = () => {
    setShowVideoList(false);
  };

  const handleHideFileManager = () => {
    setShowFileManager(false);
  };

  const handleHideSticker = () => {
    setShowStickerList(false);
  };

  const handleCloseAudioPopup = () => {
    setShowAudioList(false);
  };
  const handleCloseTemplatePopup = () => {
    setShowTemplateList(false);
    setTemplate(null);
  };

  const removeAttachment = (id) => {
    const myAttachments = selectedItems.filter((item) => item !== id);
    setSelectedItems([...myAttachments]);
    formik.setFieldValue("attachments", myAttachments);
    setMyErrors(false);
  };

  const refetchDocuments = () => {
    fetchDocuments({
      variables: {
        team_id: currentTeamId,
        contact_id: contactDetails ? parseInt(contactDetails.id) : null,
      },
      fetchPolicy: "network-only",
    })
      .then((result) => {
        if (result && result.data && result.data.error) {
          let { graphQLErrors } = result.data.error;
          console.log("Error::", getValidationMessage(graphQLErrors));
        } else if (result && result.data && result.data.allDocuments) {
          setDocuments(result.data.allDocuments);
        }
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        console.log(
          "Error::",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
      });
  };

  const handleCheckMedia = (type = "image") => {
    if (
      formik.values.images.length === 0 &&
      formik.values.stickers.length === 0 &&
      formik.values.videos.length === 0 &&
      formik.values.audios.length === 0 &&
      formik.values.attachments.length === 0
    ) {
      if (type === "image") {
        setShowImageList(true);
        setMyErrors(false);
      } else if (type === "video") {
        setShowVideoList(true);
        setMyErrors(false);
      } else if (type === "attachment") {
        refetchDocuments();
        setShowFileManager(true);
        setMyErrors(false);
      } else if (type === "audio") {
        setShowAudioList(true);
        setMyErrors(false);
      } else if (type === "template") {
        setShowTemplateList(true);
        setMyErrors(false);
      } else if (type === "stickers") {
        setShowStickerList(true);
        setMyErrors(false);
      }
    } else {
      setMyErrors({
        media: locale(
          "You can upload either {uploadLimit.video} video or {uploadLimit.images} images or {uploadLimit.audio} audio or {uploadLimit.document} document at a time!",
          { uploadLimit }
        ),
      });
    }
  };

  /**** FOR MESSAGE TEMPLATE ***/
  const handleSelect = () => {
    if (type === "image") {
      setShowImageList(true);
    } else if (type === "video") {
      setShowVideoList(true);
    } else if (type === "document") {
      setShowFileManager(true);
    }
  };

  const handleMediaChange = (url, type) => {
    /*** This call back used different ways in different pages ***/
    callback(0, url, type);
    setShowImageList(false);
    setShowVideoList(false);
    setShowFileManager(false);
  };

  /********/
  return (
    <React.Fragment>
      {isVisible ? (
        <React.Fragment>
          <div className="msg-actions mt-2 media-action-icons">
            <OverlayTrigger
              placement="top"
              key="emoji"
              overlay={<Tooltip>Emoji's</Tooltip>}
            >
              <i
                className="ri-emotion-line ri-xl text-secondary cursor-pointer"
                onClick={() => setShowEmoji(!showEmoji)}
              />
            </OverlayTrigger>
            {/* {hasWhatsappTemplate && ( */}
            <OverlayTrigger
              placement="top"
              key="stickers"
              overlay={<Tooltip>Stickers</Tooltip>}
            >
              <i
                className="ri-emotion-line ri-xl text-secondary cursor-pointer"
                onClick={() => handleCheckMedia("stickers")}
              />
            </OverlayTrigger>
            {/* )} */}
            <OverlayTrigger
              placement="top"
              key="Image"
              overlay={<Tooltip>Image</Tooltip>}
            >
              <i
                className="ri-image-line ri-xl text-secondary cursor-pointer"
                onClick={() => handleCheckMedia("image")}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              key="Video"
              overlay={<Tooltip>Video</Tooltip>}
            >
              <i
                className="ri-video-line ri-xl text-secondary cursor-pointer"
                onClick={() => handleCheckMedia("video")}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              key="Audio"
              overlay={<Tooltip>Audio</Tooltip>}
            >
              <i
                className="ri-folder-music-line ri-xl text-secondary cursor-pointer"
                onClick={() => handleCheckMedia("audio")}
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              key="Document"
              overlay={<Tooltip>Document</Tooltip>}
            >
              <i
                className="ri-attachment-line ri-xl text-secondary cursor-pointer"
                onClick={() => handleCheckMedia("attachment")}
              ></i>
            </OverlayTrigger>

            {hasWhatsappTemplate && (
              <OverlayTrigger
                placement="top"
                key="Templates"
                overlay={<Tooltip>Templates</Tooltip>}
              >
                <i
                  className="ri-todo-line ri-xl text-secondary cursor-pointer"
                  onClick={() => handleCheckMedia("template")}
                ></i>
              </OverlayTrigger>
            )}
          </div>
          <div>
            {myErrors && myErrors.media && (
              <div className="text-danger small">{myErrors.media}</div>
            )}
          </div>

          {/************Popups and preview **************/}
          <div className="d-flex media-preview-unit">
            <React.Fragment>{getMedia()}</React.Fragment>
            <React.Fragment>
              {showAttachments.map((a) => (
                <span className={"attachment-item-btn "} key={a.id}>
                  <Button variant="link">
                    <i className={getFileExtension(a.file_url).className} />
                    <strong>{a.title}</strong>
                    <i
                      className={"ri-close-fill"}
                      onClick={() => removeAttachment(a.id)}
                      style={{ top: "16px" }}
                    ></i>
                  </Button>
                </span>
              ))}
            </React.Fragment>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Button onClick={handleSelect}>{"Select " + type} </Button>
          <InLineError
            type="error"
            message={
              formik.error && formik.error.headerFile ? "File is required!" : ""
            }
            extraStyle={{ marginTop: "30px" }}
          />
        </React.Fragment>
      )}
      {/****EMOJI's POPUP ***/}
      <Modal
        className={"email-element-modal"}
        backdrop="static"
        keyboard={false}
        show={showEmoji}
        onHide={() => setShowEmoji(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className={"text-uppercase"}>
            {locale("Emoji's")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Emojis getEmoji={getEmoji} />
        </Modal.Body>
      </Modal>
      {/**** IMAGE GALLERY POPUP ***/}
      <Modal
        className={"common modalXl"}
        backdrop="static"
        keyboard={false}
        show={showImageList}
        onHide={() => setShowImageList(false)}
      >
        <ImageGalleryWithModal
          handleClose={() => setShowImageList(false)}
          getImage={(value) => {
            if (type === null) handleImageChange(value);
            else handleMediaChange(value, "image");
          }}
          locale={locale}
          mediaType="image"
          fileSize={5}
        />
      </Modal>
      {/**** Video GALLERY POPUP ***/}
      <Modal
        className={"common modalXl"}
        backdrop="static"
        keyboard={false}
        show={showVideoList}
        onHide={handleCloseVideoPopup}
      >
        <ImageGalleryWithModal
          handleClose={handleCloseVideoPopup}
          getImage={(value) => {
            if (type === null) handleVideoChange(value);
            else handleMediaChange(value, "video");
          }}
          locale={locale}
          mediaType="video"
          fileSize={16}
        />
      </Modal>
      {/**** AUDIO GALLERY POPUP ***/}

      <Modal
        className={"common modalXl"}
        backdrop="static"
        keyboard={false}
        show={showAudioList}
        onHide={handleCloseAudioPopup}
      >
        <ImageGalleryWithModal
          handleClose={handleCloseAudioPopup}
          getImage={(value) => {
            if (type === null) handleAudioChange(value);
            else handleMediaChange(value, "audio");
          }}
          locale={locale}
          mediaType="audio"
          fileSize={16}
        />
      </Modal>

      <Modal
        className={"common modalXl"}
        backdrop="static"
        keyboard={false}
        show={showFileManager}
        onHide={handleHideFileManager}
      >
        <ImageGalleryWithModal
          handleClose={handleCloseAudioPopup}
          getImage={(value) => {
            if (type === null) handleAudioChange(value);
            else handleMediaChange(value, "document");
          }}
          locale={locale}
          mediaType="document"
          fileSize={20}
        />
      </Modal>

      <Modal
        show={showTemplateList}
        onHide={handleCloseTemplatePopup}
        className={"common"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeVariant="white" closeButton className={"bg-primary"}>
          <Modal.Title className={"text-white secondary-font text-uppercase"}>
            {locale("Template Gallery")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <div className="eb-popup-body p-0">
            <WhatsappTemplateDropdown
              previewMode={"selectionOnly"}
              locale={locale}
              errorBag={{}}
              setErrorBag={() => {}}
              handleAlertMessage={() => {}}
              selectedTemplate={template}
              setSelectedTemplate={(template) => {
                setTemplate(template);
              }}
            />
          </div>
        </Modal.Body>{" "}
        <Modal.Footer>
          <Button
            onClick={() => {
              callback(template);
              handleCloseTemplatePopup();
            }}
            variant={"outline-primary"}
          >
            Use It
          </Button>
          <Button
            onClick={() => {
              callback(null);
              handleCloseTemplatePopup();
            }}
            variant={"outline-secondary"}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {showStickerList && (
        <WhatsappSticker
          showStickerList={showStickerList}
          setShowStickerList={setShowStickerList}
          getSticker={handleStickerChange}
          handleHideSticker={handleHideSticker}
          locale={locale}
        />
      )}
    </React.Fragment>
  );
};

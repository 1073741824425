import { gql } from "@apollo/client";

/** File Import**/
export const IMPORT_CONTACT = gql`
  mutation ($file: Upload, $team_id: Int, $module: Int) {
    importFile(file: $file, team_id: $team_id, module: $module) {
      id
      headings
    }
  }
`;

/** DATA IMPORT **/
export const IMPORT_CONTACT_DATA = gql`
  mutation importContact(
    $team_id: Int
    $id: Int
    $fields: [Fields]
    $owner: Int
    $importCriteria: String
    $source: String
    $applyTags: [ApplyTagInput!]
    $newTags: [NewTags!]
    $pipeline_id: String
    $stage_id: String
  ) {
    importContactsAfterMapping(
      team_id: $team_id
      id: $id
      fields: $fields
      owner: $owner
      import_criteria: $importCriteria
      source: $source
      apply_tags: $applyTags
      new_tags: $newTags
      pipeline_id: $pipeline_id
      stage_id: $stage_id
    ) {
      msg
    }
  }
`;
/** DATA IMPORT STATUS**/
export const IMPORT_CONTACT_STATUS = gql`
  query importStatus($id: ID, $team_id: Int) {
    getImportStatus(id: $id, team_id: $team_id) {
      total
      duplicate
      inserted
      ignored
      overwrite
      status
      errors
    }
  }
`;

/******************* PRODUCT ***********************/
/** IMPORT Product **/
export const PRODUCT_IMPORT_DATA = gql`
  mutation importProduct(
    $id: Int
    $fields: [Fields]
    $importCriteria: String
    $team_id: Int
  ) {
    importProductsAfterMapping(
      id: $id
      fields: $fields
      import_criteria: $importCriteria
      team_id: $team_id
    ) {
      msg
    }
  }
`;

/** GET OUTLOOK COLUMNS **/
export const IMPORTABLE_FIELDS = gql`
  query columnsForMapping($connector: String, $team_id: Int) {
    columnsForMapping(connector: $connector, team_id: $team_id) {
      message
    }
  }
`;

/** GET OUTLOOK COLUMNS **/
export const IMPORT_CONNECTOR_DATA = gql`
  mutation initiateImport($team_id: Int, $import_for: Int, $module: Int) {
    initiateImport(
      team_id: $team_id
      import_for: $import_for
      module: $module
    ) {
      id
      headings
    }
  }
`;

import { gql } from "@apollo/client";
/** Tags listing **/
export const TAGS_SEARCH_LIST = gql`
  query tags(
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $team_id: Int
  ) {
    tags(
      team_id: $team_id
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
    ) {
      data {
        id
        name
        contacts_count
        created_at
        updated_at
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

/** Tags listing **/
export const TAGS_LIST = gql`
  query getAllTags($team_id: Int) {
    allTag(team_id: $team_id) {
      id
      name
    }
  }
`;

/** Assign Tags to Selected Contacts **/
export const ASSIGN_TAGS = gql`
  mutation tagsAssigned(
    $team_id: Int
    $tags: [AssignContactTagIds!]
    $newTags: [NewTags!]
    $type: String
    $unSelected: [AssignContactIds!]
    $selected: [AssignContactIds!]
    $search: String
    $filter: [CommonFilter!]
  ) {
    assignContactTags(
      team_id: $team_id
      tags: $tags
      new_tags: $newTags
      type: $type
      unchecked_contact_ids: $unSelected
      checked_contact_ids: $selected
      search: $search
      filter: $filter
    ) {
      status
    }
  }
`;

/** Create Tags **/
export const CREATE_TAGS = gql`
  mutation createTags($name: [String], $team_id: Int) {
    createTag(name: $name, team_id: $team_id) {
      save
      un_save
      tags_list
    }
  }
`;
/** Create Tags **/
export const REMOVE_TAGS = gql`
  mutation removeTag($id: Int, $team_id: Int) {
    deleteTag(id: $id, team_id: $team_id) {
      status
    }
  }
`;

/** Create Tags **/
export const UPDATE_TAGS = gql`
  mutation updateTag($id: Int, $name: String, $team_id: Int) {
    updateTag(id: $id, name: $name, team_id: $team_id) {
      id
      name
      created_at
      updated_at
    }
  }
`;

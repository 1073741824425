import { gql } from "@apollo/client";
/** All Contact without pagination **/
export const CONTACT_ALL = gql`
  query ($contactSearch: String, $search: String, $team_id: Int) {
    allCompanies(search: $search, team_id: $team_id) {
      name
    }
    allContacts(search: $contactSearch, company: $company, team_id: $team_id) {
      id
      name
      phone
      email
      billing_address: address
    }
  }
`;
/** All Contact without pagination **/
export const CONTACT_COMPANY = gql`
  query getAllContactsWithCompany($contactSearch: String, $team_id: Int) {
    allContacts(search: $contactSearch, team_id: $team_id) {
      id
      contact_id
      name
      company
      company_id
      phone
      email
      addresses {
        id
        address_type
        address
        city
        state
        zip_code
        country
        is_default
      }
    }
  }
`;
/** All Contact without pagination **/
export const COMPANY_CONTACT_ALL = gql`
  query getAllContacts($companySearch: String, $team_id: Int) {
    allCompaniesList(search: $companySearch, team_id: $team_id) {
      id
      name
      associated_contacts_list {
        id
        name
        phone
        email
        contact_id
        addresses {
          id
          address_type
          address
          city
          state
          zip_code
          country
          is_default
        }
      }
    }
  }
`;
/** All Contact without pagination **/
export const ALL_COMPANY = gql`
  query companySearchFilter(
    $team_id: Int
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
  ) {
    companySearchFilter(
      team_id: $team_id
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
    ) {
      data {
        id
        name
        website
        phone
        mobile
        fax
        number_of_employee
        number_of_associated_contacts
        annual_revenue
        description
        addresses {
          id
          address_type
          address
          city
          state
          zip_code
          country
          is_default
        }
        primary_contact {
          id
          name
          email
        }
        owner_name
        deal_value
        industry_type
        business_type
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;
/** All Contact without pagination **/
export const COMPANY_BY_ID = gql`
  query companyDetail(
    $team_id: Int
    $id: ID
    $leadOwner: Boolean!
    $source: Boolean!
    $tags: Boolean!
  ) {
    companyDetail(id: $id, team_id: $team_id) {
      id
      team_id
      name
      website
      phone
      mobile
      fax
      number_of_employee
      number_of_associated_contacts
      annual_revenue
      description
      addresses {
        id
        address_type
        address
        city
        state
        zip_code
        country
        is_default
      }
      image {
        id
        path: url
      }
      custom_attributes {
        id
        name
        value
      }
      associated_contacts_list {
        id
        name
        email
        updated_at
        contact_owner {
          id
          name
          email
        }
      }
      social_handler
      primary_contact {
        id
        name
        email
      }
      owner_name
      deal_value
      industry_type
      business_type
    }

    allUsers(team_id: $team_id) @include(if: $leadOwner) {
      id
      name
    }
    allSource(team_id: $team_id) @include(if: $source) {
      id
      name
    }
    allTag(team_id: $team_id) @include(if: $tags) {
      id
      name
    }
  }
`;

/** Create New Company **/
export const CREATE_COMPANY = gql`
  mutation createCompany(
    $team_id: Int
    $name: String
    $address: CompanyAddress
    $service_address: CompanyServiceAddress
    $billing_address: CompanyBillingAddress
    $address_2: CompanyAddress
    $service_address_2: CompanyServiceAddress
    $billing_address_2: CompanyBillingAddress
    $address_3: CompanyAddress
    $service_address_3: CompanyServiceAddress
    $billing_address_3: CompanyBillingAddress
    $phone: String
    $mobile: String
    $fax: String
    $social_handler: SocialHandler
    $additional: String
    $website: String
    $description: String
    $primary_contact: Int
    $owner_name: String
    $industry_type: String
    $business_type: String
    $annual_revenue: String
    $number_of_employee: Int
  ) {
    createCompany(
      team_id: $team_id
      name: $name
      primary_contact: $primary_contact
      phone: $phone
      mobile: $mobile
      fax: $fax
      address: $address
      billing_address: $billing_address
      service_address: $service_address
      address_2: $address_2
      billing_address_2: $billing_address_2
      service_address_2: $service_address_2
      address_3: $address_3
      billing_address_3: $billing_address_3
      service_address_3: $service_address_3
      social_handler: $social_handler
      additional: $additional
      website: $website
      description: $description
      owner_name: $owner_name
      industry_type: $industry_type
      business_type: $business_type
      annual_revenue: $annual_revenue
      number_of_employee: $number_of_employee
    ) {
      id
    }
  }
`;
/** Create New Company **/
export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $team_id: Int
    $id: ID
    $name: String
    $address: CompanyAddress
    $service_address: CompanyServiceAddress
    $billing_address: CompanyBillingAddress
    $address_2: CompanyAddress
    $service_address_2: CompanyServiceAddress
    $billing_address_2: CompanyBillingAddress
    $address_3: CompanyAddress
    $service_address_3: CompanyServiceAddress
    $billing_address_3: CompanyBillingAddress
    $phone: String
    $mobile: String
    $fax: String
    $social_handler: SocialHandler
    $additional: String
    $website: String
    $description: String
    $primary_contact: Int
    $owner_name: String
    $industry_type: String
    $business_type: String
    $annual_revenue: String
    $number_of_employee: Int
  ) {
    updateCompany(
      team_id: $team_id
      id: $id
      name: $name
      primary_contact: $primary_contact
      address: $address
      billing_address: $billing_address
      service_address: $service_address
      address_2: $address_2
      billing_address_2: $billing_address_2
      service_address_2: $service_address_2
      address_3: $address_3
      billing_address_3: $billing_address_3
      service_address_3: $service_address_3
      phone: $phone
      mobile: $mobile
      fax: $fax
      social_handler: $social_handler
      additional: $additional
      website: $website
      description: $description
      owner_name: $owner_name
      industry_type: $industry_type
      business_type: $business_type
      annual_revenue: $annual_revenue
      number_of_employee: $number_of_employee
    ) {
      id
    }
  }
`;

/** Update Company Image **/
export const UPDATE_COMPANY_IMAGE = gql`
  mutation updateCompanyImage($id: Int!, $image: Upload, $team_id: Int) {
    updateCompanyImage(id: $id, image: $image, team_id: $team_id) {
      status
    }
  }
`;
export const ALL_COMPANIES_LIST = gql`
  query ($search: String, $team_id: Int) {
    allCompaniesList(search: $search, team_id: $team_id) {
      name
    }
  }
`;
export const ALL_COMPANIES = gql`
  query ($search: String, $team_id: Int) {
    allCompaniesList(search: $search, team_id: $team_id) {
      id
      name
      industry_type
      number_of_employee
      number_of_associated_contacts
    }
  }
`;
export const CUSTOM_ATTRIBUTE_AND_BUSINESS_TYPE = gql`
  query customAttributesList($module: String, $team_id: Int) {
    customAttributes(attributable_type: $module, team_id: $team_id) {
      id
      team_id
      label
      type
      name
      attributes
      required
      tooltip
      active
      attributable_type
      created_at
      updated_at
    }
    allBusinessIndustry(team_id: $team_id) {
      business {
        id
        name
      }
      industry {
        id
        name
      }
    }
  }
`;
export const REMOVE_COMPANY = gql`
  mutation deleteCompany($team_id: Int, $id: Int) {
    removeCompany(team_id: $team_id, id: $id) {
      status
    }
  }
`;

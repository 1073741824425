import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import InLineErrorMessage from "../../Common/AlertMessage/InLineErrorMessage";
import XAlertMessage from "../../Common/AlertMessage/XAlertMessage";
import { EMAIL_IMAP_SMTP_CONNECT } from "../../../GraphApi/EmailConnect";
import Select from "react-select";
import { createMarkup, getValidationMessage } from "../../../Utils/utils";
import { getValidationSchema } from "../../../Utils/formikValidator";
import { SmtpImapLoader } from "../Loader/SkeletonLoader";
import { nonGroupColourStyles } from "../DropDown/Style";

const link =
  '<a target="_new" href="https://myaccount.google.com">https://myaccount.google.com/</a>';

const fieldsToBeValidate = [
  {
    type: "text",
    name: "imap_host",
    isRequired: true,
    maxLength: null,
    minLength: null,
    isNullable: false,
  },
  {
    type: "text",
    name: "imap_port",
    isRequired: true,
    maxLength: null,
    minLength: null,
    isNullable: false,
  },
  {
    type: "password",
    name: "imap_password",
    isRequired: true,
    maxLength: null,
    minLength: null,
    isNullable: false,
  },
  {
    type: "text",
    name: "smtp_host",
    isRequired: true,
    maxLength: null,
    minLength: null,
    isNullable: false,
  },
  {
    type: "text",
    name: "smtp_port",
    isRequired: true,
    maxLength: null,
    minLength: null,
    isNullable: false,
  },
  {
    type: "password",
    name: "smtp_password",
    isRequired: true,
    maxLength: null,
    minLength: null,
    isNullable: false,
  },
  {
    type: "email",
    name: "imap_username",
    isRequired: true,
    maxLength: null,
    minLength: null,
    isNullable: false,
  },
  {
    type: "email",
    name: "smtp_username",
    isRequired: true,
    maxLength: null,
    minLength: null,
    isNullable: false,
  },
];

export const AddImapSmtp = ({
  handleCloseAddEmail,
  setLinkedAccountState,
  team_id,
  smtpForGoogle,
  setSmtpForGoogle = () => {},
  updateSteps = () => {},
  locale,
}) => {
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  /****/
  const [validationSchema] = useState(
    getValidationSchema(fieldsToBeValidate, locale)
  );
  const [createImapSmtpCredential, { loading: addImapSmtp }] = useMutation(
    EMAIL_IMAP_SMTP_CONNECT
  );
  const formik = useFormik({
    initialValues: {
      imap_host: "",
      imap_port: "",
      imap_encryption: "ssl",
      imap_username: "",
      imap_password: "",
      smtp_host: "",
      smtp_port: "",
      smtp_encryption: "tls",
      smtp_username: "",
      smtp_password: "",
    },
    validationSchema: validationSchema,
    onSubmit(values) {
      processData(values);
    },
  });
  useEffect(() => {
    if (smtpForGoogle) {
      formik.values.imap_host = "imap.gmail.com";
      formik.values.imap_port = "993";
      formik.values.imap_encryption = "ssl";
      formik.values.smtp_host = "smtp.gmail.com";
      formik.values.smtp_port = "465";
      formik.values.smtp_encryption = "ssl";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processData = (data) => {
    setIsSubmitting(true);
    const dataToPost = {
      variables: { ...data, team_id: team_id },
    };
    createImapSmtpCredential(dataToPost)
      .then((result) => {
        if (result.data.createImapSmtpCredential) {
          setLinkedAccountState(
            result.data.createImapSmtpCredential.imap_username,
            "smtp"
          );
          handleCloseAddEmail();
          handleAlertMessage(
            "success",
            locale(
              "messages:emailConfig.Great now you can send Emails from <br />{{email}}",
              {
                email: result.data.createImapSmtpCredential.smtp_username,
              }
            )
          );
        } else {
          handleAlertMessage(
            "error",
            locale("messages:backend.something went wrong")
          );
        }
        setIsSubmitting(false);
        return result.data.createImapSmtpCredential;
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
        setIsSubmitting(false);
      });
  };
  const handleAlertMessage = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
    // setAlertChange(true);
  };

  const [encryption] = useState([
    { value: "ssl", label: "ssl" },
    { value: "tls", label: "tls" },
  ]);

  return (
    <React.Fragment>
      {addImapSmtp ? (
        <React.Fragment>
          <Modal.Header>
            <Button
              variant={"link"}
              className={"close"}
              onClick={handleCloseAddEmail}
            >
              <i className={"ri-arrow-left-s-line"}></i> {locale("back")}
            </Button>
          </Modal.Header>
          <Modal.Body>
            <XAlertMessage
              type={alertType}
              message={alertMessage}
              setAlertShow={setAlertShow}
              alertShow={alertShow}
            />
            <SmtpImapLoader />
          </Modal.Body>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Modal.Header>
            <Button
              variant={"link"}
              className={"close"}
              onClick={handleCloseAddEmail}
            >
              <i className={"ri-arrow-left-s-line"}></i> {locale("back")}
            </Button>
          </Modal.Header>
          <Modal.Body>
            <XAlertMessage
              type={alertType}
              message={alertMessage}
              setAlertShow={setAlertShow}
              alertShow={alertShow}
            />
            <h4 className={"text-primary font-weight-semibold mb-3"}>
              {locale(
                "pages:setting.emailConfigure.Add Imap Detail (To receive Emails)"
              )}
            </h4>
            <Row>
              <Col sm={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {locale("pages:setting.emailConfigure.Host")}
                  </Form.Label>
                  <Form.Control
                    className={
                      formik.touched.imap_host && formik.errors.imap_host
                        ? "is-invalid"
                        : ""
                    }
                    type="text"
                    id="imap_host"
                    name="imap_host"
                    placeholder={locale("pages:setting.emailConfigure.Host")}
                    value={formik.values.imap_host}
                    autoFocus={true}
                    onChange={(e) => {
                      formik.setFieldValue("imap_host", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <InLineErrorMessage
                    type="error"
                    name="imap_host"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {locale("pages:setting.emailConfigure.Port")}
                  </Form.Label>
                  <Form.Control
                    className={
                      formik.touched.imap_port && formik.errors.imap_port
                        ? "is-invalid"
                        : ""
                    }
                    type="text"
                    placeholder={locale("pages:setting.emailConfigure.Port")}
                    id="imap_port"
                    name="imap_port"
                    value={formik.values.imap_port}
                    onChange={(e) => {
                      formik.setFieldValue("imap_port", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <InLineErrorMessage
                    type="error"
                    name="imap_port"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {locale("pages:setting.emailConfigure.Encryption")}
                  </Form.Label>
                  <Select
                    isSearchable={false}
                    options={encryption}
                    className={
                      formik.touched.imap_encryption &&
                      formik.errors.imap_encryption
                        ? "is-invalid"
                        : ""
                    }
                    type="text"
                    disabled={true}
                    id="imap_encryption"
                    name="imap_encryption"
                    placeholder={locale(
                      "pages:setting.emailConfigure.Encryption"
                    )}
                    value={encryption.filter(
                      (item) =>
                        item && item.value === formik.values.imap_encryption
                    )}
                    onChange={(e) => {
                      formik.setFieldValue("imap_encryption", e.value);
                    }}
                    onBlur={formik.handleBlur}
                    styles={nonGroupColourStyles}
                  />
                  <InLineErrorMessage
                    type="error"
                    name="imap_encryption"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {locale("pages:setting.emailConfigure.E-Mail Address")}
                  </Form.Label>
                  <Form.Control
                    className={
                      formik.touched.imap_username &&
                      formik.errors.imap_username
                        ? "is-invalid"
                        : ""
                    }
                    type="text"
                    placeholder={locale(
                      "pages:setting.emailConfigure.E-Mail Address"
                    )}
                    id="imap_username"
                    name="imap_username"
                    value={formik.values.imap_username}
                    onChange={(e) => {
                      formik.setFieldValue("imap_username", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <InLineErrorMessage
                    type="error"
                    name="imap_username"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {locale("pages:setting.emailConfigure.Password")}
                  </Form.Label>
                  <Form.Control
                    className={
                      formik.touched.imap_password &&
                      formik.errors.imap_password
                        ? "is-invalid"
                        : ""
                    }
                    type="password"
                    placeholder={locale(
                      "pages:setting.emailConfigure.Password"
                    )}
                    id="imap_password"
                    name="imap_password"
                    value={formik.values.imap_password}
                    onChange={(e) => {
                      formik.setFieldValue("imap_password", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <InLineErrorMessage
                    type="error"
                    name="imap_password"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
            </Row>
            <h4 className={"text-primary font-weight-semibold mb-3"}>
              <br />
              {locale(
                "pages:setting.emailConfigure.Add SMTP Detail (To send Emails)"
              )}
            </h4>
            <Row>
              <Col sm={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {locale("pages:setting.emailConfigure.Host")}
                  </Form.Label>
                  <Form.Control
                    className={
                      formik.touched.smtp_host && formik.errors.smtp_host
                        ? "is-invalid"
                        : ""
                    }
                    type="text"
                    id="smtp_host"
                    name="smtp_host"
                    placeholder={locale("pages:setting.emailConfigure.Host")}
                    value={formik.values.smtp_host}
                    onChange={(e) => {
                      formik.setFieldValue("smtp_host", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <InLineErrorMessage
                    type="error"
                    name="smtp_host"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {locale("pages:setting.emailConfigure.Port")}
                  </Form.Label>
                  <Form.Control
                    className={
                      formik.touched.smtp_port && formik.errors.smtp_port
                        ? "is-invalid"
                        : ""
                    }
                    type="text"
                    placeholder={locale("pages:setting.emailConfigure.Port")}
                    id="smtp_port"
                    name="smtp_port"
                    value={formik.values.smtp_port}
                    onChange={(e) => {
                      formik.setFieldValue("smtp_port", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    styles={nonGroupColourStyles}
                  />
                  <InLineErrorMessage
                    type="error"
                    name="smtp_port"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {locale("pages:setting.emailConfigure.Encryption")}
                  </Form.Label>
                  <Select
                    isSearchable={false}
                    options={encryption}
                    className={
                      formik.touched.smtp_encryption &&
                      formik.errors.smtp_encryption
                        ? "is-invalid"
                        : ""
                    }
                    type="text" // disabled={true}
                    id="smtp_encryption"
                    name="smtp_encryption"
                    placeholder={locale(
                      "pages:setting.emailConfigure.Encryption"
                    )}
                    value={encryption.filter(
                      (item) =>
                        item && item.value === formik.values.smtp_encryption
                    )}
                    onChange={(e) => {
                      formik.setFieldValue("smtp_encryption", e.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <InLineErrorMessage
                    type="error"
                    name="smtp_encryption"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {locale("pages:setting.emailConfigure.E-Mail Address")}
                  </Form.Label>
                  <Form.Control
                    className={
                      formik.touched.smtp_username &&
                      formik.errors.smtp_username
                        ? "is-invalid"
                        : ""
                    }
                    type="text"
                    placeholder={locale(
                      "pages:setting.emailConfigure.E-Mail Address"
                    )}
                    id="smtp_username"
                    name="smtp_username"
                    value={formik.values.smtp_username}
                    onChange={(e) => {
                      formik.setFieldValue("smtp_username", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <InLineErrorMessage
                    type="error"
                    name="smtp_username"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {locale("pages:setting.emailConfigure.Password")}
                  </Form.Label>
                  <Form.Control
                    className={
                      formik.touched.smtp_password &&
                      formik.errors.smtp_password
                        ? "is-invalid"
                        : ""
                    }
                    type="password"
                    placeholder={locale(
                      "pages:setting.emailConfigure.Password"
                    )}
                    id="smtp_password"
                    name="smtp_password"
                    value={formik.values.smtp_password}
                    onChange={(e) => {
                      formik.setFieldValue("smtp_password", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <InLineErrorMessage
                    type="error"
                    name="smtp_password"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="note-information-integrate-mail">
              <h4>{locale("pages:setting.emailConfigure.Note")}:</h4>
              <div className="note-information-inner">
                <h6>
                  {locale(
                    "pages:setting.emailConfigure.If you want to connect with Google / Gmail"
                  )}
                </h6>
                <ul>
                  <li>
                    {createMarkup(
                      locale(
                        "pages:setting.emailConfigure.Go to {link} and click on 'Security'",
                        { link }
                      )
                    )}
                  </li>
                  <li>
                    {locale(
                      "pages:setting.emailConfigure.Go to 2 step verification, and enable it, if not enabled."
                    )}
                  </li>
                  <li>
                    {locale(
                      "pages:setting.emailConfigure.Go to app password under 2 step verification and generate a new app password."
                    )}
                  </li>
                  <li>
                    {locale(
                      "pages:setting.emailConfigure.Click on 'Select app' and choose 'Mail' and click on 'Select device' and choose 'Other'"
                    )}
                  </li>
                  <li>
                    {locale(
                      "pages:setting.emailConfigure.Click on 'Generate' and use the displayed password (without any spaces) as your password"
                    )}
                  </li>
                </ul>
              </div>

              <div className="note-information-inner d-none">
                <h6>
                  {locale(
                    "pages:setting.emailConfigure.If you want to connect with Outlook / Office 365"
                  )}
                </h6>
                <ul>
                  <li>
                    {locale(
                      "pages:setting.emailConfigure.Register an app in Azure AD"
                    )}
                  </li>
                  <li>
                    {locale(
                      "pages:setting.emailConfigure.Configured it properly in Azure AD (add all required scopes for IMAP/SMTP like IMAP.AccessAsUser.All, offline_access, etc.)"
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary btn-w-icon btn-ms-icon"
              type="button"
              disabled={isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              <i className="ri-save-fill"></i>
              <span>{locale("save")}</span>
            </Button>
            <Button
              variant="outline-secondary btn-w-icon btn-ms-icon"
              onClick={handleCloseAddEmail}
            >
              <i className={"ri-close-line"}></i>{" "}
              <span>{locale("cancel")}</span>
            </Button>
          </Modal.Footer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import { gql } from "@apollo/client";

/** All Filter List **/
export const FILTER_LIST = gql`
  query filters($industry: String, $entity: String, $team_id: Int) {
    filters(industry: $industry, entity: $entity, team_id: $team_id) {
      entity
      name
      label
      group
      type
      supported_operators
      sub_type
    }
  }
`;

export const SAVE_FILTER_LIST = gql`
  mutation createUserFilter(
    $module: String!
    $name: String!
    $lines: [LineListUserFilter]!
    $team_id: Int
  ) {
    createUserFilter(
      name: $name
      module: $module
      user_filter_lines: $lines
      team_id: $team_id
    ) {
      id
      user_id
      name
      module
      user_filter_lines {
        id
        user_filter_id
        filter_type
        attribute
        operator
        attribute_id
        attribute_value
        first_value
        second_value
      }
    }
  }
`;
export const USER_FILTER_LIST = gql`
  query userFilters($module: String, $team_id: Int) {
    userFilters(module: $module, team_id: $team_id) {
      id
      user_id
      name
      module
      user_filter_lines {
        id
        user_filter_id
        filter_type
        attribute
        operator
        attribute_id
        attribute_value
        first_value
        second_value
      }
    }
  }
`;

/** for filter suggestion **/
export const FILTER_SUGGESTION_LIST = gql`
  query getFilterSuggestion(
    $attribute: String
    $operator: String
    $first_value: String
    $filter: [CommonFilter!]
    $entity: String
    $group: String
    $suggestionType: String
    $team_id: Int
  ) {
    filterSuggestions(
      attribute: $attribute
      operator: $operator
      first_value: $first_value
      filter: $filter
      entity: $entity
      group: $group
      suggestion_type: $suggestionType
      team_id: $team_id
    ) {
      filter_record
    }
  }
`;
/***********************/
/** for form listing **/
export const FORM_LIST = gql`
  query getAllSubmittedForm {
    allForms {
      id
      name
    }
  }
`;
/** Campaign listing **/
export const ASSOCIATED_CAMPAIGN_LIST = gql`
  query getAllAssociatedCampaign {
    allAssociatedCampaign {
      id
      name
    }
  }
`;
/** Lead owner listing **/
export const LEAD_OWNER_LIST = gql`
  query getAllLeadOwner {
    allUsers {
      id
      first_name
    }
  }
`;

/** for email listing **/
export const EMAIL_LIST = gql`
  query getAllEmailList($search: String) {
    allEmails(search: $search) {
      id
      subject
    }
  }
`;
/** for landing pages listing **/
export const LANDING_PAGE_LIST = gql`
  query getAllLandingPages {
    allLandingPages {
      id
      name
    }
  }
`;
/** Update Filter **/
export const UPDATE_FILTER = gql`
  mutation UpdateSavedFilter(
    $id: Int
    $module: String
    $name: String
    $filterLine: [LineListUserFilter]
    $deletedLine: [DeleteUserFilterLine]
    $team_id: Int
  ) {
    updateUserFilter(
      id: $id
      module: $module
      name: $name
      user_filter_lines_create: $filterLine
      user_filter_lines_delete: $deletedLine
      team_id: $team_id
    ) {
      id
    }
  }
`;
/*******/
/** SOURCES listing with Pagination **/
export const SOURCES_LIST = gql`
  query getSourcesList(
    $first: Int!
    $page: Int
    $team_id: Int
    $field: String!
    $order: SortOrder!
  ) {
    sourcesList(
      team_id: $team_id
      first: $first
      page: $page
      orderBy: [{ column: $field, order: $order }]
    ) {
      data {
        id
        name
        created_at
        updated_at
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

export const REMOVE_SOURCES = gql`
  mutation deleteSource($id: Int, $team_id: Int) {
    deleteSource(id: $id, team_id: $team_id) {
      status
    }
  }
`;

/** CREATE SOURCE  **/
export const CREATE_SOURCE = gql`
  mutation createSource($name: String, $team_id: Int) {
    createSource(name: $name, team_id: $team_id) {
      id
      name
      created_at
      updated_at
    }
  }
`;

/** CREATE SOURCE  **/
export const UPDATE_SOURCE = gql`
  mutation editSource($id: Int, $name: String, $team_id: Int) {
    editSource(id: $id, name: $name, team_id: $team_id) {
      id
      name
      created_at
      updated_at
    }
  }
`;

/** USER SAVED FILTER LIST***/
export const USER_SAVED_FILTER_LIST = gql`
  query getUserFiltersList(
    $first: Int!
    $page: Int
    $team_id: Int
    $module: String
  ) {
    userFiltersList(
      team_id: $team_id
      first: $first
      page: $page
      module: $module
    ) {
      data {
        id
        name
        module
        created_at
        updated_at
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;
/** DELETE FILTER ***/
export const REMOVE_FILTER = gql`
  mutation deleteUserFilter($id: Int, $team_id: Int) {
    deleteUserFilter(id: $id, team_id: $team_id) {
      status
    }
  }
`;

import {
  convertToJob,
  estimateChangeRequest,
  estimateReviewAction,
  getEstimateRequest,
  getEstimateSigningDetail,
  getEstimateTemplateRequest,
  getEstimateTemplatesRequest,
  getEstimatesRequest,
  getReceivedPayment,
  postEstimateSigningDetail,
  postLogPayment,
  postReceivedPayment,
  removeEstimateRecord,
  removeEstimateTemplateRecord,
  saveEstimateRequest,
  saveEstimateTemplateRequest,
  updateEstimateRequest,
  updateEstimateTemplateRequest,
  getOwnerDetails,
  saveEstimateAsDraft,
  allEstimateList,
} from "../../RestApi/EstimateApi";

export const getEstimateTemplates = (data) => {
  return new Promise((resolve, reject) => {
    getEstimateTemplatesRequest(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getEstimates = (data) => {
  return new Promise((resolve, reject) => {
    getEstimatesRequest(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const createEstimateTemplate = (data) => {
  return new Promise((resolve, reject) => {
    saveEstimateTemplateRequest(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const updateEstimateTemplate = (data, id) => {
  return new Promise((resolve, reject) => {
    updateEstimateTemplateRequest(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const createEstimate = (data) => {
  return new Promise((resolve, reject) => {
    saveEstimateRequest(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const updateEstimate = (data, id) => {
  return new Promise((resolve, reject) => {
    updateEstimateRequest(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getTemplate = (data, id) => {
  return new Promise((resolve, reject) => {
    getEstimateTemplateRequest(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getEstimate = (data, id) => {
  return new Promise((resolve, reject) => {
    getEstimateRequest(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// delete single agreement...
export const removeEstimate = (data, id) => {
  return new Promise((resolve, reject) => {
    removeEstimateRecord(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// delete single agreement...
export const removeEstimateTemplate = (data, id) => {
  return new Promise((resolve, reject) => {
    removeEstimateTemplateRecord(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const updateRequestEstimate = (data, id) => {
  return new Promise((resolve, reject) => {
    estimateChangeRequest(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const reviewEstimate = (data, id) => {
  return new Promise((resolve, reject) => {
    estimateReviewAction(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// get single agreement for Signing ...
export const getEstimateSigning = (data = null, id) => {
  return new Promise((resolve, reject) => {
    getEstimateSigningDetail(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// Post single agreement for Signing ...
export const postEstimateSigning = (data = null, id) => {
  return new Promise((resolve, reject) => {
    postEstimateSigningDetail(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// Post single agreement for Signing ...
export const logPayment = (data = null, id) => {
  return new Promise((resolve, reject) => {
    postLogPayment(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const acceptPayment = (data = null, id) => {
  return new Promise((resolve, reject) => {
    postReceivedPayment(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getEstimatePaymentDetails = (data = null, id) => {
  return new Promise((resolve, reject) => {
    getReceivedPayment(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const convertEstimateToJob = (data = null, id) => {
  return new Promise((resolve, reject) => {
    convertToJob(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};
export const saveEstimateDraft = (data = null, id) => {
  return new Promise((resolve, reject) => {
    saveEstimateAsDraft(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getCompanyOwner = (data = null, id) => {
  return new Promise((resolve, reject) => {
    getOwnerDetails(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const estimateListingWithoutPagination = (data, id) => {
  return new Promise((resolve, reject) => {
    allEstimateList(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getResponseError = (error) => {
  let responseError = error.response.statusText && error.response.statusText;
  if (error.response.data && error.response.data.message) {
    responseError = error.response.data.message;
  } else if (error.response.data.message) {
    responseError = error.message;
  }
  return responseError;
};

import React, { useEffect, useState } from "react";
import Select from "react-select";
import { isObjectEmpty } from "../../../Utils/utils";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { nonGroupColourStyles } from "../DropDown/Style";

export const SelectDropdown = ({
  label = "",
  labelVisibility = true,
  value,
  handleChange,
  options,
  placeholder = "Select Value",
  isSearchable = false,
  isClearable = false,
  isMulti = false,
  isError = false,
  isDisabled = false,
  noOptionsMessage = "No Options",
}) => {
  const { t: locale } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (options?.length > 0) {
      getDropDownValue(value);
    }
  }, [options, value]);

  const getDropDownValue = (value) => {
    if (!isObjectEmpty(value)) {
      if (options?.length > 0) {
        let selected = null;
        options.forEach((item) => {
          if (item?.options) {
            if (
              Array.isArray(item.options) &&
              item.options.length > 0 &&
              selected === null
            ) {
              item.options.forEach((option) => {
                if (option.value === value) {
                  selected = { value: option.value, label: option.label };
                }
              });
            } else if (item.value === value) {
              selected = item;
            }
          } else {
            if (isMulti && Array.isArray(value)) {
              selected = options.filter((item) => value.includes(item.value));
            } else {
              selected = options.find((item) => item.value === value) || "";
            }
          }
        });
        setSelectedValue(selected);
      }
    } else {
      setSelectedValue(null);
    }
  };

  /*******/
  return (
    <React.Fragment>
      <Form.Group className="form-group">
        <Form.Label className={!labelVisibility && "d-none"}>
          {locale(label || "")}
        </Form.Label>

        <Select
          value={selectedValue || ""}
          options={options}
          placeholder={placeholder || ""}
          isMulti={isMulti}
          isSearchable={isSearchable}
          isClearable={isClearable}
          styles={nonGroupColourStyles}
          onChange={(e) => {
            handleChange(e);
          }}
          className={isError ? " select-error" : ""}
          isDisabled={isDisabled}
          noOptionsMessage={() => noOptionsMessage}
        />
      </Form.Group>
    </React.Fragment>
  );
};

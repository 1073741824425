import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { LEAD_OWNER_LIST } from "../../../../../GraphApi/LeadOwner";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { nonGroupColourStyles } from "../../../../../Components/Common/DropDown/Style";
import { currentTeamId } from "../../../../../Utils/utils";
import { useTranslation } from "react-i18next";

export const AssignDealOwnerAction = ({
  values,
  handleChange,
  handleClose,
}) => {
  const { t: locale } = useTranslation();
  const [contactOwners, setContactOwners] = useState([]);
  const [selectedDealOwner, setSelectedDealOwner] = useState(null);
  const [errorBag, setErrorBag] = useState(null);
  const [operations] = useState([
    { value: "all", label: "All deals for this contact" },
    {
      value: "recent_created",
      label: "Most recently created deals for this contact",
    },
    {
      value: "recent_updated",
      label: "Most recently updated deals for this contact",
    },
  ]);
  const [operation, setOperation] = useState({
    value: "all",
    label: "All deals for this contact",
  });

  /** GET CAMPAIGN ELEMENT **/
  const { data: ownerList } = useQuery(LEAD_OWNER_LIST, {
    variables: { team_id: currentTeamId },
    fetchPolicy: "network-only",
  });

  /** ON Component Update**/
  useEffect(() => {
    if (ownerList) {
      const allLeadOwner = ownerList.allUsers.map(
        (item) => item && { value: item.id, label: item.name }
      );
      setContactOwners(allLeadOwner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerList]);

  /***On Data Load***/
  useEffect(() => {
    if (contactOwners && values) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactOwners, values]);

  /**Load Pre Selected Data**/
  const loadData = () => {
    const preSelectedValue = contactOwners.filter(
      (item) => item.value === values?.deal_owner && item
    );
    setSelectedDealOwner(
      preSelectedValue.length > 0 ? preSelectedValue[0] : ""
    );

    setOperation({
      value: values?.operation || "all",
      label: values?.operation_label || "All deals for this contact",
    });
  };

  const handleSaveData = () => {
    let newErrors = {};
    if (!selectedDealOwner) {
      newErrors = {
        ...newErrors,
        deal_owner: "Deal owner is required",
      };
    }
    if (!operation) {
      newErrors = {
        ...newErrors,
        operation: "Operation is required",
      };
    }
    setErrorBag(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setErrorBag(null);
      let str = [];
      str.push(<span key="1">Assign </span>);
      str.push(
        <span key="2" className="text-primary">
          {selectedDealOwner.label}{" "}
        </span>
      );
      str.push(<span key="3">as deal owner</span>);

      handleChange("action", {
        action: "assign_deal_owner",
        operation: operation?.value || "all",
        operation_label: operation?.label || "All deals for this contact",
        deal_owner: selectedDealOwner.value,
        string: str,
      });
      handleClose();
    }
  };

  const handelCancel = () => {
    handleClose();
  };

  /**RENDER HTML**/
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label>{locale("select Deal Owner")}</Form.Label>
              <Select
                value={selectedDealOwner}
                options={contactOwners}
                onChange={(e) => {
                  setSelectedDealOwner(e);
                }}
                styles={nonGroupColourStyles}
              />
              <InLineError
                type="error"
                message={
                  errorBag && errorBag.deal_owner ? errorBag.deal_owner : null
                }
              />
            </Form.Group>

            <Form.Group className="form-group" id="operation">
              <Form.Label>{locale("action on")}</Form.Label>
              <div>
                <Select
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  placeholder={locale("Operation")}
                  noOptionsMessage={() => locale("no operation")}
                  options={operations}
                  value={operation ? operation : ""}
                  onChange={(e) => {
                    setOperation(e);
                  }}
                  styles={nonGroupColourStyles}
                />
              </div>
              <InLineError type="error" message={errorBag?.operation || null} />
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <div className="bg-dark text-end sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

import React, { useState, useEffect, useContext, useRef } from "react";
import Select from "react-select";
import { Form, Container, Button } from "react-bootstrap";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { nonGroupColourStyles } from "../../../../../Components/Common/DropDown/Style";
import { useTranslation } from "react-i18next";
import { PipelineContext } from "../../../../../Context/PipelineContext";
import { isObjectEmpty } from "../../../../../Utils/utils";

export const UpdateDealStageAction = ({
  values,
  handleChange,
  handleClose,
}) => {
  const { t: locale } = useTranslation();
  const [stages, setStages] = useState([]);
  const [stage, setStage] = useState(null);
  const [pipelines, setPipelines] = useState([]);
  const [pipeline, setPipeline] = useState(null);
  const [operations] = useState([
    { value: "all", label: "All deals for this contact" },
    {
      value: "recent_created",
      label: "Most recently created deals for this contact",
    },
    {
      value: "recent_updated",
      label: "Most recently updated deals for this contact",
    },
  ]);
  const [operation, setOperation] = useState({
    value: "all",
    label: "All deals for this contact",
  });
  const [errorBag, setErrorBag] = useState(null);
  const { pipelineData, getPipelines } = useContext(PipelineContext);

  useEffect(() => {
    if (pipelineData?.pipelines !== null) {
      const allPipelines = [];
      pipelineData.pipelines.forEach((item) => {
        // if (!item.default_pipeline) {
        const formattedStages = item.stages
          .filter(
            (stage) =>
              stage &&
              ![
                "Completed",
                "Won",
                "Lost",
                "completed",
                "won",
                "lost",
              ].includes(stage.name)
          )
          .map((defaultStage) => ({
            label: defaultStage.label,
            value: defaultStage.id,
            pipelineId: item.id,
            category: defaultStage.category,
            created_at: defaultStage.created_at,
            id: defaultStage.id,
            name: defaultStage.name === "Completed" ? "Won" : defaultStage.name,
            probability: defaultStage.probability,
            team_id: defaultStage.team_id,
            updated_at: defaultStage.updated_at,
            sort_order: defaultStage.sort_order,
          }));

        if (formattedStages.length > 0) {
          allPipelines.push({
            ...item,
            stages: formattedStages,
            value: item.id,
            label: item.name,
          });
        }
        // }
      });
      setPipelines(allPipelines);
    } else if (pipelineData?.pipelines === null && !pipelineData.isFetching) {
      getPipelines();
    }
  }, [pipelineData, getPipelines]);

  useEffect(() => {
    if (stages && values) {
      loadData();
    }
  }, [stages, values]);

  /**LOad Pre Selected Data**/
  const loadData = () => {
    const preSelectedPipeline = values?.pipeline || null;
    const preSelectedStage = values.stage_id || null;
    const preSelectedOperation = values?.operation || null;
    if (preSelectedOperation) {
      operations.forEach((item) => {
        if (preSelectedOperation === item.value) {
          setOperation(item);
        }
      });
    }

    pipelines?.length > 0 &&
      pipelines.forEach((item) => {
        if (
          !isObjectEmpty(preSelectedPipeline) &&
          parseInt(item.id) === parseInt(preSelectedPipeline)
        ) {
          setPipeline(item);
          setStages(item.stages);

          item.stages.forEach((stg) => {
            if (parseInt(stg.id) === parseInt(preSelectedStage)) {
              setStage(stg);
            }
          });
        }
      });
  };

  //On Component update.
  const handleSaveData = () => {
    let newErrors = {};
    if (!pipeline) {
      newErrors = {
        ...newErrors,
        pipeline: "Pipeline is required",
      };
    }

    if (!stage) {
      newErrors = {
        ...newErrors,
        stage_id: "Stage is required",
      };
    }
    setErrorBag(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setErrorBag(null);
      let str = [];

      if (!isObjectEmpty(stage?.value)) {
        str.push(<span key="1">Update Deal Stage to</span>);
        str.push(
          <span key="2" className={"text-primary"}>
            {" " + stage.label + " "}
          </span>
        );
      }

      if (!isObjectEmpty(stage?.value) && !isObjectEmpty(operation)) {
        str.push(<span key="3">For</span>);
        str.push(
          <span key="4" className={"text-primary"}>
            {" " + operation.label + " "}
          </span>
        );
      }

      handleChange("action", {
        action: "update_deal_stage",
        stage_id: stage ? stage.value : null,
        pipeline: pipeline ? pipeline.value : null,
        operation: operation ? operation.value : null,
        string: str,
      });
      handleClose();
    }
  };
  const handelCancel = () => {
    handleClose();
  };
  /**RENDER HTML**/
  return (
    <React.Fragment>
      <Container className="mt-3">
        <Form.Group className="form-group">
          <Form.Label>{locale("choose pipeline")}</Form.Label>
          <Select
            isClearable={false}
            isSearchable={false}
            placeholder={locale("pipeline")}
            noOptionsMessage={() => locale("no pipeline")}
            options={pipelines}
            value={pipeline}
            onChange={(e) => {
              setPipeline(e);
              setStages(e.stages);
              setStage(null);
            }}
            styles={nonGroupColourStyles}
          />
          <InLineError type="error" message={errorBag?.pipeline || null} />
        </Form.Group>

        <Form.Group className="form-group" id="stage">
          <Form.Label>{locale("move deal to")}</Form.Label>
          <div>
            <Select
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              placeholder={locale("Stage")}
              noOptionsMessage={() => locale("no stage")}
              options={stages}
              value={stage ? stage : ""}
              onChange={(e) => {
                setStage(e);
              }}
              styles={nonGroupColourStyles}
            />
          </div>
          <InLineError type="error" message={errorBag?.stage_id || null} />
        </Form.Group>
        <Form.Group className="form-group" id="operation">
          <Form.Label>{locale("action on")}</Form.Label>
          <div>
            <Select
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              placeholder={locale("Operation")}
              noOptionsMessage={() => locale("no operation")}
              options={operations}
              value={operation ? operation : ""}
              onChange={(e) => {
                setOperation(e);
              }}
              styles={nonGroupColourStyles}
            />
          </div>
          <InLineError type="error" message={errorBag?.operation || null} />
        </Form.Group>
      </Container>
      <div className="sticky-on-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import XAlertMessage from '../AlertMessage/XAlertMessage';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MANAGE_LOCATION_API,
  GET_LOCATION_API,
} from '../../../GraphApi/CustomAttributes';
import {
  currentTeamId,
  getValidationMessage,
  isObjectEmpty,
} from '../../../Utils/utils';
import { EqupRoundLoaderWithOverlay } from '../Loader/RoundLoader';
import { useTranslation } from 'react-i18next';

export const LocationAPI = ({ forPage = 'all' }) => {
  const { t: locale } = useTranslation();
  /** Alert Message State **/
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  /****/
  const [apiKey, setApiKey] = useState(null);
  const [showAPIPopup, setShowAPIPopup] = useState(false);
  const [hasError] = useState(false);

  const [manageLocationCredential] = useMutation(MANAGE_LOCATION_API);
  const [fetchLocationCredential, { data, loading }] = useLazyQuery(
    GET_LOCATION_API,
    { fetchPolicy: 'network-only' }
  );

  useEffect(() => {
    fetchLocationCredential({
      variables: { team_id: currentTeamId },
      fetchPolicy: 'network-only',
    }).catch((error) => {
      console.log(error);
      let { graphQLErrors } = error;
      handleAlertMessage(
        'error',
        locale('messages:backend.' + getValidationMessage(graphQLErrors))
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      data &&
      data.fetchLocationCredential &&
      data.fetchLocationCredential.integration_api_key
    ) {
      setApiKey(data.fetchLocationCredential.integration_api_key);
    }
  }, [data]);

  const handleClosePopup = () => {
    setShowAPIPopup(false);
  };

  const handleAlertMessage = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    setAlertShow(true);
  };

  const handleSave = () => {
    if (isObjectEmpty(apiKey)) {
      handleAlertMessage('error', locale('API key is required.'));
    } else {
      manageLocationCredential({
        variables: {
          team_id: currentTeamId,
          status: true,
          integrated_with: 'google',
          integration_api_key: apiKey,
        },
      })
        .then((result) => {
          handleAlertMessage(
            'success',
            locale('messages:common.save', {
              fieldName: locale('API key'),
            })
          );
        })
        .catch((error) => {
          console.log(error);
          let { graphQLErrors } = error;
          handleAlertMessage(
            'error',
            locale('messages:backend.' + getValidationMessage(graphQLErrors))
          );
        });
    }
  };
  /*****/
  return (
    <React.Fragment>
      {loading && <EqupRoundLoaderWithOverlay />}
      {!isObjectEmpty(apiKey) && forPage === 'estimate' ? (
        ''
      ) : (
        <Row>
          <Col className='text-end'>
            <Button
              variant='outline-primary'
              onClick={() => setShowAPIPopup(true)}
            >
              {data &&
              data.fetchLocationCredential &&
              data.fetchLocationCredential.integration_api_key
                ? 'Change API Key'
                : 'Add Api Key'}
            </Button>
          </Col>
        </Row>
      )}

      <Modal
        className={'common modalLg'}
        show={showAPIPopup}
        backdrop='static'
        keyboard={false}
        onHide={handleClosePopup}
      >
        <Modal.Header
          closeVariant='white'
          closeButton
          className={'bg-primary'}
        >
          <Modal.Title className={'text-white secondary-font text-uppercase'}>
            {locale('API KEY')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className='alert-outer-col'>
              <XAlertMessage
                type={alertType}
                message={alertMessage}
                setAlertShow={setAlertShow}
                alertShow={alertShow}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='form-group'>
                <Form.Label>{locale('API Key')}</Form.Label>
                <Form.Control
                  className={hasError ? 'is-invalid' : ''}
                  type='text'
                  name='apiKey'
                  placeholder={locale('Enter key')}
                  value={apiKey ? apiKey : ''}
                  onChange={(e) => {
                    setApiKey(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className='text-end'>
              <Button
                variant='outline-secondary'
                onClick={handleClosePopup}
              >
                Cancel
              </Button>{' '}
              <Button
                variant='outline-primary'
                onClick={handleSave}
              >
                {data &&
                data.fetchLocationCredential &&
                data.fetchLocationCredential.integration_api_key
                  ? 'Change API Key'
                  : 'Save'}
              </Button>
            </Col>
          </Row>

          <div className='note-information-integrate-mail'>
            <h4>Note:</h4>
            <div className='note-information-inner'>
              <h6>Follow these steps to generate Google Map API key</h6>
              <ul>
                <li>
                  <div>
                    <b>Create a Google Cloud Platform Project:</b> <br /> Visit
                    the Google Cloud Platform console{' '}
                    <a
                      target='_new'
                      href='https://console.cloud.google.com/'
                    >
                      Google Cloud Console
                    </a>
                    .<br /> If you don't have a project, click "Create Project"
                    and follow the prompts. If you have an existing project,
                    select it from the dropdown menu.
                  </div>
                </li>

                <li>
                  <b>Enable the Places API:</b> <br />
                  In the console, navigate to "APIs & Services" &gt; "Library".
                  <br />
                  Search for "Places API" and select it. Click "Enable".
                </li>
                <li>
                  <b>Create an API Key:</b>
                  <br />
                  Go to "APIs & Services" &gt; "Credentials". <br />
                  Click "Create Credentials" &gt; "API key". <br />
                  Copy the generated API key and paste it above and click
                  "Save".
                </li>

                <li>
                  <b>Restrict the API Key (Recommended):</b>
                  <br />
                  Click on the newly created API key."
                  <br />
                  Navigate to Key restrictions &gt; Set an application
                  restriction.
                  <br />
                  Choose "Websites". Under Websites restrictions click on "ADD"
                  and enter *.equp.com/* and click on Done.
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { availableComponent } from "./Elements/ElementList";
import { toTitleCase } from "../../../../Utils/utils";
import { BusinessFlowActions } from "./Elements/BusinessFlowActions";
import { BusinessFlowSettingDrawer } from "./Elements/BusinessFlowSettingDrawer";
import { FormSubmission } from "./Elements/FormSubmission";
import { ChatBox } from "./Elements/ChatBox";
import { OnlineBooking } from "./Elements/OnlineBooking";
import { WhatsappMessageReceived } from "./Elements/WhatsappMessageReceived";
import { Call } from "./Elements/Call";
import { ManuallyAdd } from "./Elements/ManuallyAdd";
import { ImportAction } from "./Elements/ImportAction";
import { Card } from "react-bootstrap";

export const BusinessActions = ({
  currentCompanySetting,
  handleAlertMessage = () => {},
  automationData,
  automationStep,
}) => {
  const { refCanvas } = useRef(null);
  const [actions, setActions] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [showSetting, setShowSetting] = useState(null);

  useEffect(() => {
    if (
      automationData?.triggers?.length > 0 &&
      automationData.triggers[automationStep]
    ) {
      const name = automationData.triggers[automationStep];
      const data = automationData?.data[name] || [];
      setActions([...data]);
    }
  }, [automationData, automationStep]);

  useEffect(() => {
    if (showAction) {
      let updatedData = [...actions];
      updatedData.push({
        elementName: "delay_timer",
        delay_time_details: null,
      });
      setActions([...updatedData]);
    } else {
      actions.length > 0 && openDrawer(actions.length - 1); //:openDrawer(0);
    }
  }, [showAction]);

  const handleChange = (index, key, value) => {
    let updatedData = [];
    if (actions?.length > 0) {
      if (key === "action") {
        updatedData = actions.map((item, idx) =>
          idx === index ? { ...item, ...value } : item
        );
      } else {
        updatedData = actions.map((item, idx) =>
          idx === index ? { ...item, [key]: value.value } : item
        );
      }
    } else {
      updatedData.push({ [key]: value.value });
    }
    setActions([...updatedData]);

    actions.forEach((item, idx) => {
      if (actions.length - 1 === idx && item.elementName !== "delay_timer") {
        setShowAction(true);
      }
    });
  };

  const getAutomationTrigger = (action, idx) => {
    const name = action?.elementName || "";
    const data = action;
    switch (name) {
      case "form_submission":
        return (
          <Card>
            <Card.Body>
              <FormSubmission
                label="Select Form"
                value={data?.form_id || ""}
                handleChange={(key, value) => handleChange(0, key, value)}
              />
            </Card.Body>
          </Card>
        );
        break;
      case "chat_bot":
        return (
          <Card>
            <Card.Body>
              <ChatBox
                value={data?.chat_box || ""}
                handleChange={(key, value) => handleChange(0, key, value)}
              />
            </Card.Body>
          </Card>
        );
        break;
      case "online_booking":
        return (
          <Card>
            <Card.Body>
              <OnlineBooking
                value={data?.booking || ""}
                handleChange={(key, value) => handleChange(0, key, value)}
              />
            </Card.Body>
          </Card>
        );
        break;
      case "whatsapp":
        return (
          <Card>
            <Card.Body>
              <WhatsappMessageReceived
                value={data?.whatsapp_message || ""}
                handleChange={(key, value) => handleChange(0, key, value)}
              />
            </Card.Body>
          </Card>
        );
        break;
      case "call":
        return (
          <Card>
            <Card.Body>
              <Call
                value={data?.call || ""}
                handleChange={(key, value) => handleChange(0, key, value)}
              />
            </Card.Body>
          </Card>
        );
        break;
      case "manually_add":
        return (
          <Card>
            <Card.Body>
              <ManuallyAdd
                value={data?.manually_add || ""}
                handleChange={(key, value) => handleChange(0, key, value)}
              />
            </Card.Body>
          </Card>
        );
        break;
      case "import":
        return (
          <Card>
            <Card.Body>
              <ImportAction
                value={data?.import || ""}
                handleChange={(key, value) => handleChange(0, key, value)}
              />
            </Card.Body>
          </Card>
        );
        break;
      default:
        return getComponent(idx, action);
        break;
    }
  };

  const getComponent = (idx, element) => {
    const MyComponent = availableComponent[toTitleCase(element.elementName)];
    const appliedClasses = "";
    return (
      <MyComponent
        name={element?.elementName || "Finish"}
        itemId={element?.id || idx}
        string={element?.string || ""}
        removeWorkflowItem={removeWorkflowItem}
        openDrawer={() => openDrawer(idx, element)}
        appliedClasses={appliedClasses}
        indexNo={idx}
        isRemovable={idx > 0}
      />
    );
  };

  const removeWorkflowItem = (index) => {
    let updatedData = actions.filter((item, idx) => item?.itemId !== index);
    if (updatedData.length === actions.length) {
      updatedData = actions.filter((item, idx) => idx !== index);
    }
    setActions([...updatedData]);
  };

  const openDrawer = (idx) => {
    setShowSetting(idx);
  };
  /*****/
  return (
    <React.Fragment>
      {/* <div className="my-3">{getAutomationTrigger()}</div> */}
      <div className={""}>
        <div ref={refCanvas}>
          {actions?.length > 0 &&
            actions.map(
              (action, idx) =>
                action && (
                  <div key={"comp-" + idx} className="my-3">
                    {getAutomationTrigger(action, idx)}
                  </div>
                )
            )}
        </div>
      </div>
      {showAction && (
        <div className="my-2">
          <BusinessFlowActions
            actions={actions}
            setActions={setActions}
            handleHideAction={() => setShowAction(false)}
          />
        </div>
      )}

      <BusinessFlowSettingDrawer
        key={showSetting}
        index={showSetting}
        show={showSetting !== null}
        handleClose={() => setShowSetting(null)}
        actions={actions}
        handleChange={handleChange}
        currentCompanySetting={currentCompanySetting}
      />
    </React.Fragment>
  );
};

import React, { useState, useEffect, forwardRef } from "react";
import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import range from "lodash/range";
import { useTranslation } from "react-i18next";
import {
  defaultDateFormat,
  defaultFormat,
  getDateformatWise,
  setDefaultTimezone,
} from "../../../../Utils/EqupTimezone";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./custom-style.css";
import "../../../../Assets/Styles/datepicker.css";
import { getMonth, getYear, subDays } from "date-fns";

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <input
    className="custom-date-input"
    onClick={onClick}
    ref={ref}
    value={value}
    readOnly
  />
));

const DateFilter = ({
  labelRemove = false,
  placeholder = null,
  label = "Date",
  labelVisibility = true,
  handleChange,
  handleChangeSecond,
  type,
  isError,
  value = "",
  allowFuture = true,
  allowPast = true,
  isDisabled = false,
  disallowDateBefore = null,
  disallowDateAfter = null,
  withTime = false,
  getColorScheme = () => {},
  hasPreviewMode = false,
  withIcon = true,
  isRequired = false,
}) => {
  const { t: locale, i18n } = useTranslation(["common", "pages"]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [startDate, setStartDate] = useState(new Date());
  const years = range(1970, getYear(new Date()) + (allowFuture ? 10 : 1), 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const changeStartDate = (date) => {
    setStartDate(date);
    setDefaultTimezone();
    if (date) {
      if (withTime) {
        handleChange(getDateformatWise(date, defaultFormat));
      } else {
        handleChange(getDateformatWise(date, defaultDateFormat));
      }
    } else {
      if (withTime) {
        handleChange("");
      }
    }
  };

  const changeEndDate = (endDate) => {
    setEndDate(endDate);
    setDefaultTimezone();
    if (endDate) {
      if (withTime) {
        handleChangeSecond(getDateformatWise(endDate, defaultFormat));
      } else {
        handleChangeSecond(getDateformatWise(endDate, defaultDateFormat));
      }
    } else {
      if (withTime) {
        handleChangeSecond("");
      }
    }
  };

  useEffect(() => {
    if (value?.startDate) {
      setStartDate(getDateformatWise(value.startDate, defaultDateFormat));
    } else if (value?.endDate) {
      setEndDate(getDateformatWise(value.endDate, defaultDateFormat));
    } else {
      setStartDate("");
      setEndDate("");
    }
  }, [value]);

  const dateBox = {
    position: "relative",
    display: "block",
  };

  const allowPastDate = () => {
    if (disallowDateBefore) {
      return new Date(disallowDateBefore);
    } else {
      if (allowPast) {
        return null;
      } else {
        return subDays(new Date(), 0);
      }
    }
  };

  const allowFutureDate = () => {
    if (disallowDateAfter) {
      return new Date(disallowDateAfter);
    } else {
      if (allowFuture) {
        return null;
      } else {
        return subDays(new Date(), 1);
      }
    }
  };

  /****/
  return (
    <React.Fragment>
      {type === "single" && (
        <React.Fragment>
          <Form.Group
            className={
              hasPreviewMode ? "form-group-view form-group" : "form-group"
            }
          >
            {!labelRemove && (
              <Form.Label className={!labelVisibility ? "invisible" : ""}>
                {locale(label)}
                {isRequired && <span className="text-danger">*</span>}
              </Form.Label>
            )}
            <InputGroup
              className={
                (hasPreviewMode ? "duration form-control-view" : "duration") +
                (isError.value ? " select-error" : "")
              }
              style={{ flexWrap: "nowrap" }}
            >
              {!hasPreviewMode && withIcon && (
                <InputGroup.Text>
                  <i
                    style={getColorScheme()}
                    className={"ri-calendar-line"}
                  ></i>
                </InputGroup.Text>
              )}
              <DatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      className="font-weight-bold p-0 me-2"
                      variant="link"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <i className="ri-arrow-left-s-fill"></i>
                    </Button>
                    <select
                      style={{ outline: "none" }}
                      className="me-2"
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <select
                      style={{ outline: "none" }}
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <Button
                      className="font-weight-bold p-0 ms-2 outline-none"
                      variant="link"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <i className="ri-arrow-right-s-fill"></i>
                    </Button>
                  </div>
                )}
                selected={startDate ? startDate : ""}
                onChange={(date) => !isDisabled && changeStartDate(date)}
                minDate={allowPastDate()}
                maxDate={allowFutureDate()}
                placeholderText={placeholder ? placeholder : defaultDateFormat}
                dateFormat={"MM-dd-yyyy"}
                customInput={<ExampleCustomInput />}
                name="startDateInput"
                disabled={isDisabled}
              />
              {hasPreviewMode && <i className={"ri-pencil-line"} />}
            </InputGroup>
          </Form.Group>
        </React.Fragment>
      )}
      {type === "range" && (
        <React.Fragment>
          <Row>
            <Col>
              <Form.Group className="form-group" style={dateBox}>
                <Form.Label className={!labelVisibility && "invisible"}>
                  {locale("Start Date")}
                </Form.Label>
                <InputGroup
                  className={`duration ${hasPreviewMode ? "form-control-view" : ""} ${isError.value ? " select-error" : ""}`}
                >
                  {!hasPreviewMode && withIcon && (
                    <InputGroup.Text>
                      <i
                        style={getColorScheme()}
                        className={"ri-calendar-line"}
                      ></i>
                    </InputGroup.Text>
                  )}
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          className="font-weight-bold p-0 me-2"
                          variant="link"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          <i className="ri-arrow-left-s-fill"></i>
                        </Button>
                        <select
                          style={{ outline: "none" }}
                          className="me-2"
                          value={getYear(date)}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select
                          style={{ outline: "none" }}
                          value={months[getMonth(date)]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <Button
                          className="font-weight-bold p-0 ms-2 outline-none"
                          variant="link"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          <i className="ri-arrow-right-s-fill"></i>
                        </Button>
                      </div>
                    )}
                    selected={startDate ? startDate : ""}
                    onChange={(date) => !isDisabled && changeStartDate(date)}
                    minDate={allowPastDate()}
                    maxDate={allowFutureDate()}
                    placeholderText={
                      placeholder ? placeholder : defaultDateFormat
                    }
                    style={{ flexWrap: "nowrap" }}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group className="form-group" style={dateBox}>
                <Form.Label className={!labelVisibility && "invisible"}>
                  {" "}
                </Form.Label>
                <div>
                  <Form.Label className={"mt-3"}> {locale("TO")} </Form.Label>
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" style={dateBox}>
                <Form.Label className={!labelVisibility && "invisible"}>
                  {locale("End Date")}
                </Form.Label>{" "}
                <InputGroup
                  className={`duration ${hasPreviewMode ? "form-control-view" : ""} ${isError.value ? " select-error" : ""}`}
                >
                  {!hasPreviewMode && withIcon && (
                    <InputGroup.Text>
                      <i
                        style={getColorScheme()}
                        className={"ri-calendar-line"}
                      ></i>
                    </InputGroup.Text>
                  )}
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          className="font-weight-bold p-0 me-2"
                          variant="link"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          <i className="ri-arrow-left-s-fill"></i>
                        </Button>
                        <select
                          style={{ outline: "none" }}
                          className="me-2"
                          value={getYear(date)}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select
                          style={{ outline: "none" }}
                          value={months[getMonth(date)]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <Button
                          className="font-weight-bold p-0 ms-2 outline-none"
                          variant="link"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          <i className="ri-arrow-right-s-fill"></i>
                        </Button>
                      </div>
                    )}
                    minDate={allowPastDate()}
                    maxDate={allowFutureDate()}
                    placeholderText={
                      placeholder ? placeholder : defaultDateFormat
                    }
                    style={{ flexWrap: "nowrap" }}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default DateFilter;

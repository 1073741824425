import React, { useState, useEffect } from "react";
import { Modal, Button, CardGroup, Row, Col } from "react-bootstrap";
import { cardStyles } from "../Common/DropDown/Style";
import AgreementTemplateCard from "./AgreementTemplateCard";
import { CreateAgreementOption } from "../Agreement/Agreements/CreateAgreementPopup";

export const AgreementTemplatesListing = ({
  locale,
  templateModalLoading,
  agreementTemplatesList = [],
  fetchTemplateData,
  hideAgreementTemplateModal,
  handlePreview,
  contactId = null,
  cId = null,
  setCloneableAgreement = () => {},
  cameFrom = "",
}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const handleShowPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  useEffect(() => {
    fetchTemplateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplatePreview = (item) => {
    if (!item || !item.agreement_versions)
      return require("../../Assets/Images/agreement-default.png");
    const pdfImage = item.agreement_versions.find(
      (obj) => obj.type === "pdf_image" && obj.serial_order === 1
    );
    if (!pdfImage) return require("../../Assets/Images/agreement-default.png");
    return pdfImage.attachment.url;
  };

  return (
    <>
      <Modal.Header closeVariant="white" closeButton className={"bg-primary"}>
        <Modal.Title className={"text-white secondary-font text-uppercase"}>
          Agreement Templates
        </Modal.Title>
      </Modal.Header>
      {showPopup ? (
        <Modal.Body style={{ height: "70vh" }}>
          <Row>
            <Col className="text-end">
              <Button
                variant={"link"}
                onClick={() => {
                  handleClosePopup();
                }}
              >
                Back to Template List
              </Button>
            </Col>
          </Row>
          <CreateAgreementOption
            // showPopup={showPopup}
            // handleClosePopup={handleClosePopup}
            contactDetails={null}
            name={{
              name: "template",
              label: "Agreement Templates",
              icon: "ri-file-text-line",
              type: "template",
            }}
            cameFrom={cameFrom}
            locale={locale}
          />
        </Modal.Body>
      ) : (
        <React.Fragment>
          <Modal.Body style={{ height: "70vh" }}>
            <Row className="border-bottom p-0 pb-2 m-0">
              <Col className="text-end">
                <Button onClick={handleShowPopup}>Create New</Button>
              </Col>
            </Row>
            <CardGroup>
              {agreementTemplatesList.length ? (
                agreementTemplatesList.map((item, idx) => (
                  <AgreementTemplateCard
                    key={idx}
                    item={item}
                    cardStyles={cardStyles}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    getTemplatePreview={getTemplatePreview}
                    handlePreviewTemplate={handlePreview}
                  />
                ))
              ) : (
                <h4 className="text-center p-4 w-100">
                  Looks like you haven't created any agreement templates yet!
                  <br /> Head over to Settings &gt;&gt; Agreements to get
                  started.
                  <br />
                  Templates make creating agreement a breeze!
                </h4>
              )}
            </CardGroup>
          </Modal.Body>
          <Modal.Footer>
            {activeIndex ? (
              <Button
                variant={"outline-primary"}
                className={"btn-ms-icon"}
                onClick={() =>
                  setCloneableAgreement(
                    agreementTemplatesList.filter(
                      (item) => item.template_id === activeIndex
                    )[0]
                  )
                }
              >
                <span>{locale("select")}</span>{" "}
                <i className={"ri-arrow-right-line"}></i>
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </React.Fragment>
      )}

      {/* <CreateAgreementPopup
        showPopup={showPopup}
        handleClosePopup={handleClosePopup}
        contactDetails={null}
        name={{
          name: "template",
          label: "Agreement Templates",
          icon: "ri-file-text-line",
          type: "template",
        }}
        cameFrom={cameFrom}
      /> */}
    </>
  );
};

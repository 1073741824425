import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EqupRoundLoaderWithOverlay } from "../../Common/Loader/RoundLoader";
import { LEAD_OWNER_LIST } from "../../../GraphApi/LeadOwner";
import { SelectDropdown } from "../../Common/Conditions/SelectDropdown";
import { useLazyQuery } from "@apollo/client";
import {
  currentTeamId,
  defaultRole,
  isObjectEmpty,
  loggedInUserId,
  toWordCase,
} from "../../../Utils/utils";
import { PRODUCT_ALL } from "../../../GraphApi/ProductList";
import InLineError from "../../Common/AlertMessage/InLineError";
import { InviteTeamMember } from "./InviteTeamMember";
import { GET_ROLES_LIST } from "../../../GraphApi/TeamMembers";

export const AssignService = ({
  currentCompanySetting,
  handleAlertMessage = () => {},
  setSetup = null,
  setup = () => {},
  userData,
  withCurrentUser = false,
  setFullSetup,
  fullSetup,
}) => {
  const { t: locale } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAddTeam, setShowAddTeam] = useState(false);
  const [assignData, setAssignData] = useState([
    { service: "", team_user: "" },
  ]);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [errorBag, setErrorBag] = useState(null);
  const [allRoles, setAllRoles] = useState([]);

  const [getAllLeadOwner, { data: ownerList }] = useLazyQuery(LEAD_OWNER_LIST, {
    variables: { team_id: currentTeamId, is_setup_calendar_scheduling: false },
    fetchPolicy: "network-only",
  });
  const [getAllRoles, { data: roles }] = useLazyQuery(GET_ROLES_LIST, {
    variables: { team_id: currentTeamId },
    fetchPolicy: "network-only",
  });
  /** Fetch Product List **/
  const [getProducts, { data: allProducts, loading }] = useLazyQuery(
    PRODUCT_ALL,
    { fetchPolicy: "no-cache" }
  );

  const fetchProducts = (search = "") => {
    getProducts({
      variables: {
        productType: ["physical", "digital", "service"],
        search,
        team_id: currentTeamId,
      },
      fetchPolicy: "no-cache", // Ensure we only fetch when necessary
    });
  };

  useEffect(() => {
    getAllLeadOwner();
    fetchProducts();
    getAllRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allProducts) {
      const data = allProducts.allProducts;
      let servicesList = [];
      let productsList = [];

      if (data?.length > 0) {
        data.forEach((product) => {
          if (product?.product_type === "service") {
            servicesList.push({
              value: product.id,
              label: product.name,
            });
          } else {
            productsList.push({
              value: product.id,
              label: product.name,
            });
          }
        });
      }

      // Set the products only if they are different to avoid triggering a re-render
      setProducts([
        {
          label: (
            <label className="font-weight-bold p-1 text-primary">
              Services
            </label>
          ),
          options: servicesList,
        },
        {
          label: (
            <label className="font-weight-bold p-1 text-primary">
              Products
            </label>
          ),
          options: productsList,
        },
      ]);
    }
  }, [allProducts]);

  useEffect(() => {
    if (ownerList) {
      const allLeadOwner = [];
      ownerList.allUsers.forEach((item) => {
        if (
          !withCurrentUser &&
          loggedInUserId(userData) === parseInt(item.id)
        ) {
          //Do Nothing
        } else {
          allLeadOwner.push({
            value: parseInt(item.id),
            label: item.name,
            id: parseInt(item.id),
            team_user_id: item.team_user_id,
          });
        }
      });
      setUsers(allLeadOwner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerList]);

  useEffect(() => {
    if (roles) {
      const rolesList = roles.getAllRoles.filter(
        (item) => item && ["owner"].indexOf(item.name) === -1
      );

      setAllRoles(
        rolesList.map(
          (item) =>
            item && {
              value: item.id,
              label:
                defaultRole.indexOf(toWordCase(item.name)) === -1
                  ? toWordCase(item.name)
                  : locale(item.name),
            }
        )
      );
    }
  }, [roles]);

  const removeRow = (idx) => {
    setAssignData(assignData.filter((item, index) => index === idx));
  };

  const addRow = () => {
    setAssignData([...assignData, { service: "", team_user: "" }]);
  };

  const handleData = (index, name, e) => {
    setAssignData(
      assignData.map((item, idx) =>
        idx === index ? { ...item, [name]: e?.value || "" } : item
      )
    );
  };

  const handleAssign = (skipValidation = false) => {
    const isValidate = validate();
    if (isValidate || skipValidation) {
      setSetup({
        ...setup,
        assignService: { ...setup.assignService, status: "completed" },
      });
      setSetup({});
      setFullSetup({
        ...fullSetup,
        manageBusiness: {
          ...fullSetup.manageBusiness,
          status: "completed",
        },
        ...(fullSetup.automateBusiness
          ? {
              automateBusiness: {
                ...fullSetup.automateBusiness,
                status: "active",
              },
            }
          : fullSetup.automateJobs
            ? {
                automateJobs: {
                  ...fullSetup.automateJobs,
                  status: "active",
                },
              }
            : {}),
      });
    }
  };

  const validate = () => {
    let newErrors = {};
    assignData.forEach((item, idx) => {
      if (isObjectEmpty(item.service)) {
        newErrors = {
          ...newErrors,
          [idx]: { ...newErrors[idx], service: "Service is required" },
        };
      }
      if (isObjectEmpty(item.team_user)) {
        newErrors = {
          ...newErrors,
          [idx]: { ...newErrors[idx], team_user: "Team user is required" },
        };
      }
    });

    setErrorBag({ ...newErrors });
    return Object.keys(newErrors).length === 0;
  };
  /******/
  return (
    <Card>
      {isSubmitting && <EqupRoundLoaderWithOverlay />}
      <Card.Body className="px-3 py-3">
        <div>
          <h3 className="text-primary mb-1">
            Next Now that you have added the services, would you like to assign
            the services to your team members?
          </h3>
          <span className="text-sm">Add at-least one service or product.</span>
        </div>
        <Row className="my-2">
          <Col className="text-end">
            <Button onClick={() => setShowAddTeam(!showAddTeam)}>
              Add Team Member
            </Button>
          </Col>
        </Row>
        {assignData.map((item, idx) => (
          <Row className="my-2" key={idx}>
            <Col>
              <SelectDropdown
                label=""
                labelVisibility={false}
                value={item.service}
                options={products}
                handleChange={(e) => {
                  handleData(idx, "service", e);
                }}
                placeholder={"Choose Service "}
              />{" "}
              <InLineError
                extraStyle={{ marginTop: "-15px" }}
                type="error"
                message={
                  errorBag && errorBag[idx]?.service
                    ? errorBag[idx].service
                    : ""
                }
              />
            </Col>
            <Col>
              <SelectDropdown
                label=""
                labelVisibility={false}
                value={item.team_user}
                options={users}
                handleChange={(e) => {
                  handleData(idx, "team_user", e);
                }}
                placeholder={"Select Team Member"}
              />
              <InLineError
                extraStyle={{ marginTop: "-15px" }}
                type="error"
                message={
                  errorBag && errorBag[idx]?.team_user
                    ? errorBag[idx].team_user
                    : ""
                }
              />
            </Col>
            <Col xs={"auto"} className="mx-0">
              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "20px",
                }}
              >
                {assignData.length > 1 && (
                  <i
                    onClick={() => removeRow(idx)}
                    className="ri-close-circle-line fs-5 text-danger"
                    style={{ position: "absolute", top: "15px" }}
                  />
                )}
                <br />
                {idx + 1 === assignData.length && (
                  <i
                    onClick={addRow}
                    className="ri-add-circle-line fs-5 text-success"
                    style={{ position: "absolute", bottom: "10px" }}
                  />
                )}
              </div>
            </Col>
          </Row>
        ))}
        {showAddTeam && (
          <InviteTeamMember
            allRoles={allRoles}
            handleAlertMessage={handleAlertMessage}
            fetchTeamMemberList={getAllLeadOwner}
          />
        )}
      </Card.Body>
      <Card.Footer className="">
        <div className="d-flex justify-content-between">
          <Button
            variant="outline-secondary"
            disabled={isSubmitting}
            type="button"
            onClick={() => handleAssign(true)}
          >
            <span>{locale("Skip")}</span>
          </Button>
          <Button onClick={handleAssign}>Next</Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { currentTeamId, toWordCase } from "../../../Utils/utils";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { TAX_LIST } from "../../../GraphApi/Tax";
import { NewServiceItem } from "./NewServiceItem";
import { ConditionDivider } from "../../Common/Helper/CommonHelper";

export const PlanCard = ({
  index,
  type,
  currency = "$",
  currentCompanySetting,
}) => {
  const planItem = {
    name: "",
    description: "",
    product_type: type,
    type: "recurring",
    price: 0,
    cost: 0,
    stock: 0,
    tax_applicable: "false",
    tasks: [{ title: "", description: "", duration: "" }],
  };

  const { t: locale } = useTranslation("common");
  const [isCreateAble, setIsCreateAble] = useState(false);
  const [plans, setPlans] = useState([planItem]);
  const [taxList, setTaxList] = useState(null);
  const [errors, setErrors] = useState(null);
  const paymentType = [
    { value: "fixed", label: locale("fixed") },
    { value: "hourly", label: locale("hourly") },
  ];

  const [getTaxList, { data: allTaxes }] = useLazyQuery(TAX_LIST);

  useEffect(() => {
    getTaxList({
      variables: { team_id: currentTeamId },
      fetchPolicy: "network-only",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamId]);

  useEffect(() => {
    if (allTaxes?.allTaxes?.length > 0) {
      const taxList = allTaxes.allTaxes.map(
        (item) =>
          item && {
            id: item.id,
            label: item.name,
            value: item.name,
            taxValue: item.tax_value,
            type: item.type,
          }
      );
      setTaxList(taxList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTaxes]);

  const handleAdd = (type) => {
    setIsCreateAble(true);
  };

  const addService = () => {
    const data = plans?.length > 0 ? plans : [];
    data.push(planItem);
    setPlans([...plans]);
  };

  const removeService = (serviceIndex) => {
    const data = plans?.length > 0 ? plans : [];
    data.splice(serviceIndex, 1);
    setPlans([...data]);
  };

  const handleData = (serviceIndex, name, value) => {
    const copyServiceData = [...plans];
    let newData = [];
    copyServiceData.forEach((item, i) => {
      if (i === serviceIndex) {
        if (name === "tax_applicable" && value === false) {
          item = { ...item, tax: 0, [name]: value };
        } else {
          item = { ...item, [name]: value };
        }
        item = { ...item, line_price_total: handleLineTotal(item) };
        newData.push({ ...item });
      } else {
        newData.push({ ...item });
      }
    });

    setPlans([...newData]);
  };

  const handleLineTotal = (lineItem) => {
    let lineTotal = 0;
    lineTotal = lineItem.unit_price * lineItem.quantity;
    lineTotal =
      lineItem.tax_applicable && lineItem.tax
        ? parseFloat(lineTotal) + parseFloat((lineTotal * lineItem.tax) / 100)
        : parseFloat(lineTotal);

    return parseFloat(lineTotal.toFixed(2));
  };

  /******/
  return (
    <Card>
      <Card.Body className="px-1 py-3">
        <h4 className="text-primary mt-0">Add {toWordCase(type)}s</h4>
        {!isCreateAble ? (
          <div className="d-flex justify-content-center">
            <div>
              <Button
                variant="primary"
                className="mx-1"
                onClick={() => handleAdd(type)}
              >
                Add Manually
              </Button>
              <p className="text-small text-center">Recommended</p>
            </div>
            <div>
              <Button variant="primary" className="mx-1">
                Import Via CSV
              </Button>
            </div>
            <div>
              <Button variant="primary" className="mx-1">
                Import Via API
              </Button>
            </div>
          </div>
        ) : (
          <React.Fragment>
            {plans.length > 0 &&
              plans.map((item, idx) => (
                <React.Fragment key={idx}>
                  <Row key={idx}>
                    <Col>
                      <NewServiceItem
                        serviceIndex={idx}
                        offeringType={type}
                        key={idx}
                        service={item}
                        addService={() => addService(idx)}
                        removeService={() => removeService(idx)}
                        paymentType={paymentType}
                        handleData={(name, value) =>
                          handleData(idx, name, value)
                        }
                        errors={
                          errors?.length > 0 &&
                          errors[index]?.length > 0 &&
                          errors[index][idx]
                            ? errors[index][idx]
                            : null
                        }
                        currency={currency}
                        currentCompanySetting={currentCompanySetting}
                      />
                    </Col>
                    <Col xs={"auto"} className="mx-0">
                      <div
                        style={{
                          position: "relative",
                          height: "100%",
                          width: "20px",
                        }}
                      >
                        {plans.length > 1 && (
                          <i
                            onClick={removeService}
                            className="ri-close-circle-line fs-5 text-danger"
                            style={{ position: "absolute", top: "10px" }}
                          />
                        )}
                        <br />
                        {idx + 1 === plans.length && (
                          <i
                            onClick={addService}
                            className="ri-add-circle-line fs-5 text-success"
                            style={{ position: "absolute", bottom: "10px" }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <ConditionDivider text="" className={"mx-0 my-4"} />
                </React.Fragment>
              ))}
          </React.Fragment>
        )}
      </Card.Body>
    </Card>
  );
};

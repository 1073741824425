import React, { useState, useEffect } from "react";
import Select from "react-select";
import Flags from "react-world-flags";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLazyQuery } from "@apollo/client";
import { COUNTRY_LIST } from "../../../GraphApi/ContactList";

const PhoneInput = ({ data, formik, name, defaultCountry="US", placeholder, isDisabled, id, autocomplete }) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isActive, setIsActive] = useState(false);

  
  const [getCountry, { data: countryData }] = useLazyQuery(COUNTRY_LIST);

  // Initialize countries and selected country
  useEffect(() => {
    const loadCountries = async () => {
      const cachedCountries = localStorage.getItem("country");

      if (cachedCountries) {
        const parsedCountries = JSON.parse(cachedCountries);
        setCountries(parsedCountries.countryList.country);
      } else {
        try {
          getCountry();
          // const dataA = [
          //   { name: "United States", code: "US", phone_code: "+1" },
          //   { name: "Canada", code: "CA", phone_code: "+1" },
          //   { name: "United Kingdom", code: "GB", phone_code: "+44" },
          // ];
          // const formattedCountries = dataA.map((country) => ({
          //   label: country.name,
          //   code: country.code,
          //   phone_code: country.phone_code,
          // }));
          // setCountries(formattedCountries);
          // console.log("Countries loaded from fallback data");
          
          // // setTimeout(() => {
          // //   fetchCountryAndData(data, formattedCountries);
          // // }, 200);
        } catch (error) {
          console.error("Error fetching countries:", error);
        }
      }
    };

    loadCountries();
  }, []);

  useEffect(() => {
    if (countryData) {
      localStorage.setItem("country", JSON.stringify(countryData));
      // handleCountryData(countryData);
      setCountries(countryData.countryList.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryData]);

  const fetchCountryAndData = (data, countries) => {
    if (data && countries && countries.length > 0) {
      const [countryCode, phone, code=""] = data.split("-");
      let initialCountry = countries.find(
        (c) => c.phone_code === parseInt(countryCode) && c.code === code
      );
      if (!initialCountry) {
        initialCountry = countries[0];
      }
      setSelectedCountry({
        value: initialCountry.phone_code + initialCountry.code,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p style={{ display: "flex", alignItems: "center" }}>
              <Flags code={initialCountry.code} style={{ width: 22, marginRight: 10 }} />
            {/* </span> */}
              <span className="country_name">{initialCountry.name}</span>
            </p>
            <span>+{initialCountry.phone_code}</span>
          </div>
        ),
        data: { ...initialCountry, phone_code: String(initialCountry.phone_code) },
      });
      setPhoneNumber(phone || "");
    } else if(defaultCountry && countries && countries.length > 0 && !phoneNumber) {
      const [countryCode, phone, code=""] = ["", "", defaultCountry];
      let initialCountry = countries.find(
        (c) => c.code === code
      );
      if (!initialCountry) {
        initialCountry = countries[0];
      }
      setSelectedCountry({
        value: initialCountry.phone_code + initialCountry.code,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p style={{ display: "flex", alignItems: "center" }}>
              <Flags code={initialCountry.code} style={{ width: 22, marginRight: 10 }} />
            {/* </span> */}
              <span className="country_name">{initialCountry.name}</span>
            </p>
            <span>+{initialCountry.phone_code}</span>
          </div>
        ),
        data: { ...initialCountry, phone_code: String(initialCountry.phone_code) },
      });
      setPhoneNumber(phone || "");
    }
  }

  
  useEffect(() => {
    fetchCountryAndData(data, countries);
  }, [data, countries]);


  // Handle country selection change
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    const newPhoneNumber = `${selectedOption.data.phone_code}-${phoneNumber}-${selectedOption.data.code}`;
    formik.setFieldValue(name, newPhoneNumber);
  };

  // Handle phone number change
  const handlePhoneNumberChange = (e) => {
    // Get the value from the input
    const newNumber = e.target.value;

    // Filter out anything that is not a number using a regular expression
    const numericValue = newNumber.replace(/[^0-9]/g, "");

    // Update the phone number state with the filtered numeric value
    setPhoneNumber(numericValue);

    // Update the value in Formik if there is a selected country
    if (selectedCountry) {
      const newPhoneNumber = `${selectedCountry.data.phone_code}-${numericValue}-${selectedCountry.data.code}`;
      formik.setFieldValue(name, newPhoneNumber);
    }
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      border: isActive ? "1px solid #614fba" : "1px solid #ced4da",
      borderRight: "none",
      borderRadius: "4px 0 0 4px",
      minHeight: "40px",
      boxShadow: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginBottom: 0,
      " .country_name" : {
        display: "none"
      },
      " p": {
        marginBottom: 0
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1050,
      width: "250px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#614fba" : "white",
      color: "black",
      " p": {
        marginBottom: 0
      },
    }),
  };

  const countryOptions = countries.map((country) => ({
    value: country.phone_code + country.code,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <p style={{ display: "flex", alignItems: "center" }}>
          <Flags code={country.code} style={{ width: 22, marginRight: 10 }} />
        {/* </span> */}
          <span className="country_name">{country.name}</span>
        </p>
        <span>+{country.phone_code}</span>
      </div>
    ),
    data: { ...country, phone_code: String(country.phone_code) },
  }));

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Select
        options={countryOptions}
        value={
            countryOptions.filter((co) => co?.value === selectedCountry?.value)
        }
        onChange={handleCountryChange}
        styles={customSelectStyles}
        isSearchable
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        filterOption={(option, inputValue) => {
          return option.data.data.name
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          option?.data?.data?.phone_code?.includes(inputValue.replace(/^\+/, ""));
        }
        }
        // menuIsOpen={true}
        // formatOptionLabel={(option) => (
        //   <div style={{ display: "flex", alignItems: "center" }}>
        //     <Flags
        //       code={option.data.code}
        //       style={{ width: 20, marginRight: 10 }}
        //     />
        //     +{option.data.phone_code}
        //   </div>
        // )}
      />

      <input
        type="tel"
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        style={{
          flex: 1,
          padding: "10px",
          fontSize: "16px",
          border: isActive ? "1px solid #614fba" : "1px solid #ced4da",
          borderLeft: "none",
          borderRadius: "0 4px 4px 0",
          outline: "none",
          height: "40px",
        }}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
      />
    </div>
  );
};

export default PhoneInput;

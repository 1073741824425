import React, { useState } from 'react';
import { Card, Modal } from 'react-bootstrap';

export const WhatsappSticker = ({
  showStickerList,
  setShowStickerList,
  getSticker,
  handleHideSticker,
  locale,
}) => {
  const [stickers] = useState([
    '001-snowman.png',
    '002-cookies.png',
    '003-merry-christmas.png',
    '004-merry-christmas.png',
    '005-elf.png',
    '006-cat.png',
    '007-holly.png',
    '008-holly.png',
    '009-santa-claus.png',
    '010-hot-chocolate.png',
    '011-reindeer.png',
    '012-snow.png',
    '013-merry-christmas.png',
    '014-christmas-tree.png',
    '015-ho-ho-ho.png',
    '016-mistletoe.png',
    '017-merry-christmas.png',
    '018-ho-ho-ho.png',
    '019-christmas-sock.png',
    '020-cookie.png',
  ]);

  /********/
  return (
    <Modal
      className={'common modalXl'}
      backdrop='static'
      keyboard={false}
      show={showStickerList}
      onHide={handleHideSticker}
    >
      <Modal.Header
        closeVariant='white'
        closeButton
        className={'bg-primary'}
      >
        <Modal.Title className={'text-white secondary-font text-uppercase'}>
          {locale('Stickers')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className='eb-popup-body'>
          <Card.Body className='eb-image-gallery'>
            <ul>
              {stickers &&
                stickers.map((item, idx) => (
                  <li
                    key={idx}
                    className='text-center'
                    onClick={() =>
                      getSticker(
                        require('../../../Assets/Images/stickers/' + item)
                      )
                    }
                  >
                    <img
                      src={require('../../../Assets/Images/stickers/' + item)}
                      height='60'
                      alt={item}
                    />
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

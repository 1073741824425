import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import {
  currentTeamId,
  jsonToArray,
  tableAttributeFor,
  toWordCase,
} from "../../../Utils/utils";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation } from "@apollo/client";
import { TAX_LIST } from "../../../GraphApi/Tax";
import { NewServiceItem } from "./NewServiceItem";
import { ConditionDivider } from "../../Common/Helper/CommonHelper";
import ImportWrapper from "../../Services/Offerings/ImportWrapper";
import { GET_LIST_PAGE_DATA } from "../../../GraphApi/ListingPage";
import { API_KEY_CREATE } from "../../../GraphApi/ApiList";
import { GenerateKey } from "../Settings/API/GenerateKey";

export const ServiceCard = ({
  index,
  type,
  currency = "$",
  currentCompanySetting,
  services = [],
  setServices = () => {},
  errors,
  setErrors = () => {},
  handleAlertMessage = () => {},
}) => {
  const serviceItem = {
    name: "",
    description: null,
    product_type: type,
    type: null,
    price: null,
    cost: null,
    stock: 0,
    tax_applicable: "false",
    tasks: [{ title: "", description: "", duration: "" }],
  };

  const { t: locale } = useTranslation("common");
  const [isCreateAble, setIsCreateAble] = useState(false);
  // const [services, setServices] = useState([serviceItem]);
  const [taxList, setTaxList] = useState(null);
  const paymentType = [
    { value: "one_time", label: locale("fixed") },
    { value: "recurring", label: locale("hourly") },
  ];

  const [getTaxList, { data: allTaxes }] = useLazyQuery(TAX_LIST);
  const [showImports, setShowImports] = useState(false);
  const [mappingFields, setMappingFields] = useState([]);
  const [showGenerateKey, setShowGenerateKey] = useState(false);
  const handleCloseGenerateKey = () => setShowGenerateKey(false);
  const handleShowGenerateKey = () => setShowGenerateKey(true);

  const [createApiKey] = useMutation(API_KEY_CREATE);
  const [getRequireData, { data: listingPageData, loading: dataLoading }] =
    useLazyQuery(GET_LIST_PAGE_DATA, {
      variables: {
        module: "services_listing",
        page: "services_listing",
        source: false,
        tags: false,
        leadOwner: false,
        tableColumns: true,
        userFilters: false,
        tax: true,
        category: false,
        team_id: currentTeamId,
      },
    });

  useEffect(() => {
    if (services?.length === 0) {
      setServices([serviceItem]);
    }
  }, [services]);

  useEffect(() => {
    getRequireData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamId]);

  useEffect(() => {
    if (listingPageData?.tableAttributes) {
      const columns = jsonToArray(
        JSON.parse(listingPageData.tableAttributes.columns)
      );

      const myMappingFields =
        columns.mapping && columns.mapping.length > 0
          ? columns.mapping.map(
              (item) =>
                item && {
                  ...item,
                  label:
                    item.group !== "custom"
                      ? locale(item.label.toLowerCase())
                      : item.label,
                  module:
                    tableAttributeFor[listingPageData.tableAttributes.page],
                }
            )
          : [];
      setMappingFields(myMappingFields);
    }
    if (listingPageData?.allTaxes?.length > 0) {
      const taxList = listingPageData.allTaxes.map(
        (item) =>
          item && {
            id: item.id,
            label: item.name,
            value: item.name,
            taxValue: item.tax_value,
            type: item.type,
          }
      );
      setTaxList(taxList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingPageData]);

  const handleAdd = (type) => {
    setIsCreateAble(true);
  };

  const addService = () => {
    const data = services?.length > 0 ? services : [];
    data.push(serviceItem);
    setServices([...services]);
  };

  const removeService = (serviceIndex) => {
    const data = services?.length > 0 ? services : [];
    data.splice(serviceIndex, 1);
    setServices([...data]);
  };

  const handleData = (serviceIndex, name, value) => {
    const copyServiceData = [...services];
    let newData = [];
    copyServiceData.forEach((item, i) => {
      if (i === serviceIndex) {
        if (name === "tax_applicable" && value === false) {
          item = { ...item, tax: 0, [name]: value };
        } else {
          item = { ...item, [name]: value };
        }
        item = { ...item, line_price_total: handleLineTotal(item) };
        newData.push({ ...item });
      } else {
        newData.push({ ...item });
      }
    });

    setServices([...newData]);
  };

  const handleLineTotal = (lineItem) => {
    let lineTotal = 0;
    lineTotal = lineItem.unit_price * lineItem.quantity;
    lineTotal =
      lineItem.tax_applicable && lineItem.tax
        ? parseFloat(lineTotal) + parseFloat((lineTotal * lineItem.tax) / 100)
        : parseFloat(lineTotal);

    return parseFloat(lineTotal.toFixed(2));
  };

  const handleImport = () => {
    setShowImports(true);
  };

  /******/
  return (
    <React.Fragment>
      <Card>
        <Card.Body className="px-1 py-3">
          <div className="d-flex justify-content-between mb-2">
            <h4 className="text-primary mt-0">Add {toWordCase(type)}s</h4>
            {isCreateAble && (
              <div>
                <Button
                  variant="primary"
                  className="mx-1"
                  onClick={handleImport}
                >
                  Import Via CSV
                </Button>

                <Button
                  variant="primary"
                  className="mx-1"
                  onClick={handleShowGenerateKey}
                >
                  Import Via API
                </Button>
              </div>
            )}
          </div>
          {!isCreateAble ? (
            <div className="d-flex justify-content-center">
              <div>
                <Button
                  variant="primary"
                  className="mx-1"
                  onClick={() => handleAdd(type)}
                >
                  Add Manually
                </Button>
                <p className="text-small text-center">Recommended</p>
              </div>
              <div>
                <Button
                  variant="primary"
                  className="mx-1"
                  onClick={handleImport}
                >
                  Import Via CSV
                </Button>
              </div>
              <div>
                <Button
                  variant="primary"
                  className="mx-1"
                  onClick={handleShowGenerateKey}
                >
                  Import Via API
                </Button>
              </div>
            </div>
          ) : (
            <React.Fragment>
              {services.length > 0 &&
                services.map((item, idx) => (
                  <React.Fragment key={idx}>
                    <Row key={idx}>
                      <Col>
                        <NewServiceItem
                          serviceIndex={idx}
                          offeringType={type}
                          key={idx}
                          service={item}
                          addService={() => addService(idx)}
                          removeService={() => removeService(idx)}
                          paymentType={paymentType}
                          handleData={(name, value) =>
                            handleData(idx, name, value)
                          }
                          errors={
                            errors?.length > 0 && errors[idx]
                              ? errors[idx]
                              : null
                          }
                          currency={currency}
                          currentCompanySetting={currentCompanySetting}
                        />
                      </Col>
                      <Col xs={"auto"} className="mx-0">
                        <div
                          style={{
                            position: "relative",
                            height: "100%",
                            width: "20px",
                          }}
                        >
                          {services.length > 1 && (
                            <i
                              onClick={() => removeService(idx)}
                              className="ri-close-circle-line fs-5 text-danger"
                              style={{ position: "absolute", top: "10px" }}
                            />
                          )}
                          <br />
                          {idx + 1 === services.length && (
                            <i
                              onClick={addService}
                              className="ri-add-circle-line fs-5 text-success"
                              style={{ position: "absolute", bottom: "10px" }}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <ConditionDivider text="" className={"mx-0 my-4"} />
                  </React.Fragment>
                ))}
            </React.Fragment>
          )}
        </Card.Body>
      </Card>
      <Modal
        className={"common modalLg"}
        show={showImports}
        backdrop="static"
        keyboard={false}
        onHide={() => setShowImports(false)}
      >
        <ImportWrapper
          module="services"
          showImports={showImports}
          mappingFields={mappingFields}
          setMappingFields={setMappingFields}
          setShowImports={setShowImports}
        />
      </Modal>
      <Modal
        className={"common modalLg"}
        show={showGenerateKey}
        onHide={handleCloseGenerateKey}
        backdrop={"static"}
        keyboard={false}
      >
        <GenerateKey
          handleCloseAddContact={handleCloseGenerateKey}
          locale={locale}
          createApiKey={createApiKey}
          handleAlertMessage={handleAlertMessage}
          refetchKeyList={() => {}}
        />
      </Modal>
    </React.Fragment>
  );
};

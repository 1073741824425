export const PlusButton = () => {
  return (
    <svg
      className="m-0"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C5.832 0 0 5.832 0 13C0 20.168 5.832 26 13 26C20.168 26 26 20.168 26 13C26 5.832 20.168 0 13 0ZM13 2C19.087 2 24 6.913 24 13C24 19.087 19.087 24 13 24C6.913 24 2 19.087 2 13C2 6.913 6.913 2 13 2ZM12 7V12H7V14H12V19H14V14H19V12H14V7H12Z"
        fill="#6E5DC6"
      />
    </svg>
  );
};

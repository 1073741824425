import { gql } from "@apollo/client";

/** Campaign listing **/
export const TEAM_EMAIL_LISTING = gql`
  query teamUserEmailListing($team_id: Int) {
    teamUserEmailListing(team_id: $team_id) {
      name
      email
      feedback_enabled
      email_verified_at
    }
  }
`;

export const CREATE_TEAM_EMAIL = gql`
  mutation createTeamUserEmail(
    $team_id: Int
    $name: String
    $email: String
    $feedback_enabled: Boolean
  ) {
    createTeamUserEmail(
      team_id: $team_id
      name: $name
      email: $email
      feedback_enabled: $feedback_enabled
    ) {
      name
      email
      feedback_enabled
      email_verified_at
    }
  }
`;

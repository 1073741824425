import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { nonGroupColourStyles } from "../../../Components/Common/DropDown/Style";

export const SelectNumberFormat = ({
  label = "Set Number Format",
  showLabel = true,
  iconClass = "ri-list-ordered-2",
  value = "",
  getNumberFormat = () => {},
  locale,
}) => {
  const [numberFormat] = useState([
    { value: "1,00,000", label: "1,00,000" },
    { value: "100000", label: "100000" },
    { value: "1 00 000", label: "1 00 000" },
  ]);

  return (
    <Form.Group className="form-group">
      {showLabel ? (
        <Form.Label>{locale("pages:setting.common." + label)}</Form.Label>
      ) : (
        ""
      )}

      <InputGroup>
        <InputGroup.Text>
          <i className={iconClass}></i>
        </InputGroup.Text>
        <Select
          options={numberFormat}
          placeholder={locale("Number Format")}
          value={
            numberFormat &&
            numberFormat.filter((item) => item.value === value)[0]
          }
          onChange={(e) => {
            getNumberFormat(e);
          }}
          styles={nonGroupColourStyles}
        />
      </InputGroup>
    </Form.Group>
  );
};

import { gql } from "@apollo/client";

/** Product listing **/
export const PRODUCT_LIST = gql`
  query productSearchFilter(
    $first: Int!
    $page: Int
    $productType: [String]!
    $search: String
    $field: String!
    $order: SortOrder!
    $filter: [CommonFilter!]
    $team_id: Int
  ) {
    productSearchFilter(
      first: $first
      page: $page
      productType: $productType
      search: $search
      orderBy: [{ column: $field, order: $order }]
      filter: $filter
      team_id: $team_id
    ) {
      data {
        id
        # product_id
        product_type
        type
        validity
        auto_charge
        validity_type
        sku
        variation_id
        name
        price
        sale_price
        slug
        description
        image
        images {
          id
          name
          path: url
          defaults
          source
        }
        bought_customer_count
        downloadable
        tax_applicable
        tax_class_id
        stock
        last_order_date
        units_sold
        tax
        category
        sub_category
        category_products {
          id
          name
          parent
        }
        date_created
        created_at
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;

/** Product Preview **/
export const PRODUCT_PREVIEW = gql`
  query productPreview($id: ID, $team_id: Int) {
    productDetail(id: $id, team_id: $team_id) {
      id
      # product_id
      product_type
      type
      validity
      auto_charge
      validity_type
      sku
      slug
      variation_id
      name
      price
      sale_price
      description
      images {
        id
        name
        path: url
        defaults
        source
      }
      image
      bought_customer_count
      downloadable
      tax_applicable
      tax_class_id
      stock
      last_order_date
      units_sold
      tax
      category
      sub_category
      selected_tax {
        id
        taxes_id
        name
        type
        tax_value
        class
      }
      category_products {
        id
        # category_id
        name
        slug
        parent
        description
      }
      date_created
      digital_files {
        download_limit
        title
        path
      }
      custom_attributes {
        id
        name
      }
    }
  }
`;
/*** Export Product ***/
export const PRODUCT_REMOVE = gql`
  mutation removeProducts(
    $type: String
    $unSelected: [ProductIds!]
    $selected: [ProductIds!]
    $search: String
    $filter: [CommonFilter!]
    $team_id: Int
  ) {
    removeSelectedProducts(
      type: $type
      unchecked_product_ids: $unSelected
      checked_product_ids: $selected
      search: $search
      filter: $filter
      team_id: $team_id
    ) {
      status
    }
  }
`;

/** Product listing **/
export const PRODUCT_ALL = gql`
  query allProducts($productType: [String]!, $search: String, $team_id: Int) {
    allProducts(productType: $productType, search: $search, team_id: $team_id) {
      id
      product_id
      name
      images {
        url
      }
      price
      type
      product_type
      description
      tax_applicable
      tax
      cost
      service_task_items {
        product_item_id
        product_id
        title
        description
        duration
      }
    }
  }
`;
/** Product Preview **/
export const ADD_PRODUCT = gql`
  mutation createProduct(
    $product_type: String
    $type: String
    $validity_type: String
    $auto_charge: Boolean
    $sku: String
    $name: String
    $price: Float
    $sale_price: Float
    $url: String
    $description: String
    $images: [Upload]
    $defaultImage: Int
    $downloadable: Boolean
    $download_limit: Int
    $downloadable_name: String
    $downloadable_url: String
    $downloadable_file: Upload
    $tax_applicable: Boolean
    $tax_class_id: String
    $category_products: [CategoryProductInput]
    $team_id: Int
  ) {
    createProduct(
      product_type: $product_type
      type: $type
      validity_type: $validity_type
      auto_charge: $auto_charge
      sku: $sku
      name: $name
      price: $price
      sale_price: $sale_price
      url: $url
      description: $description
      images: $images
      defaultImage: $defaultImage
      downloadable: $downloadable
      download_limit: $download_limit
      downloadable_name: $downloadable_name
      downloadable_url: $downloadable_url
      downloadable_file: $downloadable_file
      tax_applicable: $tax_applicable
      tax_class_id: $tax_class_id
      category_products: $category_products
      variation_id: 0
      team_id: $team_id
    ) {
      id
      team_id
      # product_id
      type
      name
      price
      digital_files {
        download_limit
        title
        path
      }
    }
  }
`;
/** Product Preview **/
export const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $team_id: Int
    $id: ID
    $product_type: String
    $type: String
    $validity_type: String
    $auto_charge: Boolean
    $sku: String
    $name: String
    $price: Float
    $sale_price: Float
    $url: String
    $description: String
    $downloadable: Boolean
    $download_limit: Int
    $downloadable_name: String
    $downloadable_url: String
    $downloadable_file: Upload
    $tax_applicable: Boolean
    $tax_class_id: String
    $category_products: [CategoryProductInput]
    $remove_categories: [RemoveCategoriesInput]
  ) {
    updateProduct(
      team_id: $team_id
      id: $id
      product_type: $product_type
      type: $type
      validity_type: $validity_type
      auto_charge: $auto_charge
      sku: $sku
      name: $name
      price: $price
      sale_price: $sale_price
      url: $url
      description: $description
      downloadable: $downloadable
      download_limit: $download_limit
      downloadable_name: $downloadable_name
      downloadable_url: $downloadable_url
      downloadable_file: $downloadable_file
      tax_applicable: $tax_applicable
      tax_class_id: $tax_class_id
      category_products: $category_products
      remove_categories: $remove_categories
    ) {
      id
    }
  }
`;
/** Category listing **/
export const CATEGORY_ALL = gql`
  query allCategories($team_id: Int) {
    allCategories(team_id: $team_id) {
      id
      name
      slug
      parent
    }
  }
`;
/*** Remove Image ***/
export const REMOVE_IMAGE = gql`
  mutation removeProductImage(
    $team_id: Int
    $id: Int
    $create: [Upload]
    $delete: [DeleteProductImagesInput]
  ) {
    createDeleteProductImages(
      id: $id
      create: $create
      delete: $delete
      team_id: $team_id
    ) {
      id
    }
  }
`;

import { gql } from "@apollo/client";

/** THIS Query Fetch "CONTACT_SOURCE, TAGS, OWNER, USER_FILTER" data*/
export const GET_LIST_PAGE_DATA = gql`
  query getPageData(
    $team_id: Int
    $module: String
    $page: ID
    $source: Boolean!
    $tags: Boolean!
    $leadOwner: Boolean!
    $tableColumns: Boolean!
    $userFilters: Boolean!
    $tax: Boolean!
    $category: Boolean!
  ) {
    allSource(team_id: $team_id) @include(if: $source) {
      id
      name
    }
    allUsers(team_id: $team_id) @include(if: $leadOwner) {
      id
      name
    }
    allTag(team_id: $team_id) @include(if: $tags) {
      id
      name
    }
    allTaxes(team_id: $team_id) @include(if: $tax) {
      id
      taxes_id
      name
      type
      tax_value
      class
      created_at
      updated_at
    }
    tableAttributes(page: $page, team_id: $team_id)
      @include(if: $tableColumns) {
      page
      columns
      import
    }
    preferred(page: $page, team_id: $team_id) @include(if: $tableColumns) {
      id
      team_id
      page
      columns
      created_at
      updated_at
    }
    userFilters(module: $module, team_id: $team_id) @include(if: $userFilters) {
      id
      user_id
      name
      module
      user_filter_lines {
        id
        user_filter_id
        filter_type
        attribute
        operator
        attribute_id
        attribute_value
        first_value
        second_value
        condition
      }
    }
    allCategories(team_id: $team_id) @include(if: $category) {
      id
      name
      slug
      parent
    }
  }
`;

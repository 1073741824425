import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { nonGroupColourStyles } from "../DropDown/Style";
import { useTranslation } from "react-i18next";
import { PipelineContext } from "../../../Context/PipelineContext";
import Session from "../../../Utils/Session";
import { AuthContext } from "../../../Context/AuthContext";
import { isObjectEmpty, toWordCase } from "../../../Utils/utils";

const PipelineDropdown = ({
  pipelineValue = null,
  setPipelineValue = () => {},
  stageId = null,
  pageParams = null,
  isDefaultRequired = false,
  getPipelines = () => {},
  isPipelineUpdated = false,
  setIsPipelineUpdated = () => {},
  style = {},
}) => {
  const { userData } = useContext(AuthContext);
  const { t: locale } = useTranslation(["common", "pages"]);
  const [pipelines, setPipelines] = useState([]);
  const [pipeline, setPipeline] = useState(pipelineValue);

  const { pipelineData, getPipelines: fetchPipeline } =
    useContext(PipelineContext);

  const checkPresetPipeline = () => {
    if (userData && userData.authData) {
      const myVal = window.encodeURIComponent(
        window.btoa("pipeline-" + userData.authData.id)
      );
      const myId = Session.getSessionVar(myVal);
      if (myId) {
        return window.atob(window.decodeURIComponent(myId));
      }
    }
    return false;
  };
  /*** CALL PIPELINE LIST ***/
  useEffect(() => {
    const pipelineID = checkPresetPipeline();
    if (
      pipelines &&
      pipelines.length > 0 &&
      userData &&
      userData.authData &&
      isObjectEmpty(pipelineValue)
    ) {
      if (stageId && stageId.value) {
        pipelines.forEach((item) => {
          item.stages.forEach((defaultStage) => {
            if (parseInt(defaultStage.id) === parseInt(stageId.value)) {
              setPipeline(item.id);
              setPipelineValue(item);
            }
          });
        });
      } else if (!isObjectEmpty(pipelineID) && pipelineID) {
        // console.log("HereA::", pipelineID);
        pipelines.forEach((item) => {
          if (parseInt(pipelineID) === parseInt(item.id)) {
            setPipeline(item.id);
            setPipelineValue(item);
          }
        });
      } else if (isDefaultRequired && isObjectEmpty(pipelineValue)) {
        pipelines.forEach((item) => {
          if (item.default_pipeline) {
            setPipeline(item.id);
            setPipelineValue(item);
          }
        });
      } else if (pipelineValue && pipelineValue.id) {
        pipelines.forEach((item) => {
          if (parseInt(pipelineValue.id) === parseInt(item.id)) {
            setPipeline(item.id);
            setPipelineValue(item);
          }
        });
      }
    } else if (pipelineValue && pipelineValue.id) {
      pipelines.forEach((item) => {
        if (parseInt(pipelineValue.id) === parseInt(item.id)) {
          setPipeline(item.id);
          setPipelineValue(item);
        }
      });
    } else if (isDefaultRequired && isObjectEmpty(pipelineValue)) {
      pipelines.forEach((item) => {
        if (item.default_pipeline) {
          setPipeline(item.id);
          setPipelineValue(item);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageId, pipelineValue, pipelines, userData]);

  /*** Get ALl Pipeline with Stages ***/
  useEffect(() => {
    if (pipelineData && pipelineData.pipelines !== null) {
      const allPipelines = [];
      pipelineData.pipelines &&
        pipelineData.pipelines.length &&
        pipelineData.pipelines.forEach((item) => {
          /**** Format Stages Values ***/
          const formattedStages = [];
          item.stages.forEach((defaultStage) => {
            if (defaultStage) {
              formattedStages.push({
                label: defaultStage.label,
                value: defaultStage.id,
                category: defaultStage.category,
                created_at: defaultStage.created_at,
                id: defaultStage.id,
                name:
                  defaultStage.name === "Completed" ? "Won" : defaultStage.name,
                probability: defaultStage.probability,
                team_id: defaultStage.team_id,
                updated_at: defaultStage.updated_at,
                sort_order: defaultStage.sort_order,
                stage_id: defaultStage.stage_id,
              });
            }
          });
          /****/
          allPipelines.push({
            ...item,
            stages: formattedStages,
            value: item.id,
            label: toWordCase(item.name),
          });
          /******/
        });

      setPipelines([...allPipelines]);
      getPipelines([...allPipelines]);
    } else if (
      pipelineData.pipelines === null &&
      !pipelineData.isFetching &&
      pipelineData.error === null
    ) {
      setTimeout(() => {
        fetchPipeline();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineData]);

  /** Refetch Pipeline On Update **/
  useEffect(() => {
    if (isPipelineUpdated) {
      fetchPipeline();
      setIsPipelineUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPipelineUpdated]);

  /*** Render HTML ****/
  return (
    <React.Fragment>
      <Select
        isClearable={false}
        isSearchable={false}
        placeholder={locale("pipeline")}
        noOptionsMessage={() => locale("no pipeline")}
        options={pipelines}
        styles={{
          ...nonGroupColourStyles,
          ...style,
        }}
        value={pipelines.filter(
          (item) => parseInt(item.id) === parseInt(pipeline)
        )}
        onChange={(e) => {
          setPipelineValue(e);
          setPipeline(e.value);
        }}
      />
    </React.Fragment>
  );
};

export default PipelineDropdown;

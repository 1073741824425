export const preDefinedColumns = {
  entity: "Contact",
  data: [
    { value: "1", label: "First Name", checked: true },
    { value: "2", label: "Last Name", checked: true },
    { value: "3", label: "Email", checked: true },
    { value: "4", label: "Phone Number", checked: true },
    { value: "5", label: "Last Active", checked: true },
    { value: "6", label: "Creation Date", checked: true },
  ],
};

export const prefferedColumn = {
  id: 1,
  user_id: 1,
  team_id: 1,
  page: "Contact",
  columns: [1, 2, 3, 4, 6, 5],
};

export const mappingFields = [
  { value: "first_name", label: "First Name" },
  { value: "last_name", label: "Last Name" },
  { value: "email", label: "Email" },
  { value: "phone_number", label: "Phone Number" },
  { value: "last_active", label: "Last Active" },
];
export const importData = [
  { value: "first_name", label: "Vivek" },
  { value: "last_name", label: "Sharma" },
  { value: "email", label: "Email" },
  { value: "phone", label: "Phone" },
];

export const customAttribute = [
  { value: "text", label: "Text" },
  { value: "integer", label: "Integer" },
  { value: "decimal", label: "Decimal" },
  { value: "date", label: "Date" },
  { value: "time", label: "Time" },
  { value: "percentage", label: "Percentage" },
  { value: "email", label: "Email" },
  { value: "phone", label: "Phone" },
  { value: "url", label: "Url" },
  { value: "textarea", label: "Textarea" },
  { value: "dropdown", label: "Dropdown" },
  { value: "checkbox", label: "Checkbox" },
  { value: "radio_button", label: "Radio" },
];

export const tableColumns = [
  { value: "first_name", label: "First Name" },
  { value: "last_name", label: "Last Name" },
  { value: "email", label: "Email" },
  { value: "stage", label: "Stage" },
  { value: "score", label: "Score" },
];

export const FilterType = [
  /**Contacts**/
  {
    filter: "visited_landing_pages",
    type: "dropdownFirst",
    operator: true,
  },
  { filter: "submitted_form", type: "dropdownFirst", operator: true },
  { filter: "email_sent", type: "dropdownFirst", operator: true },
  { filter: "email_clicked", type: "dropdownFirst", operator: true },
  { filter: "email_bounced", type: "dropdownFirst", operator: true },
  { filter: "email_opened", type: "dropdownFirst", operator: true },
  { filter: "email_delivered", type: "dropdownFirst", operator: true },
  {
    filter: "email_unsubscribed",
    type: "dropdownFirst",
    operator: true,
  },
  { filter: "email_spammed", type: "dropdownFirst", operator: true },
  { filter: "deal_created", type: "dateNumber", operator: true },
  { filter: "deal_stage_updated", type: "dateNumber", operator: true },
  { filter: "service_interested", type: "dateNumber", operator: true },
  { filter: "created", type: "dateNumber", operator: true },
  { filter: "payment_due", type: "dateNumber", operator: true },
  { filter: "subscription_expired", type: "dateNumber", operator: true },
  { filter: "product_purchased", type: "dropdown", operator: true },
  { filter: "product_interested", type: "dropdown", operator: true },
  { filter: "coupon_created", type: "dateNumber", operator: true },
  { filter: "coupon_used", type: "dateNumber", operator: true },
  { filter: "expires", type: "dateNumber", operator: true },
  { filter: "received_payment", type: "dateNumber", operator: true },
  { filter: "project_started_date", type: "dateNumber", operator: true },
  { filter: "new_subscriber", type: "dateNumber", operator: true },
  { filter: "subscription_expire", type: "dateNumber", operator: true },
  { filter: "order_received", type: "dateNumber", operator: true },
  { filter: "deal_stage_updated", type: "dateNumber", operator: true },
  { filter: "order_date", type: "dateNumber", operator: true },
  { filter: "invoice_sent", type: "dateNumber", operator: true },
  { filter: "quote_sent", type: "dateNumber", operator: true },
  {
    filter: "appointment_scheduled",
    type: "dateNumber",
    operator: true,
  },
  { filter: "last_contacted", type: "dateNumber", operator: true },
  { filter: "created_at", type: "dateNumber", operator: true },
  { filter: "date_of_birth", type: "date", operator: true },
  { filter: "score", type: "number", operator: true },
  { filter: "deal_amount", type: "number", operator: true },
  { filter: "order_price", type: "number", operator: true },
  { filter: "order_status", type: "dropdownFirst", operator: true },
  { filter: "stage", type: "dropdownFirst", operator: false },
  { filter: "source", type: "dropdownFirst", operator: false },
  { filter: "lead_owner", type: "dropdown", operator: true },
  { filter: "in_campaign", type: "dropdown", operator: true },
  { filter: "associated_campaign", type: "dropdown", operator: true },
  { filter: "tag_name", type: "dropdown", operator: true },
];

import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";

import { nonGroupColourStyles } from "../../../Components/Common/DropDown/Style";
import { getTimeZOneList } from "../../../Utils/EqupTimezone";

export const SelectTimezone = ({
  label = "Time Zone",
  showLabel = true,
  iconClass = "ri-time-fill",
  value = "",
  getTimezoneFormat = () => {},
  locale,
}) => {
  const [timezonesData, setTimezonesData] = useState([]);

  useEffect(() => {
    const tzObj = getTimeZOneList().map(
      (item) => item && { value: item, label: item }
    );
    setTimezonesData(tzObj);
  }, []);

  /******/
  return (
    <Form.Group className="form-group">
      {showLabel ? (
        <Form.Label>{locale("pages:setting.common." + label)}</Form.Label>
      ) : (
        ""
      )}
      <InputGroup>
        <InputGroup.Text>
          <i className={iconClass}></i>
        </InputGroup.Text>
        <Select
          options={timezonesData}
          placeholder={locale("pages:setting.common." + label)}
          value={
            timezonesData &&
            timezonesData.filter((item) => item.value === value)[0]
          }
          onChange={(e) => {
            getTimezoneFormat(e);
          }}
          styles={nonGroupColourStyles}
        />
      </InputGroup>
    </Form.Group>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import "../../../../../Pages/Automation/Elements/Settings/Actions/email-action.css";
import {
  ConditionDivider,
  emailSignatureString,
} from "../../../../../Components/Common/Helper/CommonHelper";
import { TextAlignButtons } from "../../../../../Components/Common/CommonControl/TextAlignButtons";
import { SelectDropdown } from "../../../../../Components/Common/Conditions/SelectDropdown";
import { useLazyQuery } from "@apollo/client";
import {
  APP_URL,
  currentTeamId,
  FORM_URL,
  getNumberOfRecordToBeShow,
  isObjectEmpty,
  toWordCase,
} from "../../../../../Utils/utils";
import { ColorPickerDropdown } from "../../../../../Components/Common/CommonControl/ColorPickerDropdown";
import { FORM_LIST } from "../../../../../GraphApi/Forms";
import { getBookingPreferenceListing } from "../../../../../Components/Pages/Services/BookingWizard/BookingHelper";
import { LEAD_OWNER_LIST } from "../../../../../GraphApi/LeadOwner";
import { MailMerge } from "../../../../../Components/Common/Editor/Component/NewComponents/MailMerge";
import { useTranslation } from "react-i18next";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { ImageGalleryWithModal } from "../../../../../Components/Common/ImageGallery/ImageGalleryWithModal";
import SlateWrapperA from "../../../../../Components/Common/SlateEditor/FixedSlateEditor";
import { deserialize } from "../../../../../Components/Common/SlateEditor/Utils/slateUtils";

const defaultAction = {
  appointment: "send_appointments",
  form: "send_form",
  booking: "send_service_request_form",
};
const defaultContent = {
  appointment: {
    heading:
      '<h3 style="color:#614fba; text-align:center">Schedule Appointment<h3>',
    footer:
      "<p>Thank you, and I look forward to speaking with you soon!</p><p>&nbsp;</p>",
    button: "Schedule Appointment",
    subject: "Schedule Appointment at Your Convenience",
    message:
      "<p>Hello {contact_name},</p><p>&nbsp;</p><p>I hope this message finds you well! I would like to schedule an appointment to discuss [briefly state the purpose, e.g., your recent inquiry, project updates, etc.].</p><p>&nbsp;</p><p>Please click on the link below to choose a convenient time from my calendar:</p><p>&nbsp;</p>",
  },
  form: {
    heading: '<h3 style="color:#614fba; text-align:center">Submit Form</h3>',
    footer:
      "<p>This information will help us ensure everything is accurate and up-to-date, allowing us to serve you more effectively.<p><p>&nbsp;</p>",
    button: "Submit Form",
    subject: "Action Needed: Please Submit the Required Form",
    message:
      "<p>Hello {contact_name},</p><p>&nbsp;</p><p>I hope this message finds you well! As part of [mention any specific purpose], we kindly ask that you complete and submit the form linked below.</p><p>&nbsp;</p><p>&nbsp;</p>",
  },
  booking: {
    heading: '<h3 style="color:#614fba; text-align:center">Book Services</h3>',
    footer:
      "<p>Thank you, and we look forward to working with you!<p><p>&nbsp;</p>",
    button: "Book Your Service Now",
    subject: "Book Your Service with Our Service Request Form",
    message:
      "<p>Hello {contact_name},</p><p>&nbsp;</p><p>This information will allow us to understand your needs better and prepare to deliver an efficient and tailored service experience. Please submit the form at your earliest convenience to secure your booking.</p><p>&nbsp;</p><p>Thank you, and we look forward to working with you!</p><p>&nbsp;</p>",
  },
};

export const EmailAction = ({
  handleAlertMessage = () => {},
  defaultButton = null,
  currentCompanySetting,
  userData,
  automationId = null,
  values,
  handleChange = () => {},
  handleClose = () => {},
}) => {
  const { t: locale } = useTranslation();
  const [emailDetails, setEmailDetails] = useState({
    subject: "",
    heading: "",
    logo: { url: null, align: "left" },
    message: "",
    footer: null,
    cta: {
      type: "",
      label: "",
      align: "center",
      color: "#fff",
      bgColor: "#614fba",
      width: "auto",
      url: "https://www.example.com",
    },
  });
  const [buttonTypes] = useState([
    { name: "appointment", label: "Appointment" },
    { name: "form", label: "Form" },
    { name: "booking", label: "Service Request Form" },
  ]);
  const [widthOptions] = useState([
    { value: "auto", label: "Auto" },
    { value: "100%", label: "Full Width" },
    { value: "50%", label: "50%" },
  ]);
  const [bookings, setBookings] = useState([]);
  const [forms, setForms] = useState([]);
  const [contactOwners, setContactOwners] = useState([]);
  const [errorBag, setErrorBag] = useState(null);
  const [showImageGallery, setShowImageGallery] = useState(false);
  /** Fetch FORM List **/
  const [getFormList, { data: formsList }] = useLazyQuery(FORM_LIST);
  const [getAllLeadOwner] = useLazyQuery(LEAD_OWNER_LIST);
  /** FETCHING LIST OF FORMS **/
  const fetchFormsList = () => {
    getFormList({
      variables: { form_type: "system", team_id: currentTeamId },
      fetchPolicy: "network-only",
    });
  };

  const fetchBookingList = () => {
    getBookingPreferenceListing({
      first: getNumberOfRecordToBeShow("Booking Preference"),
      page: 1,
      filter: [],
      search: "",
      field: "created_at",
      order: "DESC",
      pipelineId: null,
      team_id: currentTeamId,
    })
      .then((result) => {
        if (result.status) {
          const data = result.data;
          setBookings(
            data.data.map(
              (item) =>
                item && {
                  id: item.booking_availability_id,
                  label: item.name,
                  value: APP_URL + "/bookings/" + item.booking_availability_id,
                }
            )
          );
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        handleAlertMessage("error", error.message);
      });
  };

  const fetchLeadOwner = () => {
    getAllLeadOwner({
      variables: { team_id: currentTeamId, is_setup_calendar_scheduling: true },
      fetchPolicy: "network-only",
    })
      .then((result) => {
        const allLeadOwner = [];
        result?.data?.allUsers.forEach((item) => {
          if (item && item.booking_availability !== null) {
            allLeadOwner.push({
              value:
                APP_URL +
                "/schedule-appointment/" +
                item.booking_availability.booking_availability_id,
              label: item.name,
              booking_availability_id:
                item.booking_availability.booking_availability_id,
            });
          }
        });
        setContactOwners(allLeadOwner);
      })
      .catch((error) => {
        console.log("Error:", error);
        handleAlertMessage("error", error.message);
      });
  };

  useEffect(() => {
    if (formsList && formsList.getAllForms.length > 0) {
      setForms(
        formsList.getAllForms.map(
          (item) =>
            item && {
              id: item.id,
              value: "https://" + item.slug + "." + FORM_URL,
              label: item.name,
              name: item.name,
            }
        )
      );
    } else {
      setForms([]);
    }
  }, [formsList]);

  useEffect(() => {
    fetchFormsList();
    fetchBookingList();
    fetchLeadOwner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isObjectEmpty(values?.emailDetails) && defaultButton !== null) {
      setEmailDetails((prevState) => {
        return {
          ...prevState,
          subject: defaultContent[defaultButton].subject,
          message: defaultContent[defaultButton].message,
          heading: defaultContent[defaultButton].heading,
          cta: {
            ...emailDetails.cta,
            url: "",
            type: defaultButton,
            label: defaultContent[defaultButton].button,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultButton, values]);

  useEffect(() => {
    if (userData && emailDetails.footer === null) {
      setEmailDetails((prevState) => {
        return {
          ...prevState,
          footer:
            defaultContent[defaultButton].footer +
            emailSignatureString(userData, currentCompanySetting),
        };
      });
    }
  }, [userData, currentCompanySetting, defaultButton]);
  // Function to handle image file selection
  const handleImageUpload = (file) => {
    // const file = event.target.files[0];

    if (file) {
      setEmailDetails({
        ...emailDetails,
        logo: { ...emailDetails.logo, url: file },
      });
    }
    handelCloseImagePopup();
  };
  // Handle change in message body editor
  const handleEditorChange = (content) => {
    setEmailDetails({ ...emailDetails, message: content });
  };
  const handleFooterChange = (content) => {
    setEmailDetails({ ...emailDetails, footer: content });
  };
  const handleHeadingChange = (content) => {
    setEmailDetails({ ...emailDetails, heading: content });
  };

  const options = () => {
    if (emailDetails?.cta?.type === "appointment") {
      return contactOwners;
    } else if (emailDetails?.cta?.type === "booking") {
      return bookings;
    } else if (emailDetails?.cta?.type === "form") {
      return forms;
    }
  };

  const getMailMergeValue = (value) => {
    const input = document.getElementById("subject");
    const myValue = input.value.concat(value);
    input.value = myValue;
    setEmailDetails({ ...emailDetails, subject: myValue });
  };

  const handleButtonClick = (buttonType) => {
    if (buttonType)
      setEmailDetails((prevState) => {
        return {
          ...prevState,
          subject: defaultContent[buttonType].subject,
          message: defaultContent[buttonType].message,
          heading: defaultContent[buttonType].heading,
          cta: {
            ...emailDetails.cta,
            type: buttonType,
            label: defaultContent[buttonType].button,
          },
          footer:
            defaultContent[buttonType].footer +
            emailSignatureString(userData, currentCompanySetting),
        };
      });
  };

  const handleSaveData = () => {
    let newErrors = {};
    if (isObjectEmpty(emailDetails?.subject)) {
      newErrors = { ...newErrors, subject: "Subject is required" };
    }
    if (isObjectEmpty(emailDetails?.message)) {
      newErrors = { ...newErrors, message: "message is required" };
    }
    if (isObjectEmpty(emailDetails?.cta?.label)) {
      newErrors = { ...newErrors, label: "Button label is required" };
    }
    if (
      isObjectEmpty(emailDetails?.cta?.url) ||
      emailDetails?.cta?.url === "https://www.example.com"
    ) {
      newErrors = { ...newErrors, url: "Button url is required" };
    }
    setErrorBag(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let str = [];
      str.push(<span key="1">Send </span>);
      str.push(
        <span key="2" className="text-primary">
          {emailDetails.subject}{" "}
        </span>
      );
      str.push(<span key="3"> Email</span>);
      const emailPreview = document.getElementById("emailPreview");
      handleChange("action", {
        action: defaultAction[defaultButton],
        email_details: {
          ...emailDetails,
          email_body: window.btoa(
            encodeURIComponent(
              '<style type="text/css">.email-preview-content { border: 1px solid #ccc; border-radius: 5px; padding: 10px; background-color: #fff; max-width: 600px; min-width: 300px; } .email-img-wrapper { width: 100%; height: 100px; overflow: hidden; } .email-logo-img { max-width: 100%; height: 100px !important; } .message-body { min-height: 100px; } p { margin: 0px !important; } h3,h3 {font-size: 1.25rem; font-weight: 600; font-family: "Public Sans", sans-serif; margin-top: 0; margin-bottom: 0.5rem; line-height: 1.2;}</style><div style="width:700px; margin:0 auto; background:#fff" >' +
                emailPreview.innerHTML.toString() +
                "</div>"
            )
          ),
        },
        string: str,
      });
      handleClose();
    }
  };

  useEffect(() => {
    if (values?.email_details) {
      setEmailDetails(values.email_details);
    }
  }, [values]);

  const handelCancel = () => {
    handleClose();
  };

  const handelCloseImagePopup = () => {
    setShowImageGallery(false);
  };

  const defaultValue = [
    {
      type: "paragraph",
      children: [{ text: "Type something awesome" }],
    },
  ];

  const initialHeadingValue = useMemo(() => {
    const value = emailDetails?.heading
      ? deserialize(emailDetails.heading)
      : defaultValue;
    const valueArray = Array.isArray(value) ? value : [];
    return valueArray;
  }, [emailDetails.heading]);

  const initialValue = useMemo(() => {
    const value = emailDetails?.message
      ? deserialize(emailDetails.message)
      : defaultValue;
    const valueArray = Array.isArray(value) ? value : [];
    return valueArray;
  }, [emailDetails.message]);

  const initialFooterValue = useMemo(() => {
    const value = emailDetails?.footer
      ? deserialize(emailDetails.footer)
      : defaultValue;
    const valueArray = Array.isArray(value) ? value : [];
    return valueArray;
  }, [emailDetails.footer]);

  /******/
  return (
    <React.Fragment>
      <Container fluid className="m-0 mb-4 p-0 bg-dark">
        <Row className="m-0 px-0 justify-content-center">
          <Col
            xs={12}
            sm={6}
            md={6}
            className="email-editor-form px-3 bg-white"
          >
            <h3 className="mt-2 text-primary">Email</h3>
            <Form.Group className="form-group">
              <Form.Label>Subject</Form.Label>
              <div className="d-flex justify-content-between">
                <Form.Control
                  type="text"
                  value={emailDetails?.subject || ""}
                  onChange={(e) =>
                    setEmailDetails({
                      ...emailDetails,
                      subject: e.target.value,
                    })
                  }
                  placeholder="Enter an eye-catching subject line"
                  id="subject"
                  className="me-2"
                />
                <div className="my-1">
                  <MailMerge
                    mode="create-popup"
                    getMailMergeValue={getMailMergeValue}
                    locale={locale}
                    forAutomation={true}
                  />
                </div>
              </div>
              <InLineError type="error" message={errorBag?.subject || ""} />
            </Form.Group>
            <Form.Group className="form-group d-flex justify-content-between">
              <div className="mt-2">
                <Form.Label>Header Image (optional)</Form.Label>
                <br />
                <Button
                  size="lg"
                  variant={"outline-primary"}
                  onClick={() => setShowImageGallery(true)}
                >
                  Upload File
                </Button>
              </div>
              <div>
                {emailDetails?.logo?.url && (
                  <div className="mt-2">
                    {/** Title Align **/}
                    <TextAlignButtons
                      name={"imageAlign"}
                      label={"Image Alignment"}
                      align={emailDetails?.logo?.align}
                      handleAlign={(value) =>
                        setEmailDetails({
                          ...emailDetails,
                          logo: { ...emailDetails.logo, align: value },
                        })
                      }
                    />
                  </div>
                )}
              </div>
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Header (optional)</Form.Label>
              <SlateWrapperA
                initialValue={initialHeadingValue}
                onChange={handleHeadingChange}
                style={{
                  minHeight: "150px",
                  alignContent: "flex-start",
                  lineHeight: 1.5,
                  borderRadius: "unset",
                  marginBottom: "60px",
                }}
                showMailMergeOption={true}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Message Body</Form.Label>
              <SlateWrapperA
                initialValue={initialValue}
                onChange={handleEditorChange}
                style={{
                  minHeight: "150px",
                  alignContent: "flex-start",
                  lineHeight: 1.5,
                  borderRadius: "unset",
                  marginBottom: "60px",
                }}
                showMailMergeOption={true}
              />
              <InLineError type="error" message={errorBag?.message || ""} />
            </Form.Group>

            {/* Link insertion functionality */}
            <Form.Group className="mt-4 form-group">
              <Form.Label>Choose Button Type</Form.Label>
              <div className="d-flex">
                {buttonTypes.map((item) => (
                  <Form.Check
                    type="radio"
                    label={item.label}
                    name="add_button"
                    className="me-2"
                    onClick={() => handleButtonClick(item.name)}
                    checked={emailDetails?.cta?.type === item.name}
                    onChange={() => {}}
                  />
                ))}
              </div>
              <div>
                <SelectDropdown
                  labelVisibility={true}
                  label="Select url"
                  placeholder={
                    "Select " +
                    (emailDetails?.cta?.type === "appointment"
                      ? "appointment with"
                      : toWordCase(emailDetails?.cta?.type || "").toLowerCase())
                  }
                  options={options()}
                  value={emailDetails?.cta?.url || null}
                  handleChange={(e) => {
                    if (emailDetails?.cta?.url) {
                    }
                    setEmailDetails({
                      ...emailDetails,
                      cta: { ...emailDetails.cta, url: e.value },
                    });
                  }}
                />
                <InLineError
                  extraStyle={{ marginTop: "-15px" }}
                  type="error"
                  message={errorBag?.url || ""}
                />
              </div>
              <ConditionDivider
                style={{
                  top: "-15px",
                  fontSize: "16px",
                  backgroundColor: "#fff",
                }}
                text={"OR"}
              />
              <Form.Label>Add external url</Form.Label>
              <Form.Control
                type="text"
                value={emailDetails.cta?.url}
                onChange={(e) =>
                  setEmailDetails({
                    ...emailDetails,
                    cta: { ...emailDetails.cta, url: e.target.value },
                  })
                }
                placeholder="Enter external link"
              />
              <InLineError type="error" message={errorBag?.url || ""} />
            </Form.Group>

            {/* Button Settings */}
            <Form.Group className="mt-4 form-group">
              <Form.Label>Button Text</Form.Label>
              <Form.Control
                type="text"
                value={emailDetails?.cta?.label || ""}
                onChange={(e) =>
                  setEmailDetails({
                    ...emailDetails,
                    cta: { ...emailDetails.cta, label: e.target.value },
                  })
                }
                placeholder="Enter CTA button text"
              />
              <InLineError type="error" message={errorBag?.label || ""} />
            </Form.Group>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <Form.Group className="form-group">
                  {/** Button Align **/}
                  <TextAlignButtons
                    name={"buttonAlign"}
                    label={"Button Alignment"}
                    align={emailDetails?.cta?.align}
                    handleAlign={(value) =>
                      setEmailDetails({
                        ...emailDetails,
                        cta: { ...emailDetails.cta, align: value },
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <Form.Group className="form-group">
                  <Form.Label>Button Color</Form.Label>
                  <ColorPickerDropdown
                    styles={{ height: "40px" }}
                    color={emailDetails?.cta?.bgColor}
                    handleColor={(color) => {
                      setEmailDetails({
                        ...emailDetails,
                        cta: { ...emailDetails.cta, bgColor: color },
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <SelectDropdown
                  label={"Button Width"}
                  options={widthOptions}
                  value={emailDetails.cta?.width || ""}
                  handleChange={(e) =>
                    setEmailDetails({
                      ...emailDetails,
                      cta: { ...emailDetails.cta, width: e.value },
                    })
                  }
                />
              </Col>
            </Row>

            <Form.Group className="form-group">
              <Form.Label>Footer (optional)</Form.Label>
              <SlateWrapperA
                initialValue={initialFooterValue}
                onChange={handleFooterChange}
                style={{
                  minHeight: "150px",
                  alignContent: "flex-start",
                  lineHeight: 1.5,
                  borderRadius: "unset",
                  marginBottom: "60px",
                }}
                showMailMergeOption={true}
              />
            </Form.Group>
          </Col>

          {/* Right side: Email Preview */}
          <Col xs={12} sm={6} md={6} className="email-preview px-3 ">
            <h3 className="text-primary my-2">Email Preview</h3>
            <div className="p-2 mb-2 border bg-white">
              <b>Subject:</b> {emailDetails?.subject || "Email subject Here"}
            </div>
            <div className="email-preview-content border" id="emailPreview">
              {emailDetails.logo?.url && (
                <div
                  className="email-img-wrapper "
                  style={{
                    textAlign: emailDetails.logo?.align,
                  }}
                >
                  <img
                    className="email-logo-img"
                    src={emailDetails.logo?.url}
                    alt="Header"
                    style={{
                      textAlign: emailDetails.logo?.align,
                    }}
                  />
                </div>
              )}
              <div
                dangerouslySetInnerHTML={{ __html: emailDetails?.heading }}
              />
              <div className="message-body">
                <div
                  dangerouslySetInnerHTML={{ __html: emailDetails?.message }}
                />
              </div>
              {/* CTA Button */}
              {/* {emailDetails?.cta?.url && ( */}
              <div
                style={{
                  textAlign: emailDetails?.cta?.align,
                  marginBottom: "20px",
                }}
              >
                <button
                  href={emailDetails?.cta?.url}
                  style={{
                    backgroundColor: emailDetails?.cta?.bgColor,
                    width: emailDetails?.cta?.width,
                    color: "#fff",
                    border: "none",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {emailDetails?.cta?.label}
                </button>
              </div>
              {/* )} */}
              <div dangerouslySetInnerHTML={{ __html: emailDetails?.footer }} />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bg-dark text-end sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button
          variant="outline-secondary"
          onClick={handelCancel}
          className="me-4"
        >
          Cancel
        </Button>
      </div>

      <Modal
        className={"common modalXl email-element-modal"}
        show={showImageGallery}
        onHide={handelCloseImagePopup}
        style={{ position: "absolute", zIndex: 9999 }}
      >
        <ImageGalleryWithModal
          getImage={handleImageUpload}
          handleClose={handelCloseImagePopup}
          locale={locale}
        />
      </Modal>
    </React.Fragment>
  );
};

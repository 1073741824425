import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { EMAIL_CREDENTIAL_LIST } from "../GraphApi/EmailConnect";
import { currentTeamId } from "../Utils/utils";

export const useEmailCredentials = () => {
  const [linkedEmail, setLinkedEmail] = useState(null);
  const [fromUser, setFromUser] = useState([]);
  const [teamEmail, setTeamEmail] = useState(null);
  // Use lazy query to fetch the email credentials
  const [fetchCredentials, { data: credentialList, loading }] = useLazyQuery(
    EMAIL_CREDENTIAL_LIST,
    {
      variables: { team_id: currentTeamId },
      fetchPolicy: "no-cache",
    }
  );

  // Effect to handle the fetched data
  useEffect(() => {
    if (credentialList?.fromList.length > 0) {
      setLinkedEmail(credentialList.fromList[0].email_id);
      const configuredEmailList = credentialList.fromList.map((item) => ({
        value: item.email_id,
        label: item.email_id,
      }));
      setFromUser(configuredEmailList);
    }
    if (credentialList?.teamUserEmailListing) {
      setTeamEmail(credentialList.teamUserEmailListing);
    }
  }, [credentialList]);

  // Return the fetched data and functions to use in a component
  return {
    linkedEmail,
    teamEmail,
    fromUser,
    loading,
    fetchCredentials, // To trigger the lazy query
  };
};

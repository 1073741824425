import React, { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { ChromePicker } from "react-color";

const popover = {
  position: "absolute",
  zIndex: "3",
};
const cover = {
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};

export const ColorPickerDropdown = ({
  name = "color",
  color,
  handleColor = () => {},
  styles = {},
}) => {
  const [showPicker, setShowPicker] = useState(false);

  return (
    <Dropdown className="p-0 m-0">
      <Dropdown.Toggle
        variant="transparent"
        className="d-flex justify-content-between border w-100 p-1"
        onClick={() => setShowPicker(!showPicker)} // Move the onClick here
      >
        <div className="w-100 text-start">
          <Form.Label className="my-1">Select Color</Form.Label>
        </div>
        <div
          style={{
            width: "30px",
            height: "30px",
            backgroundColor: color,
            border: "1px solid #ddd",
          }}
        />
      </Dropdown.Toggle>

      {showPicker && (
        <div style={popover}>
          <div style={cover} onClick={() => setShowPicker(false)} />
          <ChromePicker
            color={color}
            onChangeComplete={(color) => handleColor(color.hex)}
          />
        </div>
      )}
    </Dropdown>
  );
};

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { nonGroupColourStyles } from "../../../../../Components/Common/DropDown/Style";
import { useTranslation } from "react-i18next";

export const UpdateLifeCycleAction = ({
  handleAlertMessage,
  currentCompanySetting,
  values,
  handleChange,
  handleClose,
}) => {
  const { t: locale } = useTranslation();
  const [stages] = useState([
    { value: "contact", label: locale("contact") },
    { value: "prospect", label: locale("prospect") },
    { value: "lead", label: locale("lead") },
    { value: "customer", label: locale("customer") },
  ]);
  const [selectedStage, setSelectedStage] = useState(null);
  const [errorBag, setErrorBag] = useState(null);

  /***On Data Load***/
  useEffect(() => {
    if (stages && values) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stages, values]);

  /**LOad Pre Selected Data**/
  const loadData = () => {
    const preSelectedValue = stages.filter(
      (item) => item.value === values.life_cycle_stage && item
    );
    setSelectedStage(preSelectedValue.length ? preSelectedValue[0] : "");
  };

  //On Component update.
  const handleSaveData = () => {
    let newErrors = {};
    if (!selectedStage) {
      newErrors = {
        ...newErrors,
        life_cycle_stage: "Life cycle stage is required",
      };
      setErrorBag(newErrors);
      return;
    } else {
      setErrorBag(null);
      let str = [];
      str.push(<span key="1">Update lifecycle stage to </span>);
      str.push(
        <span key="2" className="text-primary">
          {selectedStage.label}
        </span>
      );
      handleChange("action", {
        action: "update_lifecycle_stage",
        life_cycle_stage: selectedStage.value,
        string: str,
      });
      handleClose();
    }
  };

  const handleStage = (value) => {
    setSelectedStage(value);
  };

  const handelCancel = () => {
    handleClose();
  };

  /**RENDER HTML**/
  return (
    <React.Fragment>
      <Container className="mt-3">
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label>{locale("Select Lifecycle stage")}</Form.Label>
              <Select
                value={selectedStage}
                options={stages}
                onChange={(e) => {
                  handleStage(e);
                }}
                styles={nonGroupColourStyles}
              />
              <InLineError
                type="error"
                message={errorBag?.life_cycle_stage || null}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <div className="text-end bg-dark sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

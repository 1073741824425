import { gql } from "@apollo/client";

export const EMAIL_CREDENTIAL_LIST = gql`
  query ($team_id: Int) {
    fromList(team_id: $team_id) {
      credential_for
      email_id
      refetch_permissions
    }
    teamUserEmailListing(team_id: $team_id) {
      name
      email
      feedback_enabled
      email_verified_at
    }
  }
`;

/** connect new email account for emailing **/
export const EMAIL_CONNECT = gql`
  mutation createEmailCredential(
    $emailId: String
    $credentialFor: String
    $accessToken: String
    $refreshToken: String
    $code: String
    $tokenExpireDate: DateTime
    $firstTimeUpdate: DateTime
    $team_id: Int
  ) {
    createEmailCredential(
      email_id: $emailId
      credential_for: $credentialFor
      code: $code
      access_token: $accessToken
      refresh_token: $refreshToken
      token_expire_date: $tokenExpireDate
      first_time_update: $firstTimeUpdate
      team_id: $team_id
    ) {
      id
      user_id
      team_id
      email_id
      credential_for
      # access_token
      # refresh_token
      token_expire_date
      first_time_update
      created_at
      updated_at
    }
  }
`;

/** connect new imap/smtp account for emailing **/
export const EMAIL_IMAP_SMTP_CONNECT = gql`
  mutation createImapSmtpCredential(
    $imap_host: String
    $imap_port: String
    $imap_encryption: String
    $imap_username: String
    $imap_password: String
    $smtp_host: String
    $smtp_port: String
    $smtp_encryption: String
    $smtp_username: String
    $smtp_password: String
    $team_id: Int
  ) {
    createImapSmtpCredential(
      imap_host: $imap_host
      imap_port: $imap_port
      imap_encryption: $imap_encryption
      imap_username: $imap_username
      imap_password: $imap_password
      smtp_host: $smtp_host
      smtp_port: $smtp_port
      smtp_encryption: $smtp_encryption
      smtp_username: $smtp_username
      smtp_password: $smtp_password
      team_id: $team_id
    ) {
      id
      user_id
      imap_host
      imap_port
      imap_encryption
      imap_username
      imap_password
      smtp_host
      smtp_port
      smtp_encryption
      smtp_username
      smtp_password
      created_at
      updated_at
    }
  }
`;

export const EMAIL_REMOVE = gql`
  mutation removeEmailCredential($team_id: Int) {
    removeEmailCredential(team_id: $team_id) {
      status
    }
  }
`;

import { gql } from '@apollo/client';

/** Product listing **/
export const OFFERING_LIST = gql`
  query offeringSearchFilter(
    $team_id: Int
    $first: Int!
    $page: Int
    $productType: [String]!
    $search: String
    $field: String!
    $order: SortOrder!
    $filter: [CommonFilter!]
  ) {
    productSearchFilter(
      team_id: $team_id
      first: $first
      page: $page
      productType: $productType
      search: $search
      orderBy: [{ column: $field, order: $order }]
      filter: $filter
    ) {
      data {
        id
        product_type
        type
        validity_type
        name
        price
        description
        image
        stock
        cost
        images {
          id
          name
          path: url
          defaults
          source
        }
        tax_applicable
        created_at
        updated_at
        custom_attributes {
          id
          name
          value
        }
        service_task_items {
          product_item_id
          product_id
          title
          description
          duration
          created_at
          updated_at
        }
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;

/** Product Preview **/
export const PRODUCT_PREVIEW = gql`
  query productPreview($id: ID, $team_id: Int) {
    productDetail(id: $id, team_id: $team_id) {
      id
      product_id
      product_type
      type
      validity
      auto_charge
      validity_type
      sku
      variation_id
      name
      price
      sale_price
      description
      cost
      images {
        id
        name
        path: url
        source
        defaults
      }
      bought_customer_count
      downloadable
      download_limit
      tax_applicable
      tax_class_id
      stock
      last_order_date
      units_sold
      tax
      category
      sub_category
      custom_attributes {
        id
        name
        value
      }
    }
  }
`;

/** Offering CReate / Update **/
export const OFFERING_CREATE = gql`
  mutation createOffering(
    $team_id: Int
    $product_type: String
    $type: String
    $validity_type: String
    $name: String
    $price: Float
    $stock: Int
    $description: String
    $images: [Upload]
    $tax_applicable: Boolean
    $defaultImage: Int
    $additional: String
    $cost: Float
    $service_task_items: [ServiceTaskItems]
  ) {
    createProduct(
      team_id: $team_id
      product_type: $product_type
      type: $type
      validity_type: $validity_type
      name: $name
      price: $price
      stock: $stock
      description: $description
      images: $images
      defaultImage: $defaultImage
      variation_id: 0
      tax_applicable: $tax_applicable
      additional: $additional
      cost: $cost
      service_task_items: $service_task_items
    ) {
      id
      product_type
      type
      validity_type
      name
      price
      description
      image
      stock
      cost
      images {
        id
        name
        path: url
        source
        defaults
      }
      custom_attributes {
        id
        name
        value
      }
      service_task_items {
        product_item_id
        product_id
        title
        description
        duration
        created_at
        updated_at
      }
    }
  }
`;
export const OFFERING_UPDATE = gql`
  mutation updateOffering(
    $team_id: Int
    $id: ID!
    #$product_type: String
    $type: String
    $validity_type: String
    $name: String
    $price: Float
    $stock: Int
    $tax_applicable: Boolean
    $description: String
    $additional: String
    $cost: Float
    $service_task_items: [ServiceTaskItems]
    $service_task_items_update: [UpdateServiceTaskItem]
    $service_task_items_delete: [DeleteServiceTaskItem]
  ) {
    updateProduct(
      team_id: $team_id
      id: $id
      #product_type: $product_type
      type: $type
      validity_type: $validity_type
      name: $name
      price: $price
      stock: $stock
      tax_applicable: $tax_applicable
      description: $description
      additional: $additional
      cost: $cost
      service_task_items: $service_task_items
      service_task_items_update: $service_task_items_update
      service_task_items_delete: $service_task_items_delete
    ) {
      id
      product_type
      type
      validity_type
      name
      price
      description
      image
      stock
      cost
      images {
        id
        name
        path: url
        source
        defaults
      }
      custom_attributes {
        id
        name
        value
      }
    }
  }
`;
/** Offering Delete **/
export const OFFERING_DELETE = gql`
  mutation removeOffer($offerId: [ProductIds!], $team_id: Int) {
    removeSelectedProducts(
      type: "individual"
      checked_product_ids: $offerId
      team_id: $team_id
    ) {
      status
    }
  }
`;
/** Offering File Add Remove **/
export const IMAGE_UPLOAD_DELETE = gql`
  mutation createDeleteProductImages(
    $team_id: Int
    $id: Int
    $create: [Upload]
    $delete: [DeleteProductImagesInput]
  ) {
    createDeleteProductImages(
      id: $id
      create: $create
      delete: $delete
      team_id: $team_id
    ) {
      id
      product_type
      type
      validity_type
      name
      price
      description
      image
      stock
      images {
        id
        name
        path: url
        source
        defaults
      }
    }
  }
`;

import React, { useState } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";

export const TextAlignButtons = ({
  label = "Align",
  name = "textAlign",
  align = "left",
  handleAlign = () => {},
}) => {
  const [showPicker, setShowPicker] = useState(false);

  return (
    <Form.Group id={name} className="mx-1 my-1 form-group">
      <Form.Label>{label}</Form.Label>
      <br />
      <ButtonGroup>
        <Button
          variant={"outline-primary"}
          active={align === "left"}
          onClick={() => {
            handleAlign("left");
          }}
        >
          <i className="ri-align-left"></i>
        </Button>
        <Button
          variant={"outline-primary"}
          active={align === "center"}
          onClick={() => {
            handleAlign("center");
          }}
        >
          <i className="ri-align-center"></i>
        </Button>
        <Button
          variant={"outline-primary"}
          active={align === "right"}
          onClick={() => {
            handleAlign("right");
          }}
        >
          <i className="ri-align-right"></i>
        </Button>
      </ButtonGroup>
    </Form.Group>
  );
};

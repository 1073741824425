import React, { useState, useEffect, useRef, useContext } from "react";
import { useMutation } from "@apollo/client";
import Select from "react-select";
import { Card, Button, Form, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";

import InLineErrorMessage from "../../Common/AlertMessage/InLineErrorMessage";
import {
  currentTeamId,
  getValidationMessage,
  scrollToTop,
} from "../../../Utils/utils";
import {
  TEAM_MEMBER_CREATE,
  MEMBER_UPDATE,
} from "../../../GraphApi/TeamMembers";
import { AuthContext } from "../../../Context/AuthContext";

import { nonGroupColourStyles } from "../../Common/DropDown/Style";
import { useTranslation } from "react-i18next";
import { getValidationSchema } from "../../../Utils/formikValidator";
import { getSettings } from "../../../Utils/CurrentSetting";
import PhoneInput from "../../Common/PhoneNumber/PhoneInput";
import { SelectDropdown } from "../../Common/Conditions/SelectDropdown";

const fieldsToBeValidate = [
  {
    type: "name",
    name: "first_name",
    isRequired: false,
    maxLength: 50,
    minLength: 3,
    isNullable: true,
  },
  {
    type: "name",
    name: "last_name",
    isRequired: true,
    maxLength: 50,
    minLength: 3,
    isNullable: false,
  },
  {
    type: "email",
    name: "email",
    isRequired: true,
    maxLength: 255,
    minLength: null,
    isNullable: false,
  },
  {
    type: "phone",
    name: "phone_no",
    isRequired: false,
    maxLength: 15,
    minLength: 10,
    isNullable: true,
  },
  {
    type: "text",
    name: "role_id",
    isRequired: true,
    maxLength: null,
    minLength: null,
    isNullable: false,
  },
];

export const InviteTeamMember = ({
  fetchTeamMemberList,
  technicianRole = null,
  handleAlertMessage = () => {},
  allRoles,
}) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const [company, setCompany] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState("US");

  /****/
  const [validationSchema] = useState(
    getValidationSchema(fieldsToBeValidate, locale)
  );
  /*****/
  const [createTeamUser] = useMutation(TEAM_MEMBER_CREATE);
  const { userData } = useContext(AuthContext);
  /**** GET USER INDUSTRY ****/
  useEffect(() => {
    if (userData?.authData?.currentTeam?.settings) {
      const companySettings = getSettings(
        userData.authData.currentTeam.settings
      );
      setCompany(companySettings.company_name);
      const address = companySettings.company_address
        ? JSON.parse(companySettings.company_address)
        : [];
      setDefaultCountry(address?.country || "US");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  /*****/
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      role_id: technicianRole || "",
    },
    validationSchema: validationSchema,
    onSubmit(values) {
      processData(values);
    },
  });

  const processData = (data) => {
    const phoneFormat = /\(|\)|-|\.|\s/gi;
    const teamMemberData = {
      team_id: currentTeamId,
      company: company,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_no: data.phone_no ? data.phone_no.replace(phoneFormat, "") : "",
      role_id: data.role_id ? parseInt(data.role_id) : null,
    };

    createTeamUser({
      variables: teamMemberData,
      update: () => fetchTeamMemberList(),
    })
      .then((result) => {
        handleAlertMessage(
          "success",
          locale("messages:common.sent", {
            fieldName: locale("Invitation"),
          })
        );
        formik.resetForm();
        setIsSubmitting(false);
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    const errorArray = Object.keys(formik.errors);
    if (errorArray.length) {
      errorArray.forEach((item) => {
        if (formik.touched[item] && formik.errors[item]) {
          // handleAlertMessage("error", formik.errors[item]);
          if (document.getElementById([item]) && isSubmitting) {
            document.getElementById([item]).focus();
            scrollToTop(document.getElementById([item]));
            setIsSubmitting(false);
            return false;
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.touched]);

  /******/
  return (
    <>
      <Card>
        <Card.Body className="bg-light">
          <h4 className={"text-primary font-weight-semibold mb-3"}>
            {locale("Add Team Member")}
          </h4>

          <Form noValidate onSubmit={formik.handleSubmit}>
            <div className="d-flex justify-content-between">
              <Form.Group className="form-group me-1">
                <Form.Label>{locale("first name")}</Form.Label>
                <InputGroup
                  className={
                    formik.touched.first_name && formik.errors.first_name
                      ? "is-invalid"
                      : ""
                  }
                >
                  <InputGroup.Text>
                    <i className="ri-user-fill"></i>
                  </InputGroup.Text>

                  <Form.Control
                    id="first_name"
                    name="first_name"
                    type="text"
                    placeholder={locale("first name")}
                    value={formik?.values?.first_name || ""}
                    onChange={(e) => {
                      formik.setFieldValue("first_name", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </InputGroup>
                <InLineErrorMessage
                  type="error"
                  name="first_name"
                  formik={formik}
                />
              </Form.Group>
              <Form.Group className="form-group me-1">
                <Form.Label>{locale("last name")}</Form.Label>
                <InputGroup
                  className={
                    formik.touched.last_name && formik.errors.last_name
                      ? "is-invalid"
                      : ""
                  }
                >
                  <InputGroup.Text>
                    <i className="ri-user-fill"></i>
                  </InputGroup.Text>

                  <Form.Control
                    type="text"
                    placeholder={locale("last name")}
                    id="last_name"
                    name="last_name"
                    value={formik?.values?.last_name || ""}
                    onChange={(e) => {
                      formik.setFieldValue("last_name", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </InputGroup>
                <InLineErrorMessage
                  type="error"
                  name="last_name"
                  formik={formik}
                />
              </Form.Group>

              <Form.Group className="form-group me-1">
                <Form.Label className={"text-uppercase"}>
                  {locale("Email")}
                </Form.Label>
                <InputGroup
                  className={
                    formik.touched.email && formik.errors.email
                      ? "is-invalid"
                      : ""
                  }
                >
                  <InputGroup.Text>
                    <i className="ri-mail-fill"></i>
                  </InputGroup.Text>

                  <Form.Control
                    id="email"
                    name="name"
                    type="email"
                    placeholder={locale("email")}
                    value={formik?.values?.email || ""}
                    onChange={(e) => {
                      formik.setFieldValue("email", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </InputGroup>
                <InLineErrorMessage type="error" name="email" formik={formik} />
              </Form.Group>

              {/* <Form.Group className="form-group">
                <Form.Label>{locale("phone")}</Form.Label>
                <PhoneInput
                  data={formik?.values?.phone_no || ""}
                  formik={formik}
                  defaultCountry={defaultCountry}
                  locale={locale}
                  error={
                    formik.touched.phone_no && formik.errors.phone_no
                      ? "is-invalid"
                      : ""
                  }
                  name={"phone_no"}
                  placeholder={"phone"}
                  key={"phone_no"}
                />

                <InLineErrorMessage
                  type="error"
                  name="phone_no"
                  formik={formik}
                />
              </Form.Group> */}

              <Form.Group className="form-group me-1">
                <Form.Label>{locale("role")}</Form.Label>
                <SelectDropdown
                  labe=""
                  labelVisibility={false}
                  id="role_id"
                  name="role_id"
                  options={allRoles}
                  value={formik?.values?.role_id || ""}
                  handleChange={(e) => {
                    formik.setFieldValue("role_id", e.value);
                  }}
                  onBlur={formik.handleBlur}
                  styles={nonGroupColourStyles}
                  className={
                    formik.touched.role_id && formik.errors.role_id
                      ? "is-invalid"
                      : ""
                  }
                />
                <InLineErrorMessage
                  extraStyle={{ marginTop: "-15px" }}
                  type="error"
                  name="role_id"
                  formik={formik}
                />
              </Form.Group>
              <div className="pt-4 ms-2">
                <Button
                  variant="outline-primary mt-1"
                  type={"submit"}
                  disabled={isSubmitting}
                >
                  <i className={"ri-user-add-line"}></i>{" "}
                  <span>{locale("Invite")}</span>
                </Button>
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

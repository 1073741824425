import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { APP_URL } from "../../../Utils/utils";

const responsiveClass = {
  desktop: "desktop",
  tab: "desktop",
  mobile: "mobile-responsive",
};

export const PreviewEmail = ({
  data = null,
  screen = "desktop",
  page = "preview",
  showEmailPreview,
  handleCloseEmailPreview,
  fromReferral = "campaign",
  pageUrl = null,
  locale,
}) => {
  const [previewMode, setPreviewMode] = useState("desktop");
  const defaultCss = { margin: "0 auto", background: "#fff", display: "table" };
  const width = {
    desktop: { ...defaultCss, width: '1024px' },
    tab: { ...defaultCss, width: '767px' },
    mobile: { ...defaultCss, width: '350px' },
  };

  useEffect(() => {
    if (data) {
      insertContentToFrame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const insertContentToFrame = () => {
    const htmlData =
      '<div id="template" class="main pointer-event-none">' +
      '<link rel="stylesheet" href="' +
      APP_URL +
      '/css/equp-email.css" type="text/css"/>' +
      data +
      "</div>";
    const doc =
      document.getElementById("templatePreview").contentWindow.document;
    doc.open();
    doc.write(htmlData);
    doc.close();
  };

  useEffect(() => {
    setPreviewMode(screen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen]);

  const handlePreview = (screen) => {
    setPreviewMode(screen);
  };
  /******/
  return (
    <React.Fragment>
      {page !== "email-builder" ? (
        <Modal
          size="xl"
          show={showEmailPreview}
          onHide={() => handleCloseEmailPreview()}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{locale("Preview")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PreviewEmailTemplate
              page={page}
              previewMode={previewMode}
              handlePreview={handlePreview}
              width={width}
              fromReferral={fromReferral}
              pageUrl={pageUrl}
            />
          </Modal.Body>
        </Modal>
      ) : (
        <PreviewEmailTemplate
          page={page}
          previewMode={previewMode}
          handlePreview={handlePreview}
          width={width}
          pageUrl={pageUrl}
        />
      )}
    </React.Fragment>
  );
};

export const PreviewEmailTemplate = ({
  page,
  previewMode,
  handlePreview,
  width,
  fromReferral,
  pageUrl,
}) => {
  return (
    <div className="hide-sidebar">
      {page === "preview" && (
        <div className="text-center mb-4">
          <div className="responsive-screen-buttons">
            <Button
              variant="success btn-outline-success"
              className={previewMode === "desktop" ? "active" : ""}
              onClick={() => handlePreview("desktop")}
            >
              <i className="ri-computer-line"></i>
            </Button>
            <Button
              variant="success btn-outline-success"
              className={previewMode === "tab" ? "active" : ""}
              onClick={() => handlePreview("tab")}
            >
              <i className="ri-tablet-line"></i>
            </Button>
            <Button
              variant="success btn-outline-success"
              className={previewMode === "mobile" ? "active" : ""}
              onClick={() => handlePreview("mobile")}
            >
              <i className="ri-smartphone-line"></i>
            </Button>
          </div>
        </div>
      )}
      <div className="email-builder-main" style={{ height: "100%" }}>
        <div className="email-builder-content-bar">
          <iframe
            title="Page Preview"
            id="templatePreview"
            className={
              responsiveClass[previewMode] +
              (fromReferral === "workflow" ? " p-4" : "")
            }
            style={width[previewMode]}
            // frameBorder="0"
            src={pageUrl}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

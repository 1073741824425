import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { isObjectEmpty } from "../../../../../Utils/utils";

export const Call = ({
  value = null,
  handleChange = () => {},
  handleClose = () => {},
}) => {
  //   const [errorBag, setErrorBag] = useState(null);
  useEffect(() => {
    if (isObjectEmpty(value)) {
      handleChange("call", {
        value: "any",
        label: "Any Call",
      });
    }
  }, []);

  const handleSave = () => {
    handleChange("call", {
      value: "any",
      label: "Any Call",
    });
    handleClose();
  };
  /*****/
  return (
    <React.Fragment>
      <h3 className="text-center mb-3">
        Select the trigger that will start this business flow
      </h3>
      <div className="d-flex justify-content-center">
        <div className="border mx-3 p-2 px-3 rounded-2">Call</div>
      </div>
      {/* <div className="text-end bg-dark sticky-footer ">
        <Button variant="outline-primary" className="mx-2" onClick={handleSave}>
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
      </div> */}
    </React.Fragment>
  );
};

// ColorPicker.js
import React, { useState } from "react";
import { ChromePicker } from "react-color";
import "../css/ColorPicker.css";

const ColorPicker = ({ onChange, value, icon, label }) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const handleColorChange = (color) => {
    onChange(color.hex); // Update the value in hex format
  };

  return (
    <div
      className="color-picker-wrapper"
      style={{ position: "relative", width: "36px", height: "36px" }}
    >
      {/* Trigger Icon */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          fontSize: "24px",
          cursor: "pointer",
          lineHeight: "36px",
          textAlign: "center",
          zIndex: 1,
        }}
        title={label}
        onClick={() => setIsPickerVisible(!isPickerVisible)}
      >
        {/* Pass the color prop directly to the icon */}
        {React.cloneElement(icon, { fill: value, strokeWidth: "1px", stroke: "black" })}
      </div>

      {/* Color Picker */}
      {isPickerVisible && (
        <div
          style={{
            position: "absolute",
            top: "40px", // Adjust the position of the color picker dropdown
            left: 0,
            zIndex: 100,
          }}
        >
          <ChromePicker color={value} onChange={handleColorChange} />
          <button
            style={{
              marginTop: "8px",
              cursor: "pointer",
              display: "block",
              width: "100%",
              padding: "4px 8px",
              textAlign: "center",
            }}
            onClick={() => setIsPickerVisible(false)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  currentTeamId,
  emailTemplateForPreview,
  getValidationMessage,
} from "../../../../../Utils/utils";
import { EqupRoundLoaderWithOverlay } from "../../../../../Components/Common/Loader/RoundLoader";
import { useFormik } from "formik";
import { getValidationSchema } from "../../../../../Utils/formikValidator";
import {
  SAVE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
} from "../../../../../GraphApi/Emails";
import { useMutation } from "@apollo/client";
import { CreateNewEmail } from "../../../../../Components/Common/EmailTemplate/CreateNewEmail";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";

const fieldsToBeValidate = [
  {
    type: "text",
    name: "name",
    isRequired: true,
    maxLength: 255,
    minLength: 3,
    isNullable: false,
  },
  {
    type: "textArea",
    name: "subject",
    isRequired: true,
    maxLength: 1000,
    minLength: 3,
    isNullable: false,
  },
  {
    type: "textArea",
    name: "message",
    isRequired: true,
    maxLength: 30000,
    minLength: 5,
    isNullable: false,
  },
];
export const TransactionalEmailAction = ({
  handleAlertMessage = () => {},
  userData,
  currentCompanySetting,
  values,
  handleChange = () => {},
  handleClose = () => {},
}) => {
  const { t: locale } = useTranslation();
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [errorBag, setErrorBag] = useState(null);
  const [templateType] = useState("transactional_email");
  const [validationSchema] = useState(
    getValidationSchema(fieldsToBeValidate, locale)
  );
  const [emailTemplateForClone, setEmailTemplateForClone] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mode, setMode] = useState("create");
  const [showTemplateName, setShowTemplateName] = useState(false);

  const [saveEmailTemplate] = useMutation(SAVE_EMAIL_TEMPLATE);
  const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE);

  const formik = useFormik({
    initialValues: {
      name: "",
      subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit(values) {
      processData(values);
    },
  });

  useEffect(() => {
    formik.setValues({
      name: "",
      subject: "",
      message: "",
    });
    setEmailTemplateForClone(null);
  }, []);

  const handleCancelShowTemplate = () => {
    setShowTemplateName(false);
  };

  const handleSaveTemplateData = () => {
    formik.handleSubmit();
  };

  const processData = (data) => {
    setIsSubmitting(true);
    const dataToBeProcess = {
      name: data.name,
      subject: data.subject,
      message: encodeMessage(data.message),
      template_type: templateType,
      team_id: currentTeamId,
    };

    if (
      mode === "create" ||
      [true, null].includes(emailTemplateForClone?.isSystemEmail)
    ) {
      saveEmailTemplate({
        variables: dataToBeProcess,
      })
        .then((result) => {
          handleAlertMessage(
            "success",
            locale("messages:common.create", { fieldName: locale("Template") })
          );
          setIsSubmitting(false);
          handleCancelShowTemplate();
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          console.log(graphQLErrors, error);
          setIsSubmitting(false);
          handleAlertMessage(
            "error",
            locale("messages:backend." + getValidationMessage(graphQLErrors))
          );
        });
    } else if (mode === "edit") {
      updateEmailTemplate({
        variables: {
          ...dataToBeProcess,
          id: parseInt(emailTemplateForClone.id),
        },
      })
        .then((result) => {
          handleAlertMessage(
            "success",
            locale("messages:common.update", { fieldName: locale("Template") })
          );
          setIsSubmitting(false);
          handleCancelShowTemplate();
          console.log("Here:", result.data);
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          console.log(graphQLErrors, error);
          setIsSubmitting(false);
          handleAlertMessage(
            "error",
            locale("messages:backend." + getValidationMessage(graphQLErrors))
          );
        });
    }
  };

  const encodeMessage = (message) => {
    try {
      return window.btoa(encodeURIComponent(message));
    } catch (e) {
      handleAlertMessage(
        "error",
        locale(
          "messages:common.message contain some hidden character, remove them and save again."
        )
      );
      return null;
    }
  };

  const handelCancel = () => {
    handleClose();
  };
  const handleSaveData = () => {
    const emailDetail = formik?.values || {};
    let newErrors = {};
    if (!emailDetail?.subject) {
      newErrors = { ...newErrors, subject: "Subject is required." };
    }
    if (!emailDetail?.message) {
      newErrors = { ...newErrors, message: "Message is required." };
    }

    setErrorBag(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let str = [];
      str.push(<span key="1">Send </span>);
      str.push(
        <span key="2" className="text-primary">
          {emailDetail?.subject || ""}
        </span>
      );
      str.push(<span key="3"> Email</span>);
      handleChange("action", {
        action: "send_transactional_email",
        email_details: {
          email_body: emailDetail?.message
            ? encodeMessage(emailDetail.message)
            : "",
          subject: emailDetail?.subject || "",
        },
        string: str,
      });
      handleClose();
    }
  };

  const loadData = () => {
    if (values?.email_details) {
      const msg = emailTemplateForPreview(
        values?.email_details?.email_body,
        templateType
      );

      formik.setValues({
        name: "",
        subject: values?.email_details?.subject || "",
        message: msg || "",
      });
      setEmailTemplateForClone(null);
    }
  };

  useEffect(() => {
    if (emailTemplateForClone !== null) {
      const msg = emailTemplateForPreview(
        emailTemplateForClone.message,
        emailTemplateForClone.template_type
      );
      formik.setFieldValue("name", emailTemplateForClone?.name || "");
      formik.setFieldValue("subject", emailTemplateForClone?.subject || "");
      addDefaultCssToParagraph(msg, "message");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateForClone]);

  useEffect(() => {
    if (values?.email_details) {
      setTimeout(() => {
        loadData();
      }, 1000);
    }
  }, [values]);

  const addDefaultCssToParagraph = (msg, name) => {
    let customContent = "";
    if (msg && msg.length > 0) {
      customContent = msg.replace(/<p>/g, '<p style="margin:0;padding:0">');
    } else {
      customContent = msg ? msg : "";
    }
    formik.setFieldValue(name, customContent);
  };

  const showSaveTemplateData = () => {
    setShowTemplateName(true);
  };

  if (isSubmitting) {
    return <EqupRoundLoaderWithOverlay />;
  }
  /*****/
  return (
    <React.Fragment>
      <div className="mx-2 p-1 mb-4">
        <Row>
          <Col md={12} lg={12} sm={12} xs={12}>
            <CreateNewEmail
              formik={formik}
              setEmailTemplateForClone={setEmailTemplateForClone}
              addDefaultCssToParagraph={addDefaultCssToParagraph}
              templateType={templateType}
            />
          </Col>
        </Row>
      </div>

      <div className="sticky-footer ">
        <div className="d-flex justify-content-between">
          <Button
            variant="outline-danger"
            className="me-2"
            onClick={showSaveTemplateData}
          >
            Save as Template
          </Button>
          <div className="text-end">
            <Button
              variant="outline-primary"
              className="me-2"
              onClick={handleSaveData}
            >
              Save
            </Button>
            <Button variant="outline-secondary" onClick={handelCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>

      <Modal
        className={"common"}
        centered
        show={showTemplateName}
        onHide={handleCancelShowTemplate}
      >
        <Modal.Header className="bg-primary">
          <Modal.Title className={"text-white secondary-font text-uppercase"}>
            Save as template to re-use in future
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="form-group">
            <Form.Label>{locale("Template Name")}</Form.Label>
            <Form.Control
              id={"name"}
              type="text"
              placeholder={locale("Enter Template Name")}
              value={formik?.values?.name || ""}
              onChange={(e) => {
                formik.setFieldValue("name", e?.target?.value || "");
              }}
            />
            <InLineError type="error" message={errorBag?.name || ""} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            className="me-2"
            onClick={handleCancelShowTemplate}
          >
            cancel
          </Button>
          <Button
            variant="outline-primary"
            className="me-2"
            onClick={handleSaveTemplateData}
          >
            Save as template
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

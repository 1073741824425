import React, { useState, useEffect, useContext } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AuthContext } from "../Context/AuthContext";
import Navigation from "../Layouts/Navigation";
import {
  getUserIndustry,
  currentTeamId,
  isObjectEmpty,
  fillNonExistedSettings,
  checkModPermission,
  hasAffiliate,
  getLanguage,
  publicRoutesName,
  getAllowedPages,
  hasClient,
} from "../Utils/utils";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";

import { SAVE_SETTING, RESEND_EMAIL } from "../GraphApi/TeamMembers";
import { AFFILIATES_ME } from "../GraphApi/Affiliates";

import IdleTimer from "../Utils/IdleTimer";
import AffiliateNavigation from "../Layouts/AffiliateNavigation";

import { handleTimezoneLocale } from "../i18n";
import VerifyEmail from "../Components/Common/OverlayPopup/VerifyEmail";
import { BuyAddonPlan } from "../Components/Common/Modals/BuyAddonPlan";
import { getCompanySettings } from "../Utils/UserUtils";
import Session from "../Utils/Session";
import { IsTrialExpired } from "../Components/Common/Modals/IsTrialExpired";
import { ValidatePermission } from "../Components/Common/Modals/ValidatePermission";
import UpgradePlan from "../Pages/PageNotFound/UpgradePlan";
import { TrialPlanChecker } from "../Context/TrialPlanChecker";
import { AddCard } from "../Components/Common/OverlayPopup/AddCard";
import { getUserTimeZone } from "../Utils/EqupTimezone";
import { FSPLogo } from "../Components/Common/SvgFiles/FSPLogo";

const base64regex =
  /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

const commonUrls = [
  "business-flow/create",
  "marketing/automation/create",
  "marketing/automation",
  "new-form-builder",
  "technician-form/:id/:apId",
  "booking/create",
  "jobs/:id/form",
  "dispatcher",
  "marketing/social-media/:id",
  "marketing/social-media",
  "marketing/forms",
  "marketing/landing-pages",
  "sales/service-request",
  "create-job",
  "jobs",
  "jobs/:id/detail",
  "estimates",
  "estimate-review/:id",
  "estimate-builder",
  "estimate-template-builder",
  "/agreement/:id/:agId",
  "agreement-review/:id",
  "agreement-builder",
  "client-dashboard",
  "test-page",
  "email-verification-notification",
  "contacts-verification",
  "duplicate-contacts",
  "social-media",
  "social-media/:id",
  "website-tracking-details",
  "deal/:id",
  "appointment/:id",
  "task/:id",
  "contact-details/:id",
  "notifications",
  "buy-plan",
  "buy-addon",
  "owners",
  "owner-details",
  "owners-details",
  "landing-page-builder",
  "visual-builder",
  "tasks",
  "test-chart",
  "add-dashboard",
  "edit-dashboard",
  "email-builder",
  "new-email-builder",
  "my-profile",
  "new-dashboard",
  "choose-company",
  "team-member",
  "basic-setting",
  "settings/:id",
  "settings",
  "responsive-preview",
  "company-management",
  "company-details",
  "tag-management",
  "score-management",
  "choose-prebuilt-workflows",
  "workflow-builder",
  "workflow",
  "choose-prebuilt-campaigns",
  "campaign",
  "campaign-analytics",
  "campaign-compare",
  "campaign-builder",
  "login-microsoft",
  "viewer",
  "dashboard",
  "crm",
  "contact-details",
  "contact-details/:id",
  "ecommerce",
  "sales/pipeline",
  "logout",
  "signup",
  "dialer",
  "engagements",
  "my-schedule",
  "/",
  "",
];
const industryWiseAccess = {
  ecommerce: ["ecommerce"],
  service: ["services"],
  saas: ["subscription", "add-plan", "add-layout"],
};

const redirectAblePath = {
  "/contact-details/:id": "/contact-details",
  "/task/:id": "/contact-details",
  "/appointment/:id": "/contact-details",
  "/deal/:id": "/pipeline",
  "/website-tracking-details": "/settings/tracking-script",
  "/agreement/:id/:agId": "/contact-details",
  // "/tasks/:cid/:tid": "/tasks",
};
const urlsForAffiliate = [
  "/",
  "/new-dashboard",
  "/dashboard",
  "/affiliate-crm",
  "/marketing/automation/create",
  "/marketing/automation",
  "/marketing-material",
  "/engagements",
  "/sales/pipeline",
  "/basic-setting",
  "/settings/:id",
  "/settings",
  "/my-profile",
];

let accountIsActivated = null;
const ProtectedRoutes = ({
  component: RouteComponent,
  hasDefault,
  ...rest
}) => {
  const [newPath] = useState(isObjectEmpty(rest.path) ? "/" : rest.path);
  const { pathname } = useLocation();
  const params = useParams();
  const fspLogo = <FSPLogo />;
  const profilePic = require("../Assets/Images/user-avtar.png");
  const [isFetchingData, setIsFetchingData] = useState(true);
  const { logOut, userData, setUser, authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [havePermission, setHavePermission] = useState(true);
  const [companyLogo, setCompanyLogo] = useState(fspLogo);
  const [profilePicture, setProfilePicture] = useState(profilePic);
  const [newSetting, setNewSetting] = useState(null);
  /**User Company Setting (Pass settings to all Parent pages)**/
  const [currentCompanySetting, setCurrentCompanySetting] = useState(null);
  const [industry, setIndustry] = useState("");
  // const [allowedPages, setAllowedPages] = useState([]);
  const [urlExist, setUrlExist] = useState(true);
  const { planData, fetchSubscribedPlan } = useContext(TrialPlanChecker);

  /*** AUTO LOGOUT ***/
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 30 * 60, //expire after 30 Minutes
      onTimeout: () => {
        if (publicRoutesName.indexOf(pathname) === -1) {
          logOutUser();
        }
      },
      onExpired: () => {
        //do something if expired on load
        if (publicRoutesName.indexOf(pathname) === -1) {
          logOutUser();
        }
      },
    });

    if (isObjectEmpty(Session.getSessionVar("idToken"))) {
      if (!isObjectEmpty(localStorage.getItem("token"))) {
        Session.storeSessionVar("idToken", localStorage.getItem("token"));
        Session.storeSessionVar(
          "currentTeam",
          localStorage.getItem("currentTeam")
        );
      }
    }

    return () => {
      if (publicRoutesName.indexOf(pathname) === -1) {
        timer.cleanUp();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** ON TIME OUT LOGOUT THE USER **/
  const logOutUser = async () => {
    const redirectTo =
      publicRoutesName.indexOf(pathname) > -1 ? pathname : null;
    await logOut(redirectTo);
  };

  const [affiliateMe, { data: affiliateData }] = useLazyQuery(AFFILIATES_ME, {
    fetchPolicy: "no-cache",
  });

  const [updateCompanySettings] = useMutation(SAVE_SETTING);
  const [changeEmailForUnconfirmed] = useMutation(RESEND_EMAIL);

  /*** Check User Is Affiliate ***/

  useEffect(() => {
    if (hasAffiliate(userData) && !publicRoutesName.includes(pathname)) {
      affiliateMe();
    } else if (!publicRoutesName.includes(pathname)) {
      authUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (affiliateData) {
      setUser(affiliateData.affiliateMe);
      setIsFetchingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateData]);

  /** Check If Subscribed Plan Data is Empty ***/
  // useEffect(() => {
  //   if (planData === null && !isFetchingData && currentTeamId) {
  //     fetchSubscribedPlan();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isFetchingData, planData, currentTeamId]);
  /*******/
  /*** ON URL CHANGE **/
  useEffect(() => {
    if (userData?.authenticated) {
      const pathName = rest.path.replace("/", "");
      if (!isObjectEmpty(pathName)) {
        let permission =
          commonUrls
            .concat(industryWiseAccess[industry])
            .filter((item) => item === pathName && item).length > 0;
        setUrlExist(permission);
        setIsFetchingData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industry, havePermission, planData]);

  useEffect(() => {
    const userIndustry = getUserIndustry(userData);
    setIndustry(userIndustry);
    if (userData && userData.isFetching === false) {
      setIsFetchingData(false);
    }

    if (userData?.authenticated && userData?.authData?.currentTeam) {
      getSetCompanyLogo(userData.authData.currentTeam);
    }

    if (userIndustry && !hasAffiliate(userData)) {
      /*** GET & SET PAGE PERMISSION ***/
      // const allPagesAllowed = getAllowedPages(userData);
      // setAllowedPages(allPagesAllowed);
      /***************/
      if (userData && userData.authenticated) {
        /** GET & SET PROFILE PICTURE **/
        setProfilePicture(userData?.authData?.profile_picture || profilePic);

        /**Call Language Method**/
        if (!hasAffiliate(userData)) {
          const userSettings = userData?.authData?.currentTeam?.settings || [];

          /**** SET USER LANGUAGE ****/
          if (userSettings?.length > 0) {
            let language = "en";
            fillNonExistedSettings(userSettings).forEach((setting) => {
              if (setting.setting_name === "language") {
                language = getLanguage({ language: setting.setting_value });
              }
            });
            handleLanguage(language);
          }
        }
      }
      setCurrentCompanySetting(getCompanySettings(userData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, rest.path]);

  /** AUTO SAVE SETTINGS **/
  useEffect(() => {
    if (
      userData?.authenticated &&
      !hasAffiliate(userData) &&
      userData?.authData?.currentTeam
    ) {
      // setIsFetchingData(false);
      /*****/
      const teamSettings = userData?.authData?.currentTeam?.settings || [];
      const roleInTeam = userData?.authData?.role?.role_type || "";
      /*****/
      if (teamSettings.length > 0) {
        if (
          !isObjectEmpty(roleInTeam) &&
          ["admin", "owner"].indexOf(roleInTeam) > -1
        ) {
          let allSettings = {};
          let isUpdateRequired = false;

          teamSettings.forEach((item) => {
            if (
              item.setting_name !== "company_address" &&
              item.setting_name !== "company_logo"
            ) {
              allSettings = {
                ...allSettings,
                [item.setting_name]: item.setting_value,
              };
            }
          });

          /***Check For CURRENCY, TIMEZONE ****/
          if (
            teamSettings.filter((item) => item.setting_name === "currency")
              .length === 0
          ) {
            allSettings = {
              ...allSettings,
              currency: "usd",
            };
            isUpdateRequired = true;
          }
          if (
            teamSettings.filter((item) => item.setting_name === "time_zone")
              .length === 0
          ) {
            allSettings = {
              ...allSettings,
              time_zone: getUserTimeZone(),
            };
            isUpdateRequired = true;
          }

          if (
            teamSettings.filter((item) => item.setting_name === "language")
              .length === 0 ||
            teamSettings.filter(
              (item) =>
                item.setting_name === "language" &&
                item.setting_value === "english"
            ).length === 1
          ) {
            allSettings = {
              ...allSettings,
              language: Session.getSessionVar("locale")
                ? Session.getSessionVar("locale")
                : "en",
            };
            handleTimezoneLocale(
              Session.getSessionVar("locale")
                ? Session.getSessionVar("locale")
                : "en"
            );
            isUpdateRequired = true;
          }

          /**** THIS CODE Execute if Update is Required ***/
          if (isUpdateRequired) {
            const dataForProcess = {
              team_id: parseInt(currentTeamId),
              ...allSettings,
            };
            updateCompanySettings({ variables: { ...dataForProcess } })
              .then((result) => {
                setNewSetting(result.data.updateCompanySettings);
              })
              .catch((error) => {
                console.log(error);
                let { graphQLErrors } = error;
                console.log("Error:", graphQLErrors);
              });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  /*** UPDATE NEW SETTING TO AUTH USER CONTEXT ****/
  useEffect(() => {
    if (!isObjectEmpty(newSetting) && !hasAffiliate(userData)) {
      setTimeout(() => {
        setUser({
          ...userData.authData,
          currentTeam: {
            ...userData.authData.currentTeam,
            settings: newSetting,
          },
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSetting]);

  /*** EMAIL ACTIVATE OR NOT POPUP ***/
  useEffect(() => {
    if (
      userData?.authenticated &&
      userData?.authData?.email_verified_at === null
    ) {
      setEmailVerified(true);
    }

    if (
      userData?.authenticated &&
      userData?.authData?.currentTeam?.require_card
    ) {
      setShowAddCardModal(true);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.path, userData, havePermission]);

  /**
   * User can change the language Here.
   *
   * en, fr, es, pt
   *
   **/
  const handleLanguage = (userLanguage) => {
    handleTimezoneLocale(userLanguage);
  };

  /*** Get & Set Company Logo ***/
  const getSetCompanyLogo = (currentTeam) => {
    if (currentTeam?.settings?.length > 0) {
      setCompanyLogo(
        currentTeam &&
          checkModPermission(currentTeam.lt, "white_label") &&
          currentTeam.company_logo ? (
          <img src={currentTeam.company_logo} alt="" />
        ) : (
          fspLogo
        )
      );
    }
  };

  const hasClientLoggedIn = (userData) => {
    if (hasClient(userData)) {
      /** Set Current Team Id if any **/
      // !isObjectEmpty(currentTeamId) && setTeamId(userData.authData.team_id);
      emailVerified && setEmailVerified(false);
      return true;
    }
    return false;
  };
  /**** Match & Execute Valid Route ****/
  const executeRoute = (path, routeProps, landingUrl) => {
    const { isFetching, authData, authenticated } = userData;
    /** Check TeamUser Status is active or not  **/
    if (authenticated && ["Active", 1, "1"].indexOf(authData.status) > -1) {
      accountIsActivated =
        ["Active", 1, "1"].indexOf(authData.status) > -1 ? 1 : 0;
    }

    if (!isFetching && authenticated) {
      const ownersUrl = ["/owners", "/owner-details", "/settings/affiliate"];
      if (hasAffiliate(userData)) {
        if (!havePermission) {
          return (
            <React.Fragment>
              <PageNotFound error={"404"} setLoading={setLoading} />
            </React.Fragment>
          );
        } else if (urlsForAffiliate.indexOf(path) > -1) {
          return (
            <React.Fragment>
              <RouteComponent
                loading={loading}
                setLoading={setLoading}
                currentCompanySetting={currentCompanySetting}
                industry={industry}
              />
            </React.Fragment>
          );
        } else {
          return <Navigate to="/dashboard" />;
        }
      } else if (hasClientLoggedIn(userData)) {
        if (["/dashboard", "/", ""].indexOf(pathname) > -1) {
          return (
            <Navigate to="/client-dashboard" state={{ landingUrl: path }} />
          );
        } else if (
          [
            "/client-dashboard",
            "/my-profile",
            "/notifications",
            "/choose-company",
          ].indexOf(pathname) === -1 &&
          !pathname.includes("/agreement-review/")
        ) {
          return <PageNotFound error={"404"} setLoading={setLoading} />;
        } else {
          return (
            <RouteComponent
              loading={loading}
              setLoading={setLoading}
              currentCompanySetting={currentCompanySetting}
              industry={industry}
            />
          );
        }
      } else if (authData) {
        if (havePermission === false && urlExist === false) {
          return <PageNotFound error={"404"} setLoading={setLoading} />;
        } else if (havePermission === false && urlExist === true) {
          return <UpgradePlan error={"101"} setLoading={setLoading} />;
        } else if (authData.industry_info && path === "/choose-company") {
          return (
            <React.Fragment>
              <RouteComponent
                loading={loading}
                setLoading={setLoading}
                currentCompanySetting={currentCompanySetting}
                industry={industry}
              />
            </React.Fragment>
          );
        } else if (
          authData.currentTeam &&
          authData.currentTeam.id &&
          havePermission === true &&
          urlExist === true
        ) {
          if (path === "/choose-company" && landingUrl && landingUrl !== "/") {
            return <Navigate to={landingUrl} />;
          } else if (!authData.is_super_admin && ownersUrl.indexOf(path) > -1) {
            return <Navigate to="/dashboard" />;
          } else if (["/buy-addon", "/buy-plan"].indexOf(path) > -1) {
            /** Auto Selected Tab **/
            let tabName = null;
            if (["/buy-addon"].indexOf(path) > -1) {
              tabName = "addons";
            } else if (["/buy-plans"].indexOf(path) > -1) {
              tabName = "plan";
            }
            return (
              <Navigate
                to="/settings/accounts-and-billing"
                state={{ activeTab: tabName }}
              />
            );
          } else if (
            routeProps.path &&
            !isObjectEmpty(redirectAblePath[routeProps.path])
          ) {
            let paramId =
              params && params.id
                ? base64regex.test(params.id)
                  ? window.atob(params.id)
                  : params.id
                : null;

            if (routeProps.path === "/website-tracking-details") {
              return <Navigate to="/settings/tracking-script" />;
            }

            const contactId = paramId ? paramId : null;
            let id = paramId ? paramId : null;
            let activeTab = null;

            if (routeProps.path.includes("task")) {
              activeTab = "tasks";
              id = paramId ? paramId : null;
            } else if (routeProps.path.includes("appointment")) {
              activeTab = "appointments";
              id = paramId ? paramId : null;
            } else if (routeProps.path.includes("quote")) {
              activeTab = "quotes";
              id = paramId ? paramId : null;
            } else if (routeProps.path.includes("project")) {
              activeTab = "projects";
              id = paramId ? paramId : null;
            } else if (routeProps.path.includes("tracking")) {
              activeTab = "tracking-script";
              id = paramId ? paramId : null;
            } else if (routeProps.path.includes("deal")) {
              activeTab = null;
              id = paramId ? paramId : null;
            } else if (routeProps.path.includes("/agreement/")) {
              activeTab = "agreement";
              id = paramId ? paramId : null;
            }

            return (
              <Navigate
                to={redirectAblePath[routeProps.path]}
                state={{
                  id: contactId,
                  industry: getUserIndustry(userData),
                  redirectPageParams: {
                    activeTab: activeTab,
                    id: id,
                  },
                }}
              />
            );
          } else {
            return (
              <React.Fragment>
                <RouteComponent
                  loading={loading}
                  setLoading={setLoading}
                  currentCompanySetting={currentCompanySetting}
                  industry={industry}
                />
              </React.Fragment>
            );
          }
        } else if (hasAffiliate(userData)) {
          return <Navigate to="/affiliate/login" />;
        } else if (
          havePermission === true &&
          urlExist === true &&
          ["", "/", "/dashboard"].indexOf(pathname) > -1
        ) {
          return <Navigate to="/dashboard" />;
        }
      } else {
        console.log("HERE:6");
        return <Navigate to="/sign-in" state={{ landingUrl: path }} />;
      }
    }
  };

  /**** End Route Matching *****/
  if (
    !isFetchingData &&
    userData.isFetching === false &&
    !userData.authenticated
  ) {
    return <Navigate to="/sign-in" state={{ landingUrl: pathname }} />;
  }

  if (showAddCardModal) {
    return (
      <AddCard
        currentCompanySetting={currentCompanySetting}
        language={
          Session.getSessionVar("locale")
            ? Session.getSessionVar("locale")
            : null
        }
      />
    );
  }

  if (
    (emailVerified || [0, "0"].indexOf(accountIsActivated) > -1) &&
    !hasClientLoggedIn(userData)
  ) {
    return (
      <VerifyEmail
        showPopup={emailVerified}
        changeEmailForUnconfirmed={changeEmailForUnconfirmed}
        emailStatus={
          userData && userData.authData && userData.authData.email_v_status
            ? userData.authData.email_v_status
            : ""
        }
        email={
          userData && userData.authData && userData.authData.email
            ? userData.authData.email
            : ""
        }
        fetchUserSettings={authUser}
        currentCompanySetting={currentCompanySetting}
        language={
          Session.getSessionVar("locale")
            ? Session.getSessionVar("locale")
            : null
        }
        isUserActive={[0, "0"].indexOf(accountIsActivated) === -1}
      />
    );
  }

  const showHideNavigation = () => {
    if (
      rest.hasNavigation &&
      userData.authenticated &&
      pathname !== "/choose-company"
    ) {
      if (hasAffiliate(userData)) {
        return (
          <AffiliateNavigation
            pathName={newPath.replace("/", "")}
            companyLogo={companyLogo}
            userImage={
              <img
                style={{ borderRadius: "50%", width: "32px", height: "32px" }}
                src={profilePicture}
                width="32"
                height="32"
                alt="user"
              />
            }
          />
        );
      } else {
        return (
          <Navigation
            pathName={newPath.replace("/", "")}
            companyLogo={companyLogo}
            userImage={
              <img
                style={{ borderRadius: "50%", width: "32px", height: "32px" }}
                src={profilePicture}
                width="32"
                height="32"
                alt="user"
              />
            }
          />
        );
      }
    }
  };

  /********/
  return (
    <React.Fragment>
      {isFetchingData &&
      currentTeamId &&
      planData === null &&
      ["/", "/choose-company"].indexOf(pathname) === -1 ? (
        <React.Fragment>{/* <RoundLoaderWithOverlay /> */}</React.Fragment>
      ) : (
        <React.Fragment>
          {!emailVerified && showHideNavigation()}
          {executeRoute(
            pathname,
            rest,
            rest.location && rest.location.state
              ? rest.location.state.landingUrl
              : null
          )}

          <BuyAddonPlan />
        </React.Fragment>
      )}
      <IsTrialExpired />
      <ValidatePermission
        type={"url"}
        path={pathname}
        urlHasPermission={havePermission}
        setUrlHasPermission={setHavePermission}
        hasDefault={hasDefault}
        industry={industry}
      />
      {/* <ReviewPopup /> */}
    </React.Fragment>
  );
};

export default ProtectedRoutes;

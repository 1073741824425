import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";

import {
  currentTeamId,
  getValidationMessage,
  isObjectEmpty,
  toWordCase,
} from "../../Utils/utils";
import {
  FETCH_WABA,
  WHATSAPP_TEMPLATE_LIST,
} from "../../GraphApi/OmniChannels/WhatsappMessage";
import { ShowFieldsToFillData, buildTemplate, isJson } from "./WaBaUtil";
import { EyeSvg } from "../Common/SvgFiles/EyeSvg";
import WhatsappTemplatePreview from "./Template/WhatsappTemplatePreview";
import { overTheNextElement } from "../Common/DropDown/Style";
import InLineError from "../Common/AlertMessage/InLineError";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import RoundLoaderWithOverlay from "../Common/Loader/RoundLoader";

const documentsType = [
  { value: "{quote.pdf}", name: "{quote.name}" },
  { value: "{project.pdf}", name: "{project.name}" },
  { value: "{invoice.pdf}", name: "{invoice.name}" },
];

export const WhatsappTemplateDropdown = ({
  handleAlertMessage,
  selectedTemplate,
  setSelectedTemplate,
  locale,
  previewMode = "attach",
  selectedTemplateId = null,
  setSelectedTemplateId = () => {},
  error = null,
  label = "Select Whatsapp Template",
  value = null,
}) => {
  const location = useLocation();
  const [templateList, setTemplateList] = useState([]);
  const [showWhatsappPreview, setShowWhatsappPreview] = useState(false);
  const [templateHtml, setTemplateHtml] = useState(null);
  const [whatsAppAccounts, setWhatsAppAccount] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  //   const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [fetchWaba, { loading }] = useLazyQuery(FETCH_WABA, {
    fetchPolicy: "no-cache",
  });
  const [fetchWhatsappMessageTemplates, { loading: fetching }] = useLazyQuery(
    WHATSAPP_TEMPLATE_LIST
  );

  useEffect(() => {
    fetchWhatsappAccounts();
    getWhatsappTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTemplateId && templateList?.length > 0) {
      getDropDownValue(selectedTemplateId.template_id);
    }
  }, [selectedTemplateId, templateList]);

  const getWhatsappTemplates = () => {
    fetchWhatsappMessageTemplates({
      fetchPolicy: "no-cache",
      variables: {
        team_id: currentTeamId,
        status: "APPROVED",
        industry: "service",
      },
    })
      .then((result) => {
        if (result?.data?.error) {
          let { graphQLErrors } = result.data.error;
          handleAlertMessage(
            "error",
            locale("messages:backend." + getValidationMessage(graphQLErrors))
          );
        } else if (result?.data?.fetchWhatsappMessageTemplates) {
          filterTemplates(result.data.fetchWhatsappMessageTemplates);
        }
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
      });
  };

  const filterTemplates = async (data) => {
    const categoryWiseTemplate = [];

    if (data?.length > 0) {
      data.forEach((item) => {
        if (
          selectedTemplateId?.template_id &&
          item.id === selectedTemplateId.template_id
        ) {
          if (
            !isObjectEmpty(selectedTemplateId.component_params) &&
            !isObjectEmpty(selectedTemplateId.component_params.HEADER)
          ) {
            const exampleVar = isJson(item.example_variables)
              ? JSON.parse(item.example_variables)
              : {};

            item = {
              ...item,
              example_variables: JSON.stringify({
                ...exampleVar,
                image: selectedTemplateId.component_params.HEADER,
              }),
            };
          }

          // setSelectedTemplate({
          //   ...item,
          //   value: item.id,
          //   label: toWordCase(item.name),
          // });
          // setSelectedTemplateId(null);
        }

        if (
          categoryWiseTemplate.filter((cat) => cat.name === item.category)
            .length > 0
        ) {
          categoryWiseTemplate.forEach((cat, idx) => {
            if (cat.name === item.category) {
              categoryWiseTemplate[idx] = {
                ...categoryWiseTemplate[idx],
                options: [
                  ...categoryWiseTemplate[idx].options,
                  { ...item, value: item.id, label: toWordCase(item.name) },
                ],
              };
            }
          });
        } else {
          categoryWiseTemplate.push({
            name: item.category,
            label: (
              <h5 style={{ color: "#3B5998" }}>{toWordCase(item.category)}</h5>
            ),
            options: [
              { ...item, value: item.id, label: toWordCase(item.name) },
            ],
          });
        }
      });
    }
    setTemplateList(categoryWiseTemplate);
  };

  const handleTemplateSelection = (template) => {
    setSelectedTemplate(template);
  };

  const handleData = (idx, value, type) => {
    let variables =
      selectedTemplate.example_variables !== null &&
      isJson(selectedTemplate.example_variables)
        ? JSON.parse(selectedTemplate.example_variables)
        : {};

    if (["video", "image"].indexOf(type) > -1) {
      variables = { ...variables, [type]: [value] };
    } else if (["document"].indexOf(type) > -1) {
      variables = { ...variables, [type]: [value.path, value.name] };
    } else if (!isObjectEmpty(variables) && !isObjectEmpty(variables[type])) {
      if (!isObjectEmpty(variables[type][idx])) {
        variables[type][idx] = value;
      } else {
        variables[type][idx] = value;
      }
    } else {
      variables = { ...variables, [type]: [] };
      variables[type][idx] = value;
    }
    setSelectedTemplate({
      ...selectedTemplate,
      example_variables: JSON.stringify(variables),
    });
  };

  const getPreviewWhatsappTemplate = () => {
    const templatePreview = buildTemplate(selectedValue);
    setTemplateHtml(templatePreview);
    setShowWhatsappPreview(true);
  };

  const getDocument = () => {
    let variables = [{ header_text: [], body_text: [] }];
    if (
      selectedTemplate &&
      !isObjectEmpty(selectedTemplate.example_variables)
    ) {
      if (isJson(selectedTemplate.example_variables)) {
        variables = JSON.parse(selectedTemplate.example_variables);
      } else {
        variables = selectedTemplate.example_variables;
      }
    }

    let parmaName = null;
    if (variables && (variables.image || variables.document)) {
      documentsType.forEach((item) => {
        if (
          variables.image &&
          variables.image.length > 0 &&
          item.value === variables.image[0]
        ) {
          parmaName = (
            <p>
              <span>URL : </span> {item.value}
            </p>
          );
        } else if (
          variables.document &&
          variables.document.length > 0 &&
          item.value === variables.document[0]
        ) {
          parmaName = (
            <p>
              <span>URL : </span> {item.value}
            </p>
          );
        }
      });
    }
    return parmaName;
  };

  const checkToShowUploader = () => {
    let hasPermissionToUpload = true;
    let variables = null;
    if (
      selectedTemplate &&
      !isObjectEmpty(selectedTemplate.example_variables)
    ) {
      if (isJson(selectedTemplate.example_variables)) {
        variables = JSON.parse(selectedTemplate.example_variables);
      } else {
        variables = selectedTemplate.example_variables;
      }
    }

    if (location.pathname === "/campaign-builder") {
      if (variables && (variables.image || variables.document)) {
        documentsType.forEach((item) => {
          if (
            variables.image &&
            variables.image.length > 0 &&
            item.value === variables.image[0]
          ) {
            hasPermissionToUpload = false;
          } else if (
            variables.document &&
            variables.document.length > 0 &&
            item.value === variables.document[0]
          ) {
            hasPermissionToUpload = false;
          }
        });
      }
    }
    return hasPermissionToUpload;
  };

  const fetchWhatsappAccounts = () => {
    fetchWaba({
      variables: { team_id: currentTeamId },
      fetchPolicy: "no-cache",
    })
      .then((result) => {
        if (!result?.data?.error && result?.data?.fetchWaba) {
          setWhatsAppAccount(result.data.fetchWaba);
        } else if (result.data && result.data.error) {
          handleAlertMessage(
            "error",
            locale(
              "messages:backend." + getValidationMessage(result.data.error)
            )
          );
          setWhatsAppAccount([]);
        }
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
      });
  };

  const getDropDownValue = (value) => {
    if (!isObjectEmpty(value)) {
      if (templateList?.length > 0) {
        let selected = null;
        templateList.forEach((item) => {
          if (item?.options) {
            if (
              Array.isArray(item.options) &&
              item.options.length > 0 &&
              selected === null
            ) {
              item.options.forEach((option) => {
                if (option.value === value) {
                  selected = option;
                }
              });
            } else if (item.value === value) {
              selected = item;
            }
          }
        });
        setSelectedValue(selected);
      }
    } else {
      setSelectedValue(null);
    }
  };

  if (loading || fetching) {
    return <RoundLoaderWithOverlay />;
  }
  /*******/
  return (
    <div className="message-typing-area mb-2">
      {whatsAppAccounts?.length > 0 ? (
        <Form.Group className="form-group" style={{ position: "relative" }}>
          <Form.Label className={"me-2"}>{label}</Form.Label>
          <div style={{ flex: " 1 1" }}>
            <Select
              // isClearable
              options={templateList}
              id="template_name"
              value={selectedValue || ""}
              onChange={(e) => {
                handleTemplateSelection(e);
              }}
              styles={overTheNextElement}
            />
          </div>
          <InLineError type="error" message={error ? error : null} />
        </Form.Group>
      ) : (
        <React.Fragment>
          {label}
          <p className="text-danger mt-3">
            Please configure whatsapp account to send messages on whatsapp.
            <br />
            <Link to="/settings/whatsapp-management">Configure Whatsapp</Link>
          </p>
        </React.Fragment>
      )}

      {previewMode === "button" && (
        <div className="d-flex justify-content-end">
          {selectedValue && (
            <React.Fragment>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={getPreviewWhatsappTemplate}
              >
                <EyeSvg />
                <span>{locale("preview")}</span>
              </Button>
            </React.Fragment>
          )}
        </div>
      )}

      {selectedValue && (
        <Row className="m-2">
          {previewMode !== "button" && (
            <Col>{buildTemplate(selectedValue)}</Col>
          )}
          {previewMode !== "selectionOnly" && (
            <Col>
              {!checkToShowUploader() ? (
                getDocument()
              ) : (
                <ShowFieldsToFillData
                  selectedTemplate={selectedValue}
                  handleData={handleData}
                  locale={locale}
                  showMedia={true}
                />
              )}
            </Col>
          )}
        </Row>
      )}

      {showWhatsappPreview && (
        <WhatsappTemplatePreview
          show={showWhatsappPreview}
          locale={locale}
          templateHtml={templateHtml}
          handleClose={() => {
            setShowWhatsappPreview(false);
            setTemplateHtml(null);
          }}
        />
      )}
    </div>
  );
};

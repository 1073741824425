import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import InLineError from '../Common/AlertMessage/InLineError';
import {
  currentTeamId,
  getValidationMessage,
  isObjectEmpty,
} from '../../Utils/utils';
import { generateImageViaAI } from '../../GraphApi/TooTooAPI';
import { EqupRoundLoaderWithOverlay } from '../Common/Loader/RoundLoader';
import XAlertMessage from '../Common/AlertMessage/XAlertMessage';
import { useMutation } from '@apollo/client';
import { IMAGE_UPLOAD } from '../../GraphApi/ImageGallery';

export const AIImageGenerator = ({
  getGalleryData,
  handleGeneratedImages = () => {},
}) => {
  /** Alert Message State **/
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  /****/
  const [isSubmitting, setIsSubMitting] = useState(false);
  const [isCustomPrompt, setIsCustomPrompt] = useState(false);
  const [customPromptText, setCustomPromptText] = useState(null);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [uploadImage, { loading }] = useMutation(IMAGE_UPLOAD);

  const hideCustomPrompt = () => {
    setCustomPromptText(null);
    setIsCustomPrompt(false);
  };

  const searchForCustomPrompt = () => {
    if (isObjectEmpty(customPromptText)) {
      setError({
        customPrompt:
          'For this action, it is essential to provide a description.',
      });
    } else {
      generateImageWithAI(customPromptText);
    }
  };

  const generateImageWithAI = (custom_prompt = null) => {
    setIsSubMitting(true);

    const dataToBePost = {
      team_id: currentTeamId,
      prompt: custom_prompt,
    };

    generateImageViaAI(dataToBePost)
      .then((result) => {
        console.log('Result:', result.data);
        if (['true', true].indexOf(result.data.status) > -1) {
          if (result.data.content.length > 0) {
            setImages(result.data.content);
          } else {
            handleAlertMessage('error', 'Unable to generate images.');
          }
        } else {
          console.log('Error:', result.data);
          handleAlertMessage('error', result.data.message);
        }
        setIsSubMitting(false);
        // hideCustomPrompt();
      })
      .catch((error) => {
        console.log('Error:', error.response.data);
        handleAlertMessage('error', error.response.data.message);
        setIsSubMitting(false);
        // hideCustomPrompt();
      });
  };

  const handleAlertMessage = (type, message) => {
    setType(type);
    setMessage(message);
    setAlertShow(true);
  };

  const imageToBeUpload = () => {
    if (selectedFiles.length === 0) {
      handleAlertMessage('error', 'No file selected.');
    } else {
      uploadImage({
        variables: {
          team_id: currentTeamId,
          urls: selectedFiles,
        },
      })
        .then((result) => {
          handleAlertMessage('success', 'images saved to gallery');
          getGalleryData();
          setTimeout(() => {
            handleGeneratedImages(result.data.saveEmailTemplateGallery);
            hideCustomPrompt();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          let { graphQLErrors } = error;
          handleAlertMessage('error', getValidationMessage(graphQLErrors));
        });
    }
  };
  const handleSelectedFiles = (url) => {
    // Check if the target URL is in the array
    const index = selectedFiles.indexOf(url);
    if (index > -1) {
      // If the target URL exists, remove it from the array
      selectedFiles.splice(index, 1);
      setSelectedFiles([...selectedFiles]);
    } else {
      // If the target URL doesn't exist, add it to the array
      // selectedFiles.push(url);
      setSelectedFiles([url]);
    }
  };
  /******/
  return (
    <React.Fragment>
      {(isSubmitting || loading) && <EqupRoundLoaderWithOverlay />}

      <Button
        variant={'outline-primary'}
        onClick={() => setIsCustomPrompt(true)}
      >
        Generate Image
      </Button>

      <Modal
        className={'common modalMd'}
        show={isCustomPrompt}
        onHide={hideCustomPrompt}
        keyboard={false}
        backdrop={'static'}
        enforceFocus={false}
        style={{ zIndex: 99999 }}
        centered
      >
        <Modal.Header
          closeVariant='white'
          closeButton
          className={'bg-primary'}
        >
          <Modal.Title className={'text-white secondary-font text-uppercase'}>
            AI Art Image Generator
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isSubmitting || loading) && <EqupRoundLoaderWithOverlay />}
          <div>
            <XAlertMessage
              type={type}
              message={message}
              setAlertShow={setAlertShow}
              alertShow={alertShow}
            />
          </div>
          {images && images.length > 0 ? (
            <React.Fragment>
              <Row className='uploaded-imgs-sec'>
                {images.map((image, idx) => (
                  <Col
                    key={idx}
                    style={{ maxWidth: '30%', flex: '0 0 30%' }}
                  >
                    <div
                      className={'uploaded-item border p-1 ms-1'}
                      onClick={() => handleSelectedFiles(image.url)}
                    >
                      <img
                        src={image.url}
                        alt=''
                        className={'w-100'}
                      />

                      {selectedFiles.indexOf(image.url) > -1 && (
                        <span className={'default'}>
                          <i className={'ri-check-fill'}></i>
                        </span>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
              <Row className='mt-2'>
                <Col className='text-center'>
                  <Button
                    onClick={() => setImages([])}
                    className='btn-ms-icon m-1'
                  >
                    Re Generate Image
                  </Button>
                  {selectedFiles && selectedFiles.length > 0 && (
                    <Button
                      onClick={imageToBeUpload}
                      className='btn-ms-icon m-1'
                    >
                      Use Image
                    </Button>
                  )}
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Row>
                <Col>
                  <Form.Group className='form-group'>
                    <Form.Label>Describe your creation in detail</Form.Label>
                    <Form.Control
                      className={
                        error && error.customPrompt ? 'is-invalid' : ''
                      }
                      name='customPrompt'
                      as='textarea'
                      rows='5'
                      placeholder={'Custom Prompt'}
                      value={customPromptText ? customPromptText : ''}
                      onChange={(e) => {
                        if (
                          e &&
                          e.target & e.target.value &&
                          e.target.value.length > 500
                        ) {
                          setError({
                            customPrompt:
                              'The description text may have a maximum length of 500 characters.',
                          });
                        } else {
                          setCustomPromptText(e.target.value);
                        }
                      }}
                    />
                    <InLineError
                      type='error'
                      name='customPrompt'
                      message={
                        error && error.customPrompt ? error.customPrompt : ''
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-1'>
                <Col>
                  <Button
                    onClick={searchForCustomPrompt}
                    className='btn-ms-icon'
                  >
                    Generate Image
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

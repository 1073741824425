import React from "react";

export const MessageSvg = ({ active = false }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8 0H2.2C0.99 0 0 0.99 0 2.2V15.4C0 16.61 0.99 17.6 2.2 17.6H17.6L22 22V2.2C22 0.99 21.01 0 19.8 0ZM18.48 4.67502H3.52004C2.49154 4.67502 1.65004 5.51652 1.65004 6.54502V14.19C1.65004 15.2185 2.49154 16.06 3.52004 16.06H17.985L20.35 18.15V6.54502C20.35 5.51652 19.5085 4.67502 18.48 4.67502Z"
        fill={active ? "#58C29A" : "#4C4E61"}
      ></path>
      <path
        d="M6.60004 9.89439L9.81714 13.2001L15.4 7.47553L14.5376 6.6001L9.80598 11.4378L7.44018 9.01896L6.60004 9.89439Z"
        fill={active ? "#58C29A" : "#4C4E61"}
      ></path>
    </svg>
  );
};

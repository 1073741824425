import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useEmailCredentials } from "../../../../../Hooks/useEmailCredentials";
import { EmailIntegration } from "../../../../../Components/Common/Email/EmailIntegration";
import {
  currentTeamId,
  emailTemplateForPreview,
  getValidationMessage,
} from "../../../../../Utils/utils";
import { useTranslation } from "react-i18next";
import { AddImapSmtp } from "../../../../../Components/Common/Email/AddImapSmtp";
import { EqupRoundLoaderWithOverlay } from "../../../../../Components/Common/Loader/RoundLoader";

import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { useFormik } from "formik";
import {
  // EMAIL_BY_ID,
  SAVE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
} from "../../../../../GraphApi/Emails";
import { getValidationSchema } from "../../../../../Utils/formikValidator";
import { useMutation } from "@apollo/client";
import { CreateNewEmail } from "../../../../../Components/Common/EmailTemplate/CreateNewEmail";
import { emailSignatureString } from "../../../../../Components/Common/Helper/CommonHelper";

const fieldsToBeValidate = [
  {
    type: "text",
    name: "name",
    isRequired: true,
    maxLength: 255,
    minLength: 3,
    isNullable: false,
  },
  {
    type: "textArea",
    name: "subject",
    isRequired: true,
    maxLength: 1000,
    minLength: 3,
    isNullable: false,
  },
  {
    type: "textArea",
    name: "message",
    isRequired: true,
    maxLength: 30000,
    minLength: 5,
    isNullable: false,
  },
];

export const SendPersonalEmailAction = ({
  handleAlertMessage = () => {},
  userData,
  currentCompanySetting,
  values,
  handleChange = () => {},
  handleClose = () => {},
}) => {
  const { t: locale } = useTranslation();
  const [emailDetails, setEmailDetails] = useState({
    from: null,
    from_user: null,
    email_id: null,
  });
  const [templateType] = useState("conversation");
  const [linkedAccountEmail, setLinkedAccountEmail] = useState(null);
  const [addImapSmtpDrawer, setAddImapSmtpDrawer] = useState(false);
  const [errorBag, setErrorBag] = useState(null);
  const [showTemplateName, setShowTemplateName] = useState(false);
  const [emailTemplateForClone, setEmailTemplateForClone] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mode, setMode] = useState("create");
  /****/
  const [validationSchema] = useState(
    getValidationSchema(fieldsToBeValidate, locale)
  );
  /*****/
  const [saveEmailTemplate] = useMutation(SAVE_EMAIL_TEMPLATE);
  const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE);
  // const [getEmailById] = useLazyQuery(EMAIL_BY_ID);
  const { linkedEmail, loading, fetchCredentials } = useEmailCredentials();

  useEffect(() => {
    fetchCredentials();
  }, [fetchCredentials, linkedAccountEmail]);

  useEffect(() => {
    if (linkedEmail) {
      setEmailDetails({
        ...emailDetails,
        from: linkedEmail,
        from_user: "<" + linkedEmail + ">",
        from_name: userData?.authData?.name || "",
      });
    }
  }, [linkedEmail]);

  const setLinkedAccountState = (vala, valb) => {
    setLinkedAccountEmail(vala);
  };

  const handleAddImapSmtpDrawer = () => {
    setAddImapSmtpDrawer(false);
  };

  const handelCancel = () => {
    handleClose();
  };

  const showSaveTemplateData = () => {
    setShowTemplateName(true);
  };

  const handleCancelShowTemplate = () => {
    setShowTemplateName(false);
  };

  const handleSaveTemplateData = () => {
    formik.handleSubmit();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit(values) {
      processData(values);
    },
  });

  useEffect(() => {
    formik.setValues({
      name: "",
      subject: "",
      message: "",
    });
    setEmailTemplateForClone(null);
  }, []);

  const processData = (data) => {
    setIsSubmitting(true);
    const dataToBeProcess = {
      name: data.name,
      subject: data.subject,
      message: encodeMessage(data.message),
      template_type: templateType,
      team_id: currentTeamId,
    };

    if (
      mode === "create" ||
      [true, null].includes(emailTemplateForClone?.isSystemEmail)
    ) {
      saveEmailTemplate({
        variables: dataToBeProcess,
      })
        .then((result) => {
          handleAlertMessage(
            "success",
            locale("messages:common.create", { fieldName: locale("Template") })
          );
          setIsSubmitting(false);
          handleCancelShowTemplate();
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          console.log(graphQLErrors, error);
          setIsSubmitting(false);
          handleAlertMessage(
            "error",
            locale("messages:backend." + getValidationMessage(graphQLErrors))
          );
        });
    } else if (mode === "edit") {
      updateEmailTemplate({
        variables: {
          ...dataToBeProcess,
          id: parseInt(emailTemplateForClone.id),
        },
      })
        .then((result) => {
          handleAlertMessage(
            "success",
            locale("messages:common.update", { fieldName: locale("Template") })
          );
          setIsSubmitting(false);
          handleCancelShowTemplate();
          console.log("Here:", result.data);
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          console.log(graphQLErrors, error);
          setIsSubmitting(false);
          handleAlertMessage(
            "error",
            locale("messages:backend." + getValidationMessage(graphQLErrors))
          );
        });
    }
  };

  const encodeMessage = (message) => {
    try {
      return window.btoa(encodeURIComponent(message));
    } catch (e) {
      handleAlertMessage(
        "error",
        locale(
          "messages:common.message contain some hidden character, remove them and save again."
        )
      );
      return null;
    }
  };

  const handleSaveData = () => {
    const emailDetail = formik?.values || {};
    let newErrors = {};
    if (!emailDetail?.subject) {
      newErrors = { ...newErrors, subject: "Subject is required." };
    }
    if (!emailDetail?.message) {
      newErrors = { ...newErrors, message: "Message is required." };
    }

    setErrorBag(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let str = [];
      str.push(<span key="1">Send </span>);
      str.push(
        <span key="2" className="text-primary">
          {emailDetail?.subject || ""}
        </span>
      );
      str.push(<span key="3"> Email</span>);
      handleChange("action", {
        action: "send_1:1_email",
        email_details: {
          ...emailDetails,
          email_body: emailDetail?.message
            ? encodeMessage(emailDetail.message)
            : "",
          subject: emailDetail?.subject || "",
        },
        string: str,
      });
      handleClose();
    }
  };

  const loadData = () => {
    if (values?.email_details) {
      const msg = emailTemplateForPreview(
        values?.email_details?.email_body,
        templateType
      );

      formik.setValues({
        name: "",
        subject: values?.email_details?.subject || "",
        message: msg || "",
      });
      setEmailTemplateForClone(null);
    }
  };

  useEffect(() => {
    if (emailTemplateForClone !== null) {
      setEmailDetails({
        ...emailDetails,
        email_id: emailTemplateForClone?.id || emailDetails?.email_id || null,
      });
      const msg = emailTemplateForPreview(
        emailTemplateForClone.message,
        emailTemplateForClone.template_type
      );
      formik.setFieldValue("name", emailTemplateForClone?.name || "");
      formik.setFieldValue("subject", emailTemplateForClone?.subject || "");
      addDefaultCssToParagraph(
        msg,
        "message",
        emailTemplateForClone?.isSystemEmail || false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateForClone]);

  useEffect(() => {
    if (values?.email_details) {
      setTimeout(() => {
        loadData();
      }, 1000);
    }
  }, [values]);

  useEffect(() => {
    if (
      !values?.email_details?.email_body &&
      userData &&
      currentCompanySetting &&
      formik?.values?.message === ""
    ) {
      formik.setFieldValue(
        "message",
        emailSignatureString(userData, currentCompanySetting)
      );
    }
  }, [formik]);

  const addDefaultCssToParagraph = (msg, name, withSignature = true) => {
    let customContent = "";
    if (msg && msg.length > 0) {
      customContent = msg.replace(/<p>/g, '<p style="margin:0;padding:0">');
    } else {
      customContent = msg ? msg : "";
    }
    if (withSignature) {
      customContent = customContent.concat(
        emailSignatureString(userData, currentCompanySetting)
      );
    }
    formik.setFieldValue(name, customContent);
  };

  if (loading) {
    return <EqupRoundLoaderWithOverlay />;
  }
  // /*****/
  return (
    <React.Fragment>
      {!linkedEmail ? (
        <div>
          <Row className="mx-2 p-1">
            <Col>
              <div
                className={
                  "pb-4 me-auto ms-auto text-center integrate-via-wrap"
                }
              >
                <EmailIntegration
                  setLinkedAccountState={setLinkedAccountState}
                  setAddImapSmtpDrawer={setAddImapSmtpDrawer}
                  team_id={currentTeamId}
                  locale={locale}
                />
              </div>
            </Col>
          </Row>
          <div className="sticky-on-footer py-2">
            <Button variant="outline-secondary" onClick={handelCancel}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Row className="mx-2 p-1">
            <Col md={12} lg={12} sm={12} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>From</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={emailDetails?.from_user || ""}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col md={12} lg={12} sm={12} xs={12}>
              <CreateNewEmail
                formik={formik}
                emailDetails={emailDetails}
                setEmailTemplateForClone={setEmailTemplateForClone}
                templateType={templateType}
              />
            </Col>
          </Row>
        </div>
      )}
      <div className="bg-dark text-end sticky-footer">
        <div className="d-flex justify-content-between">
          <Button
            variant="outline-danger"
            className="me-2"
            onClick={showSaveTemplateData}
          >
            Save as Template
          </Button>
          <div className="text-end">
            <Button
              variant="outline-primary"
              className="me-2"
              onClick={handleSaveData}
            >
              Save
            </Button>
            <Button variant="outline-secondary" onClick={handelCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <Modal
        className={"right"}
        show={addImapSmtpDrawer}
        onHide={handleAddImapSmtpDrawer}
      >
        <AddImapSmtp
          handleCloseAddEmail={handleAddImapSmtpDrawer}
          setLinkedAccountState={setLinkedAccountState}
          team_id={currentTeamId}
          locale={locale}
        />
      </Modal>

      <Modal
        className={"common"}
        centered
        show={showTemplateName}
        onHide={handleCancelShowTemplate}
      >
        <Modal.Header className="bg-primary">
          <Modal.Title className={"text-white secondary-font text-uppercase"}>
            Save as template to re-use in future
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="form-group">
            <Form.Label>{locale("Template Name")}</Form.Label>
            <Form.Control
              id={"name"}
              type="text"
              placeholder={locale("Enter Template Name")}
              value={formik?.values?.name || ""}
              onChange={(e) => {
                formik.setFieldValue("name", e?.target?.value || "");
              }}
            />
            <InLineError type="error" message={errorBag?.name || ""} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            className="me-2"
            onClick={handleCancelShowTemplate}
          >
            cancel
          </Button>
          <Button
            variant="outline-primary"
            className="me-2"
            onClick={handleSaveTemplateData}
          >
            Save as template
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export const triggerActionIcons = {
  approver_requested_update: <i className="ri-file-transfer-line fs-5 " />,
  approved_by_approver: <i className="ri-file-check-line fs-5 " />,
  under_client_review: <i className="ri-file-info-line fs-5 " />,
  client_requested_update: <i className="ri-file-transfer-line fs-5 " />,
  approved_by_client: <i className="ri-file-check-line fs-5 " />,
  sent_for_signature: <i className="ri-sketching fs-5 " />,
  viewed_by_client: <i className="ri-file-text-line fs-5 " />,
  partially_completed: <i className="ri-file-edit-line fs-5 " />,
  completed_by_client: <i className="ri-file-paper-line fs-5 " />,
  rejected_by_client: <i className="ri-file-forbid-line fs-5 " />,
  appointment_scheduled: <i className="ri-calendar-schedule-line fs-5 " />,
  appointment_rescheduled: <i className="ri-calendar-check-line fs-5 " />,
  appointment_cancelled: <i className="ri-calendar-close-line fs-5 " />,
  before_appointment: <i className="ri-calendar-schedule-line fs-5 " />,
  whatsapp_message_received: <i className="ri-whatsapp-line fs-5 " />,
  sms_received: <i className="ri-message-2-line fs-5 " />,
  replied_email: <i className="ri-mail-unread-line fs-5 " />,
  call_webhook: <i className="ri-share-forward-2-fill fs-5 " />,
  conditions: <i className="ri-git-merge-line fs-5 " />,
  connect_to: <i className="ri-corner-right-up-line fs-5 " />,
  delay_timer: <i className="ri-timer-line fs-5 " />,
  start_an_automation: <i className="ri-play-circle-line fs-5 " />,
  update_contact: <i className="ri-contacts-line fs-5 " />,
  request_document: <i className="ri-file-shield-line fs-5 " />,
  request_form_to_fill: <i className="ri-file-shield-line fs-5 " />,
  send_agreement: <i className="ri-file-transfer-line fs-5 " />,
  send_estimate: <i className="ri-folder-transfer-line fs-5 " />,
  start_email_sequence: <i className="ri-mail-settings-line fs-5 " />,
  add_lead_score: <i className="ri-add-box-line fs-5 " />,
  tag: <i className="ri-bookmark-line fs-5 " />,
  apply_tag: <i className="ri-bookmark-line fs-5 " />,
  assign_contact_owner: <i className="ri-pass-valid-line fs-5 " />,
  assign_team_member: <i className="ri-pass-valid-line fs-5 " />,
  assign_deal_owner: <i className="ri-id-card-line fs-5 " />,
  assign_users: <i className="ri-pass-valid-line fs-5 " />,
  create_deal: <i className="ri-suitcase-line fs-5 " />,
  create_note: <i className="ri-file-text-line fs-5 " />,
  create_task: <i className="ri-task-line fs-5 " />,
  enable_customer_portal: <i className="ri-checkbox-circle-line fs-5 " />,
  remove_duplicate_contact: <i className="ri-delete-bin-7-line fs-5 " />,
  update_deal_stage: <i className="ri-suitcase-line fs-5 " />,
  update_lifecycle_stage: <i className="ri-recycle-line fs-5 " />,
  update_life_cycle: <i className="ri-recycle-line fs-5 " />,
  team_notification: <i className="ri-notification-3-line fs-5 " />,
  notify_team_member: <i className="ri-notification-3-line fs-5 " />,
  broadcast: <i className="ri-broadcast-line fs-5 " />,
  contact_created: <i className="ri-contacts-book-2-line fs-5 " />,
  contact_updated: <i className="ri-contacts-book-upload-line fs-5 " />,
  lifecycle_stage: <i className="ri-recycle-line fs-5 " />,
  scheduler: <i className="ri-calendar-schedule-line fs-5 " />,
  tag_applied: <i className="ri-bookmark-line fs-5 " />,
  tag_removed: <i className="ri-bookmark-2-line fs-5 " />,
  task_status: <i className="ri-task-line fs-5 " />,
  email_opened: <i className="ri-mail-open-line fs-5 " />,
  email_clicked: <i className="ri-mail-check-line fs-5 " />,
  email_sent: <i className="ri-mail-send-line fs-5 " />,
  email_bounce: <i className="ri-mail-forbid-line fs-5 " />,
  email_unsubscribed: <i className="ri-mail-close-line fs-5 " />,
  under_team_review: <i className="ri-team-line fs-5 " />,
  approver_requested_update: <i className="ri-folder-transfer-line fs-5 " />,
  approved_by_approver: <i className="ri-folder-check-line fs-5 " />,
  under_client_approval: <i className="ri-folder-info-line fs-5 " />,
  client_requested_update: <i className="ri-folder-transfer-line fs-5 " />,
  approved_by_client: <i className="ri-folder-check-line fs-5 " />,
  rejected_by_client: <i className="ri-folder-close-line fs-5 " />,
  viewed_by_client: <i className="ri-folder-3-line fs-5 " />,
  converted_to_job: <i className="ri-folder-shared-line fs-5 " />,
  send_marketing_email: <i className="ri-mail-line fs-5 " />,
  "send_1:1_email": <i className="ri-mail-settings-line fs-5 " />,
  send_personal_email: <i className="ri-mail-settings-line fs-5 " />,
  send_1_1_email: <i className="ri-mail-settings-line fs-5 " />,
  send_transactional_email: <i className="ri-mail-star-line fs-5 " />,
  send_sms: <i className="ri-message-2-line fs-5 " />,
  send_whatsapp_message: <i className="ri-whatsapp-line fs-5 " />,
  send_appointments: <i className="ri-calendar-line fs-5 " />,
  request_appointment: <i className="ri-calendar-line fs-5 " />,
  job_created: <i className="ri-shield-line fs-5 " />,
  job_scheduled: <i className="ri-shield-check-line fs-5 " />,
  job_on_hold: <i className="ri-delete-back-line fs-5 " />,
  job_inprogress: <i className="ri-shield-flash-line fs-5 " />,
  job_completed: <i className="ri-shield-star-line fs-5 " />,
  job_cancelled: <i className="ri-delete-back-2-line fs-5 " />,
  job_form_sent: <i className="ri-survey-line fs-5 " />,
  job_form_submitted: <i className="ri-file-list-line fs-5 " />,
  convert_to_job: <i className="ri-folder-shared-line fs-5 " />,
  payment_received: <i className="ri-money-dollar-circle-line fs-5 " />,
  payment_failed: <i className="ri-close-circle-line fs-5 " />,
  payment_due_in: <i className="ri-calendar-view fs-5 " />,
  payment_overdue: <i className="ri-calendar-2-line fs-5 " />,
  deal_created: <i className="ri-suitcase-line fs-5 " />,
  deal_updated: <i className="ri-suitcase-line fs-5 " />,
  deal_stage_updated: <i className="ri-suitcase-line fs-5 " />,
  service_request_received: <i className="ri-arrow-down-line fs-5 " />,
  service_request_cancelled: <i className="ri-calendar-close-fill fs-5 " />,
  before_service_request_schedule: (
    <i className="ri-calendar-schedule-line fs-5 " />
  ),
  service_visit_scheduled: <i className="ri-calendar-schedule-line fs-5 " />,
  service_visit_dispatched: <i className="ri-pass-valid-line fs-5 " />,
  service_visit_inprogress: <i className="ri-progress-5-line fs-5 " />,
  service_visit_completed: <i className="ri-progress-8-line fs-5 " />,
  service_visit_cancelled: <i className="ri-close-circle-line fs-5 " />,
  before_service_visit_schedule: (
    <i className="ri-calendar-schedule-line fs-5 " />
  ),
  sms_sent: <i className="ri-chat-forward-line fs-5 " />,
  sms_delivered: <i className="ri-chat-check-line fs-5 " />,
  sms_failed: <i className="ri-chat-delete-line fs-5 " />,
  web_form_submitted: <i className="ri-article-line fs-5 " />,
  landing_page_visited: <i className="ri-pages-line fs-5 " />,
  whatsapp_message_sent: <i className="ri-whatsapp-line fs-5 " />,
  whatsapp_message_read: <i className="ri-whatsapp-line fs-5 " />,
  whatsapp_message_failed: <i className="ri-whatsapp-line fs-5 " />,
  whatsapp_message_delivered: <i className="ri-whatsapp-line fs-5 " />,
  send_form: <i className="ri-pages-line fs-5" />,
  send_service_request_form: <i className="ri-calendar-view fs-5" />,
  send_booking_link: <i className="ri-calendar-view fs-5" />,
  update_to_crm: <i className="ri-contacts-line fs-5 " />,
  initiate_communications: <i className="ri-mail-line fs-5 " />,
};

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ImportAction } from "./ImportAction";
import { ManuallyAdd } from "./ManuallyAdd";
import { Call } from "./Call";
import { WhatsappMessageReceived } from "./WhatsappMessageReceived";
import { OnlineBooking } from "./OnlineBooking";
import { ChatBox } from "./ChatBox";
import { FormSubmission } from "./FormSubmission";
import { toWordCase } from "../../../../../Utils/utils";
import "./setting-drawer.scss";
import { DelayTimerAction } from "./DelayTimerAction";
import { UpdateContactAction } from "./UpdateContact";
import { RequestDocumentAction } from "./RequestDocumentAction";
import { SendAgreementAction } from "./SendAgreementAction";
import { SendEstimateAction } from "./SendEstimateAction";
import { EmailAction } from "./EmailAction";
import { TeamNotificationAction } from "./TeamNotificationAction";
import { AssignContactOwnerAction } from "./AssignContactOwnerAction";
import { CreateDealAction } from "./CreateDealAction";
import { TagAction } from "./TagAction";
import { UpdateLifeCycleAction } from "./UpdateLifeCycleAction";
import { CreateTaskAction } from "./CreateTaskAction";
import { SendSMSAction } from "./SendSMSAction";
import { SendWhatsappAction } from "./SendWhatsappAction";
import { MarketingEmailAction } from "./MarketingEmailAction";
import { SendPersonalEmailAction } from "./SendPersonalEmailAction";
import { TransactionalEmailAction } from "./TransactionalEmailAction";
import { UpdateDealStageAction } from "./UpdateDealStageAction";
import { RemoveDuplicateAction } from "./RemoveDuplicateAction";
import { ClientPortalAction } from "./ClientPortalAction";
import { CreateNoteAction } from "./CreateNoteAction";
import { AssignDealOwnerAction } from "./AssignDealOwnerAction";
import { LeadScoreAction } from "./LeadScoreAction";

export const BusinessFlowSettingDrawer = ({
  actions,
  show = false,
  index = null,
  handleClose,
  handleChange,
  currentCompanySetting,
}) => {
  const getAutomationTrigger = () => {
    if (index !== null && actions.length > 0) {
      const name = actions[index]?.elementName || "";
      const data = actions[index] || null;

      switch (name) {
        case "form_submission":
          return (
            <FormSubmission
              label="Select Form"
              value={data?.form_id || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "chat_bot":
          return (
            <ChatBox
              value={data?.chat_box || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "online_booking":
          return (
            <OnlineBooking
              value={data?.booking || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "whatsapp":
          return (
            <WhatsappMessageReceived
              value={data?.whatsapp_message || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "call":
          return (
            <Call
              value={data?.call || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "manually_add":
          return (
            <ManuallyAdd
              value={data?.manually_add || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "import":
          return (
            <ImportAction
              value={data?.import || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "delay_timer":
          return (
            <DelayTimerAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "update_contact":
          return (
            <UpdateContactAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "request_document":
          return (
            <RequestDocumentAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "send_agreement":
          return (
            <SendAgreementAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "send_estimate":
          return (
            <SendEstimateAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "request_appointment":
        case "send_booking_link":
        case "send_form":
          return (
            <EmailAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
              defaultButton={
                name === "request_appointment"
                  ? "appointment"
                  : name === "send_booking_link"
                    ? "booking"
                    : name === "send_form"
                      ? "form"
                      : ""
              }
            />
          );
          break;
        case "notify_team_member":
          return (
            <TeamNotificationAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "assign_team_member":
          return (
            <AssignContactOwnerAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "create_deal":
          return (
            <CreateDealAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "create_deal":
          return (
            <CreateDealAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "apply_tag":
          return (
            <TagAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "update_life_cycle":
          return (
            <UpdateLifeCycleAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "create_task":
          return (
            <CreateTaskAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "send_sms":
          return (
            <SendSMSAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "send_whatsapp_message":
          return (
            <SendWhatsappAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "send_marketing_email":
          return (
            <MarketingEmailAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "send_personal_email":
          return (
            <SendPersonalEmailAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "send_transactional_email":
          return (
            <TransactionalEmailAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "update_deal_stage":
          return (
            <UpdateDealStageAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "remove_duplicate_contact":
          return (
            <RemoveDuplicateAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "enable_customer_portal":
          return (
            <ClientPortalAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "create_note":
          return (
            <CreateNoteAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "assign_deal_owner":
          return (
            <AssignDealOwnerAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        case "add_lead_score":
          return (
            <LeadScoreAction
              currentCompanySetting={currentCompanySetting}
              values={data || ""}
              handleChange={(key, value) => handleChange(index, key, value)}
              handleClose={handleClose}
            />
          );
          break;
        default:
          return "";
          break;
      }
    }
  };

  /******/
  return (
    <Modal
      className={
        "right " +
        (["request_appointment", "send_form", "send_booking_link"].includes(
          actions[index]?.elementName
        )
          ? "modalXl"
          : "")
      }
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      enforceFocus={false}
    >
      <Modal.Header>
        <Modal.Title>
          {toWordCase(actions[index]?.elementName) || "Business Flow Setting"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{getAutomationTrigger()}</Modal.Body>
    </Modal>
  );
};

import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSMSData } from "../../../../../Hooks/useSMSData";
import { toWordCase } from "../../../../../Utils/utils";
import "./sms-action.scss";

export const SMSTemplates = ({ show, setShow, handleChange = () => {} }) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const { messages } = useSMSData();
  const [selectedSMS, setSelectedSMS] = useState(null);

  const handleClose = () => {
    setShow(false);
  };
  /******/
  return (
    <React.Fragment>
      <Modal
        className={"common modalXl fixed-height-with-scroll"}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="bg-primary">
          <Modal.Title className="text-white">Choose SMS Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {messages?.length > 0
              ? messages.map((msg, idx) => (
                  <Col key={idx} md={3} sm={3} lg={3} xs={12}>
                    <div
                      className={`sms-box ${selectedSMS?.value === msg.value ? "active" : ""}`}
                      onClick={() => setSelectedSMS(msg)}
                    >
                      {toWordCase(msg.label)}
                    </div>
                  </Col>
                ))
              : ""}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              if (selectedSMS) {
                handleChange({ message: selectedSMS?.message || "" });
                handleClose();
              }
            }}
            disabled={!selectedSMS}
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { MyTooltip } from "../../../../Common/Helper/CommonHelper";
import { triggerActionIcons } from "../../../../../Pages/Automation/Elements/triggerActionIcons";

export const BusinessFlowActions = ({
  actions,
  setActions = () => {},
  handleHideAction = () => {},
}) => {
  const [selectedAction, setSelectedAction] = useState(null);
  const [subActions, setSubActions] = useState(null);
  const [allActions] = useState([
    {
      label: "Initiate Communications",
      name: "initiate_communications",
      description:
        "Actions to communicate with the contact through various channels.",
      subActions: [
        {
          label: "Send Marketing Email",
          name: "send_marketing_email",
          description:
            "Send a marketing email to promote products or services.",
        },
        {
          label: "Send Personal Email",
          name: "send_personal_email",
          description: "Send a personalized email to the contact.",
        },
        {
          label: "Send Transactional Email",
          name: "send_transactional_email",
          description:
            "Send a transactional email for updates or confirmations.",
        },
        {
          label: "Send SMS",
          name: "send_sms",
          description: "Send an SMS message to the contact.",
        },
        {
          label: "Send Whatsapp Message",
          name: "send_whatsapp_message",
          description: "Send a WhatsApp message to the contact.",
        },
      ],
    },
    {
      label: "Update to CRM",
      name: "update_to_crm",
      description:
        "Actions to update contact or deal information in the CRM system.",
      subActions: [
        {
          name: "add_lead_score",
          label: "Add Lead Score",
          description:
            "Assign a lead score to the contact based on predefined criteria.",
        },
        {
          name: "assign_deal_owner",
          label: "Assign Deal Owner",
          description:
            "Assign or reassign a deal to a specific owner for management.",
        },
        {
          name: "create_note",
          label: "Create Note",
          description: "Add a note to the contact or deal for reference.",
        },
        {
          name: "enable_customer_portal",
          label: "Enable Customer Portal",
          description:
            "Grant the contact access to the customer portal for support or resources.",
        },
        {
          name: "remove_duplicate_contact",
          label: "Remove Duplicate Contact",
          description: "Identify and remove duplicate contacts in the system.",
        },
        {
          name: "update_deal_stage",
          label: "Update Deal Stage",
          description: "Move a deal to a different stage in the pipeline.",
        },
      ],
    },
    {
      label: "Update Contact",
      name: "update_contact",
      description: "Update the contact's details in the system.",
    },
    {
      label: "Request Document",
      name: "request_document",
      description: "Request the contact to submit required documents.",
    },
    {
      label: "Send Agreement",
      name: "send_agreement",
      description:
        "Send an agreement document to the contact for review or signing.",
    },
    {
      label: "Send Estimate",
      name: "send_estimate",
      description: "Send an estimate or quote to the contact.",
    },
    {
      label: "Request Appointment",
      name: "request_appointment",
      description: "Request an appointment with the contact.",
    },
    {
      label: "Send Booking Link",
      name: "send_booking_link",
      description: "Send a link for the contact to book an appointment.",
    },
    {
      label: "Send Form",
      name: "send_form",
      description: "Send a form to the contact for collecting information.",
    },
    {
      label: "Notify Team Member",
      name: "notify_team_member",
      description: "Send a notification to a specific team member.",
    },
    {
      label: "Assign Team Member",
      name: "assign_team_member",
      description: "Assign a team member to handle a specific task or contact.",
    },
    {
      label: "Create Deal",
      name: "create_deal",
      description: "Create a new deal associated with the contact.",
    },
    {
      label: "Apply Tag",
      name: "apply_tag",
      description: "Add or remove tags for better segmentation and filtering.",
    },
    {
      label: "Update Life Cycle",
      name: "update_life_cycle",
      description: "Update the lifecycle stage of the contact.",
    },
    {
      label: "Create Task",
      name: "create_task",
      description:
        "Schedule a task for follow-up or other activities related to the contact.",
    },
  ]);

  const handleAction = (item) => {
    if (["initiate_communications", "update_to_crm"].includes(item?.name)) {
      setSubActions(item);
    } else {
      setSelectedAction(item);
    }
  };

  useEffect(() => {
    if (selectedAction) {
      const { name, label } = selectedAction;
      setActions([...actions, { elementName: name, label: label }]);
      handleHideAction();
    }
  }, [selectedAction]);
  /*****/
  return (
    <Card>
      <Card.Body className="bg-light">
        <Card.Title className="text-center">
          What action would you like to perform next?
        </Card.Title>
        {["initiate_communications", "update_to_crm"].includes(
          subActions?.name
        ) ? (
          <React.Fragment>
            <div className="text-end">
              <Button
                variant="link"
                onClick={() => {
                  setSubActions(null);
                  handleAction(null);
                }}
              >
                Back
              </Button>
            </div>
            <Row className="justify-content-center">
              {subActions?.subActions.map((item) => (
                <Col
                  key={item.name}
                  xs={5}
                  sm={3}
                  md={3}
                  lg={3}
                  className={
                    "border action-list-box " +
                    (item.name === selectedAction?.name ? "active" : "")
                  }
                  onClick={() => handleAction(item)}
                >
                  <span className="tooltip-position">
                    <MyTooltip text={item?.description} />
                  </span>
                  <span>{triggerActionIcons[item.name] || ""}</span>
                  <div>{item.label}</div>
                </Col>
              ))}
            </Row>
          </React.Fragment>
        ) : (
          <Row className="justify-content-center">
            {allActions.map((item) => (
              <Col
                key={item.name}
                xs={5}
                sm={3}
                md={3}
                lg={3}
                className={
                  "border action-list-box " +
                  (item.name === selectedAction?.name ? "active" : "")
                }
                onClick={() => handleAction(item)}
              >
                <span className="tooltip-position">
                  <MyTooltip text={item?.description} />
                </span>
                <span>{triggerActionIcons[item.name] || ""}</span>
                <div>{item.label}</div>
              </Col>
            ))}
            <Col
              key={"finish"}
              xs={5}
              sm={3}
              md={3}
              lg={3}
              className="border action-list-box my-2"
              onClick={handleHideAction}
            >
              <div>Finish</div>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

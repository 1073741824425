import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
export const FinishComp = () => {
  const { t: locale } = useTranslation(["common", "pages"]);
  return (
    <React.Fragment>
      <div className={"campaign-finish-btn text-center"}>
        <Button variant="success btn-finish mt-1">
          <i className={"ri-checkbox-circle-line font-weight-normal"}></i>{" "}
          <span>{locale("finish")}</span>
        </Button>
      </div>
    </React.Fragment>
  );
};

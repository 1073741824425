import React, { useEffect, useState } from "react";
/* Retrieve Data From Server */
import { useMutation } from "@apollo/client";

// or you can use `import gql from 'graphql-tag';` instead
import Select from "react-select";
import { Button, Form, Row, Col } from "react-bootstrap";
import { nonGroupColourStyles } from "../../Common/DropDown/Style";
import {
  ADD_CUSTOM_ATTRIBUTE,
  UPDATE_CUSTOM_ATTRIBUTE,
} from "../../../GraphApi/CustomAttributes";

import { getValidationMessage, isObjectEmpty } from "../../../Utils/utils";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import InLineError from "../AlertMessage/InLineError";
import { LocationAPI } from "../GoogleMap/LocationAPI";

const errorStyle = {
  border: "1px solid #f00",
  borderRadius: "3px",
};

const customModules = [
  { value: "contacts", label: "Contact" },
  { value: "deals", label: "Deal" },
];

const AddAttribute = ({
  module,
  setPopup,
  preDefinedColumnUpdate,
  handleAlertMessage,
  handleNewAttribute = () => {},
  team_id,
  customFieldDetails,
  popupFor = "default",
  hiddenFields = [],
}) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const [error, setError] = useState({
    attribute: false,
    attributeType: false,
    options: {},
  });

  const [attributeId, setAttributeId] = useState(null);
  const [attribute, setAttribute] = useState("");
  const [attributeType, setAttributeType] = useState("");
  const [option, setOption] = useState([{ value: "", label: "" }]);
  const [customField] = useState(
    [
      { value: "text", label: locale("pages:setting.customField.Text") },
      { value: "integer", label: locale("pages:setting.customField.Integer") },
      { value: "decimal", label: locale("pages:setting.customField.Decimal") },
      { value: "date", label: locale("pages:setting.customField.Date") },
      { value: "time", label: locale("pages:setting.customField.Time") },
      {
        value: "percentage",
        label: locale("pages:setting.customField.Percentage"),
      },
      { value: "email", label: locale("pages:setting.customField.Email") },
      { value: "upload", label: locale("pages:setting.customField.Upload") },
      { value: "phone", label: locale("pages:setting.customField.Phone") },
      { value: "url", label: locale("pages:setting.customField.Url") },
      {
        value: "textarea",
        label: locale("pages:setting.customField.Textarea"),
      },
      {
        value: "dropdown",
        label: locale("pages:setting.customField.Dropdown"),
      },
      {
        value: "checkbox",
        label: locale("pages:setting.customField.Checkbox"),
      },
      {
        value: "radio_button",
        label: locale("pages:setting.customField.Radio"),
      },
      ...(["contacts", "companies"].indexOf(module) > -1
        ? [
            {
              value: "consent",
              label: locale("pages:setting.customField.Consent"),
            },
          ]
        : []),
      {
        value: "location",
        label: locale("pages:setting.customField.Location"),
      },
    ].filter((item) => hiddenFields.indexOf(item.value) === -1)
  );
  const [showAttribute, setShowAttribute] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [isIncludeMailMerge, setIsIncludeMailMerge] = useState(false);
  const [consentValue, setConsentValue] = useState("");
  const [selectedModule, setSelectedModule] = useState(null);
  const [createCustomAttribute] = useMutation(ADD_CUSTOM_ATTRIBUTE);
  const [updateCustomAttribute] = useMutation(UPDATE_CUSTOM_ATTRIBUTE);

  const resetForm = () => {
    setAttributeId(null);
    setAttribute("");
    setAttributeType("");
    setOption([{ value: "", label: "" }]);
    setShowAttribute(false);
    setIsRequired(false);
    setIsIncludeMailMerge(false);
  };

  useEffect(() => {
    if (customFieldDetails) {
      setAttributeId(customFieldDetails.id);
      setAttribute(customFieldDetails.label);
      setAttributeType(customFieldDetails.fieldType);

      if (customFieldDetails.fieldType === "consent") {
        let att = customFieldDetails.attributes
          ? JSON.parse(customFieldDetails.attributes)
          : null;
        if (
          att &&
          att.options &&
          att.options.length > 0 &&
          att.options[0].label
        ) {
          setConsentValue(att.options[0].label);
        }
      } else {
        let att = customFieldDetails.attributes
          ? JSON.parse(customFieldDetails.attributes)
          : { options: [] };
        setOption(att.options && att.options.length > 0 ? att.options : []);
        setShowAttribute(false);
      }

      setIsRequired(
        customFieldDetails.required.toLowerCase() === "yes" ? true : false
      );
      setIsIncludeMailMerge(
        customFieldDetails.in_mailmerge.toLowerCase() === "yes" ? true : false
      );
    }
  }, [customFieldDetails]);
  /* Insert new custom attribute field*/
  const addAttribute = () => {
    setOption([...option, { value: "", label: "" }]);
  };

  /* Remove Custom Attribute */
  const removeAttribute = (index) => {
    let items = [];
    option.forEach((item, idx) => {
      if (idx !== index) {
        items.push(item);
      }
    });
    setOption(items);
  };

  /* On Select Value in Search Box */
  const handleSearchChange = (option) => {
    setAttributeType(option.value);
  };

  useEffect(() => {
    if (attributeType) {
      if (
        attributeType === "radio_button" ||
        attributeType === "checkbox" ||
        attributeType === "dropdown"
      ) {
        setShowAttribute(true);
      } else {
        setShowAttribute(false);
      }
    }
  }, [attributeType]);

  useEffect(() => {
    if (module) {
      setSelectedModule(module);
    }
  }, [module]);
  /** Add Attribute **/
  const handleAttributeValues = (value, index) => {
    let items = [...option];
    items[index].label = value;
    items[index].value = value.trim();
    setOption(items);
  };

  const handleAddAttribute = () => {
    if (attribute !== "" && attributeType !== "") {
      setError({
        ...error,
        attribute: false,
        attributeType: false,
        options: {},
      });
      if (showAttribute) {
        let errorCount = 0;
        if (option.length) {
          option.forEach((item, index) => {
            if (item.label === "") {
              setError({
                ...error,
                options: { ...error, [index]: true },
              });
              handleAlertMessage(
                "error",
                locale("messages:formik.{{name}} is required!", {
                  name: locale("Option field"),
                })
              );
              errorCount = errorCount + 1;
              return false;
            }
          });
          if (!errorCount) {
            saveCustomAttribute();
          }
        }
      } else {
        setError({ attribute: false, attributeType: false, options: {} });
        //Execute Query Here
        saveCustomAttribute();
      }
    } else {
      if (attribute === "") {
        setError({ ...error, attribute: true });
        handleAlertMessage(
          "error",
          locale("messages:formik.{{name}} is required!", {
            name: locale("Attribute field"),
          })
        );
      } else {
        setError({ ...error, attribute: false, attributeType: true });
        handleAlertMessage(
          "error",
          locale("messages:formik.{{name}} is required!", {
            name: locale("Attribute type field"),
          })
        );
      }
    }
  };

  const saveCustomAttribute = () => {
    if (attributeId) {
      updateCustomAttribute({
        variables: {
          id: attributeId,
          team_id: team_id,
          label: attribute,
          // type: attributeType,
          attributes:
            attributeType === "consent" && !isObjectEmpty(consentValue)
              ? JSON.stringify({
                  options: [{ value: "consent", label: consentValue }],
                })
              : showAttribute
                ? JSON.stringify({
                    options: option,
                  })
                : "",
          required: isRequired,
          inMailmerge: isIncludeMailMerge,
          tooltip: "",
          active: true,
        },
        update: () => preDefinedColumnUpdate(),
      })
        .then((result) => {
          if (result.data.updateCustomAttribute) {
            handleAlertMessage(
              "success",
              locale("messages:common.update", {
                fieldName: locale("Custom attribute"),
              })
            );
            handleNewAttribute({
              ...result.data.updateCustomAttribute,
              module: selectedModule,
            });
            resetForm();
          } else {
            handleAlertMessage(
              "error",
              locale("messages:backend.something went wrong")
            );
          }
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          console.log(graphQLErrors);
          handleAlertMessage(
            "error",
            locale("messages:backend." + getValidationMessage(graphQLErrors))
          );
        })
        .finally(() => {
          setPopup(false);
        });
    } else {
      createCustomAttribute({
        variables: {
          team_id: team_id,
          label: attribute,
          type: attributeType,
          name: attribute.trim(),
          attributes:
            attributeType === "consent" && !isObjectEmpty(consentValue)
              ? JSON.stringify({
                  options: [{ value: "consent", label: consentValue }],
                })
              : showAttribute
                ? JSON.stringify({
                    options: option,
                  })
                : "",
          required: isRequired,
          inMailmerge: isIncludeMailMerge,
          attributableType: selectedModule,
          tooltip: "",
          active: true,
        },
        update: () => preDefinedColumnUpdate(),
      })
        .then((result) => {
          if (result.data.createCustomAttribute) {
            handleAlertMessage(
              "success",
              locale("messages:common.create", {
                fieldName: locale("Custom attribute"),
              })
            );
            handleNewAttribute({
              ...result.data.createCustomAttribute,
              module: selectedModule,
            });
            resetForm();
          } else {
            handleAlertMessage(
              "error",
              locale("messages:backend.Something went wrong")
            );
          }
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          console.log(graphQLErrors);
          handleAlertMessage(
            "error",
            locale("messages:backend." + getValidationMessage(graphQLErrors))
          );
        })
        .finally(() => {
          setPopup(false);
        });
    }
    /**/
  };

  const handleEditorChange = async (content) => {
    const customContent = await content.replace(
      /<p>/g,
      '<p style="margin:0;padding:0">'
    );
    setConsentValue(customContent);
  };

  const getFieldLabel = () => {
    if (attributeType) {
      const selectedField = customField.filter(
        (cf) => cf.value === attributeType
      );
      if (selectedField.length > 0) {
        return selectedField[0].label;
      } else {
        return "";
      }
    }
  };

  const handleModule = (value) => {
    setSelectedModule(value.value);
  };
  /********/
  return (
    <>
      <h4 className={"text-primary font-weight-semibold mb-3"}>
        {locale(
          isObjectEmpty(attributeId) ? "add custom field" : "edit custom field"
        )}{" "}
      </h4>
      {popupFor !== "default" && (
        <Form.Group className="form-group">
          <Form.Label>{locale("custom field for")}</Form.Label>
          <Select
            options={customModules}
            onChange={(e) => handleModule(e)}
            styles={nonGroupColourStyles}
            placeholder={locale("select module")}
            value={customModules.filter((cf) => cf.value === selectedModule)}
          />
        </Form.Group>
      )}
      <Form.Group className="form-group">
        <Form.Label>{locale("custom field name")}</Form.Label>
        <Form.Control
          className={error.attribute ? "is-invalid" : ""}
          type="text"
          placeholder={locale("enter custom field name")}
          value={attribute}
          onChange={(e) => {
            setAttribute(e.target.value);
          }}
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label>{locale("input data properties")}</Form.Label>
        <div
          className={error && error.attributeType ? "select-error" : ""}
          // style={error && error.attributeType ? errorStyle : {}}
        >
          {attributeId ? (
            getFieldLabel()
          ) : (
            <Select
              className={error && error.attributeType ? "is-invalid" : ""}
              options={customField}
              onChange={(e) => handleSearchChange(e)}
              styles={nonGroupColourStyles}
              placeholder={locale("select data property")}
              noOptionsMessage={() => locale("no data property found")}
              value={customField.filter((cf) => cf.value === attributeType)}
            />
          )}
        </div>
      </Form.Group>
      {showAttribute &&
        option &&
        option.map((item, index) => (
          <Row key={index}>
            <Col>
              <Form.Group className="form-group">
                <Form.Label visuallyHidden>
                  {locale("custom field name")}
                </Form.Label>
                <Form.Control
                  className={error.options[index] ? "is-invalid" : ""}
                  type="text"
                  value={item.label}
                  placeholder={locale("enter field name")}
                  onChange={(e) => {
                    item.label = e.target.value;
                    handleAttributeValues(e.target.value, index);
                  }}
                  autoFocus={true}
                />
              </Form.Group>
            </Col>
            <Col md="auto">
              {index >= 1 && (
                <Button
                  variant="outline-danger btn-icon"
                  onClick={(e) => {
                    removeAttribute(index);
                  }}
                >
                  <i
                    className={"ri-close-circle-line ri-lg font-weight-normal"}
                  ></i>
                </Button>
              )}
              <Button variant="outline-success btn-icon" onClick={addAttribute}>
                <i
                  className={"ri-add-circle-line ri-lg font-weight-normal"}
                ></i>
              </Button>
            </Col>
          </Row>
        ))}

      {attributeType === "consent" && (
        <Row className="mb-2">
          <Col>
            <Form.Group className="form-group">
              <Form.Label visuallyHidden>
                {locale("custom field name")}
              </Form.Label>

              <Editor
                apiKey={process.env.REACT_APP_TINY_KEY}
                init={{
                  //auto_focus: true,
                  paste_as_text: true,
                  smart_paste: true,
                  content_style: "p {margin: 0; padding: 0;}",
                  min_height: 100,
                  height: 150,
                  placeholder: "Start writing your message here...",
                  menubar: false,
                  branding: false,
                  relative_urls: false,
                  remove_script_host: false,
                  convert_urls: false,
                  plugins: ["link"],
                  toolbar: [
                    "bold italic underline | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignfull | link",
                  ],
                }}
                value={consentValue}
                onEditorChange={handleEditorChange}
              />
              <InLineError
                type="error"
                message={error.attribute ? "Field required" : ""}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {attributeType === "location" && <LocationAPI locale={locale} />}
      <Form.Group controlId="formBasicCheckbox" className="form-group ps-2">
        <Form.Label className={"d-block mb-2"}>
          {locale("custom field properties")}
        </Form.Label>
        <Form.Check
          type="checkbox"
          label={locale("is mandatory")}
          id="is_mandatory"
          inline
          checked={isRequired}
          onChange={(e) => {
            setIsRequired(!isRequired);
          }}
        />
        <Form.Check
          type="checkbox"
          label={locale("include in mail merge")}
          id="include_mail_merge"
          inline
          checked={isIncludeMailMerge}
          onChange={(e) => {
            setIsIncludeMailMerge(!isIncludeMailMerge);
          }}
        />
      </Form.Group>
      <div className="">
        <Button
          variant="outline-primary btn-ms-icon"
          onClick={handleAddAttribute}
        >
          <i className={"ri-save-2-line"}></i>{" "}
          <span>{locale("save custom field")}</span>
        </Button>
      </div>
    </>
  );
};

export default AddAttribute;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import "./fileUpload.css";
import { useTranslation } from "react-i18next";
import { acceptableType } from "../../../Utils/commonParams";

export const APP_FILE_SIZE = process.env.REACT_APP_FILE_SIZE;

const XFileUploadButton = ({
  buttonLabel = "",
  accept,
  handleUpload,
  handleAlertMessage,
  isMulti,
  fileSize,
  getColorScheme = () => {},
  isDisabled = false,
  fileCount = isMulti ? 5 : 1,
}) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const [uploadFileSize, setUploadFileSize] = useState(APP_FILE_SIZE);
  useEffect(() => {
    if (fileSize && fileSize > 0) {
      setUploadFileSize(fileSize);
    }
  }, [fileSize]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptableType(accept),
    onDrop: (acceptedFile) => {
      if (acceptedFile.length > 0) {
        if (acceptedFile.length > fileCount && isMulti) {
          handleAlertMessage(
            "error",
            "You can upload max " + fileCount + " files at a time."
          );
        } else if (isMulti) {
          const file = acceptedFile.map((item) =>
            Object.assign(item, {
              preview: URL.createObjectURL(item),
            })
          );

          if (acceptedFile.filter((item) => getFileSize(item)).length > 0) {
            handleAlertMessage(
              "error",
              "File size exceeds max upload file size of " +
                uploadFileSize +
                " MB"
            );
          } else {
            handleUpload([file]);
          }
        } else {
          const file = [
            Object.assign(acceptedFile[0], {
              preview: URL.createObjectURL(acceptedFile[0]),
            }),
          ];
          if (getFileSize(acceptedFile[0])) {
            handleAlertMessage(
              "error",
              "File size exceeds max upload file size of " +
                uploadFileSize +
                " MB"
            );
          } else {
            handleUpload([file]);
          }
        }
      } else {
        handleAlertMessage(
          "error",
          "File format not supported. Upload a valid file!!"
        );
      }
    },
  });

  /** GEt FILE SIZE **/
  const getFileSize = (file) => {
    const requiredFileSizeInBytes = uploadFileSize * 1024 * 1024;
    if (file.size <= requiredFileSizeInBytes) {
      return false;
    } else {
      return true;
    }
  };

  /******/
  return (
    <React.Fragment>
      <div className={""}>
        <div
          {...getRootProps()}
          style={getColorScheme("button")}
          className={
            "btn text-uppercase btn-outline-success " +
            (getColorScheme("button") ? "text-white" : "text-default")
          }
        >
          <div className="inputHidden">
            <input
              multiple={isMulti}
              accept={acceptableType(accept)}
              {...getInputProps()}
              disabled={isDisabled}
            />
          </div>
          <i className={"ri-upload-2-line"}></i>{" "}
          <span>
            {buttonLabel !== ""
              ? buttonLabel
              : accept === "image"
                ? locale("Select Image")
                : locale("Select File")}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
XFileUploadButton.propTypes = {
  isMulti: PropTypes.bool,
  accept: PropTypes.string.isRequired,
  handleAlertMessage: PropTypes.func.isRequired,
};
export default XFileUploadButton;

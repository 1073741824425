import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { LEAD_OWNER_LIST } from "../../../GraphApi/LeadOwner";
import { currentTeamId, loggedInUserId } from "../../../Utils/utils";
import { Form } from "react-bootstrap";
import { AuthContext } from "../../../Context/AuthContext";
import { nonGroupColourStyles } from "../DropDown/Style";

const extraStyle = {
  border: "1px solid rgb(255, 0, 0)",
  borderRadius: "3px",
  paddingRight: "1px",
};

export const TeamUserDropdown = ({
  isMulti = false,
  isClearable = false,
  value,
  handleValue = () => {},
  labelVisible = true,
  label = "Team User",
  withCurrentUser = true,
  isError = false,
  placeholder = "Select Team User",
}) => {
  const { userData } = useContext(AuthContext);
  const [users, setUsers] = useState([]);

  const [getAllLeadOwner, { data: ownerList }] = useLazyQuery(LEAD_OWNER_LIST, {
    variables: { team_id: currentTeamId, is_setup_calendar_scheduling: false },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getAllLeadOwner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ownerList) {
      const allLeadOwner = [];
      ownerList.allUsers.forEach((item) => {
        if (
          !withCurrentUser &&
          loggedInUserId(userData) === parseInt(item.id)
        ) {
          //Do Nothing
        } else {
          allLeadOwner.push({
            value: parseInt(item.id),
            label: item.name,
            id: parseInt(item.id),
            team_user_id: item.team_user_id,
          });
        }
      });
      setUsers(allLeadOwner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerList]);

  const handleGetValue = () => {
    if (users && users.length > 0 && value && value.length > 0) {
      const values = value.map((item) => parseInt(item.value));
      return users.filter((item) => values.indexOf(parseInt(item.value)) > -1);
    } else {
      return [];
    }
  };
  /******/
  return (
    <Form.Group className="form-group">
      <Form.Label className={!labelVisible ? "invisible" : ""}>
        {label}
      </Form.Label>
      <div
        className={isError ? "select-error" : ""}
        // style={isError ? extraStyle : {}}
      >
        <Select
          isMulti={isMulti}
          isClearable={isClearable}
          options={users}
          value={handleGetValue()}
          onChange={(e) => {
            if (isMulti) {
              handleValue(e.length > 0 ? e : []);
            } else {
              handleValue(e);
            }
          }}
          styles={nonGroupColourStyles}
          placeholder={placeholder}
        />
      </div>
    </Form.Group>
  );
};

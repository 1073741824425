import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { currentTeamId } from "../../../../../Utils/utils";
import { useTranslation } from "react-i18next";
import { USER_LIST } from "../../../../../GraphApi/TaskAndDocuments";
import { useQuery } from "@apollo/client";
import { MailMerge } from "../../../../../Components/Common/Editor/Component/NewComponents/MailMerge";
import Select from "react-select";
import { nonGroupColourStyles } from "../../../../../Components/Common/DropDown/Style";
import { Editor } from "@tinymce/tinymce-react";

const maxLimit = {
  day: 31,
  hour: 23,
};

export const CreateTaskAction = ({
  handleAlertMessage = () => {},
  currentCompanySetting = null,
  values = null,
  handleChange,
  handleClose,
}) => {
  const { t: locale } = useTranslation();
  const [scheduleType] = useState([
    { value: "immediate", label: locale("Immediate") },
    { value: "scheduled", label: locale("Scheduled") },
  ]);
  const [scheduleAfterType] = useState([
    { value: "day", label: locale("Days") },
    { value: "hour", label: locale("Hours") },
  ]);
  const [attendees, setAttendees] = useState([]);

  const [taskDetails, setTaskDetails] = useState({
    task_type: "task",
    title: "",
    description: "",
    assigned_to: "",
    scheduled_type: "immediate",
    scheduled_after_type: "day",
    scheduled_value: "",
  });

  const [typingTimeout, setTypingTimeout] = useState(0);

  const { data: usersData } = useQuery(USER_LIST, {
    variables: { team_id: currentTeamId },
    fetchPolicy: "network-only",
  });
  const [errorBag, setErrorBag] = useState(null);

  const handleSaveData = () => {
    let newErrors = {};
    if (!taskDetails?.title) {
      newErrors = { ...newErrors, title: "Title is required" };
    }
    if (!taskDetails?.scheduled_type) {
      newErrors = {
        ...newErrors,
        scheduled_type: "Scheduled type is required",
      };
    }
    if (!taskDetails?.description) {
      newErrors = { ...newErrors, description: "Description is required" };
    }
    setErrorBag(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setErrorBag(null);
      let str = [];

      if (taskDetails.title !== "") {
        str.push(<span key="2">{"Task Create" + " "}</span>);
        str.push(
          <span key="3" className="text-primary">
            {taskDetails.title}
          </span>
        );
        str.push(<span key="4">{" For "}</span>);
        if (taskDetails.assigned_to && taskDetails.assigned_to !== "") {
          str.push(
            <span key="5" className="text-primary">
              {" " + taskDetails.assigned_to.label + " "}
            </span>
          );
        }
      }
      if (taskDetails.notify === "yes") {
        if (taskDetails.title !== "" && taskDetails.assigned_to !== "") {
          str.push(<span className="text-highlighter-dark">{"AND"}</span>);
        }
        str.push(<span key="7">{"Notify"}</span>);
        if (taskDetails.assigned_to !== "") {
          str.push(
            <span key="8" className="text-primary">
              {" " + taskDetails.assigned_to.label + " "}
            </span>
          );
        } else {
          str.push(<span key="8">{" " + "user" + " "}</span>);
        }
        str.push(<span key="9">{"when the task is assigned"}</span>);
      }

      handleChange("action", {
        action: "create_task",
        task_details: taskDetails,
        string: str,
      });
      handleClose();
    }
  };

  /**Load user list**/
  useEffect(() => {
    if (usersData) {
      const users = usersData.allUsers.map((user) => ({
        value: user.id,
        label: user.name,
      }));
      setAttendees(users);
    }
  }, [usersData]);

  /**Get Data and Put into Page**/
  useEffect(() => {
    if (values && usersData) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, usersData]);

  const loadData = () => {
    const { task_details, ...rest } = values;
    setTaskDetails(task_details);
  };

  /** Handle TAsk Values **/
  const handleTaskDetails = (type, value) => {
    setTaskDetails({ ...taskDetails, [type]: value });
  };

  /* Handle Type and Execute Save */
  const onStopTyping = (type, searchedText) => {
    if (searchedText.length >= 3) {
      clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(function () {
          handleTaskDetails(type, searchedText);
        }, 600)
      );
    } else if (searchedText.length === 0 || !searchedText) {
      clearTimeout(typingTimeout);
      handleTaskDetails(type, searchedText);
    }
  };

  const getMailMergeValue = (value) => {
    const input = document.getElementById("title");
    const myValue = input.value;
    input.value = myValue.concat(value);
    handleTaskDetails("title", input.value);
  };

  const handelCancel = () => {
    handleClose();
  };

  /******/
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label>{locale("title")}</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  id={"title"}
                  type="text"
                  placeholder={locale("Enter title of task")}
                  defaultValue={taskDetails?.title || ""}
                  onChange={(e) => {
                    handleTaskDetails("title", e.target.value);
                  }}
                />
                <InLineError type="error" message={errorBag?.title || null} />
              </Col>
              <Col xs={"auto"}>
                <MailMerge
                  mode="create-popup"
                  getMailMergeValue={getMailMergeValue}
                  locale={locale}
                  forAutomation={true}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label>
              {locale("When you want to schedule this task?")}
            </Form.Label>
            <div>
              <Select
                isMulti={false}
                isClearable={false}
                isSearchable={false}
                placeholder={locale("Schedule Type")}
                options={scheduleType}
                styles={nonGroupColourStyles}
                value={
                  taskDetails?.scheduled_type
                    ? scheduleType.filter(
                        (item) => item.value === taskDetails.scheduled_type
                      )[0]
                    : ""
                }
                onChange={(e) => {
                  handleTaskDetails("scheduled_type", e.value);
                }}
              />
              <InLineError
                type="error"
                message={errorBag?.scheduled_type || null}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
      {taskDetails?.scheduled_type === "scheduled" && (
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label>{locale("After")}</Form.Label>
              <div>
                <Select
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  placeholder={locale("Schedule After")}
                  options={scheduleAfterType}
                  styles={nonGroupColourStyles}
                  value={
                    taskDetails?.scheduled_after_type
                      ? scheduleAfterType.filter(
                          (item) =>
                            item.value === taskDetails.scheduled_after_type
                        )[0]
                      : ""
                  }
                  onChange={(e) => {
                    handleTaskDetails("scheduled_after_type", e.value);
                  }}
                />
                <InLineError
                  type="error"
                  message={errorBag?.scheduled_after_type || null}
                />
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="form-group">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                id={"scheduled_value"}
                type="number"
                min={1}
                max={maxLimit[taskDetails?.scheduled_after_type || ""]}
                placeholder={locale(
                  "Enter " + (taskDetails?.scheduled_after_type || "")
                )}
                defaultValue={taskDetails?.scheduled_value}
                onChange={(e) => {
                  handleTaskDetails("scheduled_value", e.target.value);
                }}
              />
              <InLineError
                type="error"
                message={errorBag?.scheduled_value || null}
              />
            </Form.Group>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label>{locale("description")}</Form.Label>{" "}
            <Editor
              apiKey={process.env.REACT_APP_TINY_KEY}
              value={taskDetails?.description || ""}
              init={{
                paste_as_text: true,
                smart_paste: true,
                content_style: "p {margin: 0; padding: 0;}",
                min_height: 200, // Set min height for editor
                menubar: false,
                branding: false,
                plugins: ["link"],
                relative_urls: false,
                remove_script_host: false,
                toolbar: [
                  "bold italic underline | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignfull | outdent indent | shortcode | link",
                ],
              }}
              onEditorChange={async (content, editor) => {
                const customContent = await content.replace(
                  /<p>/g,
                  '<p style="margin:0;padding:0">'
                );
                handleTaskDetails("description", customContent);
              }}
            />
            <InLineError type="error" message={errorBag?.description || null} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label>{locale("assign to")} </Form.Label>
            <div>
              <Select
                id={"assigned_to"}
                isMulti={false}
                isClearable={false}
                isSearchable={true}
                options={attendees}
                value={taskDetails?.assigned_to || ""}
                onChange={(e) => {
                  handleTaskDetails("assigned_to", e);
                }}
                styles={nonGroupColourStyles}
                placeholder="Select user"
                noOptionsMessage={() => locale("no user found")}
              />
              <InLineError
                type="error"
                message={errorBag?.assigned_to || null}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Form.Check
            inline
            type={"checkbox"}
            label={locale("notify contact owner")}
            value={"yes"}
            checked={taskDetails?.notify === "yes"}
            onChange={(e) =>
              handleTaskDetails(
                "notify",
                e.target.value === taskDetails?.notify ? "no" : "yes"
              )
            }
          />
        </Col>
      </Row>
      <div className="my-2">&nbsp;</div>
      <div className="bg-dark text-end sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

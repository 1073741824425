import { gql } from "@apollo/client";

export const GET_EMAIL_TEMPLATES = gql`
  query getEmailTemplates($template_type: String, $team_id: Int) {
    getEmailTemplates(template_type: $template_type, team_id: $team_id) {
      id
      name
      subject
    }
  }
`;

export const GET_ALL_FORMS = gql`
  query getAllForms($team_id: Int) {
    getAllForms(team_id: $team_id) {
      id
      name
    }
  }
`;

export const GET_FILTERS = gql`
  query filters($industry: String, $entity: String, $team_id: Int) {
    filters(industry: $industry, entity: $entity, team_id: $team_id) {
      entity
      name
      label
      group
      type
      supported_operators
    }
  }
`;

export const GET_ALL_LANDING_PAGES = gql`
  query getAllLandingPages($team_id: Int) {
    getAllLandingPages(team_id: $team_id) {
      id
      name
      page_type
      page_url
      landing_page_uid
    }
  }
`;

export const GET_ALL_TAGS = gql`
  query allTag($team_id: Int) {
    allTag(team_id: $team_id) {
      id
      name
    }
  }
`;

export const GET_ALL_SYSTEM_EMAIL_TEMPLATES = gql`
  query getAllSystemEmailTemplate(
    $industry: String
    $group_name: String
    $template_type: String
  ) {
    getAllSystemEmailTemplate(
      industry: $industry
      group_name: $group_name
      template_type: $template_type
    ) {
      id
      name
      subject
      template_type
      group_name
      description
      icon
      industry
      screenshot
      screenshot_url
    }
  }
`;

export const FETCH_CALLING_ACCOUNT = gql`
  query fetchCallingAccount($team_id: Int) {
    fetchCallingAccount(team_id: $team_id) {
      address {
        team_id
        address_id
        customer_name
        street
        city
        region
        postal_code
        country
        validated
        verified
        created_at
        updated_at
      }
      number {
        twilio_number_id
        address_requirements
        beta
        voice_capabilities
        sms_capabilities
        mms_capabilities
        fax_capabilities
        phone_number
        sms_application_sid
        voice_application_sid
        voice_receive_mode
        status
        created_at
        updated_at
      }
      incomingNumber {
        team_id
        number
        channel
        via_service_sid
        verified_at
      }
      friendly_name
      twilio_id
      auth_token
      status
      type
      created_at
      updated_at
    }
  }
`;

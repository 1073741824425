import {
  bookingPreference,
  bookingPreferenceById,
  clientBookingPage,
  crateBookingRequest,
  deleteBookingAvailability,
  getServicesRequests,
  offeringBySearch,
  saveBookingRequest,
  updateBookingRequest,
} from "../../../../RestApi/BookingApi";

export const getBookingPreferenceListing = (data) => {
  return new Promise((resolve, reject) => {
    bookingPreference(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};
export const getBookingPreference = (data, id) => {
  return new Promise((resolve, reject) => {
    bookingPreferenceById(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};
export const createBookingPreference = (data) => {
  return new Promise((resolve, reject) => {
    saveBookingRequest(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const updateBookingPreference = (data, id) => {
  return new Promise((resolve, reject) => {
    updateBookingRequest(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const removeBookingAvailability = (data, id) => {
  return new Promise((resolve, reject) => {
    deleteBookingAvailability(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getBookingForClient = (data, id) => {
  return new Promise((resolve, reject) => {
    clientBookingPage(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getOfferingForClient = (data, id) => {
  return new Promise((resolve, reject) => {
    offeringBySearch(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};
export const createBooking = (data) => {
  return new Promise((resolve, reject) => {
    crateBookingRequest(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};
/** SERVICE REQUEST **/
export const bookingListing = (data) => {
  return new Promise((resolve, reject) => {
    getServicesRequests(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};
/*****/
export const getResponseError = (error) => {
  let responseError = error.response.statusText && error.response.statusText;
  if (error.response.data && error.response.data.message) {
    responseError = error.response.data.message;
  } else if (error.response.data.message) {
    responseError = error.message;
  }
  return responseError;
};

import React, { useEffect, useMemo, useState } from "react";
import InLineErrorMessage from "../AlertMessage/InLineErrorMessage";
import { Button, Col, Form, Row } from "react-bootstrap";
import { MailMerge } from "../Editor/Component/NewComponents/MailMerge";
import { useTranslation } from "react-i18next";
import { currentTeamId, groupBy } from "../../../Utils/utils";
import { CreateOrCloneModal } from "./CreateOrCloneModal/CreateOrCloneModal";
import { useLazyQuery } from "@apollo/client";
import {
  EMAIL_LIST,
  EMAIL_TEMPLATE_PREDEFINED,
} from "../../../GraphApi/Emails";
import { GenerateEmailPreview } from "../../Pages/EmailBuilder/GenerateEmailPreview";
import SlateWrapperA from "../SlateEditor/FixedSlateEditor";
import { deserialize } from "../SlateEditor/Utils/slateUtils";

export const CreateNewEmail = ({
  formik,
  setEmailTemplateForClone,
  templateType,
}) => {
  const { t: locale } = useTranslation();
  const [show, setShow] = useState(false);
  /*** FOR EMAIL POPUP ***/
  const [systemEmailTemplates, setSystemEmailTemplates] = useState([]);
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [emails, setEmails] = useState([]);

  const [getSystemEmailTemplate, { data: emailTemplatesList }] = useLazyQuery(
    EMAIL_TEMPLATE_PREDEFINED,
    {
      variables: {
        $industry: "service",
        template_type: templateType,
      },
      fetchPolicy: "network-only",
    }
  );
  const [getEmailsList] = useLazyQuery(EMAIL_LIST, {
    fetchPolicy: "network-only",
  });

  const handleCloseEmailPreview = () => {
    setEmailTemplate(null);
    setShowEmailPreview(false);
  };

  const handleShowEmailPreview = () => setShowEmailPreview(true);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const getMailMergeValue = (value) => {
    const input = document.getElementById("subject");
    const myValue = input.value.concat(value);
    input.value = myValue;
    formik.setFieldValue("subject", myValue);
  };

  const previewEmailTemplates = (email) => {
    setEmailTemplate(email);
    handleShowEmailPreview();
  };

  const handleClone = (email) => {
    const updatedEmail = {
      ...email,
      isSystemEmail: email.team_id ? false : true,
    };
    setEmailTemplateForClone(updatedEmail);
    handleClose();
  };

  useEffect(() => {
    if (show) {
      fetchData();
      getSystemEmailTemplate();
    }
  }, [show]);

  useEffect(() => {
    let templateGroupBy = [];
    if (emailTemplatesList?.getAllSystemEmailTemplate) {
      templateGroupBy = groupBy(
        emailTemplatesList.getAllSystemEmailTemplate,
        "group_name"
      );
      setSystemEmailTemplates(templateGroupBy);
    }

    if (templateType === "transactional_email") {
      let yourTemplate = [];
      let systemTemplate = [];
      emails.forEach((item) => {
        if (item.team_id) {
          yourTemplate.push(item);
        } else {
          systemTemplate.push(item);
        }
      });
      setSystemEmailTemplates([
        { label: "Your Templates", option: yourTemplate },
        { label: "System Templates", option: systemTemplate },
      ]);
    } else {
      setSystemEmailTemplates([
        { label: "Your Templates", option: emails },
        ...(emailTemplatesList ? templateGroupBy : []),
      ]);
    }
  }, [emailTemplatesList, emails]);

  const fetchData = () => {
    getEmailsList({
      variables: { team_id: currentTeamId, template_type: templateType },
      fetchPolicy: "network-only",
    })
      .then((result) => {
        const data = result.data;
        let emailSList =
          data?.getEmailTemplates?.length > 0
            ? data.getEmailTemplates.map(
                (item) =>
                  item && {
                    id: parseInt(item.id),
                    name: item.name || "",
                    subject: item.subject || "",
                    templateName: item.name || "",
                    imageSrc: item.screenshot_url || "",
                    industry: "service",
                    template_type: templateType,
                    team_id: item.team_id || "",
                  }
              )
            : [];
        setEmails([...emailSList]);
      })
      .catch((error) => {
        console.log("error", error.message);
        // handleAlertMessage("error", error.message);
      });
  };

  const handleEditorChange = async (content) => {
    console.log("I am going to change content like this: ", content);
    const customContent = await content.replace(
      /<td>/g,
      '<td style="border: 1px solid rgb(221, 221, 221); padding: 8px; vertical-align: top;">'
    );
    await formik.setFieldValue("message", customContent);
  };

  const defaultValue = [
    {
      type: "paragraph",
      children: [{ text: "Type something awesome" }],
    },
  ];

  const initialValue = useMemo(() => {
    const value = formik?.values?.message
      ? deserialize(formik.values.message)
      : defaultValue;
    const valueArray = Array.isArray(value) ? value : [];
    return valueArray;
  }, [formik.values.message]);

  //******/
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <h4 className="text-primary text-start">Create Email</h4>
        <Button className="mx-1" variant="success" onClick={handleShow}>
          Choose Template
        </Button>
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label>{locale("Subject")}</Form.Label>
              <Form.Control
                id={"subject"}
                className={
                  formik.touched.subject && formik.errors.subject
                    ? "is-invalid"
                    : ""
                }
                type="text"
                placeholder={locale("Enter Subject")}
                value={formik.values.subject}
                onChange={(e) => {
                  formik.setFieldValue("subject", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
              <InLineErrorMessage type="error" name="subject" formik={formik} />
            </Form.Group>
          </Col>
          <Col xs={"auto"}>
            <Form.Group className="form-group">
              <Form.Label>&nbsp;</Form.Label>
              <div className="mt-1">
                <MailMerge
                  mode="create-popup"
                  getMailMergeValue={getMailMergeValue}
                  locale={locale}
                  forAutomation={true}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label>{locale("Message")}</Form.Label>
              <SlateWrapperA
                initialValue={initialValue}
                onChange={handleEditorChange} 
                style={{
                  minHeight: "150px",
                  alignContent: "flex-start",
                  lineHeight: 1.5,
                  borderRadius: "unset",
                  marginBottom: "60px",
                }}
                showMailMergeOption={true}
              />
              <InLineErrorMessage type="error" name="message" formik={formik} />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <CreateOrCloneModal
        showEmailTemplate={show}
        handleShowEmailTemplate={handleShow}
        handleCloseEmailTemplate={handleClose}
        systemEmailTemplates={systemEmailTemplates}
        industry={"service"}
        previewEmailTemplates={previewEmailTemplates}
        handleClone={handleClone}
        page={"workflow"}
        setCreateFromScratch={null}
        locale={locale}
        templateType={templateType}
      />
      <GenerateEmailPreview
        email={emailTemplate}
        showEmailPreview={showEmailPreview}
        handleCloseEmailPreview={handleCloseEmailPreview}
      />
    </React.Fragment>
  );
};

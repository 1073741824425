import React from "react";
import { useSlate, ReactEditor } from "slate-react";
import { Editor, Transforms, Element as SlateElement } from "slate";
import { toggleBlock } from "../Richtext";

const BLOCK_TYPES = [
  { label: "Normal", value: "paragraph" },
  { label: "Heading 1", value: "heading-one" },
  { label: "Heading 2", value: "heading-two" },
  { label: "Heading 3", value: "heading-three" },
  { label: "Heading 4", value: "heading-four" },
  { label: "Heading 5", value: "heading-five" },
  { label: "Heading 6", value: "heading-six" },
];

const BlockTypeDropdown = () => {
  const editor = useSlate();

  const onChange = (event) => {
    const format = event.target.value;
    // const format = event.target.value;
    toggleBlock(editor, format);
    return;

    // Unwrap any existing list items
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        ["numbered-list", "bulleted-list"].includes(n.type),
      split: true,
    });

    console.log(
      "It is going to apply: ",
      BLOCK_TYPES.find((type) => type.value === format) ? format : "paragraph"
    );
    // Set the new block type
    Transforms.setNodes(
      editor,
      {
        type: BLOCK_TYPES.find((type) => type.value === format)
          ? format
          : "paragraph",
      },
      { match: (n) => Editor.isBlock(editor, n) }
    );

    // Ensure the editor remains focused
    ReactEditor.focus(editor);
  };

  // Determine the current block type
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type !== undefined,
  });
  const currentType = match ? match[0].type : "paragraph";

  return (
    <select
      value={currentType}
      onChange={onChange}
      className="form-select me-2"
      style={{ width: "150px" }}
    >
      {BLOCK_TYPES.map((type) => (
        <option key={type.value} value={type.value}>
          {type.label}
        </option>
      ))}
    </select>
  );
};

export default BlockTypeDropdown;

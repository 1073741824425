import { gql } from "@apollo/client";

export const FORM_LIST = gql`
  query getAllForms(
    $team_id: Int
    $search: String
    $campaign_id: Int
    $form_type: String
  ) {
    getAllForms(
      team_id: $team_id
      search: $search
      campaign_id: $campaign_id
      form_type: $form_type
    ) {
      slug
      id
      name
      formContent {
        content
        is_wizard
        form_style
      }
      form_uid
      attachment {
        path: url
        name
      }
      landingPages {
        id
        name
        landing_page_uid
        page_url
      }
    }
  }
`;
export const FORM_LISTING = gql`
  query getFormsListing($first: Int!, $page: Int, $team_id: Int) {
    getPaginatedCampaignForms(first: $first, page: $page, team_id: $team_id) {
      data {
        slug
        id
        name
        form_uid
        created_at
        createdBy {
          name
        }
        #publishedBy{name}
        lastModifiedBy {
          name
        }
        submission_count
        landingPages {
          id
          name
          landing_page_uid
          page_url
        }
        attachment {
          path: url
          name
        }
        status
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;
/** CREATE NEW FORM **/
export const CREATE_FORM = gql`
  mutation createForm(
    $team_id: Int
    $name: String
    $content: String
    $thanks_msg: String
    $redirect_url: String
    $downloadable_document: Boolean
    $upload_document: Upload
    $status: Boolean
    $landing_page_id: String
    $is_wizard: Boolean
    $form_style: String
    $html_content: String
  ) {
    createFormPage(
      name: $name
      team_id: $team_id
      content: $content
      thanks_msg: $thanks_msg
      redirect_url: $redirect_url
      downloadable_document: $downloadable_document
      upload_document: $upload_document
      status: $status
      landing_page_id: $landing_page_id
      is_wizard: $is_wizard
      form_style: $form_style
      html_content: $html_content
    ) {
      id
      name
      formContent {
        content
        is_wizard
        form_style
      }
      form_uid
      thanks_msg
      redirect_url
      downloadable_document
      attachment {
        path: url
        name
      }
      slug
    }
  }
`;

/** CREATE NEW FORM **/
export const UPDATE_FORM = gql`
  mutation updateForm(
    $id: Int
    $team_id: Int
    $name: String
    $content: String
    $thanks_msg: String
    $redirect_url: String
    $downloadable_document: Boolean
    $upload_document: Upload
    $status: Boolean
    $landing_page_id: String
    $is_wizard: Boolean
    $form_style: String
    $html_content: String
  ) {
    updateFormPage(
      id: $id
      name: $name
      team_id: $team_id
      content: $content
      thanks_msg: $thanks_msg
      redirect_url: $redirect_url
      downloadable_document: $downloadable_document
      upload_document: $upload_document
      status: $status
      landing_page_id: $landing_page_id
      is_wizard: $is_wizard
      form_style: $form_style
      html_content: $html_content
    ) {
      id
      name
      formContent {
        content
        is_wizard
        form_style
      }
      form_uid
      thanks_msg
      redirect_url
      downloadable_document
      slug
    }
  }
`;

/** GET FROM BY ID **/
export const GET_FORM_BY_ID = gql`
  query getFormById($team_id: Int, $id: Int) {
    getFormById(team_id: $team_id, id: $id) {
      id
      slug
      name
      formContent {
        content
        is_wizard
        form_style
      }
      form_uid
      thanks_msg
      redirect_url
      downloadable_document
      attachment {
        path: url
        name
      }
      landingPages {
        id
        name
        landing_page_uid
      }
    }
  }
`;

/** DELETE FROM BY ID **/
export const DELETE_FORM = gql`
  mutation deleteForm($team_id: Int, $id: Int) {
    deleteForm(team_id: $team_id, id: $id) {
      status
    }
  }
`;

export const GET_PUBLIC_FORM = gql`
  query getForm($form_id: String) {
    getForm(form_id: $form_id) {
      form_uid
      name
      formContent {
        content
        is_wizard
        form_style
      }
      tracking
      thanks_msg
      form_popup
      redirect_url
      downloadable_document
      doc_msg
      doc_label
      assign_to
      status
      attachment {
        id
        attachable_to
        attachable_id
        name
        path
        size
        url
        file_url
        source
        disk
        created_at
        updated_at
      }
      url
    }
  }
`;

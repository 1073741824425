import React from "react";

export const CalendarSvg = ({ active = false, fill = true }) => {
  return (
    <React.Fragment>
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8962 17.0522L18.69 18.69L17.9193 19.9424L14.4511 17.8229V13.0059H15.8962V17.0522ZM21.1949 16.8595C21.1949 13.6707 18.6033 11.0791 15.4145 11.0791C12.2256 11.0791 9.63403 13.6707 9.63403 16.8595C9.63403 20.0484 12.2256 22.64 15.4145 22.64C18.6033 22.64 21.1949 20.0484 21.1949 16.8595Z"
          fill={active ? "#FB9B51" : "#4C4E61"}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3779 1.92681H17.3413C18.401 1.92681 19.2681 2.79387 19.2681 3.85362V11.2121C18.6984 10.7335 18.0484 10.3474 17.3413 10.0769V6.74383H1.92681V19.2681H8.83782C9.18089 19.9903 9.64853 20.6418 10.213 21.1949H1.92681C0.867064 21.1949 0 20.3278 0 19.2681V3.85362C0 2.79387 0.867064 1.92681 1.92681 1.92681H2.89021V0H4.81702V1.92681H14.4511V0H16.3779V1.92681Z"
          fill={active ? "#FB9B51" : "#4C4E61"}
        ></path>
        <rect
          x="4.0943"
          y="8.67078"
          width="2.40851"
          height="1.44511"
          fill={active ? "#FB9B51" : "#4C4E61"}
        ></rect>
        <rect
          x="4.0943"
          y="11.8018"
          width="2.40851"
          height="1.44511"
          fill={active ? "#FB9B51" : "#4C4E61"}
        ></rect>
        <rect
          x="4.0943"
          y="14.9327"
          width="2.40851"
          height="1.44511"
          fill={active ? "#FB9B51" : "#4C4E61"}
        ></rect>
        <rect
          x="8.42967"
          y="8.67078"
          width="2.40851"
          height="1.44511"
          fill={active ? "#FB9B51" : "#4C4E61"}
        ></rect>
        <rect
          x="8.42967"
          y="11.8018"
          width="2.40851"
          height="1.44511"
          fill={active ? "#FB9B51" : "#4C4E61"}
        ></rect>
        <rect
          x="12.7653"
          y="8.67078"
          width="2.40851"
          height="1.44511"
          fill={active ? "#FB9B51" : "#4C4E61"}
        ></rect>
      </svg>
    </React.Fragment>
  );
};

import React, { useState, useEffect } from 'react';

import { Form, Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import InLineErrorMessage from '../../../../Common/AlertMessage/InLineErrorMessage';
import XAlertMessage from '../../../../Common/AlertMessage/XAlertMessage';
import {
  currentTeamId,
  getValidationMessage,
} from '../../../../../Utils/utils';
import { CREATE_TEAM_EMAIL } from '../../../../../GraphApi/Campaigns/TeamEmails';
import { CampaignFormLoader } from '../../../../Common/Loader/SkeletonLoader';

import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

export const AddEmailForVerification = ({
  teamEmails,
  setTeamEmails,
  emailChange = false,
  setEmailChange = () => {},
}) => {
  const { t: locale } = useTranslation(['common', 'pages']);
  /** Alert Message State **/
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wannaChangeEmail, setWannaChangeEmail] = useState(emailChange);
  const [createTeamUserEmail, { loading }] = useMutation(CREATE_TEAM_EMAIL);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      feedback_enabled: false,
    },
    validationSchema,
    onSubmit(values) {
      processData(values);
    },
  });

  const processData = (data) => {
    const dataToBeProcess = {
      name: data.name,
      email: data.email,
      feedback_enabled: data.feedback_enabled,
      team_id: currentTeamId,
    };
    setIsSubmitting(true);
    createTeamUserEmail({
      variables: dataToBeProcess,
    })
      .then((result) => {
        setWannaChangeEmail(false);

        handleAlertMessage(
          'success',
          `A verification link has been sent on ${data.email}. Please click on the link to verify the email.`
        );
        formik.resetForm();
        setTeamEmails({
          ...result.data.createTeamUserEmail,
        });
        setEmailChange(false);
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        console.log(graphQLErrors, error);
        handleAlertMessage('error', getValidationMessage(graphQLErrors));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  /** This Methods use in Formik Only ***/
  const handleAlertMessage = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
  };

  useEffect(() => {
    if (teamEmails === null) {
      setWannaChangeEmail(true);
    } else {
      formik.setFieldValue(
        'name',
        teamEmails && teamEmails.name ? teamEmails.name : ''
      );
      formik.setFieldValue(
        'email',
        teamEmails && teamEmails.email ? teamEmails.email : ''
      );
      formik.setFieldValue(
        'feedback_enabled',
        teamEmails && teamEmails.feedback_enabled
          ? teamEmails.feedback_enabled
          : false
      );

      setWannaChangeEmail(emailChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamEmails]);

  if (
    !wannaChangeEmail &&
    teamEmails &&
    teamEmails.email &&
    !teamEmails.email_verified_at
  ) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <p>
              {locale(
                'pages:verifyEmail.A verification link has been sent on {teamEmails.email}. Please click on the link to verify the email.',
                { teamEmails }
              )}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant='outline-primary btn-ms-icon'
              type='button'
              onClick={() => {
                setWannaChangeEmail(true);
              }}
            >
              <i className={'ri-edit-box-line'}></i>{' '}
              <span>{locale('pages:verifyEmail.Change Email')}</span>
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  if (loading) {
    return (
      <div className={'sidebar-body'}>
        <CampaignFormLoader />
      </div>
    );
  }
  return (
    <React.Fragment>
      {wannaChangeEmail && (
        <Form>
          <XAlertMessage
            type={alertType}
            message={alertMessage}
            setAlertShow={setAlertShow}
            alertShow={alertShow}
          />
          <Row>
            <Col>
              <p className='font-weight-bold'>
                {locale(
                  'pages:verifyEmail.Add email which will be shown under from when sending emails from campaigns'
                )}
              </p>
              <Form.Group className='form-group'>
                <Form.Label>{locale('name')}</Form.Label>
                <Form.Control
                  id={'name'}
                  className={
                    formik.touched.name && formik.errors.name
                      ? 'is-invalid'
                      : ''
                  }
                  type='text'
                  placeholder={locale('name')}
                  value={formik.values.name}
                  onChange={(e) => {
                    formik.setFieldValue('name', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <InLineErrorMessage
                  type='error'
                  name='name'
                  formik={formik}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='form-group'>
                <Form.Label>{locale('email')}</Form.Label>
                <Form.Control
                  id={'email'}
                  className={
                    formik.touched.email && formik.errors.email
                      ? 'is-invalid'
                      : ''
                  }
                  type='text'
                  placeholder={locale('email')}
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setFieldValue('email', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <InLineErrorMessage
                  type='error'
                  name='email'
                  formik={formik}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='form-group'>
                <Form.Check
                  value='yes'
                  type='checkbox'
                  label={locale(
                    'pages:verifyEmail.Receive bounce and complaint notifications'
                  )}
                  checked={formik.values.feedback_enabled}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'feedback_enabled',
                      !formik.values.feedback_enabled
                    );
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className='d-flex justify-content-end'>
            <Button
              variant='outline-primary btn-ms-icon'
              type='button'
              disabled={isSubmitting}
              onClick={() => {
                // setIsSubmitting(true);
                formik.handleSubmit();
              }}
            >
              <i className={'ri-mail-check-line'}></i>{' '}
              <span>{locale('pages:verifyEmail.Verify Email')}</span>
            </Button>
          </div>
        </Form>
      )}
    </React.Fragment>
  );
};

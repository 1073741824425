import { gql } from "@apollo/client";

/** Tax listing without pagination **/
export const TAX_LIST = gql`
  query($team_id: Int) {
    allTaxes (team_id: $team_id){
      id
      taxes_id
      name
      type
      tax_value
      class
      created_at
      updated_at
    }
  }
`;

/** Tax listing with Pagination **/
export const TAX_LIST_PAGINATION = gql`
query getTaxList(
  $first: Int!
  $page: Int
  $search: String
  $field: String!
  $order: SortOrder!
  $team_id: Int
) {
  taxListing(
    team_id: $team_id
    first: $first
    page: $page
    search: $search
    orderBy: [{ column: $field, order: $order }]
  ) {
    data {
      id
      name
      taxes_id
      type
      tax_value
      class
      created_at
      updated_at
    }
    paginatorInfo {
      currentPage
      total
      lastPage
    }
  }
}`;

/** Remove Tax **/
export const REMOVE_TAX = gql`
  mutation removeTaxes($id: Int, $team_id: Int) {
    removeTax(id: $id, team_id: $team_id) {
      status
    }
  }
`;

/** Create Tax **/
export const UPDATE_TAX = gql`
  mutation updateTax(
    $id: Int
    $name : String
    $type:String
    $tax_value: Float
    $team_id: Int
    ){
    updateTax(
      id: $id
      name: $name
      type: $type
      tax_value: $tax_value
      team_id: $team_id
    ) {
      id
      name
      taxes_id
      type
      tax_value
      class
      created_at
    }
  }`;
/** Update Tax **/
export const CREATE_TAX = gql`
  mutation createTax(
      $name: String
      $tax_value: Float
      $type: String
      $team_id: Int
    ){
    createTax(
      name: $name
      type: $type
      tax_value: $tax_value
      team_id: $team_id
    ) {
      id
      name
      taxes_id
      type
      tax_value
      class
      created_at
    }
  }`;

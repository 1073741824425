import React, { useEffect } from "react";

import { isObjectEmpty } from "../../../../../Utils/utils";
import { Button } from "react-bootstrap";

export const ImportAction = ({
  value = null,
  handleChange = () => {},
  handleClose = () => {},
}) => {
  //   const [errorBag, setErrorBag] = useState(null);
  useEffect(() => {
    if (isObjectEmpty(value)) {
      handleChange("import", {
        value: "any",
        label: "Any Contact Import",
      });
    }
  }, []);

  const handleSave = () => {
    handleChange("import", {
      value: "any",
      label: "Any Contact Import",
    });
    handleClose();
  };
  /*****/
  return (
    <React.Fragment>
      <h3 className="text-center mb-3">
        Select the trigger that will start this business flow
      </h3>
      <div className="d-flex justify-content-center">
        <div className="border mx-3 p-2 px-3 rounded-2">Import</div>
      </div>
      {/* <div className="text-end bg-dark sticky-footer ">
        <Button variant="outline-primary" className="mx-2" onClick={handleSave}>
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
      </div> */}
    </React.Fragment>
  );
};

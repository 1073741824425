import { Text, Editor } from "slate";
import { Transforms } from 'slate';

// Define constants for node types
const ELEMENT_NODE = 1;
const TEXT_NODE = 3;

export const serialize = (nodes) => {
  return nodes.map((n) => serializeNode(n)).join("");
};

const serializeNode = (node) => {
  if (Text.isText(node)) {
    let string = node.text;
    if (node.bold) string = `<strong>${string}</strong>`;
    if (node.italic) string = `<em>${string}</em>`;
    if (node.underline) string = `<u>${string}</u>`;
    if (node.code) string = `<code>${string}</code>`;

    let style = "";
    if (node.color) style += `color: ${node.color};`;
    if (node.bgColor) style += `background-color: ${node.bgColor};`;
    if (node.fontFamily) style += `font-family: ${node.fontFamily};`;
    if (node.fontSize) style += `font-size: ${node.fontSize};`;

    return style ? `<span style="${style}">${string}</span>` : string;
  }

  const children = node.children.map((n) => serializeNode(n)).join("");

  const alignStyle = node.align ? `text-align: ${node.align};` : "";

  switch (node.type) {
    case "heading-one":
      return `<h1 style="${alignStyle}">${children}</h1>`;
    case "heading-two":
      return `<h2 style="${alignStyle}">${children}</h2>`;
    case "heading-three":
      return `<h3 style="${alignStyle}">${children}</h3>`;
    case "heading-four":
      return `<h4 style="${alignStyle}">${children}</h1>`;
    case "heading-five":
      return `<h5 style="${alignStyle}">${children}</h2>`;
    case "heading-six":
      return `<h6 style="${alignStyle}">${children}</h3>`;
    case "block-quote":
      return `<blockquote style="${alignStyle}">${children}</blockquote>`;
    case "numbered-list":
      return `<ol style="${alignStyle}">${children}</ol>`;
    case "bulleted-list":
      return `<ul style="${alignStyle}">${children}</ul>`;
    case "list-item":
      return `<li>${children}</li>`;
    case "paragraph":
      return `<p style="${alignStyle}">${children}</p>`;
    case "table":
      return `<table style="${alignStyle}"><tbody>${children}</tbody></table>`;
    case "table-row":
      return `<tr>${children}</tr>`;
    case "table-cell":
      return `<td>${children}</td>`;
      
    default:
      return children; // For inline content
  }
};


export const deserialize = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const body = doc.body;

  const children = Array.from(body.childNodes)
    .map(deserializeElement)
    .flat()
    .filter(Boolean);

  return children.length > 0
    ? children
    : [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ];
};

const deserializeElement = (element) => {
  if (element.nodeType === Node.TEXT_NODE) {
    return [{ text: element.textContent }];
  }

  if (element.nodeType !== Node.ELEMENT_NODE) {
    return [];
  }

  const nodeName = element.nodeName.toLowerCase();
  let children = Array.from(element.childNodes)
    .map(deserializeElement)
    .flat()
    .filter(Boolean);

  if (children.length === 0) {
    children = [{ text: "" }];
  }

  const data = {};

  // Handle inline styles
  if (element.hasAttribute("style")) {
    const styleString = element.getAttribute("style");
    const textAlignMatch = styleString.match(/text-align:\s*(left|center|right|justify);?/);
    if (textAlignMatch) {
      data.align = textAlignMatch[1];
    }
    const style = {};

    styleString.split(";").forEach((rule) => {
      const [key, value] = rule.split(":").map((s) => s.trim());
      if (key && value) {
        style[key] = value;
      }
    });

    children = children.map((child) => applyStyleToChild(child, style));
  }

  switch (nodeName) {
    // Preserve heading tags
    case "h1":
      return [{ type: "heading-one", align: data.align || "left", children }];
    case "h2":
      return [{ type: "heading-two", align: data.align || "left", children }];
    case "h3":
      return [{ type: "heading-three", align: data.align || "left", children }];
    case "h4":
      return [{ type: "heading-four", align: data.align || "left", children }];
    case "h5":
      return [{ type: "heading-five", align: data.align || "left", children }];
    case "h6":
      return [{ type: "heading-six", align: data.align || "left", children }];
    // Preserve blockquote
    case "blockquote":
      return [{ type: "block-quote", align: data.align || "left", children }];
    // Preserve ordered and unordered lists
    case "ol":
      return [{ type: "numbered-list", align: data.align || "left", children }];
    case "ul":
      return [{ type: "bulleted-list", align: data.align || "left", children }];
    case "li":
      return [{ type: "list-item", align: data.align || "left", children }];
    // Preserve paragraphs
    case "p":
      return [{ type: "paragraph", align: data.align || "left", children }];
    // Preserve inline elements
    case "span":
      return children; // Inline spans do not create new blocks
    case "strong":
      return children.map((child) => applyFormatToChild(child, "bold"));
    case "em":
      return children.map((child) => applyFormatToChild(child, "italic"));
    case "u":
      return children.map((child) => applyFormatToChild(child, "underline"));
    case "code":
      return children.map((child) => applyFormatToChild(child, "code"));
    case "table":
      return [{ type: "table", align: data.align || "left", children }];
    case "tr":
      return [{ type: "table-row", align: data.align || "left", children }];
    case "td":
      return [{ type: "table-cell", align: data.align || "left", children }];
      
    default:
      return children; // Treat unknown tags as inline content
  }
};

const applyStyleToChild = (child, style) => {
  console.log("style::::", style);
  if (Text.isText(child)) {
    const newChild = { ...child };
    if (style["color"]) newChild.color = style["color"];
    if (style["background-color"]) newChild.bgColor = style["background-color"];
    if (style["font-family"]) newChild.fontFamily = style["font-family"];
    if (style["font-size"]) newChild.fontSize = style["font-size"];
    return newChild;
  } else if (child.children) {
    return {
      ...child,
      children: child.children.map((grandchild) =>
        applyStyleToChild(grandchild, style)
      ),
    };
  }
  return child;
};

const applyFormatToChild = (child, format) => {
  if (Text.isText(child)) {
    return { ...child, [format]: true };
  } else if (child.children) {
    return {
      ...child,
      children: child.children.map((grandchild) =>
        applyFormatToChild(grandchild, format)
      ),
    };
  }
  return child;
};

// const deserializeElement = (element) => {
//   if (element.nodeType === TEXT_NODE) {
//     return [{ text: element.textContent }];
//   }

//   if (element.nodeType !== ELEMENT_NODE) {
//     return [];
//   }

//   const nodeName = element.nodeName;
//   let children = Array.from(element.childNodes)
//     .map(deserializeElement)
//     .flat()
//     .filter(Boolean);

//   if (children.length === 0) {
//     children = [{ text: '' }];
//   }

//   switch (nodeName) {
//     case 'STRONG':
//     case 'EM':
//     case 'U':
//     case 'CODE':
//       let format = {};
//       if (nodeName === 'STRONG') format.bold = true;
//       if (nodeName === 'EM') format.italic = true;
//       if (nodeName === 'U') format.underline = true;
//       if (nodeName === 'CODE') format.code = true;
//       return children.map((child) => applyFormatToChildren(child, format));
//     case 'H1':
//       return [{ type: 'heading-one', children }];
//     case 'H2':
//       return [{ type: 'heading-two', children }];
//     case 'BLOCKQUOTE':
//       return [{ type: 'block-quote', children }];
//     case 'UL':
//       return [{ type: 'bulleted-list', children }];
//     case 'OL':
//       return [{ type: 'numbered-list', children }];
//     case 'LI':
//       return [{ type: 'list-item', children }];
//     case 'P':
//       return [{ type: 'paragraph', children }];
//     default:
//       return [{ type: 'paragraph', children }];
//   }
// };

const applyFormatToChildren = (node, format) => {
  if (Text.isText(node)) {
    return { ...node, ...format };
  } else if (node.children && node.children.length > 0) {
    return {
      ...node,
      children: node.children.map((child) =>
        applyFormatToChildren(child, format)
      ),
    };
  } else {
    return node;
  }
};

export const toggleMark = (editor, format, value) => {
  const isActive = isMarkActive(editor, format, value);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, value);
  }
};

export const isMarkActive = (editor, format, value) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === value : false;
};

export const insertTable = (editor, rows = 2, columns = 2) => {
  const table = {
    type: 'table',
    children: Array.from({ length: rows }).map(() => ({
      type: 'table-row',
      children: Array.from({ length: columns }).map(() => ({
        type: 'table-cell',
        children: [{ text: '' }]
      }))
    }))
  };

  Transforms.insertNodes(editor, table);
};

import React from "react";
import { Button } from "react-bootstrap";
const ConfirmBox = ({
  setShowImports,
  setIsConfirm,
  inActiveStatusAndClearData,
}) => {
  return (
    <div className={"inset-popup"}>
      <div className={"inset-popup-body"}>
        <h3>Are you sure! you want to cancel the wizard?</h3>
        <Button
          variant="outline-primary text-uppercase"
          onClick={(e) => {
            setShowImports(false);
            inActiveStatusAndClearData();
            setIsConfirm(false);
          }}
        >
          Yes
        </Button>{" "}
        {"  "}
        <Button
          variant="outline-primary text-uppercase"
          onClick={(e) => {
            setIsConfirm(false);
          }}
        >
          No
        </Button>
      </div>
    </div>
  );
};
export default ConfirmBox;

import React from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
// import { ProductDropdown } from "../../Pages/Products/ProductDropdown";
import { nonGroupColourStyles } from "../../Common/DropDown/Style";
import { getCurrencyByUserFormat } from "../../../Utils/CurrentSetting";
import { useTranslation } from "react-i18next";
import { NewServiceTask } from "./NewServiceTask";
import InLineError from "../../Common/AlertMessage/InLineError";

const pattern = "[0-9]*(.[0-9]+)?";
export const NewServiceItem = ({
  serviceIndex,
  service,
  paymentType,
  handleData = () => {},
  currency,
  offeringType = "service",
  currentCompanySetting,
  errors,
}) => {
  const { t: locale } = useTranslation();

  const onHandleFloatingNumberChange = (e, name) => {
    if (e.target.value) {
      const decimalA = /^[0-9.\-/]+$/;
      if (decimalA.test(e.target.value)) handleData(name, e.target.value);
    } else {
      handleData(name, "");
    }
  };

  const updateServiceTaskName = (value) => {
    const serviceTasks = service.tasks || [];
    handleData(
      "tasks",
      serviceTasks.map((item) => item && { ...item, title: value })
    );
  };

  const hasValue = (name) => {
    return service[name] ? service[name] : "";
  };
  /*****/
  return (
    <React.Fragment>
      <Row>
        <Col xs={12} sm={4} md={4}>
          <Form.Group className="form-group">
            <Form.Label>
              {locale(
                (offeringType === "service" ? "Service" : "Product") + " name"
              )}
            </Form.Label>
            <Form.Control
              //   className={hasError("name")}
              id={"name"}
              name="name"
              type="text"
              placeholder={locale(
                (offeringType === "service" ? "Service" : "Product") + " name"
              )}
              value={hasValue("name")}
              onChange={(e) => {
                handleData("name", e.target.value);
              }}
              onBlur={(e) => {
                handleData("name", e.target.value);
                updateServiceTaskName(e.target.value);
              }}
            />
            <InLineError type="error" message={errors?.name || ""} />
          </Form.Group>
        </Col>

        {offeringType === "service" && (
          <Col xs={"auto"}>
            <Form.Group className="form-group">
              <Form.Label>{locale("payment type")}</Form.Label>
              <Select
                id={"type"}
                name="type"
                isSearchable={false}
                options={paymentType}
                value={paymentType.filter(
                  (item) => item.value === service.type
                )}
                onChange={(e) => {
                  handleData("type", e.value);
                  if (e.value === "free") {
                    handleData("price", 0);
                  }
                }}
                styles={nonGroupColourStyles}
              />{" "}
              <InLineError type="error" message={errors?.type || ""} />
            </Form.Group>
          </Col>
        )}
        {service.type !== "free" && (
          <React.Fragment>
            <Col xs={"auto"} sm={2} md={2}>
              <Form.Group className="form-group">
                <Form.Label>
                  {locale(
                    service.product_type !== "physical" &&
                      service.type === "recurring"
                      ? "Your Rate"
                      : "Cost"
                  )}
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    {getCurrencyByUserFormat(currentCompanySetting)}
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="cost"
                    pattern={pattern}
                    placeholder={locale(
                      service.product_type !== "physical" &&
                        ["recurring"].indexOf(service.type) > -1
                        ? "Your Rate"
                        : "Cost"
                    )}
                    min={0}
                    max={9999999}
                    value={hasValue("cost")}
                    onChange={(e) => {
                      onHandleFloatingNumberChange(e, "cost");
                    }}
                  />
                </InputGroup>
                <InLineError type="error" message={errors?.cost || ""} />
              </Form.Group>
            </Col>
            <Col xs={"auto"} sm={2} md={2}>
              <Form.Group className="form-group">
                <Form.Label>
                  {service.product_type === "service"
                    ? locale(service.type === "recurring" ? "Rate" : "Price")
                    : locale("price")}
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    {getCurrencyByUserFormat(currentCompanySetting)}
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="price"
                    pattern={pattern}
                    placeholder={locale(
                      service.type === "recurring" ? "Rate" : "Price"
                    )}
                    min={0}
                    max={9999999}
                    value={hasValue("price")}
                    onChange={(e) => {
                      onHandleFloatingNumberChange(e, "price");
                    }}
                  />
                </InputGroup>
                <InLineError type="error" message={errors?.price || ""} />
              </Form.Group>
            </Col>
          </React.Fragment>
        )}
        {service.product_type === "physical" && (
          <Col xs={"auto"} sm={2} md={2}>
            <Form.Group className="form-group w-auto">
              <Form.Label>{locale("stock")}</Form.Label>
              <Form.Control
                // className={hasError("stock")}
                type="number"
                name="stock"
                id={"stock"}
                min={0}
                max={999999}
                value={hasValue("stock")}
                placeholder={locale("stock")}
                onChange={(e) =>
                  handleData("stock", e ? parseInt(e.target.value) : "")
                }
              />
              <InLineError type="error" message={errors?.stock || ""} />
            </Form.Group>
          </Col>
        )}
        {service.type !== "free" && (
          <Col xs={"auto"} sm={2} md={2} className="px-0">
            <Form.Group className="form-group">
              <Form.Label>{locale("taxable item?")}</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  id="radio1"
                  name="tax_applicable"
                  label={locale("yes")}
                  className={"mt-2"}
                  inline
                  value={"true"}
                  checked={service.tax_applicable === "true"}
                  onChange={(e) => handleData("tax_applicable", e.target.value)}
                />
                <Form.Check
                  type="radio"
                  id="radio2"
                  name="tax_applicable"
                  label={locale("no")}
                  className={"mt-2"}
                  inline
                  value={"false"}
                  checked={service.tax_applicable === "false"}
                  onChange={(e) => handleData("tax_applicable", e.target.value)}
                />
              </div>
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12} sm={4} md={4}>
          <Form.Group className="form-group">
            <Form.Label>{locale("description")}</Form.Label>
            <Form.Control
              id={"description"}
              as="textarea"
              rows="5"
              placeholder={locale("description") + "..."}
              name="description"
              value={hasValue("description")}
              onChange={(e) => {
                handleData("description", e.target.value);
              }}
            />
            <InLineError type="error" message={errors?.description || ""} />
          </Form.Group>
        </Col>
        <Col>
          {offeringType === "service" && (
            <NewServiceTask
              service={service}
              handleData={handleData}
              errors={errors?.tasks || null}
            />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

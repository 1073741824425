import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { nonGroupColourStyles } from "../../../Components/Common/DropDown/Style";
import {
  availableDateFormat,
  getUserTimeZone,
  showTimeZoneString,
} from "../../../Utils/EqupTimezone";

export const SelectDateFormat = ({
  label = "Set Date Format",
  showLabel = true,
  iconClass = "ri-calendar-2-fill",
  value = "",
  getDateFormat = () => {},
  currentCompanySetting,
  locale,
}) => {
  const [dateFormat, setDateFormat] = useState();

  useEffect(() => {
    const timezone = getUserTimeZone(currentCompanySetting);
    setDateFormat(
      availableDateFormat.map(
        (format) =>
          format && {
            value: format,
            label: (
              <div>
                <span className="small font-weight-bold">{format}</span>
                <br />
                <span className="small ">
                  {showTimeZoneString(timezone, format)}
                </span>
              </div>
            ),
          }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Group className="form-group">
      {showLabel ? (
        <Form.Label>{locale("pages:setting.common." + label)}</Form.Label>
      ) : (
        ""
      )}
      <InputGroup>
        <InputGroup.Text>
          <i className={iconClass}></i>
        </InputGroup.Text>
        <Select
          isSearchable={false}
          options={dateFormat}
          placeholder={locale("Date Format")}
          value={
            dateFormat && dateFormat.filter((item) => item.value === value)[0]
          }
          onChange={(e) => {
            getDateFormat(e);
          }}
          styles={nonGroupColourStyles}
        />
      </InputGroup>
    </Form.Group>
  );
};

import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./business-flow.scss";
import { StartBusinessFlow } from "./StartBusinessFlow";

const options = [
  { value: "manageBusiness", label: "Manage My Business" },
  { value: "automateBusiness", label: "Automation My Business" },
  { value: "automateJobs", label: "Automate Jobs" },
];
export const BusinessFlowBuilder = () => {
  const [selectedOption, setSelectedOption] = useState(["manageBusiness"]);
  const [isStart, setIsStart] = useState(false);

  const handleSelect = (value) => {
    if (selectedOption.includes(value)) {
      setSelectedOption(selectedOption.filter((item) => item !== value));
    } else {
      setSelectedOption([...selectedOption, value]);
    }
  };

  /******/
  return (
    <Container fluid className="business-flow-builder m-0 p-0">
      {!isStart ? (
        <div className="choose-options">
          <div className="option-container">
            <h3 className="text-primary mb-2">
              What would you like to do with FieldServicePro?
            </h3>
            <Row>
              {options.map((item, idx) => (
                <Col key={idx}>
                  <div className="text-end mt-1 hint-icon">
                    <i className="ri-question-line ri-lg" />
                  </div>
                  <div className="my-2 label">
                    <p>{item.label}</p>
                  </div>
                  <div className="text-end check-button">
                    <Form.Check
                      type="checkbox"
                      label=""
                      onClick={() =>
                        item.value !== "manageBusiness" &&
                        handleSelect(item.value)
                      }
                      checked={
                        selectedOption.includes(item.value) ||
                        item.value == "manageBusiness"
                      }
                      onChange={() => {}}
                    />
                  </div>
                </Col>
              ))}
            </Row>
            <div className="text-end my-2">
              <Button className="primary" onClick={() => setIsStart(true)}>
                Let's Go
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <StartBusinessFlow selectedOption={selectedOption} />
      )}
    </Container>
  );
};

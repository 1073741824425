import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import "./business-flow.scss";
import { SelectDropdown } from "../../Common/Conditions/SelectDropdown";
import { SelectPhoneNumberFormat } from "../../Common/SettingComponents/SelectPhoneNumberFormat";
import { SelectNumberFormat } from "../../Common/SettingComponents/SelectNumberFormat";
import { SelectDateFormat } from "../../Common/SettingComponents/SelectDateFormat";
import { SelectTimezone } from "../../Common/SettingComponents/SelectTimezone";
import { SelectLanguage } from "../../Common/SettingComponents/SelectLanguage";
import { SelectCurrencyFormat } from "../../Common/SettingComponents/SelectCurrencyFormat";
import { useFormik } from "formik";
import { getValidationSchema } from "../../../Utils/formikValidator";
import { useTranslation } from "react-i18next";
import { getPagePermissions } from "../../../Utils/RolesAndPermission";
import {
  currentTeamId,
  fillNonExistedSettings,
  getLanguage as getLanguageFromUtils,
  getValidationMessage,
} from "../../../Utils/utils";
import EditProfileImage from "../../Common/FileUpload/EditProfileImage";
import {
  getCountriesList,
  getCountryByCode,
  getCountryWiseStates,
  getStateByName,
} from "../../../Utils/Countries";
import InLineErrorMessage from "../../Common/AlertMessage/InLineErrorMessage";
import PhoneInput from "../../Common/PhoneNumber/PhoneInput";
import { nonGroupColourStyles } from "../../Common/DropDown/Style";
import Select from "react-select";
import { PermissionDenied } from "../../../Pages/PageNotFound/PageNotFound";
import { handleTimezoneLocale } from "../../../i18n";
import { SAVE_SETTING } from "../../../GraphApi/TeamMembers";
import { useLazyQuery, useMutation } from "@apollo/client";
import { COUNTRY_LIST } from "../../../GraphApi/ContactList";

const options = [
  { value: "manage_business", label: "Manage My Business" },
  { value: "automate_business", label: "Manage My Automation" },
  { value: "automate_job", label: "Automate Jobs" },
];

const fieldsToBeValidate = [
  {
    type: "text",
    name: "address",
    isRequired: false,
    maxLength: 255,
    minLength: 10,
    isNullable: true,
  },
  {
    type: "text",
    name: "city",
    isRequired: false,
    maxLength: 50,
    minLength: 3,
    isNullable: true,
  },
  {
    type: "object",
    name: "state",
    isRequired: false,
    maxLength: 50,
    minLength: 3,
    isNullable: true,
  },
  {
    type: "object",
    name: "country",
    isRequired: false,
    maxLength: 50,
    minLength: 2,
    isNullable: true,
  },
  {
    type: "text",
    name: "zip_code",
    isRequired: false,
    maxLength: 10,
    minLength: 5,
    isNullable: true,
  },
  {
    type: "url",
    name: "company_website",
    isRequired: false,
    maxLength: null,
    minLength: 5,
    isNullable: true,
  },
  {
    type: "text",
    name: "company_name",
    isRequired: true,
    maxLength: 50,
    minLength: 3,
    isNullable: false,
  },
];
export const CompanyBasicInfo = ({
  currentCompanySetting,
  companyDetails,
  handleAlertMessage,
  userData,
  setUser,
  setup,
  setSetup = () => {},
}) => {
  const { t: locale } = useTranslation();
  const refDrawer = useRef(null);
  const companyAvatar = require("../../../Assets/Images/company-avtar.png");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState("US");
  /****/
  const [validationSchema] = useState(
    getValidationSchema(fieldsToBeValidate, locale)
  );
  const [avtar, setAvtar] = useState(companyAvatar);
  const [file, setFile] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [newSetting, setNewSetting] = useState(null);
  const [pagePermissions, setPagePermissions] = useState({});
  const [permissionsError, setPermissionsError] = useState({});
  const [industries, setIndustries] = useState(null);

  const [getCountry, { data: countriesData }] = useLazyQuery(COUNTRY_LIST);
  /** Update Company Profile Image **/
  const [updateCompanySettings] = useMutation(SAVE_SETTING);

  useEffect(() => {
    if (userData?.authData?.currentTeam) {
      const permissions = getPagePermissions(userData, "company_setting");
      setPagePermissions(permissions);

      if (permissions?.company_settings_access?.isAllow) {
        setPermissionsError({});
      } else {
        setPermissionsError({
          ...permissionsError,
          view: locale("messages:Permissions.view_error"),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  /*** INITIALIZE FORMIK ***/
  const formik = useFormik({
    initialValues: {
      company_name: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
      company_website: "",
      phone_number: "",
      phone_number_format: "",
      number_format: "",
      date_format: "",
      time_zone: "",
      language: "",
      currency: "",
      company_logo: null,
    },
    validationSchema: validationSchema,
    //enableReinitialize: true,
    onSubmit(values) {
      setIsSubmitting(true);
      const errors = validateAddress(values);
      if (Object.keys(errors).length > 0) {
        formik.setErrors(errors);
        setIsSubmitting(false);
      } else {
        processData(values);
      }
    },
  });
  function validateAddress(fields) {
    const { address, city, state, country, zip_code } = fields;
    const errors = {};
    // Check if any of the fields are filled
    const isAnyFieldFilled = address || city || state || country || zip_code;

    // If any field is filled, check for empty fields and add error messages
    if (isAnyFieldFilled) {
      if (!address) errors.address = "Address is required.";
      if (!city) errors.city = "City is required.";
      if (!state) errors.state = "State is required.";
      if (!country) errors.country = "Country is required.";
      if (!zip_code) errors.zip_code = "Zip code is required.";
    }

    // Return the error messages
    return errors;
  }
  /*** ON FORM SUBMIT ***/
  const processData = (dataToBeProcess) => {
    const dataForProcess = {
      team_id: currentTeamId,
      company_name: dataToBeProcess.company_name,
      phone_number: dataToBeProcess.phone_number,
      company_website: dataToBeProcess.company_website,
      phone_number_format: dataToBeProcess.phone_number_format,
      number_format: dataToBeProcess.number_format,
      date_format: dataToBeProcess.date_format,
      time_zone: dataToBeProcess.time_zone,
      language: dataToBeProcess.language,
      currency: dataToBeProcess.currency
        ? dataToBeProcess.currency.toLowerCase()
        : dataToBeProcess.currency,
      company_address: {
        address: dataToBeProcess.address,
        country: dataToBeProcess.country.countryCode,
        state: dataToBeProcess.state.label,
        zip: dataToBeProcess.zip_code,
        city: dataToBeProcess.city,
      },
      company_logo: file !== null && file.length > 0 ? file[0] : null,
      //   booking_availability: JSON.stringify(availability),
    };

    updateCompanySettings({ variables: { ...dataForProcess } })
      .then((result) => {
        handleAlertMessage(
          "success",
          locale("messages:common.update", {
            fieldName: locale("company details"),
          })
        );
        const currentTeamData = result.data.updateCompanySettings;
        setNewSetting(currentTeamData);
        setIsSubmitting(false);
        setSetup({
          ...setup,
          basicInfo: { ...setup.basicInfo, status: "completed" },
          addService: { ...setup.addService, status: "active" },
        });
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
        setIsSubmitting(false);
      });
  };

  const selectCountry = (value) => {
    if (value) {
      const countryWiseState = getCountryWiseStates(
        JSON.parse(localStorage.country).countryList,
        value.value
      );
      setStates(countryWiseState);
    } else {
      setStates([]);
    }
  };

  const getDateFormat = (e) => {
    formik.setFieldValue("date_format", e.value);
  };
  const getPhoneNumberFormat = (e) => {
    formik.setFieldValue("phone_number_format", e.value);
  };
  const getNumberFormat = (e) => {
    formik.setFieldValue("number_format", e.value);
  };
  const getTimezoneFormat = (e) => {
    formik.setFieldValue("time_zone", e.value);
  };
  const getCurrencyFormat = (e) => {
    formik.setFieldValue("currency", e.value);
  };
  const getLanguage = (e) => {
    formik.setFieldValue("language", e.value);
  };

  /***UPDATE NEW SETTING TO AUTH USER CONTEXT ****/
  useEffect(() => {
    if (newSetting !== null && newSetting !== undefined) {
      let companyLogo = null;
      let language = "en";
      fillNonExistedSettings(newSetting).forEach((setting) => {
        if (setting.setting_name === "company_logo") {
          companyLogo =
            setting?.setting_value ||
            userData?.authData?.currentTeam?.company_logo ||
            "";
        } else if (setting.setting_name === "language") {
          language = getLanguageFromUtils({ language: setting.setting_value });
        }
      });

      handleTimezoneLocale(language);

      setTimeout(() => {
        setUser({
          ...userData.authData,
          currentTeam: {
            ...userData.authData.currentTeam,
            company_logo: companyLogo,
            settings: newSetting,
          },
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSetting]);
  useEffect(() => {
    if (countriesData) {
      localStorage.setItem("country", JSON.stringify(countriesData));
      const country = getCountriesList(countriesData.countryList);
      setCountries(country);
    }
  }, [countriesData]);

  useEffect(() => {
    if (!localStorage?.country) {
      getCountry();
    } else {
      const country = getCountriesList(
        JSON.parse(localStorage.country).countryList
      );
      setCountries(country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /****/
  useEffect(() => {
    if (userData?.authData?.currentTeam && countries?.length > 0) {
      if (userData.authData.currentTeam?.settings?.length > 0) {
        fillNonExistedSettings(userData.authData.currentTeam.settings).forEach(
          (setting) => {
            if (
              setting.setting_name === "company_address" &&
              setting.setting_value
            ) {
              const { address, city, state, country, zip } = JSON.parse(
                setting.setting_value
              );
              if (country) setDefaultCountry(country);
              formik.setFieldValue("address", address);
              formik.setFieldValue("city", city);
              formik.setFieldValue("zip_code", zip);
              const countryNew = getCountryByCode(countries, country);
              selectCountry(countryNew);
              const stateNew = getStateByName(
                JSON.parse(localStorage.country).countryList,
                state
              );
              formik.setFieldValue("country", countryNew);
              formik.setFieldValue("state", stateNew);
            } else if (setting.setting_name === "company_logo") {
              setAvtar(setting?.setting_value || companyAvatar);
            } else {
              formik.setFieldValue(
                setting.setting_name,
                setting?.setting_value || ""
              );
            }
          }
        );
      } else {
        setAvtar(
          userData?.authData?.currentTeam?.company_logo || companyAvatar
        );
        formik.setFieldValue(
          "company_name",
          userData?.authData?.currentTeam?.company_name || ""
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, countries]);

  useEffect(() => {
    if (file !== null && file.length > 0) {
      console.log(file[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  // useEffect(() => {
  //   if (file === "" && companyDetails) {
  //     setAvtar(
  //       companyDetails.image ? companyDetails.image.path : companyAvatar
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [companyDetails]);
  /******/
  if (permissionsError?.view?.length > 0) {
    return <PermissionDenied />;
  }

  return (
    <React.Fragment>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Card>
          <Card.Body className="p-2">
            <div>
              <h3 className="text-primary mb-1">
                Select the industry or industries best suits your business
              </h3>
              <div>
                <Row className="my-2">
                  <Col xs={12} md={6}>
                    <SelectDropdown
                      labelVisibility={false}
                      value={industries}
                      options={options}
                      isMulti={true}
                      handleChange={(e) => {
                        setIndustries(
                          e?.length > 0 ? e.map((item) => item.value) : []
                        );
                      }}
                      placeholder="Select Industries"
                      styles={nonGroupColourStyles}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="form-group">
                      <Row>
                        <Col>
                          <Form.Label>Company Name</Form.Label>

                          <InputGroup>
                            <InputGroup.Text>
                              <i className="ri-building-fill"></i>
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder={locale("company name")}
                              value={formik?.values?.company_name || ""}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "company_name",
                                  e.target.value
                                )
                              }
                            />
                          </InputGroup>
                          <InLineErrorMessage
                            type="error"
                            name="company_name"
                            formik={formik}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="form-group mb-0">
                      <Row>
                        <Col>
                          <Form.Label>Company Address</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <i className="ri-map-pin-fill"></i>
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder={locale("address")}
                              value={formik.values.address || ""}
                              onChange={(e) =>
                                formik.setFieldValue("address", e.target.value)
                              }
                            />
                          </InputGroup>
                          <InLineErrorMessage
                            type="error"
                            name="address"
                            formik={formik}
                          />
                          <Form.Group className="form-group">
                            <div className="d-flex mt-3">
                              <div className="w-25 me-1">
                                <Form.Label>Country</Form.Label>
                                <Select
                                  options={countries}
                                  placeholder={locale("country")}
                                  value={formik.values.country}
                                  styles={nonGroupColourStyles}
                                  onChange={(e) => {
                                    formik.setFieldValue("country", e);
                                    selectCountry(e);
                                  }}
                                />
                                <InLineErrorMessage
                                  type="error"
                                  name="country"
                                  formik={formik}
                                />
                              </div>
                              <div className="w-25 me-1">
                                <Form.Label>State</Form.Label>
                                <Select
                                  options={states}
                                  placeholder={locale("state")}
                                  value={formik.values.state}
                                  styles={nonGroupColourStyles}
                                  onChange={(e) => {
                                    formik.setFieldValue("state", e);
                                  }}
                                />
                              </div>
                              <div className="w-25 me-1">
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder={locale("city")}
                                  value={formik?.values?.city || ""}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "city",
                                      e.target.value
                                    );
                                  }}
                                />
                                <InLineErrorMessage
                                  type="error"
                                  name="city"
                                  formik={formik}
                                />
                              </div>
                              <div className="w-25">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder={locale("zip code")}
                                  value={formik?.values?.zip_code || ""}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "zip_code",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Row>
                        <Col>
                          <Form.Label>Phone number</Form.Label>
                          <InputGroup style={{ width: "100%" }}>
                            <PhoneInput
                              data={formik.values.phone_number}
                              formik={formik}
                              defaultCountry={defaultCountry}
                              locale={locale}
                              error={
                                formik?.touched?.phone_number &&
                                formik?.errors?.phone_number
                                  ? "is-invalid"
                                  : ""
                              }
                              name={"phone_number"}
                              placeholder={"Phone"}
                              key={"phone_number"}
                              isDisabled={false}
                            />
                          </InputGroup>
                          <InLineErrorMessage
                            type="error"
                            name="phone_number"
                            formik={formik}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <div className="setting-company-detail-inner">
                      <div
                        className={
                          "company-profile d-flex justify-content-start"
                        }
                      >
                        <div style={{ width: "100px", height: "100px" }}>
                          <img
                            src={avtar}
                            className={"rounded-circle"}
                            alt="Basic Avtar"
                            height={100}
                            width={100}
                          />
                        </div>

                        <Button
                          variant="outline-primary"
                          style={{ width: "100px", height: "100px" }}
                        >
                          <EditProfileImage
                            accept="image"
                            handleUpload={(e) => {
                              setFile(e);
                              setAvtar(
                                e && e[0].preview
                                  ? e[0].preview
                                  : companyDetails?.image?.path || companyAvatar
                              );
                            }}
                            handleAlertMessage={() => {}}
                            isMulti={false}
                            extraStyle={{
                              width: "100px",
                              height: "100px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={4} sm={4} lg={3}>
                    <Row>
                      {/* <Col md={5}>
                        <Form.Group>
                          <Form.Label>Phone Number Format</Form.Label>
                        </Form.Group>
                      </Col>*/}
                      <Col>
                        <SelectPhoneNumberFormat
                          value={formik.values.phone_number_format}
                          getPhoneNumberFormat={getPhoneNumberFormat}
                          locale={locale}
                          // showLabel={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col md={5}>
                        <Form.Group>
                          <Form.Label>Number Format</Form.Label>
                        </Form.Group>
                      </Col> */}
                      <Col>
                        <SelectNumberFormat
                          value={formik.values.number_format}
                          getNumberFormat={getNumberFormat}
                          locale={locale}
                          // showLabel={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col md={5}>
                        <Form.Group>
                          <Form.Label>Date Format</Form.Label>
                        </Form.Group>
                      </Col> */}
                      <Col>
                        <SelectDateFormat
                          value={formik.values.date_format}
                          getDateFormat={getDateFormat}
                          currentCompanySetting={currentCompanySetting}
                          locale={locale}
                          // showLabel={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col md={5}>
                        <Form.Group>
                          <Form.Label>Timezone</Form.Label>
                        </Form.Group>
                      </Col> */}
                      <Col>
                        <SelectTimezone
                          value={formik.values.time_zone}
                          getTimezoneFormat={getTimezoneFormat}
                          locale={locale}
                          // showLabel={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col md={5}>
                        <Form.Group>
                          <Form.Label>Language</Form.Label>
                        </Form.Group>
                      </Col> */}
                      <Col>
                        <SelectLanguage
                          value={formik.values.language}
                          getLanguage={getLanguage}
                          locale={locale}
                          // showLabel={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col md={5}>
                        <Form.Group>
                          <Form.Label>Currency</Form.Label>
                        </Form.Group>
                      </Col> */}
                      <Col>
                        <SelectCurrencyFormat
                          value={formik.values.currency}
                          getCurrencyFormat={getCurrencyFormat}
                          locale={locale}
                          // showLabel={false}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="">
            <div className="d-flex justify-content-between">
              <Button
                variant="outline-secondary"
                disabled={isSubmitting}
                type="button"
                onClick={() =>
                  setSetup({
                    ...setup,
                    basicInfo: { ...setup.basicInfo, status: "completed" },
                    addService: { ...setup.addService, status: "active" },
                  })
                }
              >
                <span>{locale("Skip")}</span>
              </Button>
              <Button
                variant="outline-primary"
                disabled={isSubmitting}
                type="submit"
              >
                <i className={"ri-save-2-line font-weight-normal"}></i>{" "}
                <span>{locale("save")}</span>
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Form>
    </React.Fragment>
  );
};

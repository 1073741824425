import { RestApi, getIdToken } from "../RestApi/RestApi";
import { API_URL, getCookie } from "../Utils/utils";

export async function getAgreementTemplates(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement-template";
  return await RestApi.get(url, {
    params: data,
  });
}

export async function getAgreementTemplate(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement-template/" + (operation ? operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}

export async function saveAgreementTemplate(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement-template";
  return await RestApi.post(url, data);
}

export async function updateAgreementTemplate(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement-template/" + (operation ? operation : "");
  return await RestApi.put(url, data);
}

export async function removeAgreementTemplate(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement-template/" + (operation ? operation : "");
  return await RestApi.delete(url, {
    params: data,
  });
}

// export const saveTemplateWithFile = (file, name, teamId) => {
//   const url = API_URL + "/api/agreement-template";
//   return new Promise((resolve, reject) => {
//     try {
//       const formData = new FormData();
//       formData.append("team_id", teamId);
//       formData.append("name", name);
//       formData.append("document", file);
//       const xhr = new XMLHttpRequest();
//       xhr.open("POST", url, true);
//       // Set the authorization header with the token
//       xhr.setRequestHeader("Authorization", "Bearer " + getCookie("AUTH-TOKEN"));
//       xhr.onreadystatechange = function () {
//         if (xhr.readyState === 4) {
//           if (xhr.status === 200) {
//             const response = JSON.parse(xhr.responseText);
//             resolve(response);
//           } else {
//             reject(xhr.status);
//           }
//         }
//       };
//       xhr.send(formData);
//     } catch (error) {
//       console.log("ERROR:", error);
//       reject(error);
//     }
//   });
// };

export const saveTemplateWithFile = (file, name, teamId) => {
  const url = API_URL + "/api/agreement-template";
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("team_id", teamId);
    formData.append("name", name);
    formData.append("document", file);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    // Set the authorization header with the token
    if (process.env.REACT_APP_ENV === "local-online") {
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + getCookie("AUTH-TOKEN")
      );
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        } else {
          reject(xhr.status);
        }
      }
    };
    xhr.send(formData);
  }).catch((error) => {
    console.log("Error message:", error);
    return error;
  });
};

/**
 * Agreements related endpoints...
 */

// agreements listing...
export async function getAgreementLists(data = null, operation = null) {
  // first, field, order, page, search
  await getIdToken();
  const url = "/api/agreements/list";
  return await RestApi.get(url, {
    params: data,
  });
}
export async function getAgreements(data = null, operation = null) {
  // first, field, order, page, search
  await getIdToken();
  const url = "/api/agreements";
  return await RestApi.get(url, {
    params: data,
  });
}

// save agreement...
export async function saveAgreementApi(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement";
  return await RestApi.post(url, data);
}

// get agreement detail...
export async function getAgreementDetail(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement/" + (operation ? operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}

// delete agreement...
export async function removeAgreementRecord(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement/" + (operation ? operation : "");
  return await RestApi.delete(url, {
    params: data,
  });
}

// update agreement...
export async function updateAgreementRecord(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement/" + (operation ? operation : "");
  return await RestApi.put(url, data);
}

// get agreement detail...
export async function getAgreementSigningDetail(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement-signing/" + (operation ? operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}

// get agreement detail...
export async function postAgreementSigningDetail(
  data = null,
  operation = null
) {
  await getIdToken();
  const url = "/api/post-signing/" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

export async function postPartiallySigningDetail(
  data = null,
  operation = null
) {
  await getIdToken();
  const url = "/api/post-partially-signing/" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

// Post Signature Image...
export async function postSignatureImage(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement-signature" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

export async function agreementSendForApproval(data = null, operation = null) {
  await getIdToken();
  const url = "/api/manage-approvers";
  return await RestApi.post(url, data);
}

export async function agreementReviewAction(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement-status/" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

export async function agreementPartiallyFilled(data = null, operation = null) {
  await getIdToken();
  const url = "/api/post-partially-signing/" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

export async function agreementChangeRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/agreement-request-update/" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

export async function removeCommentsFromAgreement(
  data = null,
  operation = null
) {
  await getIdToken();
  const url = "/api/agreement-comment-delete/" + (operation ? operation : "");
  return await RestApi.delete(url, {
    params: data,
  });
}

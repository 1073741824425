import { RestApi, getIdToken } from "../RestApi/RestApi";
/**
 * Agreements related endpoints...
 */

// agreements listing...
export async function getEstimateTemplatesRequest(
  data = null,
  operation = null
) {
  // first, field, order, page, search
  await getIdToken();
  const url = "/api/estimate-templates";
  return await RestApi.get(url, {
    params: data,
  });
}

// agreements listing...
export async function getOwnerDetails(data = null, operation = null) {
  // first, field, order, page, search
  await getIdToken();
  const url = "/api/get-owner-Detail/" + (operation ? operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}

// agreements listing...
export async function getEstimatesRequest(data = null, operation = null) {
  // first, field, order, page, search
  await getIdToken();
  const url = "/api/estimates";
  return await RestApi.get(url, {
    params: data,
  });
}

export async function saveEstimateTemplateRequest(
  data = null,
  operation = null
) {
  await getIdToken();
  const url = "/api/estimate-template";
  return await RestApi.post(url, data);
}

export async function updateEstimateTemplateRequest(
  data = null,
  operation = null
) {
  await getIdToken();
  const url = "/api/estimate-template/" + (operation ? operation : "");
  return await RestApi.put(url, data);
}

export async function saveEstimateRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/estimate";
  return await RestApi.post(url, data);
}

export async function updateEstimateRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/estimate/" + (operation ? operation : "");
  return await RestApi.put(url, data);
}

export async function getEstimateTemplateRequest(
  data = null,
  operation = null
) {
  await getIdToken();
  const url = "/api/estimate-template/" + (operation ? operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}

export async function getEstimateRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/estimate/" + (operation ? operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}

// delete agreement...
export async function removeEstimateRecord(data = null, operation = null) {
  await getIdToken();
  const url = "/api/estimate/" + (operation ? operation : "");
  return await RestApi.delete(url, {
    params: data,
  });
}

// delete agreement...
export async function removeEstimateTemplateRecord(
  data = null,
  operation = null
) {
  await getIdToken();
  const url = "/api/estimate-template/" + (operation ? operation : "");
  console.log(url);
  return await RestApi.delete(url, {
    params: data,
  });
}

export async function estimateChangeRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/estimate-request-update/" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

export async function estimateReviewAction(data = null, operation = null) {
  await getIdToken();
  const url = "/api/estimate-status/" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

// get agreement detail...
export async function getEstimateSigningDetail(data = null, operation = null) {
  await getIdToken();
  const url = "/api/estimate-signing/" + (operation ? operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}
export async function postEstimateSigningDetail(data = null, operation = null) {
  await getIdToken();
  const url = "/api/estimate-post-signing/" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

export async function postLogPayment(data = null, operation = null) {
  await getIdToken();
  const url = "/api/accept-log-payment/" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

export async function postReceivedPayment(data = null, operation = null) {
  await getIdToken();
  const url = "/api/accept-payment/" + (operation ? operation : "");
  return await RestApi.post(url, data);
}

export async function getReceivedPayment(data = null, operation = null) {
  await getIdToken();
  const url = "/api/view-estimate/" + (operation ? operation : "");
  return await RestApi.get(url, {
    params: data,
  });
}

export async function convertToJob(data = null, operation = null) {
  await getIdToken();
  const url =
    "/api/estimate/" + (operation ? operation : "") + "/convert-to-job";
  return await RestApi.post(url, data);
}

export async function saveEstimateAsDraft(data = null, operation = null) {
  await getIdToken();
  const url = "/api/estimate/save-as-draft/" + (operation ? operation : "");
  if (operation) return await RestApi.put(url, data);
  else return await RestApi.post(url, data);
}

export async function allEstimateList(data = null, operation = null) {
  await getIdToken();
  const url = "/api/estimates/list";
  return await RestApi.get(url, {
    params: data,
  });
}

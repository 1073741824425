import { RestApi, getIdToken } from "../RestApi/RestApi";

// ALL SMS listing W/O Pagination
export async function getSMSWithoutPaginate(data = null, operation = null) {
  // first, field, order, page, search
  await getIdToken();
  const url = "/api/sms-templates-list";
  return await RestApi.get(url, {
    params: data,
  });
}

export async function saveSMSRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/sms-templates";
  return await RestApi.post(url, data);
}

export async function updateSMSRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/sms-templates/" + (operation ? operation : "");
  return await RestApi.put(url, data);
}

export async function deleteSMSRequest(data = null, operation = null) {
  await getIdToken();
  const url = "/api/sms-templates/" + (operation ? operation : "");
  return await RestApi.delete(url, {
    params: data,
  });
}

import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./business-flow.scss";
import { getSettings } from "../../../Utils/CurrentSetting";
import { CompanyBasicInfo } from "./CompanyBasicInfo";
import { AuthContext } from "../../../Context/AuthContext";
import { useTranslation } from "react-i18next";
import XAlertMessage from "../../Common/AlertMessage/XAlertMessage";
import { scrollToTop } from "../../../Utils/utils";
import { AddServices } from "./AddServices";
import { AssignService } from "./AssignService";
import { AutomationChannels } from "./BusinessAutomation/AutomationChannels";
import { BusinessAutomation } from "./BusinessAutomation/BusinessAutomation";
import { AutomateJobs } from "./AutomateJobs";
import { Link } from "react-router-dom";

const defaultSetupValues = {
  manageBusiness: { status: "active", label: "Manage My Business" },
  automateBusiness: { status: null, label: "Automate My Business" },
  automateJobs: { status: null, label: "Automate Jobs" },
};
export const StartBusinessFlow = ({ selectedOption }) => {
  const { t: locale } = useTranslation();
  /** Alert Message State **/
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);

  const [currentCompanySetting, setCurrentCompanySetting] = useState(null);
  const [fullSetup, setFullSetup] = useState({});
  const [setup, setSetup] = useState({
    basicInfo: { status: "active", label: "Basic Info" },
    addService: { status: null, label: "Add Service" },
    assignService: { status: null, label: "Assign Service" },
  });
  const [automationSetup, setAutomationSetup] = useState({
    reachOutChannel: { status: "active", label: "Setup Reach out Channels" },
    setupAutomation: { status: null, label: "Setup Automation" },
  });
  const [automationData, setAutomationData] = useState({
    triggers: [],
    data: {},
  });

  const { userData, setUser } = useContext(AuthContext);

  useEffect(() => {
    let selectedValues = {};
    Object.keys(defaultSetupValues).forEach((item) => {
      if (selectedOption.includes(item)) {
        selectedValues = {
          ...selectedValues,
          [item]: defaultSetupValues[item],
        };
      }
    });
    setFullSetup(selectedValues);
  }, [selectedOption]);

  useEffect(() => {
    if (userData?.authData?.currentTeam?.settings) {
      const companySettings = getSettings(
        userData.authData.currentTeam.settings
      );
      setCurrentCompanySetting(companySettings);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleAlertMessage = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
    scrollToTop();
  };

  const components = [
    {
      key: "basicInfo",
      component: (
        <CompanyBasicInfo
          selectedOption={selectedOption}
          userData={userData}
          currentCompanySetting={currentCompanySetting}
          setUser={setUser}
          handleAlertMessage={handleAlertMessage}
          setSetup={setSetup}
          setup={setup}
        />
      ),
    },
    {
      key: "addService",
      component: (
        <AddServices
          selectedOption={selectedOption}
          userData={userData}
          setSetup={setSetup}
          setup={setup}
          currentCompanySetting={currentCompanySetting}
          handleAlertMessage={handleAlertMessage}
        />
      ),
    },
    {
      key: "assignService",
      component: (
        <AssignService
          selectedOption={selectedOption}
          userData={userData}
          setSetup={setSetup}
          setup={setup}
          setFullSetup={setFullSetup}
          fullSetup={fullSetup}
        />
      ),
    },
  ];

  const automationComponents = [
    {
      key: "reachOutChannel",
      component: (
        <AutomationChannels
          selectedOption={selectedOption}
          userData={userData}
          currentCompanySetting={currentCompanySetting}
          setUser={setUser}
          handleAlertMessage={handleAlertMessage}
          setSetup={setAutomationSetup}
          fullSetup={fullSetup}
          setFullSetup={setFullSetup}
          setup={automationSetup}
          automationData={automationData}
          setAutomationData={setAutomationData}
        />
      ),
    },
    {
      key: "setupAutomation",
      component: (
        <BusinessAutomation
          selectedOption={selectedOption}
          userData={userData}
          currentCompanySetting={currentCompanySetting}
          setUser={setUser}
          handleAlertMessage={handleAlertMessage}
          setSetup={setAutomationSetup}
          fullSetup={fullSetup}
          setFullSetup={setFullSetup}
          setup={automationSetup}
          automationData={automationData}
          setAutomationData={setAutomationData}
        />
      ),
    },
  ];

  const renderComponent = () => {
    if (fullSetup?.manageBusiness?.status === "active") {
      for (let i = 0; i < components.length; i++) {
        const { key, component } = components[i];
        if (setup[key]?.status === "active") {
          return component;
        }
      }
    } else if (fullSetup?.automateBusiness?.status === "active") {
      for (let i = 0; i < automationComponents.length; i++) {
        const { key, component } = automationComponents[i];
        if (automationSetup[key]?.status === "active") {
          return component;
        }
      }
    } else if (fullSetup?.automateJobs?.status === "active") {
      return (
        <AutomateJobs
          selectedOption={selectedOption}
          userData={userData}
          currentCompanySetting={currentCompanySetting}
          setUser={setUser}
          handleAlertMessage={handleAlertMessage}
          fullSetup={fullSetup}
          setFullSetup={setFullSetup}
        />
      );
    }

    return (
      <Card>
        <Card.Body className="text-center">
          <h4 className="text-primary my-3">
            Congrats!! You have successfully finished Business Automation Setup.
          </h4>
          <Link className="btn btn-primary" to="/crm">
            Finish
          </Link>
        </Card.Body>
      </Card>
    );
  };
  /*******/
  return (
    <div className="start-business-flow">
      <Container fluid>
        <div>
          <XAlertMessage
            type={alertType}
            message={alertMessage}
            setAlertShow={setAlertShow}
            alertShow={alertShow}
          />
        </div>{" "}
        <Row>
          <Col xs={12} sm={9} md={9} lg={9} className="left-side mx-0">
            {fullSetup?.manageBusiness?.status === "active" && (
              <Row>
                <Col>
                  <div className="stepper">
                    {Object.entries(setup).map((item, idx) => (
                      <div
                        className={"step " + (item[1].status || "")}
                        key={idx}
                      >
                        <div className="step-circle"></div>
                        <div className="step-title">{item[1].label}</div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            )}
            {fullSetup?.automateBusiness?.status === "active" && (
              <Row>
                <Col>
                  <div className="stepper">
                    {Object.entries(automationSetup).map((item, idx) => (
                      <div
                        className={"step " + (item[1].status || "")}
                        key={idx}
                      >
                        <div className="step-circle"></div>
                        <div className="step-title">{item[1].label}</div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            )}

            <div>{renderComponent()}</div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3} className="right-side sticky-right">
            <Card>
              <Card.Body className="py-2">
                <div className="container mt-4">
                  <div className="step-wrapper">
                    {Object.entries(fullSetup).map((item, idx) => (
                      <div
                        className={"step-item " + (item[1].status || "")}
                        key={idx}
                      >
                        <div className="circle">
                          {item[1].status === "completed" && (
                            <i
                              className="ri-thumb-up-line text-warning fs-5"
                              style={{ marginLeft: "3px" }}
                            />
                          )}
                        </div>
                        <div className="content">{item[1].label}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { toWordCase } from "../../../../../Utils/utils";

export const LeadScoreAction = ({ values, handleClose, handleChange }) => {
  // const { t: locale } = useTranslation();
  const [leadScore, setLeadScore] = useState({
    lead_score_details: { type: "add", score: null },
  });
  const [errorBag, setErrorBag] = useState({});

  const handleSaveData = () => {
    let newErrors = {};
    if (!leadScore?.lead_score_details?.type) {
      newErrors = { ...newErrors, score_type: "Score type is required" };
    }
    if (leadScore?.lead_score_details?.score === null) {
      newErrors = { ...newErrors, score: "Score is required" };
    }

    setErrorBag(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let str = [];
      str.push(
        <span key="6" className="text-primary">
          {toWordCase(leadScore.lead_score_details.type) +
            " score " +
            leadScore.lead_score_details.score}
        </span>
      );

      handleChange("action", {
        action: "add_lead_score",
        ...leadScore,
        string: str,
      });
      handleClose();
    }
  };

  useEffect(() => {
    if (values) {
      setLeadScore({
        lead_score_details: values?.lead_score_details || {
          type: "add",
          score: null,
        },
      });
    }
  }, [values]);

  const handelCancel = () => {
    handleClose();
  };
  /*****/
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label>Add Score</Form.Label>
              <InLineError
                extraStyle={{ marginLeft: "30px" }}
                type="error"
                name="score"
                message={errorBag?.score_type || ""}
              />
              <br />
              <ButtonGroup className="w-50">
                <Button
                  variant="outline-primary"
                  className={
                    leadScore?.lead_score_details?.type === "add"
                      ? "active"
                      : ""
                  }
                  onClick={() =>
                    setLeadScore({
                      ...leadScore,
                      lead_score_details: { type: "add", score: null },
                    })
                  }
                >
                  Add Score
                </Button>
                <Button
                  variant="outline-primary"
                  className={
                    leadScore?.lead_score_details?.type === "subtract"
                      ? "active"
                      : ""
                  }
                  onClick={() =>
                    setLeadScore({
                      ...leadScore,
                      lead_score_details: { type: "subtract", score: null },
                    })
                  }
                >
                  Remove Score
                </Button>
              </ButtonGroup>

              <Form.Control
                className="mt-3"
                name="score"
                placeholder={"Enter score"}
                value={leadScore?.lead_score_details?.score || ""}
                onChange={(e) =>
                  setLeadScore({
                    ...leadScore,
                    lead_score_details: {
                      ...leadScore.lead_score_details,
                      score: e?.target?.value || null,
                    },
                  })
                }
              />
              <InLineError
                type="error"
                name="score"
                message={errorBag?.score || ""}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <div className="text-end bg-dark sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

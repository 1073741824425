import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const textEllipsis = {
  textOverflow: "ellipsis",
  maxWidth: "130px",
  overflow: "hidden",
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
  zIndex: 2,
  cursor: "pointer",
};

const AgreementTemplateCard = ({
  cardStyles,
  item,
  activeIndex,
  getTemplatePreview,
  handlePreviewTemplate,
  setActiveIndex,
}) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  return (
    <div
      key={item.template_id}
      style={{
        ...cardStyles.card,
        ...(activeIndex === item.template_id && cardStyles.activeCard),
      }}
      onMouseEnter={() => setIsOverlayVisible(true)}
      onMouseLeave={() => setIsOverlayVisible(false)}
      onClick={() => {
        setActiveIndex(item.template_id);
      }}
    >
      <div style={cardStyles.cardContent}>
        <img src={getTemplatePreview(item)} style={cardStyles.image} alt="" />
        <div
          style={{
            ...cardStyles.overlay,
            opacity: isOverlayVisible ? 1 : 0,
          }}
        >
          <i
            className="ri-eye-fill"
            style={cardStyles.eyeIcon}
            onClick={
              () => handlePreviewTemplate(item)
              // previewEmailTemplates(item, "system")
            }
          ></i>
        </div>
      </div>
      <div style={cardStyles.cardFooter}>
        <OverlayTrigger
          placement="auto"
          overlay={<Tooltip>{item.name}</Tooltip>}
        >
          <h4 style={textEllipsis}>{item.name}</h4>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default AgreementTemplateCard;

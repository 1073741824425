import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TeamUserDropdown } from "../../Common/TeamMember/TeamUserDropdown";
import {
  currentTeamId,
  isObjectEmpty,
  scrollToTop,
  toHyphenCase,
} from "../../../Utils/utils";
import InLineError from "../../Common/AlertMessage/InLineError";
import Select from "react-select";
import { sendForApproval } from "../AgreementHelper";
import XAlertMessage from "../../Common/AlertMessage/XAlertMessage";
import { EqupRoundLoaderWithOverlay } from "../../Common/Loader/RoundLoader";
import { nonGroupColourStyles } from "../../Common/DropDown/Style";

const units = [
  { label: "Hour", value: "h" },
  { label: "Day", value: "d" },
];
const headingStyle = {
  letterSpacing: "1px",
  fontSize: "16px",
  fontWeight: "bold",
};
const popupLabel = {
  team_review: "Share with team member",
  client_review: "Share with client",
  client_signature: "Send for client signature",
};
const btnLabel = {
  team_review: "Send for approval",
  client_review: "Send for review",
  client_signature: "Send for client signature",
};
export const ChooseReviewer = ({
  show = null,
  agreement,
  handleClose,
  fetchData = () => {},
}) => {
  const { t: locale } = useTranslation();
  const tableRef = useRef(null);
  /** Alert Message State **/
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  /******/
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState(null);
  const [reviewer, setReviewer] = useState(null);
  const [validUpto] = useState(-1);
  const [userDetails, setUserDetails] = useState({
    approver_reminder: { value: 0, unit: "d" },
    approver_reminder_repeat: { value: 0, unit: "d", repeat: 0 },
    approver_required: 1,
  });

  const [clientDetails, setClientDetails] = useState({
    client_reminder: { value: 0, unit: "d" },
    client_reminder_repeat: { value: 0, unit: "d" },
  });
  const [reminder, setReminder] = useState(false);
  const [clientReminder, setClientReminder] = useState(false);
  const [repeatReminder, setRepeatReminder] = useState(false);
  const [repeatReminderClient, setRepeatReminderClient] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (show !== null) {
      setAction(toHyphenCase(show));
    }
  }, [show]);

  useEffect(() => {
    if (action === null) {
      setUserDetails({
        approver_reminder: { value: 0, unit: "d" },
        approver_reminder_repeat: { value: 0, unit: "d", repeat: 0 },
        approver_required: 1,
      });
      setClientDetails({
        client_reminder: { value: 0, unit: "d" },
        client_reminder_repeat: { value: 0, unit: "d" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const handleData = (type, value, subType) => {
    if (
      action === "team-review" &&
      ["approver_reminder", "approver_reminder_repeat"].indexOf(type) > -1
    ) {
      let prevValue = userDetails[type];
      prevValue = { ...prevValue, [subType]: value };
      setUserDetails({ ...userDetails, [type]: prevValue });
    } else if (
      ["client-review", "client-signature"].indexOf(action) > -1 &&
      ["client_reminder", "client_reminder_repeat"].indexOf(type) > -1
    ) {
      let prevValue = clientDetails[type];
      prevValue = { ...prevValue, [subType]: value };
      setClientDetails({ ...clientDetails, [type]: prevValue });
    }
  };

  const handleValidation = () => {
    let myErrors = null;
    /** Check Expiration **/
    if (validUpto === 0 || validUpto < -1) {
      myErrors = { valid_upto: "Select agreement expiration" };
    }
    /*** Check Team USer Reminder **/
    if (action === "team-review") {
      if (isObjectEmpty(reviewer) || (reviewer && reviewer.length === 0)) {
        myErrors = { approvers: "Select approvers" };
      } else if (reviewer && reviewer.length > 0) {
        if (userDetails.approver_reminder) {
          if (
            !isObjectEmpty(userDetails.approver_reminder.value) &&
            !isObjectEmpty(userDetails.approver_reminder.unit)
          ) {
            //Check Repeat Reminder
            if (!isObjectEmpty(userDetails.approver_reminder_repeat)) {
              if (
                !isObjectEmpty(userDetails.approver_reminder_repeat.value) &&
                isObjectEmpty(userDetails.approver_reminder_repeat.unit)
              ) {
                myErrors = {
                  approver_reminder_repeat_unit: "Select reminder unit",
                };
              } else if (
                isObjectEmpty(userDetails.approver_reminder_repeat.value) &&
                !isObjectEmpty(userDetails.approver_reminder_repeat.unit)
              ) {
                myErrors = {
                  approver_reminder_repeat_value: "Select reminder value",
                };
              }
            }
          } else if (
            !isObjectEmpty(userDetails.approver_reminder.value) &&
            isObjectEmpty(userDetails.approver_reminder.unit)
          ) {
            myErrors = { approver_reminder_unit: "Select reminder unit" };
          } else if (
            isObjectEmpty(userDetails.approver_reminder.value) &&
            !isObjectEmpty(userDetails.approver_reminder.unit)
          ) {
            myErrors = { approver_reminder_value: "Select reminder value" };
          }
          /** IF Repeat Selected Only **/
          if (
            userDetails.approver_reminder_repeat &&
            userDetails.approver_reminder_repeat.repeat &&
            isObjectEmpty(userDetails.approver_reminder_repeat.value)
          ) {
            myErrors = { approver_reminder_value: "Select reminder value" };
          } else if (
            userDetails.approver_reminder_repeat &&
            userDetails.approver_reminder_repeat.repeat &&
            isObjectEmpty(userDetails.approver_reminder.unit)
          ) {
            myErrors = { approver_reminder_unit: "Select reminder unit" };
          }
        }
      }
      /*** Check Client Reminder **/
    } else if (["client-review", "client-signature"].indexOf(action) > -1) {
      if (clientDetails.client_reminder) {
        if (
          isObjectEmpty(clientDetails.client_reminder.value) &&
          !isObjectEmpty(clientDetails.client_reminder.unit)
        ) {
          myErrors = { client_reminder_value: "Select reminder value" };
        } else if (
          !isObjectEmpty(clientDetails.client_reminder.value) &&
          isObjectEmpty(clientDetails.client_reminder.unit)
        ) {
          myErrors = { client_reminder_unit: "Select reminder unit" };
        } else if (
          !isObjectEmpty(clientDetails.client_reminder.value) &&
          !isObjectEmpty(clientDetails.client_reminder.unit)
        ) {
          if (
            clientDetails.client_reminder_repeat &&
            isObjectEmpty(clientDetails.client_reminder_repeat.value) &&
            !isObjectEmpty(clientDetails.client_reminder_repeat.unit)
          ) {
            myErrors = {
              client_reminder_repeat_value: "Select reminder repeat value",
            };
          } else if (
            clientDetails.client_reminder_repeat &&
            !isObjectEmpty(clientDetails.client_reminder_repeat.value) &&
            isObjectEmpty(clientDetails.client_reminder_repeat.unit)
          ) {
            myErrors = {
              client_reminder_repeat_unit: "Select reminder repeat unit",
            };
          }
        }
      }
    } else if (
      ["team-review", "client-review", "client-signature"].indexOf(action) ===
      -1
    ) {
      myErrors = { action: "Select atleast 1 action." };
    }

    setErrors(myErrors);

    console.log("MyErrors:", myErrors);
    return myErrors;
  };

  const handleProcessData = () => {
    setIsLoading(true);
    if (handleValidation() === null) {
      let dataToBeProcessed = {
        team_id: currentTeamId,
        entity: "agreement",
        entity_id: agreement.agreement_id,
        valid_upto: validUpto,
        approvers: [],
        approver_reminder: null,
        approver_reminder_repeat: null,
        approver_required: 1,
        client_reminder: null,
        client_reminder_repeat: null,
        status: "",
      };

      if (action === "team-review") {
        dataToBeProcessed = {
          ...dataToBeProcessed,
          approvers:
            reviewer && reviewer.length > 0
              ? reviewer.map((item) => parseInt(item.value))
              : [],
          approver_reminder:
            reviewer &&
            reviewer.length > 0 &&
            userDetails.approver_reminder &&
            userDetails.approver_reminder.value
              ? userDetails.approver_reminder
              : null,
          approver_reminder_repeat:
            reviewer &&
            reviewer.length > 0 &&
            userDetails.approver_reminder &&
            userDetails.approver_reminder.value &&
            userDetails.approver_reminder_repeat &&
            userDetails.approver_reminder_repeat.value
              ? userDetails.approver_reminder_repeat
              : null,
          approver_required:
            reviewer && reviewer.length === 0
              ? null
              : userDetails.approver_required,
          status: "under_team_review",
        };
      } else if (["client-review", "client-signature"].indexOf(action) > -1) {
        dataToBeProcessed = {
          ...dataToBeProcessed,
          client_reminder:
            clientDetails.client_reminder && clientDetails.client_reminder.value
              ? clientDetails.client_reminder
              : null,
          client_reminder_repeat:
            clientDetails.client_reminder &&
            clientDetails.client_reminder.value &&
            clientDetails.client_reminder_repeat &&
            clientDetails.client_reminder_repeat.value
              ? clientDetails.client_reminder_repeat
              : null,
          status:
            action === "client-review"
              ? "under_client_review"
              : "sent_for_signature",
        };
      }

      sendForApproval({
        ...dataToBeProcessed,
      })
        .then((result) => {
          if (result && result.status) {
            handleAlertMessage(
              "success",
              locale("messages:common.sendForReview", {
                fieldName: locale("agreement"),
                sendFor: locale(action),
              })
            );
            scrollToTop(tableRef);
            fetchData();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          handleAlertMessage("error", error.message);
          scrollToTop(tableRef);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const handleAlertMessage = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
  };
  /*****/
  return (
    <Modal
      show={!!show}
      className="common modalMd"
      centered
      onHide={handleClose}
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white" className="bg-primary">
        <Modal.Title className="text-white">
          {show && popupLabel[show] ? popupLabel[show] : "Choose Action"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={tableRef}>
        {isLoading && <EqupRoundLoaderWithOverlay />}
        <div className="alert-outer-col">
          <XAlertMessage
            type={alertType}
            message={alertMessage}
            setAlertShow={setAlertShow}
            alertShow={alertShow}
          />
        </div>
        <div>
          <div>{errors && errors.action ? errors.action : ""}</div>
          {["team-review", null].indexOf(action) > -1 && (
            <div>
              <p style={headingStyle}>
                Do you want this to be reviewed by team member?{" "}
                {show === null && (
                  <span className="mx-3">
                    {locale("no")}{" "}
                    <Form.Check
                      id={"chooseAction"}
                      type="switch"
                      label={locale("yes")}
                      className={"mt-0 me-0 mb-0"}
                      inline
                      checked={action === "team-review"}
                      onChange={(e) => {
                        if (action === "team-review") {
                          setAction(null);
                        } else {
                          setAction("team-review");
                        }
                      }}
                    />
                  </span>
                )}
              </p>
            </div>
          )}
          {action === null && (
            <div className={"and-divider my-4"}>
              <span className="divider">OR</span>
            </div>
          )}
          {["client-review", null].indexOf(action) > -1 && (
            <div>
              <p style={headingStyle}>
                Do you want share this agreement for client review?{" "}
                {show === null && (
                  <span className="mx-3">
                    {locale("no")}{" "}
                    <Form.Check
                      id={"chooseAction"}
                      type="switch"
                      label={locale("yes")}
                      className={"mt-0 me-0 mb-0"}
                      inline
                      checked={action === "client-review"}
                      onChange={(e) => {
                        if (action === "client-review") {
                          setAction(null);
                        } else {
                          setAction("client-review");
                        }
                      }}
                    />
                  </span>
                )}
              </p>
            </div>
          )}
          {action === null && (
            <div className={"and-divider my-4"}>
              <span className="divider">OR</span>
            </div>
          )}
          {["client-signature", null].indexOf(action) > -1 && (
            <div>
              <p style={headingStyle}>
                Send to client for signature?{" "}
                {show === null && (
                  <span className="mx-3">
                    {locale("no")}{" "}
                    <Form.Check
                      id={"chooseAction"}
                      type="switch"
                      label={locale("yes")}
                      className={"mt-0 me-0 mb-0"}
                      inline
                      checked={action === "client-signature"}
                      onChange={(e) => {
                        if (action === "client-signature") {
                          setAction(null);
                        } else {
                          setAction("client-signature");
                        }
                      }}
                    />
                  </span>
                )}
              </p>
            </div>
          )}
        </div>
        <div className="p-3">
          {action === "team-review" && (
            <div>
              <TeamUserDropdown
                isMulti={true}
                isClearable={false}
                labelVisible={true}
                label="Select Approvers"
                name="team_user"
                handleValue={setReviewer}
                value={reviewer}
                withCurrentUser={false}
              />
              <InLineError
                extraStyle={{ marginTop: "-12px" }}
                type="error"
                name="approvers"
                message={errors && errors.approvers}
              />

              <React.Fragment>
                <div>
                  <Form.Group className="form-group">
                    <Form.Label>Required approvers?</Form.Label>
                    <div>
                      <Form.Check
                        name="approverRequired"
                        inline
                        type={"radio"}
                        label={locale(
                          "Atleast 1 approval from approvers is required"
                        )}
                        checked={
                          userDetails &&
                          userDetails.approver_required &&
                          userDetails.approver_required === 1
                        }
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            approver_required: 1,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <Form.Check
                        name="approverRequired"
                        inline
                        type={"radio"}
                        label={locale("All approvers need to approve")}
                        checked={
                          userDetails &&
                          userDetails.approver_required &&
                          userDetails.approver_required === -1
                        }
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            approver_required: -1,
                          });
                        }}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div>
                  <Form.Group className="form-group">
                    <Form.Label>Remind approver, if not approved?</Form.Label>
                    <div>
                      <Form.Check
                        name="remindApprover"
                        inline
                        type={"radio"}
                        label={locale("Yes")}
                        checked={reminder}
                        onChange={(e) => setReminder(true)}
                      />

                      <Form.Check
                        name="remindApprover"
                        inline
                        type={"radio"}
                        label={locale("No")}
                        checked={!reminder}
                        onChange={(e) => {
                          setReminder(false);
                          setUserDetails({
                            ...userDetails,
                            approver_reminder: {
                              value: 0,
                              unit: "d",
                            },
                            approver_reminder_repeat: {
                              value: 0,
                              unit: "d",
                              repeat: 0,
                            },
                          });
                        }}
                      />
                    </div>
                    {reminder && (
                      <Row>
                        <Col xs="auto" className="mt-2">
                          <label>Remind approver after</label>
                        </Col>
                        <Col xs={"auto"} md={3} lg={3}>
                          <Form.Control
                            type="number"
                            min="0"
                            max="30"
                            value={
                              userDetails &&
                              userDetails.approver_reminder &&
                              userDetails.approver_reminder.value
                                ? userDetails.approver_reminder.value
                                : ""
                            }
                            onChange={(e) =>
                              handleData(
                                "approver_reminder",
                                e.target.value,
                                "value"
                              )
                            }
                          />
                          <InLineError
                            type="error"
                            name="approver_reminder_value"
                            message={errors && errors.approver_reminder_value}
                          />
                        </Col>
                        <Col xs={"auto"} md={4} lg={4}>
                          <Select
                            isMulti={false}
                            isClearable={false}
                            options={units}
                            value={
                              units.filter(
                                (item) =>
                                  item &&
                                  !isObjectEmpty(
                                    userDetails.approver_reminder
                                  ) &&
                                  userDetails.approver_reminder.unit &&
                                  item.value ===
                                    userDetails.approver_reminder.unit
                              )[0]
                            }
                            onChange={(e) => {
                              handleData("approver_reminder", e.value, "unit");
                            }}
                            styles={nonGroupColourStyles}
                          />
                          <InLineError
                            type="error"
                            name="approver_reminder_unit"
                            message={errors && errors.approver_reminder_unit}
                          />
                        </Col>
                      </Row>
                    )}
                  </Form.Group>
                  {reminder && (
                    <div>
                      <Form.Group className="form-group">
                        <Form.Label>Repeat reminder?</Form.Label>
                        <div>
                          <Form.Check
                            name="remindApprover"
                            inline
                            type={"radio"}
                            label={locale("Yes")}
                            checked={repeatReminder}
                            onChange={(e) => setRepeatReminder(true)}
                          />

                          <Form.Check
                            name="remindApprover"
                            inline
                            type={"radio"}
                            label={locale("No")}
                            checked={!repeatReminder}
                            onChange={(e) => {
                              setRepeatReminder(false);
                              setUserDetails({
                                ...userDetails,
                                approver_reminder_repeat: {
                                  value: 0,
                                  unit: "d",
                                  repeat: 0,
                                },
                              });
                            }}
                          />
                        </div>
                      </Form.Group>
                      {repeatReminder && (
                        <Form.Group className="form-group">
                          <Form.Label>Repeat reminder</Form.Label>
                          <Row>
                            <Col xs="auto" className="mt-2">
                              <label>Every</label>
                            </Col>
                            <Col xs="auto" md={2} lg={2}>
                              <Form.Control
                                type="number"
                                min="0"
                                max="30"
                                value={
                                  userDetails &&
                                  userDetails.approver_reminder_repeat &&
                                  userDetails.approver_reminder_repeat.value
                                    ? userDetails.approver_reminder_repeat.value
                                    : ""
                                }
                                onChange={(e) =>
                                  handleData(
                                    "approver_reminder_repeat",
                                    e.target.value,
                                    "value"
                                  )
                                }
                              />
                              <InLineError
                                type="error"
                                name="approver_reminder_repeat_value"
                                message={
                                  errors &&
                                  errors.approver_reminder_repeat_value
                                }
                              />
                            </Col>
                            <Col xs="auto" md={4} lg={4}>
                              <Select
                                isMulti={false}
                                isClearable={false}
                                options={units}
                                value={
                                  units.filter(
                                    (item) =>
                                      item &&
                                      !isObjectEmpty(
                                        userDetails.approver_reminder_repeat
                                      ) &&
                                      userDetails.approver_reminder_repeat
                                        .unit &&
                                      item.value ===
                                        userDetails.approver_reminder_repeat
                                          .unit
                                  )[0]
                                }
                                onChange={(e) => {
                                  handleData(
                                    "approver_reminder_repeat",
                                    e.value,
                                    "unit"
                                  );
                                }}
                                styles={nonGroupColourStyles}
                              />
                              <InLineError
                                type="error"
                                name="approver_reminder_repeat_unit"
                                message={
                                  errors && errors.approver_reminder_repeat_unit
                                }
                              />
                            </Col>
                            <Col xs="auto" className="mt-2">
                              <label>Upto</label>
                            </Col>
                            <Col xs="auto" md={2} lg={2}>
                              <Form.Control
                                type="number"
                                min="1"
                                max="5"
                                value={
                                  userDetails &&
                                  userDetails.approver_reminder_repeat &&
                                  userDetails.approver_reminder_repeat.repeat
                                    ? userDetails.approver_reminder_repeat
                                        .repeat
                                    : ""
                                }
                                onChange={(e) =>
                                  handleData(
                                    "approver_reminder_repeat",
                                    e.target.value,
                                    "repeat"
                                  )
                                }
                              />
                              <InLineError
                                type="error"
                                name="approver_reminder_repeat"
                                message={
                                  errors && errors.approver_reminder_repeat
                                }
                              />
                            </Col>
                            <Col xs="auto" className="mt-2">
                              <label>Times</label>
                            </Col>
                          </Row>
                        </Form.Group>
                      )}
                    </div>
                  )}
                </div>
              </React.Fragment>
            </div>
          )}
          {["client-review", "client-signature"].indexOf(action) > -1 && (
            <div>
              <Form.Group className="form-group">
                <Form.Label>
                  Send a reminder if agreement not{" "}
                  {action === "client-review" ? "review?" : "signed?"}
                </Form.Label>
                <div>
                  <Form.Check
                    name="remindApprover"
                    inline
                    type={"radio"}
                    label={locale("Yes")}
                    checked={clientReminder}
                    onChange={(e) => setClientReminder(true)}
                  />

                  <Form.Check
                    name="remindApprover"
                    inline
                    type={"radio"}
                    label={locale("No")}
                    checked={!clientReminder}
                    onChange={(e) => {
                      setClientReminder(false);
                      setUserDetails({
                        ...userDetails,
                        client_reminder: {
                          value: 0,
                          unit: "d",
                        },
                        client_reminder_repeat: {
                          value: 0,
                          unit: "d",
                        },
                      });
                    }}
                  />
                </div>
                {clientReminder && (
                  <Row>
                    <Col xs="auto" className="mt-2">
                      <label>Remind client after</label>
                    </Col>
                    <Col xs={"auto"} md={2} lg={2}>
                      <Form.Control
                        type="number"
                        min="0"
                        max="30"
                        value={
                          clientDetails &&
                          clientDetails.client_reminder &&
                          clientDetails.client_reminder.value
                            ? clientDetails.client_reminder.value
                            : ""
                        }
                        onChange={(e) =>
                          handleData("client_reminder", e.target.value, "value")
                        }
                      />
                      <InLineError
                        type="error"
                        name="client_reminder_value"
                        message={errors && errors.client_reminder_value}
                      />
                    </Col>
                    <Col xs={"auto"} md={4} lg={4}>
                      <Select
                        isMulti={false}
                        isClearable={false}
                        options={units}
                        value={
                          units.filter(
                            (item) =>
                              item &&
                              !isObjectEmpty(clientDetails.client_reminder) &&
                              clientDetails.client_reminder.unit &&
                              item.value === clientDetails.client_reminder.unit
                          )[0]
                        }
                        onChange={(e) => {
                          handleData("client_reminder", e.value, "unit");
                        }}
                        styles={nonGroupColourStyles}
                      />
                      <InLineError
                        type="error"
                        name="client_reminder_unit"
                        message={errors && errors.client_reminder_unit}
                      />
                    </Col>
                    <Col xs="auto">
                      {" "}
                      <label className="mt-2">of sending.</label>
                    </Col>
                  </Row>
                )}
              </Form.Group>

              {clientReminder && (
                <React.Fragment>
                  <Form.Group className="form-group">
                    <Form.Label>Repeat reminder?</Form.Label>
                    <div>
                      <Form.Check
                        name="remindClient"
                        inline
                        type={"radio"}
                        label={locale("Yes")}
                        checked={repeatReminderClient}
                        onChange={(e) => setRepeatReminderClient(true)}
                      />

                      <Form.Check
                        name="remindClient"
                        inline
                        type={"radio"}
                        label={locale("No")}
                        checked={!repeatReminderClient}
                        onChange={(e) => {
                          setRepeatReminderClient(false);
                          setClientDetails({
                            ...clientDetails,
                            client_reminder_repeat: null,
                          });
                        }}
                      />
                    </div>
                  </Form.Group>
                  {repeatReminderClient && (
                    <Form.Group className="form-group">
                      <Form.Label>Repeat reminder</Form.Label>
                      <Row>
                        <Col className="mt-2" xs={"auto"} md={1} lg={1}>
                          <label>After</label>
                        </Col>
                        <Col xs={"auto"} md={2} lg={2}>
                          <Form.Control
                            type="number"
                            min="0"
                            max="30"
                            value={
                              clientDetails &&
                              clientDetails.client_reminder_repeat &&
                              clientDetails.client_reminder_repeat.value
                                ? clientDetails.client_reminder_repeat.value
                                : ""
                            }
                            onChange={(e) =>
                              handleData(
                                "client_reminder_repeat",
                                e.target.value,
                                "value"
                              )
                            }
                          />
                          <InLineError
                            type="error"
                            name="client_reminder_repeat_value"
                            message={
                              errors && errors.client_reminder_repeat_value
                            }
                          />
                        </Col>
                        <Col xs={"auto"} md={4} lg={4}>
                          <Select
                            isMulti={false}
                            isClearable={false}
                            options={units}
                            value={
                              units.filter(
                                (item) =>
                                  item &&
                                  !isObjectEmpty(
                                    clientDetails.client_reminder_repeat
                                  ) &&
                                  clientDetails.client_reminder_repeat.unit &&
                                  item.value ===
                                    clientDetails.client_reminder_repeat.unit
                              )[0]
                            }
                            onChange={(e) => {
                              handleData(
                                "client_reminder_repeat",
                                e.value,
                                "unit"
                              );
                            }}
                            styles={nonGroupColourStyles}
                          />
                          <InLineError
                            type="error"
                            name="client_reminder_repeat_unit"
                            message={
                              errors && errors.client_reminder_repeat_unit
                            }
                          />
                        </Col>
                        <Col xs={"auto"} md={5} lg={5}>
                          <label className="mt-2">
                            days of previous reminder if not{" "}
                            {action === "client-review" ? "review" : "signed"}
                          </label>
                        </Col>
                      </Row>
                    </Form.Group>
                  )}{" "}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"outline-warning"} onClick={handleProcessData}>
          {show && btnLabel[show] ? btnLabel[show] : "Send"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { checkContactHasName, currentTeamId } from "../../../Utils/utils";

import { autoHeightMultiValue, nonGroupColourStyles } from "../DropDown/Style";
import { useLazyQuery } from "@apollo/client";
import { CONTACT_COMPANY } from "../../../GraphApi/CompanyList";
import InLineError from "../AlertMessage/InLineError";

const errorStyle = {
  border: "1px solid #f00",
  borderRadius: "3px",
  paddingRight: "1px",
};

export const ContactDropDown = ({
  locale,
  contact,
  isError,
  isDisabled = false,
  isMulti = false,
  isClearable = true,
  isSearchable = true,
  handleContact,
}) => {
  const [contacts, setContacts] = useState([]);
  const [state, setState] = useState({
    typingTimeout: 0,
  });
  const [contactOptionMessage, setContactOptionMessage] = useState("");

  const [getAllContactsWithCompany, { data: allContacts, loading }] =
    useLazyQuery(CONTACT_COMPANY, { fetchPolicy: "network-only" });

  useEffect(() => {
    getAllContactsWithCompany({ variables: { team_id: currentTeamId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**********************/
    if (allContacts) {
      const contactData = allContacts.allContacts;
      const searchContact = [];
      if (contactData.length > 0) {
        contactData.forEach((contact) => {
          searchContact.push({
            value: parseInt(contact.id),
            label: checkContactHasName(contact),
            id: contact.contact_id,
          });
        });
        setContacts(searchContact);
      } else {
        contactData.length === 0 && setContactOptionMessage("No Contact");
        setContacts([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContacts]);

  const handleChangeContact = (e) => {
    handleContact(e);
  };

  /* Handle Search contact with Associate Company.*/
  const getSearchValueContact = (searchedText) => {
    if (searchedText.length >= 2) {
      clearTimeout(state.typingTimeout);
      setState({
        typingTimeout: setTimeout(function () {
          getAllContactsWithCompany({
            variables: {
              contactSearch: searchedText,
              team_id: currentTeamId,
            },
          });
        }, 500),
      });
      return searchedText;
    } else {
      setContactOptionMessage("Type at least 2 character");
    }
  };
  /*******/
  return (
    <React.Fragment>
      <Form.Group className={"form-group"}>
        <Form.Label>{locale("contact")}</Form.Label>
        <div className={isError && isError.error ? "select-error" : ""}>
          <Select
            isDisabled={isDisabled}
            placeholder={locale("type and search contact")}
            isMulti={isMulti}
            isClearable={isClearable}
            isSearchable={isSearchable}
            options={contacts}
            name={"contact"}
            value={contact}
            onInputChange={getSearchValueContact}
            onChange={(e) => {
              handleChangeContact(e);
            }}
            noOptionsMessage={() =>
              loading
                ? locale("loading")
                : locale(contactOptionMessage.toLowerCase())
            }
            styles={{ ...nonGroupColourStyles, ...autoHeightMultiValue }}
          />
        </div>

        <InLineError
          type="error"
          name="contact"
          message={isError && isError.error ? isError.msg : ""}
        />
      </Form.Group>
    </React.Fragment>
  );
};

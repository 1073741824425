import React, { useEffect, useState } from "react";
import { SelectDropdown } from "../../../../../Components/Common/Conditions/SelectDropdown";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { currentTeamId } from "../../../../../Utils/utils";
import { MailMerge } from "../../../../../Components/Common/Editor/Component/NewComponents/MailMerge";
import { useTranslation } from "react-i18next";
import { getEstimateTemplates } from "../../../../../Pages/EstimateManagement/EstimateHelper";
import { SetupJobReminderAction } from "../../../../../Pages/Automation/Elements/Settings/Actions/SetupJobReminderAction";
import { jobPriorites } from "../../../../../Utils/commonParams";

const defaultConfig = {
  remind: [],
  on_type: 1,
  value: [
    {
      when: "",
      type: "",
      unit: "",
    },
  ],
};
export const SendEstimateAction = ({
  handleAlertMessage = () => {},
  currentCompanySetting = null,
  values = null,
  handleChange,
  handleClose,
}) => {
  const { t: locale } = useTranslation();
  const [estimateDetails, setEstimateDetails] = useState({
    name: "",
    due_date_type: "days",
    due_date_value: new Date(),
    amount_need_to_be_paid: 25,
    payment_required: 1,
    estimate_template_id: null,
    billing: {
      type: 1,
      auto_invoice: 1,
      auto_charge: 0,
      send_to_client: 0,
      reminder_config: null,
      cycle: null,
      cycle_unit: {},
      monthly_amount: null,
      tax_applicable: false,
      end_date: null,
    },
  });
  const [errorBag, setErrorBag] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [billingTypes] = useState([
    { value: 1, label: "Milestone Payment" },
    {
      value: 2,
      label: "Full Upfront",
    },
    {
      value: 3,
      label: "On Job Completion",
    },
  ]);
  const [invoiceTypes] = useState([
    { value: "generate_invoice", label: "Generate Invoice" },
    { value: "sent_to_client", label: "Generate & send Invoice to the client" },
    {
      value: "auto_charge",
      label: "Auto charge attached card and send invoice to the client",
    },
  ]);
  const [reminderConfig, setReminderConfig] = useState(defaultConfig);

  const fetchData = () => {
    getEstimateTemplates({
      team_id: currentTeamId,
      search: "",
      first: 100,
      page: 1,
      field: "created_at",
      order: "DESC",
      filter: [],
    })
      .then((result) => {
        if (result.status) {
          const data = result.data.data;
          const allTemp = data.map(
            (item) => item && { value: item.id, label: item.name }
          );
          setTemplates(allTemp);
        }
      })
      .catch((error) => {
        handleAlertMessage("error", error.message);
      });
  };

  const handleSaveData = () => {
    const newErrors = {};

    if (!estimateDetails.estimate_template_id) {
      newErrors.estimate_template_id = "Estimate template is required";
    }
    if (!estimateDetails.name) {
      newErrors.estimate_name = "Estimate name is required";
    }
    if (
      estimateDetails.due_date_type !== "manually" &&
      !estimateDetails.due_date_value
    ) {
      newErrors.due_date_value = "Due date value is required";
    }
    if (!estimateDetails.priority) {
      newErrors.priority = "Priority is required";
    }
    if (!estimateDetails?.billing?.type) {
      newErrors.billing_type = "Billing type is required";
    }

    setErrorBag(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setErrorBag(null);
      let str = [];
      str.push(<span key="1">Send </span>);
      str.push(
        <span key="2" className="text-primary">
          {estimateDetails.name}
        </span>
      );
      str.push(<span key="3"> Estimate</span>);

      handleChange("action", {
        action: "send_estimate",
        estimate_details: {
          ...estimateDetails,
          billing: {
            ...estimateDetails.billing,
            reminder_config: reminderConfig,
          },
        },
        string: str,
      });
      handleClose();
    }
  };

  const handleDetail = (type, e) => {
    if (type === "name") {
      setEstimateDetails({ ...estimateDetails, name: e.target.value });
    } else if (type === "template") {
      setEstimateDetails({
        ...estimateDetails,
        estimate_template_id: e?.value || "",
      });
    } else if (type === "due_date_type") {
      setEstimateDetails({
        ...estimateDetails,
        due_date_type: e,
        due_date_value: null,
      });
    } else if (type === "due_date_value") {
      setEstimateDetails({
        ...estimateDetails,
        due_date_value: e?.target?.value,
      });
    } else if (type === "priority") {
      setEstimateDetails({
        ...estimateDetails,
        priority: e?.value,
      });
    } else if (type === "type") {
      let amountPaid = 0;
      if (e.value === 1) {
        amountPaid = 25;
      } else if (e.value === 2) {
        amountPaid = 100;
      }
      setEstimateDetails({
        ...estimateDetails,
        amount_need_to_be_paid: amountPaid,
        billing: { ...estimateDetails.billing, type: e?.value },
      });
    } else if (type === "auto_invoice" || type === "generate_invoice") {
      setEstimateDetails({
        ...estimateDetails,
        billing: {
          ...estimateDetails.billing,
          auto_invoice: e,
          send_to_client: 0,
          auto_charge: 0,
        },
      });
    } else if (type === "sent_to_client") {
      setEstimateDetails({
        ...estimateDetails,
        billing: {
          ...estimateDetails.billing,
          auto_invoice: 1,
          send_to_client: 1,
          auto_charge: 0,
        },
      });
    } else if (type === "auto_charge") {
      setEstimateDetails({
        ...estimateDetails,
        billing: {
          ...estimateDetails.billing,
          auto_invoice: 1,
          send_to_client: 1,
          auto_charge: 1,
        },
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (values?.estimate_details) {
      setEstimateDetails(values.estimate_details);
    }
  }, [values]);

  const getInvoiceValue = () => {
    if (
      estimateDetails?.billing?.auto_invoice === 1 &&
      estimateDetails?.billing?.send_to_client === 1 &&
      estimateDetails?.billing?.auto_charge === 1
    ) {
      return "auto_charge";
    } else if (
      estimateDetails?.billing?.auto_invoice === 1 &&
      estimateDetails?.billing?.send_to_client === 1
    ) {
      return "sent_to_client";
    } else if (estimateDetails?.billing?.auto_invoice === 1) {
      return "generate_invoice";
    } else {
      return "";
    }
  };

  const getMailMergeValue = (value) => {
    const input = document.getElementById("jobName");
    const myValue = input.value.concat(value);
    input.value = myValue;
    setEstimateDetails({ ...estimateDetails, name: myValue });
  };

  const handelCancel = () => {
    handleClose();
  };
  /******/
  return (
    <Container>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label>Estimate Info</Form.Label>
            <SelectDropdown
              label="Select Estimate Template"
              options={templates}
              value={estimateDetails?.estimate_template_id || null}
              handleChange={(e) => handleDetail("template", e)}
            />
            <InLineError
              extraStyle={{ marginTop: "-15px" }}
              type="error"
              name="estimate_template_id"
              message={errorBag?.estimate_template_id || ""}
            />
          </Form.Group>
          <Form.Group className="form-group mt-3">
            <Form.Label>Estimate Name</Form.Label>
            <div className="d-flex justify-content-between">
              <Form.Control
                name="estimate_name"
                value={estimateDetails?.name || ""}
                onChange={(e) => handleDetail("name", e)}
                id="jobName"
              />
              <div className="m-1">
                <MailMerge
                  mode="create-popup"
                  getMailMergeValue={getMailMergeValue}
                  locale={locale}
                  forAutomation={true}
                />
              </div>
            </div>
            <InLineError
              type="error"
              name="estimate_name"
              message={errorBag?.estimate_name || ""}
            />
          </Form.Group>
          <Form.Group className="form-group mt-3">
            <Form.Label>Due Date</Form.Label>
            <Row className="justify-content-start">
              <Col xs="auto" className="pt-2">
                <Form.Check
                  type="radio"
                  name="dueDate"
                  id="dueDate"
                  label="After"
                  checked={estimateDetails?.due_date_type === "days"}
                  onChange={(e) => handleDetail("due_date_type", "days")}
                />
              </Col>
              <Col xs="auto">
                <Form.Control
                  type="number"
                  className=""
                  name="due_date_value"
                  value={
                    estimateDetails?.due_date_type === "days"
                      ? estimateDetails?.due_date_value || ""
                      : ""
                  }
                  onChange={(e) => handleDetail("due_date_value", e)}
                  disabled={
                    estimateDetails?.due_date_type === "days" ? false : true
                  }
                />
              </Col>
              <Col xs="auto" className="pt-2">
                Days of this action occurrence
              </Col>
            </Row>
            <Row className="justify-content-start mt-2">
              <Col xs="auto" className="pt-2">
                <Form.Check
                  type="radio"
                  name="dueDate"
                  id="dueDate"
                  label="After"
                  checked={estimateDetails?.due_date_type === "weeks"}
                  onChange={(e) => handleDetail("due_date_type", "weeks")}
                />
              </Col>
              <Col xs="auto">
                <Form.Control
                  type="number"
                  className=""
                  name="due_date_value"
                  onChange={(e) => handleDetail("due_date_value", e)}
                  disabled={
                    estimateDetails?.due_date_type === "weeks" ? false : true
                  }
                  value={
                    estimateDetails?.due_date_type === "weeks"
                      ? estimateDetails?.due_date_value || ""
                      : ""
                  }
                />
              </Col>
              <Col xs="auto" className="pt-2">
                Weeks of this action occurrence
              </Col>
            </Row>
            <Row className="justify-content-start mt-2">
              <Col xs="auto" className="pt-2">
                <Form.Check
                  type="radio"
                  name="dueDate"
                  id="dueDate"
                  label="Will add manually later."
                  checked={estimateDetails?.due_date_type === "manually"}
                  onChange={(e) => handleDetail("due_date_type", "manually")}
                />
              </Col>
            </Row>
            <InLineError
              type="error"
              name="due_date_value`"
              message={errorBag?.due_date_value || ""}
            />
          </Form.Group>
        </Col>
      </Row>
      <div>
        <SelectDropdown
          label="Select Priority"
          options={jobPriorites}
          value={estimateDetails?.priority || null}
          handleChange={(e) => handleDetail("priority", e)}
        />
        <InLineError
          extraStyle={{ marginTop: "-15px" }}
          type="error"
          name="priority"
          message={errorBag?.priority || ""}
        />
      </div>
      <div>
        <Form.Group>
          <Form.Label>Billing & Invoicing</Form.Label>
        </Form.Group>
        <SelectDropdown
          label="Billing Type"
          options={billingTypes}
          value={estimateDetails?.billing?.type || null}
          handleChange={(e) => handleDetail("type", e)}
        />
        <InLineError
          extraStyle={{ marginTop: "-15px" }}
          type="error"
          name="billing_type"
          message={errorBag?.billing_type || ""}
        />
        {estimateDetails?.billing?.type === 1 && (
          <React.Fragment>
            <p className="text-center">
              <label>{estimateDetails?.amount_need_to_be_paid || 0}%</label>
            </p>
            <Form.Range
              min="1"
              max="100"
              value={estimateDetails?.amount_need_to_be_paid || 0}
              onChange={(e) => {
                setEstimateDetails({
                  ...estimateDetails,
                  amount_need_to_be_paid: e.target.value,
                });
              }}
              disabled={false}
            />
          </React.Fragment>
        )}
      </div>
      <div className="my-4">
        <h5>How the invoice will be generated?</h5>
        <div className="mb-1">
          <Form.Check
            label={"Auto generate invoice on job completion"}
            name="auto_invoice"
            type={"radio"}
            id="auto_invoice"
            checked={estimateDetails?.billing?.auto_invoice === 1}
            onChange={(e) => {
              handleDetail("auto_invoice", 1);
            }}
          />
          {estimateDetails?.billing?.auto_invoice === 1 && (
            <div>
              <SelectDropdown
                label=""
                options={invoiceTypes}
                value={getInvoiceValue()}
                handleChange={(e) => {
                  setReminderConfig(defaultConfig);
                  handleDetail(e.value, e);
                }}
              />
              <InLineError
                extraStyle={{ marginTop: "-15px" }}
                type="error"
                name="auto_invoice"
                message={errorBag?.auto_invoice || ""}
              />
            </div>
          )}
          <Form.Check
            label="I will manually generate the invoice"
            name="auto_invoice"
            type={"radio"}
            id="auto_invoice"
            checked={!estimateDetails?.billing?.auto_invoice}
            onChange={(e) => {
              handleDetail("auto_invoice", 0);
            }}
          />
        </div>
        {!estimateDetails?.billing?.auto_invoice && (
          <SetupJobReminderAction
            isDisabled={false}
            reminderConfig={reminderConfig}
            setReminderConfig={setReminderConfig}
          />
        )}
      </div>
      <div className="my-4">&nbsp;</div>
      <div className="text-end bg-dark sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </Container>
  );
};

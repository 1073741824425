export const CloseButton = ({ onClick = () => {} }) => {
  return (
    <svg
      className="m-0"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M22.1924 3.80762C17.1238 -1.26092 8.87615 -1.26092 3.80761 3.80762C-1.26093 8.87616 -1.26093 17.1239 3.80761 22.1924C8.87615 27.2609 17.1238 27.2609 22.1924 22.1924C27.2609 17.1239 27.2609 8.87616 22.1924 3.80762ZM20.7782 5.22184C25.0823 9.52599 25.0823 16.474 20.7782 20.7782C16.474 25.0823 9.52598 25.0823 5.22183 20.7782C0.917667 16.474 0.917667 9.52599 5.22183 5.22184C9.52598 0.917677 16.474 0.917677 20.7782 5.22184ZM16.5355 8.05026L13 11.5858L9.46447 8.05026L8.05025 9.46448L11.5858 13L8.05025 16.5355L9.46447 17.9498L13 14.4142L16.5355 17.9498L17.9497 16.5355L14.4142 13L17.9497 9.46448L16.5355 8.05026Z"
        fill="#f00"
      ></path>
    </svg>
  );
};

import {
  getAgreementTemplate,
  getAgreementTemplates,
  removeAgreementTemplate,
  saveAgreementTemplate,
  updateAgreementTemplate,
  getAgreements,
  getAgreementDetail,
  saveAgreementApi,
  removeAgreementRecord,
  updateAgreementRecord,
  getAgreementSigningDetail,
  postAgreementSigningDetail,
  postSignatureImage,
  agreementSendForApproval,
  agreementReviewAction,
  agreementPartiallyFilled,
  agreementChangeRequest,
  postPartiallySigningDetail,
  removeCommentsFromAgreement,
  getAgreementLists,
} from "../../RestApi/AgreementsApi";

export const saveTemplate = (data) => {
  return new Promise((resolve, reject) => {
    saveAgreementTemplate(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const updateTemplate = (data, id) => {
  return new Promise((resolve, reject) => {
    updateAgreementTemplate(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getTemplatesList = (data) => {
  return new Promise((resolve, reject) => {
    getAgreementTemplates(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const removeTemplate = (data, id) => {
  return new Promise((resolve, reject) => {
    removeAgreementTemplate(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getTemplate = (data, id) => {
  return new Promise((resolve, reject) => {
    getAgreementTemplate(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

/**
 * Agreements related endpoints...
 */

// agreements listing...
export const getAgreementsListWithoutPaginate = (data) => {
  return new Promise((resolve, reject) => {
    getAgreementLists(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};
// with pagination
export const getAgreementsList = (data) => {
  return new Promise((resolve, reject) => {
    getAgreements(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// save agreement...
export const saveAgreement = (data) => {
  return new Promise((resolve, reject) => {
    saveAgreementApi(data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("promise", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// get single agreement...
export const getAgreement = (data, id) => {
  return new Promise((resolve, reject) => {
    getAgreementDetail(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// delete single agreement...
export const removeAgreement = (data, id) => {
  return new Promise((resolve, reject) => {
    removeAgreementRecord(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// update agreement...
export const updateAgreement = (data, id) => {
  return new Promise((resolve, reject) => {
    updateAgreementRecord(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// get single agreement for Signing ...
export const getAgreementSigning = (data = null, id) => {
  return new Promise((resolve, reject) => {
    getAgreementSigningDetail(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

// Post single agreement for Signing ...
export const postAgreementSigning = (data = null, id) => {
  return new Promise((resolve, reject) => {
    postAgreementSigningDetail(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message:
            error.response && error.response.statusText
              ? error.response.statusText
              : error.response.data && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
      });
  });
};

export const postPartiallySigning = (data = null, id) => {
  return new Promise((resolve, reject) => {
    postPartiallySigningDetail(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message: error.response.statusText
            ? error.response.statusText
            : error.response.data && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      });
  });
};

// Post Signature Image...
export const UploadSignature = (data = null, id) => {
  return new Promise((resolve, reject) => {
    postSignatureImage(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        return reject({
          status: false,
          message:
            error.response && error.response.statusText
              ? error.response.statusText
              : error.response.data && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
      });
  });
};

export const sendForApproval = (data, id) => {
  return new Promise((resolve, reject) => {
    agreementSendForApproval(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const reviewAgreement = (data, id) => {
  return new Promise((resolve, reject) => {
    agreementReviewAction(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const partiallyFilledAgreement = (data, id) => {
  return new Promise((resolve, reject) => {
    agreementPartiallyFilled(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const updateRequestAgreement = (data, id) => {
  return new Promise((resolve, reject) => {
    agreementChangeRequest(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const deleteCommentsFromAgreement = (data, id) => {
  return new Promise((resolve, reject) => {
    removeCommentsFromAgreement(data, id)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        return reject({
          status: false,
          message: getResponseError(error),
        });
      });
  });
};

export const getResponseError = (error) => {
  let responseError = error.response.statusText && error.response.statusText;
  if (error.response.data && error.response.data.message) {
    responseError = error.response.data.message;
  } else if (error.response.data.message) {
    responseError = error.message;
  }
  return responseError;
};

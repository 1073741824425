import React, { useCallback, useEffect, useMemo, useState } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";
import { insertTable, serialize } from "./Utils/slateUtils";
import {
  RiBold,
  RiItalic,
  RiUnderline,
  RiCodeSSlashLine,
  RiH1,
  RiH2,
  RiDoubleQuotesL,
  RiListOrdered,
  RiListUnordered,
  RiAlignLeft,
  RiAlignCenter,
  RiAlignRight,
  RiAlignJustify,
  RiTableLine,
} from "react-icons/ri";
import Leaf from "./components/Leaf";
import ColorPicker from "./components/ColorPicker";
import FontFamilySelector from "./components/FontFamilySelector";
import FontSizeSelector from "./components/FontSizeSelector";
import { RiPaintFill, RiPaintBrushFill } from "react-icons/ri";
import "./css/slateStyle.css";
import BlockTypeDropdown from "./components/BlockTypeDropdown";
import { Button, Toolbar } from "./components/Toolbar";
import MailMergeDropdown from "./components/MailMergeDropdown";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
const MARKS = [
  "bold",
  "italic",
  "underline",
  "code",
  "color",
  "bgColor",
  "fontFamily",
  "strikethrough",
  "fontSize",
];

const SlateWrapperA = ({
  initialValue,
  onChange,
  style = {},
  readOnly = false,
  showMailMergeOption = false,
  showTable = false,
  inOneLine = false,
  selection,
  onBlur = () => {},
  setSelection = () => {},
}) => {
  const [slateKey, setSlateKey] = useState(0);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  useEffect(() => {
    if (initialValue) {
      // Whenever initialValue changes, regenerate a key to remount the Slate editor
      setSlateKey((prevKey) => prevKey + 1);
    }
  }, [initialValue]);

  const onBlurEvent = () => {
      // console.log("onBlurEvent:::", editor.selection);
      // if (editor.selection) {
      //   setSelection(editor.selection); // Save the selection before blur
      // }
      onBlur();
    };

  const handleColorChange = (value) => {
    toggleMark(editor, "color", value);
  };

  const handleBgColorChange = (value) => {
    toggleMark(editor, "bgColor", value);
  };

  const handleFontFamilyChange = (value) => {
    toggleMark(editor, "fontFamily", value);
  };

  const handleFontSizeChange = (value) => {
    toggleMark(editor, "fontSize", value);
  };

  // const handleEditorFocus = () => {
  //   if (
  //     selection &&
  //     Editor.hasPath(editor, selection.anchor.path) &&
  //     Editor.hasPath(editor, selection.focus.path)
  //   ) {
  //     try {
  //       Transforms.select(editor, selection);
  //     } catch (error) {
  //       console.error("Error restoring selection:", error);
  //     }
  //   } else {
  //     console.warn("Invalid selection path. Resetting to start of document.");
  //     const defaultSelection = Editor.start(editor, []); // Default to document start
  //     Transforms.select(editor, defaultSelection);
  //   }
  // };
  
  
  return (
    <Slate
      key={slateKey}
      editor={editor}
      initialValue={initialValue}
      onChange={(value) => onChange(serialize(value))}
    >
      <Toolbar className="bg-light border-bottom d-flex align-items-center shadow-sm">
        <div className="toolbar-group">
          <MarkButton format="bold" icon={<RiBold />} />
          <MarkButton format="italic" icon={<RiItalic />} />
          <MarkButton format="underline" icon={<RiUnderline />} />
          <MarkButton format="code" icon={<RiCodeSSlashLine />} />
        </div>
        <div className="toolbar-separator"></div>
        <div className="toolbar-group">
          <FontSizeSelector
            onChange={handleFontSizeChange}
            value={Editor.marks(editor)?.fontSize || "16px"}
          />
          {/* Add color picker for text color */}
          <ColorPicker
            label="Text Color"
            icon={<RiPaintBrushFill className="fs-5 text-muted me-1" />}
            onChange={handleColorChange}
            value={Editor.marks(editor)?.color || "#000000"}
          />
          {/* Add color picker for background color */}
          <ColorPicker
            label="Background Color"
            icon={<RiPaintFill className="fs-5 text-muted me-1" />}
            onChange={handleBgColorChange}
            value={Editor.marks(editor)?.bgColor || "#ffffff"}
          />
        </div>
        <div className="toolbar-separator"></div>
        <div className="toolbar-group">
          <BlockButton format="numbered-list" icon={<RiListOrdered />} />
          <BlockButton format="bulleted-list" icon={<RiListUnordered />} />
          <BlockButton format="left" icon={<RiAlignLeft />} />
          <BlockButton format="center" icon={<RiAlignCenter />} />
          <BlockButton format="right" icon={<RiAlignRight />} />
          <BlockButton format="justify" icon={<RiAlignJustify />} />
        </div>
        {showTable && (
          <>
            <div className="toolbar-separator"></div>
            <div className="toolbar-group">
              <TableButton />
            </div>
          </>
        )}
        {showMailMergeOption && (
          <>
            {!inOneLine && <div className="w-100"></div>}

            {/* New line with the Mail Merge Fields */}
            <div className="toolbar-group mailMergeGroupStyle">
              <MailMergeDropdown />
            </div>
          </>
        )}
      </Toolbar>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Write something here"
        spellCheck
        autoFocus
        className="form-control"
        readOnly={readOnly}
        style={style}
        onBlur={onBlurEvent}
        // onFocus={handleEditorFocus}
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );

  const isList = LIST_TYPES.includes(format);

  // Unwrap existing list nodes if necessary
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    // Handle alignment toggling
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else if (isList) {
    // Handle list toggling
    newProperties = {
      type: isActive ? "paragraph" : "list-item",
    };
  } else {
    // Handle general block type toggling
    newProperties = {
      type: isActive ? "paragraph" : format,
    };
  }

  // Apply the new properties
  Transforms.setNodes(editor, newProperties);

  // Wrap nodes in a list if necessary
  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format, value) => {
  const isActive = isMarkActive(editor, format, value);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, value);
  }
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );
  return !!match;
};
const isMarkActive = (editor, format, value) => {
  const marks = Editor.marks(editor);
  if (!marks) return false;
  if (value !== undefined) {
    return marks[format] === value;
  }
  return marks[format] !== undefined;
};

// MarkButton and BlockButton components
export const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format, true);
      }}
    >
      {icon}
    </Button>
  );
};

const TableButton = () => {
  const editor = useSlate();
  return (
    <Button
      onMouseDown={(event) => {
        event.preventDefault();
        insertTable(editor, 2, 2); // Insert a 2x2 table by default
      }}
    >
      <RiTableLine />
    </Button>
  );
};

export const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      className={"toolbar-button"}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </Button>
  );
};

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  const textAlign = element?.align || "left";
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "heading-three":
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case "heading-four":
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      );
    case "heading-five":
      return (
        <h5 style={style} {...attributes}>
          {children}
        </h5>
      );
    case "heading-six":
      return (
        <h6 style={style} {...attributes}>
          {children}
        </h6>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case "table":
      return (
        <table
          {...attributes}
          style={{ borderCollapse: "collapse", marginBottom: "10px" }}
        >
          <tbody>{children}</tbody>
        </table>
      );
    case "table-row":
      return <tr {...attributes}>{children}</tr>;
    case "table-cell":
      return (
        <td
          {...attributes}
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            verticalAlign: "top",
            textAlign: element.align || "left",
          }}
        >
          {children}
        </td>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export default SlateWrapperA;

// FontFamilySelector.js
import React from 'react';
import { Form } from 'react-bootstrap';

const FONT_FAMILIES = [
  { label: 'Arial', value: 'Arial, sans-serif' },
  { label: 'Helvetica', value: 'Helvetica, sans-serif' },
  { label: 'Times New Roman', value: 'Times New Roman, serif' },
  { label: 'Georgia', value: 'Georgia, serif' },
  { label: 'Courier New', value: 'Courier New, monospace' },
  { label: 'Verdana', value: 'Verdana, sans-serif' },
  { label: 'Tahoma', value: 'Tahoma, sans-serif' },
  { label: 'Trebuchet MS', value: 'Trebuchet MS, sans-serif' },
  { label: 'Impact', value: 'Impact, sans-serif' },
  { label: 'Comic Sans MS', value: 'Comic Sans MS, cursive, sans-serif' },
  { label: 'Lucida Console', value: 'Lucida Console, monospace' },
  { label: 'Roboto', value: 'Roboto, sans-serif' },
  { label: 'Open Sans', value: 'Open Sans, sans-serif' },
  { label: 'Lato', value: 'Lato, sans-serif' },
  { label: 'Montserrat', value: 'Montserrat, sans-serif' },
  { label: 'Oswald', value: 'Oswald, sans-serif' },
  { label: 'Source Sans Pro', value: 'Source Sans Pro, sans-serif' },
  { label: 'Raleway', value: 'Raleway, sans-serif' },
  { label: 'PT Sans', value: 'PT Sans, sans-serif' },
  { label: 'Merriweather', value: 'Merriweather, serif' },
  // Add more fonts as desired
];

const FontFamilySelector = ({ onChange, value }) => (
  <div className="me-3 d-flex align-items-center">
    <label className="form-label mb-0 me-2">Font</label>
    <Form.Select
      onChange={(e) => onChange(e.target.value)}
      value={value}
      className="form-select"
      style={{ width: '150px',  fontSize: "small" }}
    >
      {FONT_FAMILIES.map((font) => (
        <option key={font.label} value={font.value} style={{ fontFamily: font.value }}>
          {font.label}
        </option>
      ))}
    </Form.Select>
  </div>
);

export default FontFamilySelector;

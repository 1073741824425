import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { currentTeamId } from '../../../Utils/utils';
import { findUrlInMessage } from './SocialMediaUtils';
import { GET_META_INFO } from '../../../GraphApi/socialMedia';

export const MetaDetailsInfo = ({ metaInfo = null, channel, message = '' }) => {
  const [metaDetails, setMetaDetails] = useState(null);
  const [getMetaInfo] = useLazyQuery(GET_META_INFO);
  const [selectedUrl, setSelectedUrl] = useState(null);
  useEffect(() => {
    if (metaInfo === null && message !== '') {
      const allLinkInMessage = findUrlInMessage(message);
      if (allLinkInMessage && allLinkInMessage.length > 0) {
        setSelectedUrl(allLinkInMessage[0]);
        fetchMetaInfo(allLinkInMessage[0]);
      } else {
        setMetaDetails(null);
      }
    } else if (metaInfo !== null) {
      setMetaDetails(metaInfo);
    } else {
      setMetaDetails(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaInfo]);

  const fetchMetaInfo = (url) => {
    getMetaInfo({
      variables: { team_id: currentTeamId, url: url },
      fetchPolicy: 'no-cache',
    })
      .then((result) => {
        if (result.data && !result.error) {
          setMetaDetails(JSON.parse(result.data.getMetaInformation.data));
        }
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        console.log('Error::', graphQLErrors);
      });
  };

  const showMetaDetails = (details, channel) => {
    let metaData = {};
    if (['facebook', 'linkedin', 'instagram'].indexOf(channel) > -1) {
      metaData = {
        ...metaData,
        title: details['og:title']
          ? details['og:title']
          : details.title
            ? details.title
            : '',
      };
      metaData = {
        ...metaData,
        description: details['og:description']
          ? details['og:description']
          : details.description
            ? details.description
            : '',
      };
      metaData = {
        ...metaData,
        name: details['og:name']
          ? details['og:name']
          : details.name
            ? details.name
            : '',
      };
      metaData = {
        ...metaData,
        image: details['og:image']
          ? details['og:image']
          : details.image
            ? details.image
            : '',
      };
      metaData = {
        ...metaData,
        url: details['og:url']
          ? details['og:url']
          : details.image
            ? details.url
            : '',
      };
    } else if (channel === 'twitter') {
      metaData = {
        ...metaData,
        title: details['twitter:title']
          ? details['twitter:title']
          : details.title
            ? details.title
            : '',
      };
      metaData = {
        ...metaData,
        description: details['twitter:description']
          ? details['og:description']
          : details.description
            ? details.description
            : '',
      };
      metaData = {
        ...metaData,
        name: details['twitter:name']
          ? details['twitter:name']
          : details.name
            ? details.name
            : '',
      };
      metaData = {
        ...metaData,
        image: details['twitter:image']
          ? details['twitter:image']
          : details.image
            ? details.image
            : '',
      };
      metaData = {
        ...metaData,
        url: details['twitter:site']
          ? details['twitter:site']
          : details.image
            ? details.url
            : '',
      };
    }

    if (metaData) {
      return (
        <div className='photo-cover'>
          <a
            target='_new'
            href={selectedUrl ? selectedUrl : '#'}
          >
            {metaData.name || metaData.image || metaData.description ? (
              <div className='border p-1'>
                {metaData.image ? (
                  <img
                    className='img-fluid pv-img'
                    src={metaData.image}
                    alt=''
                    align='center'
                  />
                ) : (
                  ''
                )}
                <div className='cover-content cursor-pointer'>
                  <div>{metaData.name}</div>
                  <div className='photo-title'>{metaData.title}</div>
                  <div>{metaData.description}</div>
                </div>
              </div>
            ) : (
              ''
            )}
          </a>
        </div>
      );
    }
    return '';
  };

  return (
    <React.Fragment>
      {metaDetails ? showMetaDetails(metaDetails, channel) : ''}
    </React.Fragment>
  );
};

// Leaf.js
import React from 'react';

const Leaf = ({ attributes, children, leaf }) => {
  let style = {};

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  // Apply color, background color, and font family styles
  if (leaf.color) {
    style.color = leaf.color;
  }

  if (leaf.bgColor) {
    style.backgroundColor = leaf.bgColor;
  }

  if (leaf.fontFamily) {
    style.fontFamily = leaf.fontFamily;
  }
  
  if (leaf.fontSize) {
    style.fontSize = leaf.fontSize;
  }

  return (
    <span {...attributes} style={style}>
      {children}
    </span>
  );
};

export default Leaf;

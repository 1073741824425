import React from "react";
import { Modal } from "react-bootstrap";
import { EmailTemplatesListing } from "./EmailTemplatesListing";
import { CreateEmail } from "../../../Pages/EmailBuilder/CreateEmail";
import { useTranslation } from "react-i18next";

export const CreateOrCloneModal = ({
  showEmailTemplate,
  handleShowEmailTemplate,
  handleCloseEmailTemplate,
  showCreateEmail,
  setShowCreateEmail,
  subject,
  setSubject,
  name,
  setName,
  handleSave,
  systemEmailTemplates,
  industry,
  previewEmailTemplates,
  handleClone,
  emailMode = {},
  page = "campaign",
  templateType,
  setCreateFromScratch = () => {},
}) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const handleCloseCreateEmail = () => setShowCreateEmail(false);
  const handleShowCreateEmail = () => setShowCreateEmail(true);
  return (
    <React.Fragment>
      <Modal
        className={"common modalXl fixed-height-with-scroll"}
        show={showEmailTemplate}
        onHide={handleCloseEmailTemplate}
        backdrop="static"
        keyboard={false}
      >
        <EmailTemplatesListing
          handleClose={handleCloseEmailTemplate}
          handleShowCreateEmail={handleShowCreateEmail}
          systemEmailTemplates={systemEmailTemplates}
          industry={industry}
          previewEmailTemplates={previewEmailTemplates}
          handleClone={handleClone}
          emailMode={emailMode}
          page={page}
          setCreateFromScratch={setCreateFromScratch}
          locale={locale}
          templateType={templateType}
        />
      </Modal>
      <Modal
        className={"common modalSm"}
        show={showCreateEmail}
        onHide={handleCloseCreateEmail}
        backdrop="static"
        keyboard={false}
      >
        <CreateEmail
          handleClose={handleSave}
          subject={subject}
          setSubject={setSubject}
          name={name}
          setName={setName}
          handleCloseCreateEmail={handleCloseCreateEmail}
          locale={locale}
        />
      </Modal>
    </React.Fragment>
  );
};

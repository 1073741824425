import { gql } from "@apollo/client";

export const ADD_SOCIAL_ACCOUNT = gql`
  mutation createSocialCredentialBasedOfCode(
    $team_id: Int
    $credential_for: String
    $code: String
    $redirect_uri: String
    $oauth_token: String
    $oauth_verifier: String
  ) {
    createSocialCredentialBasedOfCode(
      team_id: $team_id
      credential_for: $credential_for
      code: $code
      redirect_uri: $redirect_uri
      oauth_token: $oauth_token
      oauth_verifier: $oauth_verifier
    ) {
      id
      userID: social_id
      credential_for
      accessToken: access_token
    }
  }
`;

export const GET_SOCIAL_TOKEN = gql`
  query getTokenFor($teamId: Int, $for: String) {
    getSocialToken(team_id: $teamId, credential_for: $for) {
      id
      userID: social_id
      credential_for
      accessToken: access_token
      status
      social_profile {
        name
        profile_picture
      }
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation createSchedulePost(
    $teamId: Int
    $durationType: String
    $detail: SchedulePostDetail
    $scheduleDates: String
    $channels: [ChannelPost]
    $schedule: String
  ) {
    createScheduledPost(
      team_id: $teamId
      duration_type: $durationType
      schedule_dates: $scheduleDates
      detail: $detail
      channels: $channels
      duration: null
      schedule: $schedule
    ) {
      id
      team_id
      duration_type
      duration
      schedule_dates
      detail {
        id
        description
        images
        videos
      }
      channels {
        id
        channel
        social_id
        social_channel_id
        post_logs {
          id
          scheduled_date
          status
          response
        }
      }
    }
  }
`;

export const GET_SCHEDULED_POST = gql`
  query getSchedulePosts($teamId: Int) {
    allSocialSchedule(team_id: $teamId) {
      id
      team_id
      duration_type
      schedule_dates
      detail {
        id
        description
        images
        videos
      }
      channels {
        id
        channel
        social_id
        social_channel_id
        post_logs {
          id
          scheduled_date
          status
          response
        }
      }
    }
  }
`;
export const GET_SCHEDULED_POST_LIST = gql`
  query getSchedulePosts($teamId: Int, $first: Int!, $page: Int) {
    socialScheduleListing(team_id: $teamId, first: $first, page: $page) {
      data {
        id
        team_id
        duration_type
        schedule_dates
        detail {
          id
          description
          images
          videos
        }
        channels {
          id
          channel
          social_id
          social_channel_id
          post_logs {
            id
            scheduled_date
            status
            response
          }
        }
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

export const EDIT_SCHEDULED_POST = gql`
  mutation updateSchedulePost(
    $id: Int
    $teamId: Int
    $durationType: String
    $detail: SchedulePostDetail
    $scheduleDates: String
    $channels: [ChannelPost]
    $deleteChannels: [Int]
  ) {
    updateScheduledPost(
      id: $id
      team_id: $teamId
      duration_type: $durationType
      duration: null
      schedule_dates: $scheduleDates
      detail: $detail
      channels: $channels
      delete_channels: $deleteChannels
    ) {
      id
      team_id
      duration_type
      duration
      schedule_dates
      detail {
        id
        description
        images
        videos
      }
      channels {
        id
        channel
        social_id
        social_channel_id
        post_logs {
          scheduled_date
          status
          response
        }
      }
    }
  }
`;

export const DELETE_SCHEDULED_POST = gql`
  mutation deleteSchedulePost($id: Int, $teamId: Int) {
    deleteScheduledPost(id: $id, team_id: $teamId) {
      status
    }
  }
`;

export const DELETE_SINGLE_POST = gql`
  mutation deleteScheduledPostLog($id: Int, $teamId: Int) {
    deleteScheduledPostLog(team_id: $teamId, post_id: $id) {
      status
    }
  }
`;

export const DELETE_SOCIAL_ACCOUNT = gql`
  mutation removeAccount(
    #$id: Int
    $teamId: Int
    $socialId: String
    $for: String
    $permanentDelete: Boolean
  ) {
    removeSocialEmailCredential(
      #id: $id
      team_id: $teamId
      social_id: $socialId
      credential_for: $for
      delete_permanent: $permanentDelete
    ) {
      status
    }
  }
`;
export const GET_META_INFO = gql`
  query getMetaInformation($team_id: Int, $url: String) {
    getMetaInformation(team_id: $team_id, url: $url) {
      data
    }
  }
`;

export const GET_DATA_VIA_API = gql`
  # linkedin, twitter
  query getDataViaAPI(
    $credential_for: String
    $social_id: String
    $team_id: Int
    $request_url: String
    $method: String
    $params: String
  ) {
    getDataViaAPI(
      credential_for: $credential_for
      social_id: $social_id
      team_id: $team_id
      request_url: $request_url
      method: $method
      params: $params
    ) {
      data
      status
    }
  }
`;

export const WHATSAPP_AUTHENTICATION = gql`
  mutation authenticateWhatsapp($team_id: Int, $code: String) {
    authenticateWhatsapp(team_id: $team_id, code: $code) {
      message
    }
  }
`;

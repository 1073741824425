import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { SelectDropdown } from "../../../../Components/Common/Conditions/SelectDropdown";
import { PlusButton } from "../../../../Components/Common/SvgFiles/PlusButton";
import { CloseButton } from "../../../../Components/Common/SvgFiles/CloseButton";
import {
  currentTeamId,
  isObjectEmpty,
  toWordCase,
} from "../../../../Utils/utils";
import BetweenNumber from "../../../../Components/Common/Filters/SubFilters/BetweenNumber";
import NumberField from "../../../../Components/Common/Filters/SubFilters/NumberField";
import DateFilter from "../../../../Components/Common/Filters/SubFilters/DateFilter";
import { FILTER_SUGGESTION_LIST } from "../../../../GraphApi/Filters";
import { useLazyQuery } from "@apollo/client";
import InLineError from "../../../../Components/Common/AlertMessage/InLineError";

const extraFilter = [
  "visited_landing_pages",
  "email_sent",
  "email_clicked",
  "email_spammed",
  "email_bounced",
  "email_delivered",
  "email_opened",
  "email_unsubscribed",
  "deal_stage",
];

const noOptionLabels = {
  email_clicked: "Email",
  email_sent: "Email",
  email_spammed: "Email",
  email_bounced: "Email",
  email_delivered: "Email",
  email_opened: "Email",
  email_unsubscribed: "Email",
  product_purchased: "Product",
  product_interested: "Product",
  submitted_form: "Form",
  visited_landing_pages: "Landing Page",
  associated_campaign: "Campaign",
  country: "Country",
};
const multiSelectFilter = [
  "tag_name",
  "estimate_services_included",
  "estimate_products_included",
  "job_services_included",
  "job_products_included",
  "service_visit_service_included",
  "service_purchased",
];
const noEmailSelectFilter = ["email_unsubscribed", "email_bounced"];
const withoutOperatorFields = [
  "source",
  "category",
  "sub_source",
  "category",
  "sub_category",
  "order_status",
  "stage",
  "payment_status",
  "status",
  "associated_product",
  "customer",
  "on_free_trial",
  "billing_type",
  "plan_id",
  "coupon_applied",
  "tax",
  "life_cycle",
  "deal_win_probability",
  "subscription_plan",
  "service_status",
  "recommended_tasks",
  "verified_email",
  "agreement_status",
];
const staticSuggestionField = [
  "on_free_trial",
  "billing_type",
  "country",
  "deal_stage",
  "recommended_tasks",
  "verified_email",
  "email_spammed",
  "email_unsubscribed",
];
const fieldsWithId = [
  // "tag_name",
  "lead_owner",
  "visited_landing_pages",
  "submitted_form",
  "associated_campaign",
  "email_sent",
  "email_clicked",
  "email_bounced",
  "email_opened",
  "email_delivered",
  "email_unsubscribed",
  "email_spammed",
  "deal_owner",
  "deal_stage",
  // "estimate_services_included",
  // "estimate_products_included",
  // "job_services_included",
  // "job_products_included",
  "service_visit_technician",
  // "service_visit_service_included",
  "service_purchased",
  "service_visit_status",
];

export const ConditionRow = ({
  idx,
  count = 0,
  handleChange,
  addCondition,
  removeCondition,
  rowData,
  attributes,
  countries = [],
  display = "inline",
  hasMultiCondition = true,
  errorBag = {},
}) => {
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [operators, setOperators] = useState([]);
  const [filterType, setFilterType] = useState("text");
  const [filterGroup, setFilterGroup] = useState(null);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [previousAppliedFilters, setPreviousAppliedFilters] = useState([]);

  const [
    getFilterSuggestion,
    { data: suggestionQuery, loading: suggestionLoading },
  ] = useLazyQuery(FILTER_SUGGESTION_LIST, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setDropDownOptions([]);
    getDropDownValue(rowData.attribute);
  }, [rowData.attribute, attributes]);

  const executeFilterQuery = (group) => {
    getFilterSuggestion({
      variables: {
        attribute: rowData?.attribute,
        operator: rowData?.operator,
        first_value: filterSearchValue && filterSearchValue,
        filter: previousAppliedFilters,
        group: group,
        entity: "automation",
        suggestionType: "score",
        team_id: currentTeamId,
      },
    });
  };

  const getDropDownValue = (value) => {
    if (!isObjectEmpty(value)) {
      if (attributes?.length > 0) {
        let selected = null;
        attributes.forEach((item) => {
          if (
            Array.isArray(item.options) &&
            item.options.length > 0 &&
            selected === null
          ) {
            item.options.forEach((option) => {
              if (option.value === value) {
                setFilterType(option.type);
                selected = option.supported_operators;
                getSuggestion(option.group);
              }
            });
          } else if (item.value === value && selected === null) {
            setFilterType(item.type);
            selected = item.supported_operators;
            getSuggestion(item.group);
          }
        });
        setOperators(selected);
      }
    } else {
      setOperators([]);
    }
  };

  const getSuggestion = (group) => {
    setDropDownOptions([]);
    const attributeValue = rowData.attribute;
    /** Check Filter type and Execute Suggestion Query **/
    if (["text", "dropdownFirst", "dropdown"].includes(filterType)) {
      if (staticSuggestionField.includes(attributeValue)) {
        console.log("CHECK:", attributeValue);
        setStaticSuggestions(attributeValue);
      } else if (!["country"].includes(attributeValue)) {
        if (isObjectEmpty(filterSearchValue)) {
          executeFilterQuery(group);
        }
      }
    }
  };

  /** Dynamic  Suggestions***/
  useEffect(() => {
    if (suggestionQuery) {
      let outputList = JSON.parse(
        suggestionQuery.filterSuggestions.filter_record
      );
      // For "custom attribute" with options
      if (
        filterGroup === "custom attribute" &&
        outputList?.options?.length > 0
      ) {
        outputList = outputList.options;
      }

      const suggestionArray = [];
      const options = [];

      if (outputList) {
        outputList.forEach((item) => {
          const itemLabel = item.label || item.name || item;
          const itemValue = item.value || item.id || item;

          switch (filterGroup) {
            case "custom attribute":
              switch (filterType) {
                case "text":
                  suggestionArray.push({ label: item });
                  break;
                case "number":
                  // Do nothing for number type
                  break;
                default:
                  options.push({ value: itemValue, label: itemLabel });
                  break;
              }
              break;

            default:
              switch (filterType) {
                case "text":
                  options.push({ value: itemValue, label: itemLabel });
                  break;

                case "dropdownFirst":
                  switch (rowData.attribute) {
                    case "payment_status":
                      options.push({
                        value: item,
                        label: toWordCase(item),
                      });
                      break;
                    case "stage":
                    case "source":
                    case "order_status":
                    case "status":
                    case "life_cycle":
                    case "deal_win_probability":
                    case "service_status":
                    case "agreement_status":
                    case "estimate_status":
                    case "invoice_status":
                      options.push({
                        value: itemValue,
                        label: toWordCase(item.toString()),
                      });
                      break;
                    case "service_visit_status":
                      options.push({ value: item.id, label: item.name });
                      break;
                    default:
                      if (
                        extraFilter.includes(rowData.attribute) &&
                        !["submitted_form", "visited_landing_pages"].includes(
                          rowData.attribute
                        )
                      ) {
                        options.push({
                          value: item.id,
                          label: item.subject || item.name,
                        });
                      } else {
                        options.push({ value: item.id, label: item.name });
                      }
                      break;
                  }
                  break;

                case "number":
                  // Do nothing for number type
                  break;

                default:
                  if (rowData.attribute === "status") {
                    options.push({
                      value: item.toString(),
                      label: toWordCase(item.toString()),
                    });
                  } else if (rowData.attribute === "tag_name") {
                    options.push({
                      value: item.id,
                      label: item.name,
                    });
                  } else if (
                    [
                      "email_sent",
                      "email_bounced",
                      "email_opened",
                      "email_clicked",
                      "email_delivered",
                    ].includes(rowData.attribute)
                  ) {
                    options.push({
                      value: item.id,
                      label: item?.subject || item?.name || "",
                    });
                  } else {
                    options.push({
                      value: item.id,
                      label: item.name,
                    });
                  }
                  break;
              }
              break;
          }
        });
      }

      setDropDownOptions(options);
    }
  }, [suggestionQuery]);

  /** Static Suggestions***/
  const setStaticSuggestions = (fieldName, suggestions) => {
    let options = [];
    if (
      ["recommended_tasks", "verified_email", "on_free_trial"].indexOf(
        fieldName
      ) > -1
    ) {
      options = [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ];
    } else if (fieldName === "billing_type") {
      options = [
        { value: "monthly", label: "Monthly" },
        { value: "yearly", label: "Yearly" },
      ];
    } else if (fieldName === "country") {
      options = countries.map(
        (cName) =>
          cName && {
            value: cName.code,
            label: cName.name,
          }
      );
    } else if (fieldName === "deal_stage") {
      if (suggestions && suggestions.length > 0) {
        options = suggestions.map(
          (suggestion) =>
            suggestion && {
              value: suggestion.id,
              label: suggestion.label,
            }
        );
      }
    } else if (fieldName === "payment_status") {
      options = [
        { value: "1", label: "Success" },
        { value: "0", label: "Failed" },
      ];
    } else if (["email_spammed", "email_unsubscribed"].includes(fieldName)) {
      options = [{ value: "any", label: "Any Email" }];
      handleChange("operator", { value: "any", label: "Any" });
    }
    setDropDownOptions(options);
  };

  const handleDropdownValue = (e) => {
    if (
      rowData?.type === "dropdownFirst" &&
      rowData?.group === "custom attribute"
    ) {
      if (rowData.sub_type === "checkbox") {
        const myVal = rowData.value ? JSON.parse(rowData.value) : "";
        return myVal.length > 0 ? { value: myVal[0], label: myVal[0] } : "";
      } else {
        const myFilterValue =
          typeof rowData.value === "object"
            ? rowData.value.value.toString()
            : rowData.value.toString();

        const selectedOptionValue = dropDownOptions.filter(
          (item) =>
            item?.value &&
            rowData?.value &&
            item.value.toString() === myFilterValue
        );

        return selectedOptionValue.length > 0
          ? selectedOptionValue[0]
          : rowData.value;
      }
    } else if (fieldsWithId.includes(rowData.attribute)) {
      handleChange("attribute_id", e);
    } else {
      handleChange("first_value", e);
    }
  };

  /******/
  return (
    <Row>
      <Col xs={10} sm={11} md={11} className="ms-0 ps-0">
        <Row>
          <Col
            xs={12}
            sm={display === "inline" ? 4 : 12}
            md={display === "inline" ? 4 : 12}
          >
            <SelectDropdown
              value={rowData?.attribute || null}
              label="Condition"
              options={attributes || []}
              isMulti={false}
              isClearable={true}
              isSearchable={true}
              handleChange={(e) => {
                setOperators(e?.supported_operators || []);
                handleChange("attribute", e);
              }}
              placeholder={"Select Condition"}
            />
            <InLineError
              extraStyle={{ marginTop: "-15px" }}
              type="error"
              name="condition"
              message={
                errorBag && errorBag[idx] && errorBag[idx]?.condition
                  ? errorBag[idx].condition
                  : ""
              }
            />
          </Col>

          {filterType === "dropdownFirst" && rowData.attribute && (
            <React.Fragment>
              <Col
                xs={12}
                sm={display === "inline" ? 6 : 12}
                md={display === "inline" ? 6 : 12}
              >
                <SelectDropdown
                  value={
                    fieldsWithId.includes(rowData.attribute)
                      ? rowData.attribute_id
                      : rowData.first_value
                  }
                  label="Value"
                  options={dropDownOptions}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  handleChange={(e) => handleDropdownValue(e)}
                />
                <InLineError
                  extraStyle={{ marginTop: "-15px" }}
                  type="error"
                  name="value"
                  message={
                    errorBag && errorBag[idx] && errorBag[idx]?.value
                      ? errorBag[idx].value
                      : ""
                  }
                />
              </Col>
            </React.Fragment>
          )}
          {[
            "dropdown",
            "text",
            "date",
            "dateNumber",
            "time",
            "number",
          ].includes(filterType) &&
            rowData.attribute && (
              <React.Fragment>
                <Col
                  xs={12}
                  sm={display === "inline" ? 2 : 12}
                  md={display === "inline" ? 2 : 12}
                >
                  <SelectDropdown
                    value={rowData.operator}
                    label="Operator"
                    options={operators || []}
                    isMulti={false}
                    isClearable={false}
                    isSearchable={false}
                    handleChange={(e) => handleChange("operator", e)}
                  />
                  <InLineError
                    extraStyle={{ marginTop: "-15px" }}
                    type="error"
                    name="operator"
                    message={
                      errorBag && errorBag[idx] && errorBag[idx]?.operator
                        ? errorBag[idx].operator
                        : ""
                    }
                  />
                </Col>
                {rowData.operator && (
                  <Col
                    xs={12}
                    sm={display === "inline" ? 6 : 12}
                    md={display === "inline" ? 6 : 12}
                  >
                    {!["any", "empty"].includes(rowData.operator) && (
                      <React.Fragment>
                        {filterType === "text" && (
                          <Form.Group className="form-group">
                            <Form.Label>Filter value</Form.Label>
                            <Form.Control
                              id={"title"}
                              type="text"
                              placeholder={"Enter value"}
                              value={rowData?.first_value || ""}
                              onChange={(e) => {
                                handleChange(
                                  "first_value",
                                  e?.target?.value
                                    ? {
                                        value: e.target.value,
                                        label: e.target.value,
                                      }
                                    : ""
                                );
                              }}
                            />{" "}
                            <InLineError
                              // extraStyle={{ marginTop: "-15px" }}
                              type="error"
                              name="value"
                              message={
                                errorBag &&
                                errorBag[idx] &&
                                errorBag[idx]?.value
                                  ? errorBag[idx].value
                                  : ""
                              }
                            />
                          </Form.Group>
                        )}

                        {filterType === "dropdown" && (
                          <React.Fragment>
                            <SelectDropdown
                              value={
                                fieldsWithId.includes(rowData.attribute)
                                  ? rowData.attribute_id
                                  : rowData.first_value
                              }
                              label="Value"
                              options={dropDownOptions}
                              isMulti={multiSelectFilter.includes(
                                rowData.attribute
                              )}
                              isClearable={false}
                              isSearchable={false}
                              handleChange={(e) => handleDropdownValue(e)}
                            />
                            <InLineError
                              extraStyle={{ marginTop: "-15px" }}
                              type="error"
                              name="value"
                              message={
                                errorBag &&
                                errorBag[idx] &&
                                errorBag[idx]?.value
                                  ? errorBag[idx].value
                                  : ""
                              }
                            />
                          </React.Fragment>
                        )}

                        {filterType === "number" && (
                          <React.Fragment>
                            {rowData.operator === "between" ? (
                              <BetweenNumber
                                placeholder={"enter number"}
                                label="Number"
                                labelVisibility={false}
                                value={
                                  rowData?.first_value
                                    ? parseInt(rowData.first_value)
                                    : ""
                                }
                                valueSecond={
                                  rowData?.second_value
                                    ? parseInt(rowData.second_value)
                                    : ""
                                }
                                handleChange={(e) => {
                                  handleChange("first_value", {
                                    value: e.target.value,
                                  });
                                }}
                                handleChangeSecond={(e) => {
                                  handleChange("second_value", {
                                    value: e.target.value,
                                  });
                                }}
                                isError={
                                  errorBag &&
                                  errorBag[idx] &&
                                  errorBag[idx]?.value
                                    ? true
                                    : false
                                }
                              />
                            ) : (
                              <NumberField
                                placeholder={"Enter number"}
                                label="Number"
                                labelVisibility={false}
                                value={
                                  rowData?.first_value
                                    ? parseInt(rowData.first_value)
                                    : ""
                                }
                                handleChange={(value) => {
                                  handleChange("first_value", {
                                    value: value,
                                  });
                                }}
                                isError={
                                  errorBag &&
                                  errorBag[idx] &&
                                  errorBag[idx]?.value
                                    ? true
                                    : false
                                }
                              />
                            )}
                          </React.Fragment>
                        )}

                        {["date", "dateNumber"].includes(filterType) && (
                          <React.Fragment>
                            {rowData.operator === "between" ? (
                              <DateFilter
                                placeholder="Select Date"
                                labelVisibility={false}
                                value={{
                                  startDate: rowData?.first_value || "",
                                  endDate: rowData?.second_value || "",
                                }}
                                type={"range"}
                                handleChange={(startDate) => {
                                  handleChange("first_value", {
                                    value: startDate,
                                  });
                                }}
                                handleChangeSecond={(endDate) => {
                                  handleChange("second_value", {
                                    value: endDate,
                                  });
                                }}
                                isError={
                                  errorBag &&
                                  errorBag[idx] &&
                                  errorBag[idx]?.value
                                    ? true
                                    : false
                                }
                              />
                            ) : (
                              <DateFilter
                                placeholder="Select Date"
                                labelVisibility={false}
                                value={{
                                  startDate: rowData?.first_value || "",
                                }}
                                type={"single"}
                                handleChange={(date) => {
                                  handleChange("first_value", {
                                    value: date,
                                  });
                                }}
                                isError={
                                  errorBag &&
                                  errorBag[idx] &&
                                  errorBag[idx]?.value
                                    ? true
                                    : false
                                }
                              />
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </Col>
                )}
              </React.Fragment>
            )}
        </Row>
      </Col>
      <Col xs={2} sm={1} md={1} className="px-0">
        <div className="mt-3 pt-3 d-flex justify-content-between">
          {count - 1 === idx && hasMultiCondition && (
            <Button
              variant="link"
              className="mx-0 p-0"
              onClick={() => addCondition()}
            >
              <PlusButton />
            </Button>
          )}
          <Button
            variant="link"
            className="mx-0 p-0"
            onClick={() => removeCondition(idx)}
          >
            <CloseButton />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
/* Retrieve Data From Server */
import { useLazyQuery, useMutation } from "@apollo/client";
import XAlertMessage from "../AlertMessage/XAlertMessage";
import {
  IMAGE_GALLERY,
  IMAGE_UPLOAD,
  // IMAGE_REMOVE,
} from "../../../GraphApi/ImageGallery";
import {
  currentTeamId,
  getValidationMessage,
  imagesForGallery,
  addImagesToGallery,
  toWordCase,
  videosForGallery,
  attachmentForGallery,
  documentsForGallery,
} from "../../../Utils/utils";
import XFileUploadButton from "../FileUpload/XFileUploadButton";
import "./image-gallery.scss";
import { GalleryLoading } from "../Loader/SkeletonLoader";
import useCheckInventoryConsumption from "../../../Hooks/useCheckInventoryConsumption";
import { AIImageGenerator } from "../../TooToo/AIImageGenerator";

export const ImageGalleryWithModal = ({
  getImage,
  handleClose,
  locale,
  mediaType = "image",
  fileSize = 100,
  onlyUrl = true,
  hasAttachment = false,
  noSvg = false,
}) => {
  /** Alert Message State **/
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  /*****/
  const [files, setFiles] = useState([]);
  const [galleryFiles, setGalleryFiles] = useState([]);
  const [galleryContent, setGalleryContent] = useState(null);
  const [isGalleryLoading, setIsGalleryLoading] = useState(true);
  const [showUploadImageButton, setShowUploadImageButton] = useState(true);
  // Note: Customhook for checking inventory consumption...
  const [
    showModelForEntity,
    // setShowModelForEntity,
    fetchInventoryStatus,
    setShowBuyAddonPlan,
  ] = useCheckInventoryConsumption("space");

  useEffect(() => {
    fetchInventoryStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showModelForEntity === true) {
      setShowUploadImageButton(true);
    } else if (showModelForEntity === false) {
      setShowUploadImageButton(false);
    }
  }, [showModelForEntity]);

  const [uploadImage, { loading: uploading }] = useMutation(IMAGE_UPLOAD);
  // const [removeGalleryImage, { loading: removing }] = useMutation(IMAGE_REMOVE);
  const [getGalleryFiles, { loading, data }] = useLazyQuery(IMAGE_GALLERY, {
    variables: { team_id: currentTeamId },
    fetchPolicy: "network-only",
  });
  /** Initialize Gallery **/
  const getGalleryData = () => {
    getGalleryFiles({
      variables: { team_id: currentTeamId },
      fetchPolicy: "network-only",
    });
  };
  /*****/
  useEffect(() => {
    getGalleryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** On Get Gallery Data **/
  useEffect(() => {
    if (data) {
      let galleryFiles = [];
      if (mediaType === "image" && hasAttachment) {
        galleryFiles = attachmentForGallery(
          data.allGalleryFiles.attachment_images
        );
      } else if (mediaType === "image") {
        galleryFiles = imagesForGallery(data.allGalleryFiles.images);
      } else if (mediaType === "document") {
        galleryFiles = imagesForGallery(data.allGalleryFiles.images);
      } else if (mediaType === "audio") {
        galleryFiles = videosForGallery(data.allGalleryFiles.audios);
      } else if (mediaType === "video") {
        galleryFiles = videosForGallery(data.allGalleryFiles.videos);
      } else {
        galleryFiles = videosForGallery(data.allGalleryFiles.others);
      }
      setGalleryFiles([...galleryFiles]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  /********/
  useEffect(() => {
    if (files && files.length > 0) {
      imageToBeUpload(files[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);
  /** UPLOAD IMAGE FILE **/
  const imageToBeUpload = (file) => {
    uploadImage({
      variables: {
        team_id: currentTeamId,
        file: file,
      },
    })
      .then((result) => {
        handleAlertMessage("success", toWordCase(mediaType) + " uploaded");
        getGalleryData();
        setFiles(null);
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage("error", getValidationMessage(graphQLErrors));
      });
  };
  /** REMOVE IMAGE **/
  /*const removeImage = (id) => {
    removeGalleryImage({
      variables: {
        team_id: currentTeamId,
        id: id,
      },
    })
      .then((result) => {
        handleAlertMessage("success", "Image deleted");
        getGalleryData();
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        handleAlertMessage("error", getValidationMessage(graphQLErrors));
      });
  };*/
  /**Error Message Settings and methods For Formik **/
  const handleAlertMessage = (type, message) => {
    setType(type);
    setMessage(message);
    setAlertShow(true);
  };

  const handleImages = (images) => {
    if (images && images.length > 0) {
      if (onlyUrl) {
        getImage(images[0].attachment.url);
      } else {
        getImage(images);
      }

      handleClose();
    }
  };

  useEffect(() => {
    const fetchGallery = async () => {
      setIsGalleryLoading(true);
      const gallery = await addImagesToGallery(
        galleryFiles,
        { get: getImage, onlyUrl: onlyUrl },
        mediaType,
        noSvg
      );
      setGalleryContent(gallery);
      setIsGalleryLoading(false);
    };

    fetchGallery();
  }, [galleryFiles, getImage, onlyUrl, mediaType, noSvg]);

  /*****/
  return (
    <React.Fragment>
      <Modal.Header closeVariant="white" closeButton className={"bg-primary"}>
        <Modal.Title className={"text-white secondary-font text-uppercase"}>
          {locale(toWordCase(mediaType) + " Gallery")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="eb-popup-body p-0">
          <div>
            <XAlertMessage
              type={type}
              message={message}
              setAlertShow={setAlertShow}
              alertShow={alertShow}
            />
          </div>

          <div className="eb-image-gallery">
            {loading || uploading || isGalleryLoading ? (
        <GalleryLoading externalClass="spinner-wrap-modal-with-bg" />
      ) : (
        <ul>{galleryContent}</ul>
      )}
            <hr />
            <div className="text-center">
              {!showUploadImageButton ? (
                <div
                  className={"btn btn-outline-success text-uppercase"}
                  onClick={() => {
                    setShowBuyAddonPlan(true);
                    handleClose();
                  }}
                >
                  <i className={"ri-upload-2-line text-default"}></i>{" "}
                  <span className={"text-default"}>
                    {locale("Upload Image")}
                  </span>
                </div>
              ) : (
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <XFileUploadButton
                      buttonLabel={"Upload " + toWordCase(mediaType)}
                      isMulti={true}
                      accept={mediaType}
                      handleUpload={(e) => setFiles(e)}
                      handleAlertMessage={handleAlertMessage}
                      fileSize={fileSize}
                    />
                  </Col>
                  <Col xs="auto">
                    <div className={""}>
                      <AIImageGenerator
                        handleAlertMessage={handleAlertMessage}
                        handleGeneratedImages={(images) => handleImages(images)}
                        getGalleryData={getGalleryData}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </React.Fragment>
  );
};

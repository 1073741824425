import React, { useState } from "react";
import { Button, Card, InputGroup } from "react-bootstrap";

export const AutomationChannels = ({
  currentCompanySetting,
  handleAlertMessage = () => {},
  setSetup = null,
  setup = () => {},
  automationData,
  setAutomationData = () => {},
}) => {
  const [checkboxData] = useState([
    { label: "They usually fill out a form", value: "form_submission" },
    { label: "They call us on our business phone", value: "call" },
    { label: "They text us via WhatsApp", value: "whatsapp" },
    { label: "We add them manually in the CRM", value: "manually_add" },
    {
      label: "Have heard about EQUIP's BOOKING Would love to try it.",
      value: "online_booking",
    },
    {
      label:
        "Would love to try Tootoo, EQUIP's AI bot to generate leads, schedule appointments & provide support.",
      value: "chat_bot",
    },
    {
      label: "Want to import existing leads/customers?",
      value: "import",
    },
  ]);

  const handleSelect = (value) => {
    if (automationData.triggers.includes(value)) {
      setAutomationData((prevData) => ({
        ...prevData,
        triggers: prevData.triggers.filter((item) => item !== value),
        data: Object.fromEntries(
          Object.entries(prevData.data).filter(([key]) => key !== value)
        ),
      }));
    } else {
      setAutomationData((prevData) => ({
        ...prevData,
        triggers: [...prevData.triggers, value],
        data: { ...prevData.data, [value]: [{ elementName: value }] },
      }));
    }
  };

  const handleNext = () => {
    if (automationData.triggers?.length > 0) {
      setSetup({
        ...setup,
        reachOutChannel: {
          ...setup.reachOutChannel,
          status: "completed",
        },
        setupAutomation: {
          ...setup.setupAutomation,
          status: "active",
        },
      });
    } else {
    }
  };
  /*****/
  return (
    <Card>
      <Card.Body>
        <h3 className="text-primary mb-1">
          How do clients reach out to you to avail your services
        </h3>
        <p className="fs-sm">
          Select at-least one, though we recommend all. More lead channels the
          better!!
        </p>
        {checkboxData.map((item, index) => (
          <div key={index} className={"checkbox-wrapper my-2"}>
            <InputGroup>
              <InputGroup.Text className="p-2">
                <input
                  type="checkbox"
                  id={`checkbox-${index}`}
                  name={`checkbox-${index}`}
                  className="mr-2"
                  onClick={() => handleSelect(item.value)}
                  checked={automationData?.triggers.includes(item.value)}
                  onChange={() => {}}
                />
              </InputGroup.Text>
              <label
                className="border px-2 pt-1 small w-100"
                htmlFor={`checkbox-${index}`}
              >
                {item.label}
              </label>
            </InputGroup>
          </div>
        ))}
      </Card.Body>
      <Card.Footer>
        <div className="text-end">
          <Button onClick={handleNext}>Next</Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

import { gql } from "@apollo/client";

/** API KEY listing **/
export const API_KEY_LIST = gql`
  query getKeyDetailListing(
    $first: Int!
    $page: Int
    $search: String
    $team_id: Int
    $field: String!
    $order: SortOrder!
  ) {
    getKeyDetailListing(
      first: $first
      page: $page
      search: $search
      team_id: $team_id
      orderBy: [{ column: $field, order: $order }]
    ) {
      data {
        id
        name
        last_used_at
        created_at
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

export const API_KEY_CREATE = gql`
  mutation createApiKey($team_id: Int, $name: String) {
    createApiKey(team_id: $team_id, name: $name) {
      id
      name
      last_used_at
      created_at
      token
    }
  }
`;

export const REMOVE_KEY = gql`
  mutation deleteApiKey($id: Int, $team_id: Int) {
    deleteApiKey(id: $id, team_id: $team_id) {
      status
    }
  }
`;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isObjectEmpty, toWordCase } from "../../../../../Utils/utils";

import { conditionObj } from "../../../../../Utils/commonParams";
import InLineError from "../../../../../Components/Common/AlertMessage/InLineError";
import { GeneralConditions } from "../../../../../Pages/Automation/Elements/Conditions/GeneralConditions";

const maxValue = {
  Minutes: 59,
  Hours: 23,
  Days: 30,
  Months: 0,
};
const delayActions = ["Months", "Days", "Hours", "Minutes"];

export const DelayTimerAction = ({
  currentCompanySetting,
  values,
  handleChange = () => {},
  handleClose = () => {},
}) => {
  const { t: locale } = useTranslation();
  const [errorBag, setErrorBag] = useState(null);

  const [delayTimeDetails, setDelayTimeDetails] = useState({
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    is_duration: true,
    conditions: [conditionObj],
  });
  const [delayTimer, setDelayTimer] = useState(null);

  const onStopTyping = (type, data) => {
    if (toWordCase(type) !== "Months") {
      if (parseInt(data) > 0 && parseInt(data) <= maxValue[toWordCase(type)]) {
        data = parseInt(data);
      } else if (parseInt(data)) {
        data = parseInt(data) * -1;
        if (
          parseInt(data) > 0 &&
          parseInt(data) <= maxValue[toWordCase(type)]
        ) {
          data = parseInt(data);
        } else {
          data = maxValue[toWordCase(type)];
        }
      }
    } else {
      if (parseInt(data) > 0) {
        data = parseInt(data);
      } else if (parseInt(data)) {
        data = parseInt(data) * -1;
      }
    }

    setDelayTimeDetails({ ...delayTimeDetails, [type]: parseInt(data) });
    const valueInMinutes = getTimeInDesiredType({
      ...delayTimeDetails,
      [type]: parseInt(data),
    });

    setDelayTimer(valueInMinutes > 0 ? valueInMinutes : null);
  };

  const getTimeInDesiredType = (timeDetails, type = "i") => {
    const valueFormatter = {
      m: 30,
      d: 24,
      h: 60,
      i: 60,
    };

    let finalValue = 0;
    if (!isObjectEmpty(timeDetails.months) && timeDetails.months > 0) {
      finalValue =
        finalValue +
        timeDetails.months *
          valueFormatter["m"] *
          valueFormatter["d"] *
          valueFormatter["i"];
    }
    if (!isObjectEmpty(timeDetails.days) && timeDetails.days > 0) {
      finalValue =
        finalValue +
        timeDetails.days * valueFormatter["d"] * valueFormatter["i"];
    }
    if (!isObjectEmpty(timeDetails.hours) && timeDetails.hours > 0) {
      finalValue = finalValue + timeDetails.hours * valueFormatter["i"];
    }
    if (!isObjectEmpty(timeDetails.minutes) && timeDetails.minutes > 0) {
      finalValue = finalValue + timeDetails.minutes;
    }
    /******/
    if (type === "s") {
      return finalValue * 60;
    } else if (type === "i") {
      return finalValue;
    } else if (type === "h") {
      return finalValue / 60;
    } else if (type === "d") {
      return finalValue / (60 * 24);
    } else if (type === "m") {
      return finalValue / (60 * 24 * 30);
    }
  };

  useEffect(() => {
    if (values?.delay_time_details?.delay_time_details) {
      setDelayTimeDetails(values?.delay_time_details?.delay_time_details);
      setDelayTimer(values?.delay_time_details?.delay_timer || null);
    }
  }, [values]);

  const handleSaveData = () => {
    let newErrors = {};
    if (delayTimeDetails?.is_duration && !delayTimer) {
      newErrors = {
        ...newErrors,
        delay_time:
          "One filed (Hour, Minutes, Month, Day) of delay time is required.",
      };
    }
    if (!delayTimeDetails?.is_duration) {
      if (delayTimeDetails?.conditions) {
        delayTimeDetails.conditions.forEach((item, idx) => {
          let contactError = {};

          if (!item.attribute) {
            contactError.condition = "Condition is required";
          }
          if (!item.operator) {
            contactError.operator = "Operator is required";
          }
          if (!["any", "empty"].includes(item.operator)) {
            if (!item.first_value && !item.attribute_id) {
              contactError.value = "Value is required";
            }
          }

          // Only add errors if they exist for this index
          if (Object.keys(contactError).length > 0) {
            newErrors.contact_properties = newErrors.contact_properties || {};
            newErrors.contact_properties[idx] = contactError;
          }
        });
      }
    }
    setErrorBag(newErrors);

    if (Object.keys(newErrors).length === 0) {
      let str = [];
      str.push(<span key="1">Wait for</span>);
      if (delayTimeDetails?.is_duration) {
        delayActions.forEach((item, idx) => {
          if (
            !isObjectEmpty(delayTimeDetails[item.toLowerCase()]) &&
            delayTimeDetails[item.toLowerCase()] > 0
          ) {
            str.push(
              <span className="text-primary" key={"2-" + idx}>
                {"" + delayTimeDetails[item.toLowerCase()] + " " + locale(item)}
              </span>
            );
          }
        });
      } else {
        str.push(" un-till conditions are match ");
        str.push(<span className="text-highlighter-dark">Where</span>);
        str.push(...handleConditionString());
      }

      handleChange("action", {
        delay_time_details: {
          action: "i",
          type: !delayTimeDetails?.is_duration ? "condition" : "duration",
          contact_properties: !delayTimeDetails?.is_duration
            ? delayTimeDetails?.conditions
            : [],
          delay_timer: delayTimer,
          delay_time_details: delayTimeDetails,
        },
        string: str,
      });
      handleClose();
    }
  };

  const handleConditionString = () => {
    const highlighter = "text-success";
    let myString = [];
    if (delayTimeDetails?.conditions?.length > 0) {
      delayTimeDetails.conditions.forEach((condition, idx) => {
        if (condition?.attribute) {
          myString.push(toWordCase(condition?.attribute));
          myString.push(" " + toWordCase(condition?.operator || "is") + " ");

          if (condition?.attribute_id) {
            if (Array.isArray(condition?.attribute_id_label)) {
              myString.push(condition?.attribute_id_label.join(", "));
            } else {
              myString.push(toWordCase(condition?.attribute_id_label || ""));
            }
          } else if (condition?.first_value) {
            if (Array.isArray(condition?.first_value)) {
              myString.push(condition?.first_value_label.join(", "));
            } else {
              myString.push(
                condition?.first_value_label || condition?.first_value
              );
            }
          }

          if (condition?.second_value) {
            myString.push(
              " - " + (condition?.second_value_label || condition?.second_value)
            );
          }

          if (
            condition?.condition &&
            idx < delayTimeDetails?.conditions.length - 1
          ) {
            myString.push(
              <span className="text-highlighter-dark">
                {toWordCase(condition?.condition)}
              </span>
            );
          }
        }
      });
    }
    return myString;
  };

  const handelCancel = () => {
    handleClose();
  };
  /*****/
  return (
    <React.Fragment>
      <Container className="my-3">
        <div>
          <Form.Check
            name="isDuration"
            type="radio"
            label="Wait for set duration"
            checked={delayTimeDetails?.is_duration}
            onClick={() => {
              if (!delayTimeDetails?.is_duration) {
                setDelayTimeDetails({
                  ...delayTimeDetails,
                  is_duration: true,
                  conditions: null,
                });
              }
            }}
            onChange={() => {}}
          />
          {delayTimeDetails?.is_duration &&
            delayActions.map((item, idx) => (
              <Row key={idx}>
                <Col>
                  <Form.Group className="form-group">
                    <div className="d-flex justify-content-between">
                      <Form.Label>{locale(toWordCase(item))}</Form.Label>
                      {maxValue[item] ? (
                        <Form.Label>
                          {locale("Suggested value [ 0 To {myValue} ]", {
                            myValue: maxValue[item],
                          })}
                        </Form.Label>
                      ) : (
                        ""
                      )}
                    </div>
                    <Form.Control
                      type="number"
                      value={
                        delayTimeDetails && delayTimeDetails[item.toLowerCase()]
                          ? delayTimeDetails[item.toLowerCase()]
                          : ""
                      }
                      onChange={(e) =>
                        onStopTyping(item.toLowerCase(), e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            ))}
          <InLineError
            type="error"
            message={errorBag?.delay_time || ""}
            extraStyle={{ marginTop: "-15px" }}
          />
        </div>
        <div>
          <Form.Check
            name="isDuration"
            type="radio"
            label="Wait until conditions are met"
            checked={!delayTimeDetails?.is_duration}
            onClick={() => {
              if (delayTimeDetails?.is_duration) {
                setDelayTimeDetails({
                  ...delayTimeDetails,
                  is_duration: false,
                  months: 0,
                  days: 0,
                  hours: 0,
                  minutes: 0,
                  conditions: [conditionObj],
                });
              }
            }}
            onChange={() => {}}
          />
          {!delayTimeDetails?.is_duration && (
            <GeneralConditions
              triggerCondition={delayTimeDetails?.conditions || []}
              setTriggerCondition={(values) =>
                setDelayTimeDetails({
                  ...delayTimeDetails,
                  conditions: [...values],
                })
              }
              currentCompanySetting={currentCompanySetting}
              entity={"contacts"}
              errorBag={errorBag}
            />
          )}
        </div>
      </Container>{" "}
      <div className="bg-dark text-end sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

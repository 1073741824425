import React from "react";
import { ConditionRow } from "./ConditionRow";
import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";
import { useFilters } from "../../../../Hooks/useFilters";
import { conditionObj } from "../../../../Utils/commonParams";

const joinWith = [
  { value: "and", label: "And" },
  { value: "or", label: "Or" },
];

export const GeneralConditions = ({
  triggerCondition,
  setTriggerCondition,
  entity = "contacts",
  hasMultiCondition = true,
  errorBag = {},
}) => {
  const { attributes } = useFilters({
    industry: "service",
    pageId: "contacts",
  });

  const handleChange = (index, type, value) => {
    const updatedConditions = structuredClone(triggerCondition);
    updatedConditions[index] = {
      ...updatedConditions[index],
      [type]: value
        ? Array.isArray(value)
          ? value.map((item) => item && item.value)
          : value?.value || null
        : null,
      ...(type !== "condition" && {
        [type + "_label"]: value
          ? Array.isArray(value)
            ? value.map((item) => item && item.label)
            : value?.label || null
          : null,
      }),
    };
    setTriggerCondition([...updatedConditions]);
  };

  const addCondition = () => {
    let allConditions = structuredClone(triggerCondition);
    allConditions.push(conditionObj);
    setTriggerCondition([...allConditions]);
  };

  const removeCondition = (index) => {
    let updatedConditions = structuredClone(triggerCondition);
    if (updatedConditions.length > 1) {
      updatedConditions.splice(index, 1);
    } else {
      updatedConditions = [{ ...conditionObj }];
    }
    setTriggerCondition(updatedConditions);
  };

  /******/
  return (
    <React.Fragment>
      {triggerCondition?.length > 0 &&
        triggerCondition.map((condition, idx) => (
          <Container key={idx}>
            <ConditionRow
              idx={idx}
              count={triggerCondition?.length || 0}
              handleChange={(type, value) => handleChange(idx, type, value)}
              addCondition={() => addCondition(idx)}
              removeCondition={() => removeCondition(idx)}
              rowData={condition}
              attributes={attributes}
              entity={entity}
              display="block"
              hasMultiCondition={hasMultiCondition}
              errorBag={errorBag}
            />
            {triggerCondition?.length - 1 !== idx && (
              <Row className="my-2">
                <Col xs={12} sm={12} md={12} className="text-center">
                  <ButtonGroup>
                    {joinWith?.map((join) => (
                      <Button
                        variant={"outline-danger"}
                        className={
                          condition?.condition &&
                          condition.condition === join.value
                            ? "active w-10"
                            : "w-10"
                        }
                        key={join.value}
                        onClick={() => handleChange(idx, "condition", join)}
                      >
                        {join.label.toUpperCase()}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Col>
              </Row>
            )}
          </Container>
        ))}
    </React.Fragment>
  );
};

import React, { useState, useEffect } from 'react';
import stringSimilarity from 'string-similarity';
import { Button, Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { nonGroupColourStyles } from '../../Common/DropDown/Style';
import { tableAttributesInGroups, toSnackCase } from '../../../Utils/utils';

const MapFields = ({
  importData,
  mappingField,
  showAddAttribute,
  setToBeImport,
  popup,
  toBeImport,
  mappedIndex,
  module = [],
  setMappedIndex,
  mappedValue,
  setMappedValue,
  newAttribute,
  setNewAttribute,
  fromParent = 'Excel/CSV',
  locale,
}) => {
  const [restMappingFields, setRestMappingFields] = useState([]);
  /** Get Index no for send data to import **/
  const [mappingFields, setMappingFields] = useState([]);

  useEffect(() => {
    if (mappingField && mappingField.length > 0) {
      const myFields = tableAttributesInGroups(mappingField, locale);
      setMappingFields([...myFields]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingField]);

  const handleMapField = (index) => {
    let mappedIndexes = [...mappedIndex];
    if (mappedIndexes.indexOf(index) === -1) {
      mappedIndexes.push(index);
    } else {
      mappedIndexes.splice(mappedIndexes.indexOf(index), 1);
      let newMappedValue = {};
      Object.keys(mappedValue).forEach((item) => {
        if (parseInt(item) !== index) {
          newMappedValue = { ...newMappedValue, [item]: mappedValue[item] };
        }
      });
      setMappedValue(newMappedValue);
    }
    setMappedIndex(mappedIndexes);
  };

  const handleCustomMapping = (value, index) => {
    let mappedValues = mappedValue;
    if (value === 'default') {
      let newMappedValue = {};
      Object.keys(mappedValues).forEach((item) => {
        if (parseInt(item) !== index) {
          newMappedValue = { ...newMappedValue, [item]: mappedValues[item] };
        }
      });

      if (mappedIndex.length !== Object.keys(newMappedValue).length) {
        handleMapField(index);
      }
      setMappedValue(newMappedValue);
      getRestOfMappingValue();
    } else {
      mappedValues = { ...mappedValues, [index]: value };
      setMappedValue(mappedValues);
      if (mappedIndex.indexOf(index) === -1) {
        handleMapField(index);
      }
    }
  };

  // Check for value field in array of json, if it matches with provided value then it will be omitted from output
  const reduceArray = (items, value) => {
    // return items.filter((item) => item.value !== value);
    if (items && items.length > 0) {
      items.forEach((item) => {
        if (item.options && item.options.length > 0) {
          item.options = [
            ...item.options.filter((op) => op.value && op.value !== value),
          ];
        }
      });
    }
    return items;
  };

  useEffect(() => {
    const formattedData = mappedIndex.map((index) => {
      return {
        header: importData[index],
        field: mappedValue[index],
      };
    });
    setToBeImport(formattedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedValue]);

  /** Fields Mapping Automatically **/
  useEffect(() => {
    if (importData && mappingFields && toBeImport.length === 0) {
      let autoMappedValues = {};
      let autoMappedIndex = [];
      let reducedMappingFields = Array.from(mappingFields);

      importData.forEach((item, idx) => {
        let maxValue = 0;
        let field = '';
        reducedMappingFields.forEach((group) => {
          if (
            group.value !== 'default' &&
            group.options &&
            group.options.length > 0
          ) {
            group.options.forEach((fields) => {
              const newMax = stringSimilarity.compareTwoStrings(
                item.toString(),
                fields.value
              );
              if (newMax >= maxValue) {
                maxValue = newMax;
                field = fields;
              }
            });
          }
        });

        if (maxValue >= 0.4) {
          autoMappedValues = {
            ...autoMappedValues,
            [parseInt(idx)]: field.value,
          };
          reducedMappingFields = reduceArray(reducedMappingFields, field.value);
          autoMappedIndex.push(parseInt(idx));
        }
      });

      setMappedIndex([...autoMappedIndex]);
      setMappedValue({ ...autoMappedValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importData, mappingFields]);

  const getRestOfMappingValue = () => {
    const restFields = Array.from([...mappingFields]);
    if (restFields && restFields.length > 0) {
      restFields.forEach((group) => {
        if (group.options && group.options.length > 0) {
          group.options = [
            ...group.options.filter(
              (field) => Object.values(mappedValue).indexOf(field.value) === -1
            ),
          ];
        }
      });
    }
    setRestMappingFields([...restFields]);
    // console.log("restFields::", restFields, mappingFields);
  };

  useEffect(() => {
    if (mappedValue) {
      getRestOfMappingValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedValue, mappingFields]);

  const showOptions = (selectedValue) => {
    if (restMappingFields && restMappingFields.length > 0) {
      const restFields = tableAttributesInGroups(mappingField, locale);
      // const restFields = [...restMappingFields];
      if (restFields && restFields.length > 0) {
        restFields.forEach((group) => {
          if (group.options && group.options.length > 0) {
            group.options = [
              ...group.options.filter(
                (field) =>
                  Object.values(mappedValue).indexOf(field.value) === -1
              ),
            ];

            if (
              selectedValue &&
              selectedValue.module &&
              group.options.length > 0 &&
              group.options[0] &&
              group.options[0].module &&
              selectedValue.module === group.options[0].module &&
              selectedValue.value !== 'default'
            ) {
              group.options = [...group.options.concat([selectedValue])];
            }
          }
        });
      }

      return restFields;
    }
    return [];
  };

  const showSelectedOptions = (idx) => {
    const allMappingFields = tableAttributesInGroups(mappingField, locale);
    let selectedValue = allMappingFields.filter(
      (item) => item.value === 'default'
    )[0];

    if (allMappingFields && allMappingFields.length > 0) {
      allMappingFields.forEach((item) => {
        if (item.options && item.options.length > 0) {
          item.options.forEach((op) => {
            if (op.value && op.value === mappedValue[idx]) {
              selectedValue = op;
            }
          });
        }
      });
    }
    return selectedValue;
  };
  /********/
  return (
    <div style={popup ? { height: '1px', overflow: 'hidden' } : {}}>
      <h4 className={'font-weight-semibold mb-3 mt-4'}>
        {locale('map fields in the')} {fromParent}{' '}
        {locale('to the corresponding fields in equp.')}
      </h4>
      <div className={'map-fields-table'}>
        <Table
          responsive
          hover
          className={'custom-table table-align-middle'}
          size='sm'
        >
          <thead>
            <tr>
              <th className={'checkbox-td'}>{'  '}</th>
              <th>
                {['CSV', 'Excel/CSV'].indexOf(fromParent) > -1
                  ? locale('field in ' + fromParent + ' file')
                  : 'Field in ' + fromParent}
              </th>
              <th>{locale('map to equp field')}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {importData.map((data, idx) => (
              <tr
                key={idx}
                className={
                  mappedIndex && mappedIndex.indexOf(idx) === -1
                    ? 'mapped-row'
                    : ''
                }
              >
                <td className={'checkbox-td'}>
                  <Form.Check
                    type='checkbox'
                    label=''
                    checked={mappedIndex && mappedIndex.indexOf(idx) !== -1}
                    onChange={(e) => {
                      handleMapField(idx);
                    }}
                  />
                </td>
                <td className={''}>
                  <OverlayTrigger
                    placement='auto'
                    overlay={
                      <Tooltip key={toSnackCase(data.toString())}>
                        {locale(data.toString().toLowerCase())}
                      </Tooltip>
                    }
                  >
                    <span>{locale(data.toString().toLowerCase())}</span>
                  </OverlayTrigger>
                </td>
                <td
                  className={'map-field'}
                  style={{ overflow: 'visible' }}
                >
                  <Form.Group
                    controlId='exampleForm.ControlSelect1'
                    className='form-group mb-0'
                  >
                    <div className={'select-field'}>
                      <Select
                        key={idx}
                        options={showOptions(showSelectedOptions(idx))}
                        isSearchable
                        value={showSelectedOptions(idx)}
                        onChange={(e) => {
                          handleCustomMapping(e.value, idx);
                        }}
                        styles={nonGroupColourStyles}
                        noOptionsMessage={() => locale('no fields')}
                        placeholder={locale('select field')}
                      />
                    </div>
                  </Form.Group>
                </td>
                <td
                  className={''}
                  style={{ maxWidth: 'unset' }}
                >
                  {mappedIndex.indexOf(idx) === -1 && ["products", "services"].indexOf(module) === -1 && (
                    <Button
                      variant='outline-success btn-w-icon text-uppercase'
                      size='sm'
                      className={'float-end'}
                      onClick={() => {
                        setNewAttribute({ ...newAttribute, index: idx });
                        showAddAttribute();
                      }}
                    >
                      <i className={'ri-add-circle-line'}></i>{' '}
                      <span>{locale('add custom field')}</span>
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default MapFields;

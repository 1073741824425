import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAddress, toWordCase } from "../../../Utils/utils";

export const TextLength = ({ style = {}, textLength = 0, maxLength = 25 }) => {
  return (
    <span
      style={{
        ...style,
      }}
      className="text-length"
    >
      {textLength + "/" + maxLength}
    </span>
  );
};

export const isTextLengthValid = (obj, maxLength = 25) => {
  return obj && obj.length <= maxLength;
};

export const MyTooltip = ({
  text = "Tooltip text goes here",
  placement = "auto",
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip>
          <span>{text}</span>
        </Tooltip>
      }
    >
      <i className="ri-information-line" />
    </OverlayTrigger>
  );
};

export const FieldOptionalLabel = ({
  text = "Optional",
  style = {},
  bg = "light",
}) => {
  return (
    <Badge
      style={{
        ...style,
      }}
      className="text-dark ms-2 option-label"
      pill
      bg={bg}
    >
      {text}
    </Badge>
  );
};

export const addPrefixToObject = (valueObj, prefix = "", skipOn = []) => {
  if (Array.isArray(valueObj)) {
    return valueObj.map((item) => addPrefixToObject(item, prefix, skipOn));
  } else if (typeof valueObj === "object" && valueObj !== null) {
    return Object.keys(valueObj).reduce((acc, key) => {
      const newKey = skipOn.includes(key) ? key : prefix + key;
      acc[newKey] = addPrefixToObject(valueObj[key], prefix, skipOn);
      return acc;
    }, {});
  } else {
    return valueObj;
  }
};

export const ConditionDivider = ({
  text = "Condition",
  className = "",
  style = {},
}) => {
  return (
    <div className={`condition-divider ${className}`}>
      <div></div>
      <span style={style}>{text}</span>
    </div>
  );
};


export const emailSignatureString = (userData, currentCompanySetting) => {
  const user = userData?.authData || {};
  const displayName = user.signature ? user.signature : user.name;
  const displayTitle = toWordCase(user.job_profile ? user.job_profile : user.role.name);
  const companyName = currentCompanySetting?.company_name || "";
  const website = currentCompanySetting?.company_website || "";
  const address = getAddress(); // Ensure this returns a string

  return `<p style="text-align: left"></p><p style="text-align: left"></p><p style="text-align: left"></p><p style="text-align: left"></p><p style="text-align: left">${displayName}</p><p style="text-align: left">${displayTitle}</p><p style="text-align: left">${companyName}</p><p style="text-align: left"><strong>Address:- </strong> ${address}</p><p style="text-align: left">${website}</p>
  `;
};

export const emailSignatureString1 = (userData, currentCompanySetting) => {
  const user = userData?.authData || {};
  return (
    "<table cellpadding='0' cellspacing='0' style='width: 400px;padding: 20px 10px;font-family: Arial, sans-serif;border-spacing: 0;border: 0;'><tr> " +
    (user?.profile_picture !== ""
      ? "<td width='60px' style='vertical-align: top; border: 0;'> <div style='overflow: hidden; max-width: 60px;height: 60px;border-radius: 50%;border: 1px solid #ddd;' > <img src='" +
        user.profile_picture +
        "' alt='" +
        (user.signature ? user.signature : user.name) +
        "' style='width: 100%; height: 100%; object-fit: cover' /> </div> </td>"
      : "") +
    " <td style='vertical-align: top;line-height: 1.3;color: #614fba;font-family: Arial, sans-serif;padding-left: 20px; border: 0;' > <h3 style='margin-top: 0;margin-bottom: 2px;font-size: 16px;font-family: Arial, sans-serif;color: #614fba;' > " +
    (user.signature ? user.signature : user.name) +
    " </h3> <p style='margin-top: 0;margin-bottom: 6px;font-size: 13px;font-family: Arial, sans-serif;color: #555;font-weight: 600;' > " +
    toWordCase(user.job_profile ? user.job_profile : user.role.name) +
    " </p> <p style='margin-top: 0;margin-bottom: 5px;font-weight: 600;font-family: Arial, sans-serif;color: #222;font-size: 13px;' > " +
    (currentCompanySetting?.company_name || "") +
    " </p> <p style='margin-top: 0;margin-bottom: 5px;font-family: Arial, sans-serif;color: #222;font-size: 13px;' > <span style='font-weight: 600'>Address - </span> " +
    getAddress() +
    " </p> <p style='display: block; color: #614fba; font-size: 13px'> " +
    (currentCompanySetting?.company_website || "") +
    " </p> </td> </tr></table>"
  );
};

export const findDefaultAddress = (addresses, type = "address") => {
  let defaultAddress = null;
  if (Array.isArray(addresses) && addresses?.length > 0) {
    addresses.forEach((address) => {
      if (address.is_default && type === address.address_type) {
        defaultAddress = address;
      }
    });
  } else {
    defaultAddress = addresses;
  }

  if (defaultAddress === null && type === "address") {
    defaultAddress = findDefaultAddress(addresses, "service");
  }

  if (defaultAddress === null && type === "service") {
    defaultAddress = findDefaultAddress(addresses, "billing");
  }

  return defaultAddress;
};

export const isStringifiedJSON = (input) => {
  if (typeof input !== "string") {
    return false;
  }
  try {
    const parsed = JSON.parse(input);
    return typeof parsed === "object" && parsed !== null;
  } catch (e) {
    return false;
  }
};

import React, { useState, useEffect } from "react";
/* Retrieve Data From Server */
import { useMutation } from "@apollo/client";
// or you can use `import gql from 'graphql-tag';` instead
import { Button, Modal } from "react-bootstrap";
import ImportContacts from "./ImportContacts";
import AddAttribute from "./AddAttribute";
import MapFields from "./MapFields";
import ImportActions from "./ImportActions";
import Summary from "./Summary";
import ConfirmBox from "./ConfirmBox";
import XAlertMessage from "../AlertMessage/XAlertMessage";
import {
  IMPORT_CONTACT,
  IMPORT_CONTACT_DATA,
  PRODUCT_IMPORT_DATA,
  IMPORT_CONNECTOR_DATA,
} from "../../../GraphApi/Import";
import { ScriptURLLoader } from "../../Common/Loader/SkeletonLoader";
import {
  connectorType,
  getValidationMessage,
  importForType,
  isObjectEmpty,
  moduleType,
  toWordCase,
} from "../../../Utils/utils";
import { useTranslation } from "react-i18next";
import { EqupRoundLoaderWithOverlay } from "../Loader/RoundLoader";

const ImportWizard = ({
  handleClose,
  customAttribute,
  importFile,
  mappingField,
  leadOwners,
  module,
  preDefinedColumnUpdate,
  step,
  setStep,
  tagsList,
  getSummary,
  summary,
  importInProgress,
  importError,
  isConfirm,
  setIsConfirm,
  setShowImports,
  refetchData,
  team_id,
  importVia,
  moduleProps = null,
  setMappingFields,
}) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const hideContent = {
    height: "1px",
    overflow: "hidden",
  };
  /** Alert Message State **/
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);

  const [isAgree, setIsAgree] = useState(false);
  const [popup, setPopup] = useState(false);
  const [files, setFiles] = useState([]);
  const [importData, setImportData] = useState([]);
  const [mappedIndex, setMappedIndex] = useState([]);
  const [mappedValue, setMappedValue] = useState({});
  const [newAttribute, setNewAttribute] = useState("");
  const [toBeImport, setToBeImport] = useState([]);
  const [importId, setImportId] = useState(null);
  const [actionOnDuplicate, setActionOnDuplicate] = useState({
    ignore: true,
    overwrite: false,
  });
  const [leadOwner, setLeadOwner] = useState("");
  const [contactSource, setContactSource] = useState(null);
  const [contactTags, setContactTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [newTagError, setNewTagError] = useState(null);
  /* inactive | active */
  const [status, setStatus] = useState("inactive");
  const [dealStage, setDealStage] = useState(null);

  const showAddAttribute = () => {
    setPopup(true);
  };

  /** Import Data Query **/
  const [importContact, { loading: contactImporting }] =
    useMutation(IMPORT_CONTACT_DATA);
  /** Import File (Contact, Deal, Product)**/
  const [contactImport, { loading: fileImporting }] =
    useMutation(IMPORT_CONTACT);
  /*********************************/
  /** Import Product Data Query **/
  const [importProduct] = useMutation(PRODUCT_IMPORT_DATA);
  /** Import Product Data Query **/
  const [initiateImport] = useMutation(IMPORT_CONNECTOR_DATA);
  /*********************************/
  const inActiveStatusAndClearData = () => {
    setStatus("inactive");
    setToBeImport([]);
    setImportData([]);
    setIsAgree(false);
    setStep(1);
  };

  /**This Function Upload File for Import**/
  const fileUploadAndSetHeaders = () => {
    contactImport({
      variables: {
        file: files[0],
        team_id: team_id,
        module: moduleType[module.toUpperCase()],
      },
    })
      .then((result) => {
        if (result.data && result.data.importFile.id) {
          let counter = 1;
          let headings = [];
          try {
            counter = result.data.importFile.headings.filter(
              (fileHeader) => fileHeader === null
            ).length;
            headings = result.data.importFile.headings;
          } catch (e) {
            headings = Object.values(result.data.importFile.headings);
            counter = headings.filter(
              (fileHeader) => fileHeader === null
            ).length;
          }

          if (counter === 0) {
            setStatus("active");
            setImportData([...headings]);
            setImportId(parseInt(result.data.importFile.id));
            setStep(step < 4 ? step + 1 : 4);
          } else {
            handleAlertMessage(
              "error",
              locale(
                "messages:backend.File contain null headers! Please upload a valid file."
              )
            );
            inActiveStatusAndClearData();
            setFiles([]);
          }
        } else {
          handleAlertMessage(
            "error",
            locale("messages:backend.File upload failed please try again!")
          );
          inActiveStatusAndClearData();
          setFiles([]);
        }
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );

        inActiveStatusAndClearData();
        setFiles([]);
      });
  };
  /** Handle validation check required field mapped or not.**/
  const handleValidation = () => {
    if (
      module === "contacts" &&
      toBeImport.filter((item) => item.field === "last_name" && item).length ===
        0
    ) {
      handleAlertMessage(
        "error",
        locale("messages:import.Last Name field is compulsory for import.")
      );
    } else if (
      (module === "products" || module === "services") &&
      toBeImport.filter((item) => item.field === "name" && item)
        .length === 0
    ) {
      handleAlertMessage(
        "error",
        locale("messages:import.Product Id field is compulsory for import.")
      );
    } else {
      setStep(step < 4 ? step + 1 : 4);
    }
  };

  /**Contact Import Start **/
  const processContactImport = () => {
    importContact({
      variables: {
        team_id: team_id,
        id: importId,
        fields: toBeImport,
        importCriteria: actionOnDuplicate.ignore ? "ignore" : "overwrite",
        owner: leadOwner.value,

        ...(["contacts", "deals"].indexOf(module) > -1
          ? {
              source: contactSource && contactSource.label,
              applyTags: contactTags,
              newTags: newTags,
            }
          : {}),

        ...(["contacts", "deals"].indexOf(module) > -1 &&
        moduleProps &&
        moduleProps[module] &&
        moduleProps[module].pipelineValue &&
        dealStage
          ? {
              pipeline_id: moduleProps[module].pipelineValue.pipeline_id,
              stage_id: dealStage && dealStage.stage_id,
            }
          : {}),
      },
      update: () => refetchData(),
    })
      .then((result) => {
        setStep(step < 4 ? step + 1 : 4);
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
      });
  };

  /**Initiate Contact Import for OUTLOOK, GOOGLE etc... **/
  const initiateImportProcess = (iType, mType = "CONTACTS") => {
    initiateImport({
      variables: {
        team_id: team_id,
        module: moduleType[mType],
        import_for: importForType[iType],
      },
    })
      .then((result) => {
        if (
          result.data &&
          result.data.initiateImport &&
          result.data.initiateImport.id
        ) {
          const appFields =
            result.data.initiateImport.headings &&
            result.data.initiateImport.headings.length > 0
              ? result.data.initiateImport.headings
              : [];

          setImportData(appFields);
          setImportId(parseInt(result.data.initiateImport.id));
        }
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
      });
  };
  /**Product Import Start **/
  const processProductImport = () => {
    importContact({
      variables: {
        team_id: team_id,
        id: importId,
        fields: toBeImport,
        importCriteria: actionOnDuplicate.ignore ? "ignore" : "overwrite",
      },
      update: () => refetchData(),
    })
      .then((result) => {
        setStep(step < 4 ? step + 1 : 4);
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
      });
  };
  /** start Importing data **/
  const handleImportData = () => {
    if (module === "contacts") {
      processContactImport();
    } else if (module === "products") {
      processProductImport();
    } else if (module === "services") {
      processProductImport();
    } else if (module === "deals") {
      processContactImport();
    }
  };
  /** Handle new added attribute/ Custom fields.
    This method preselect the custom fields after create.
  **/
  const handleNewAttribute = async (newField) => {
    await setMappingFields([
      ...mappingField,
      {
        ...newField,
        value: newField.name,
      },
    ]);
    setMappedIndex([...mappedIndex, newAttribute.index]);
    setMappedValue({
      ...mappedValue,
      [newAttribute.index]: newField.name,
    });
  };

  /** Validation On Step Change **/
  const handleStepChange = () => {
    if (step === 1 && status === "inactive" && files.length === 0) {
      inActiveStatusAndClearData();
      handleAlertMessage("error", locale("messages:import.No file selected!"));
    } else if (step === 1 && !isAgree && module === "contacts") {
      handleAlertMessage(
        "error",
        locale(
          "messages:import.Please check this checkbox to proceed to next step."
        )
      );
    } else if (step === 1 && status === "inactive" && files.length > 0) {
      fileUploadAndSetHeaders();
    } else if (step === 1 && status === "active" && files.length > 0) {
      setStep(step < 4 ? step + 1 : 4);
    } else if (step === 2 && status === "active") {
      handleValidation();
    } else if (
      ["contacts", "deals"].indexOf(module) > -1 &&
      step === 3 &&
      isObjectEmpty(leadOwner)
    ) {
      const msgLabel = module === "contact" ? "Lead" : "Deal";
      handleAlertMessage(
        "error",
        locale("messages:import.{{msgLabel}} owner is required", {
          msgLabel: locale(msgLabel),
        })
      );
    } else if (
      module === "contacts" &&
      step === 3 &&
      isObjectEmpty(contactSource)
    ) {
      handleAlertMessage(
        "error",
        locale("messages:import.Contact Source is required")
      );
    } else if (
      module === "deals" &&
      step === 3 &&
      isObjectEmpty(moduleProps[module].pipelineValue)
    ) {
      handleAlertMessage(
        "error",
        locale("messages:import.Pipeline is required")
      );
    } else if (module === "deals" && step === 3 && isObjectEmpty(dealStage)) {
      handleAlertMessage(
        "error",
        locale("messages:import.deal stage is required")
      );
    } else if (step === 3) {
      handleImportData();
    } else {
      setStep(step < 4 ? step + 1 : 4);
    }
  };

  /** Handle Alert Message **/
  useEffect(() => {
    if (alertShow === false) {
      setAlertMessage("");
      setAlertType("");
    }
  }, [alertShow]);

  const handleAlertMessage = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    setAlertShow(true);
  };

  const hasPreviousStep = (step) => {
    if (step === 2 && importVia !== "Excel/CSV") {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (importVia && ["CSV", "Excel/CSV"].indexOf(importVia) === -1) {
      initiateImportProcess(connectorType[importVia], "CONTACTS");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importVia]);

  /*****/
  return (
    <>
      {step === 2 && importData && importData.length === 0 && (
        <EqupRoundLoaderWithOverlay />
      )}
      <Modal.Header
        closeButton={step !== 4 && !popup ? true : false}
        className={"bg-primary justify-content-between"}
        closeVariant="white"
      >
        <Modal.Title className={"text-white secondary-font text-uppercase"}>
          {module === "contacts" && locale("import contacts")}
          {module === "products" && locale("import products")}
          {module === "services" && locale("import services")}
          {module === "deals" && locale("import deals")}
        </Modal.Title>
        {popup && (
          <Button
            variant="link btn-link-dark btn-w-icon p-0 text-white"
            onClick={(e) => setPopup(false)}
          >
            <i className={"ri-arrow-left-s-line"}></i>{" "}
            {locale("back to import")}
          </Button>
        )}
      </Modal.Header>
      <Modal.Body>
        {!popup && (
          <React.Fragment>
            {isConfirm && step !== 2 && (
              <ConfirmBox
                setShowImports={setShowImports}
                setIsConfirm={setIsConfirm}
                inActiveStatusAndClearData={() => {
                  inActiveStatusAndClearData();
                }}
              />
            )}
            <div style={isConfirm ? hideContent : {}}>
              <ul className="import-step d-flex flex-nowrap">
                <li className={step === 1 ? "step-item active" : "step-item"}>
                  <button title="1">{locale("import")}</button>
                </li>
                <li className={step === 2 ? "step-item active" : "step-item"}>
                  <button href="#!" title="2">
                    {locale("map fields")}
                  </button>
                </li>
                {["contacts"].indexOf(module) > -1 && (
                  <li className={step === 3 ? "step-item active" : "step-item"}>
                    <button href="#!" title="3">
                      {locale("actions")}
                    </button>
                  </li>
                )}
                <li className={step === 4 ? "step-item active" : "step-item"}>
                  <button href="#!" title="4">
                    {locale("summary")}
                  </button>
                </li>
              </ul>
              <XAlertMessage
                type={alertType}
                message={alertMessage}
                setAlertShow={setAlertShow}
                alertShow={alertShow}
              />
              {(fileImporting || contactImporting) && (
                <div className="my-5">
                  <ScriptURLLoader />
                  <br />
                  <ScriptURLLoader />
                  <br />
                  <ScriptURLLoader />
                  <br />
                  <ScriptURLLoader />
                </div>
              )}
              {!(fileImporting || contactImporting) && step === 1 && (
                <ImportContacts
                  files={files}
                  setFiles={setFiles}
                  inActiveStatusAndClearData={inActiveStatusAndClearData}
                  importFile={importFile}
                  handleAlertMessage={handleAlertMessage}
                  isAgree={isAgree}
                  setIsAgree={setIsAgree}
                  module={module}
                  locale={locale}
                />
              )}

              {!(fileImporting || contactImporting) && step === 3 && (
                <ImportActions
                  leadOwners={leadOwners}
                  setActionOnDuplicate={setActionOnDuplicate}
                  actionOnDuplicate={actionOnDuplicate}
                  setLeadOwner={setLeadOwner}
                  leadOwner={leadOwner}
                  setContactSource={setContactSource}
                  contactSource={contactSource}
                  handleAlertMessage={handleAlertMessage}
                  tagsList={tagsList}
                  contactTags={contactTags}
                  setContactTags={setContactTags}
                  newTags={newTags}
                  setNewTags={setNewTags}
                  module={module}
                  locale={locale}
                  newTagError={newTagError}
                  setNewTagError={setNewTagError}
                  moduleProps={
                    moduleProps && moduleProps[module]
                      ? {
                          ...moduleProps[module],
                          setDealStage: setDealStage,
                          dealStage: dealStage,
                        }
                      : null
                  }
                />
              )}

              {!(fileImporting || contactImporting) && step === 4 && (
                <Summary
                  importId={importId}
                  handleAlertMessage={handleAlertMessage}
                  summary={summary}
                  getSummary={getSummary}
                  importInProgress={importInProgress}
                  importError={importError}
                  module={module}
                  locale={locale}
                />
              )}
            </div>
          </React.Fragment>
        )}
        {!(fileImporting || contactImporting) && step === 2 && !popup && (
          <React.Fragment>
            {isConfirm && (
              <ConfirmBox
                setShowImports={setShowImports}
                setIsConfirm={setIsConfirm}
                inActiveStatusAndClearData={() => {
                  inActiveStatusAndClearData();
                }}
              />
            )}
            <div style={isConfirm ? hideContent : {}}>
              <MapFields
                showAddAttribute={showAddAttribute}
                importData={importData}
                mappingField={mappingField}
                setToBeImport={setToBeImport}
                handleAlertMessage={handleAlertMessage}
                popup={popup}
                toBeImport={toBeImport}
                mappedIndex={mappedIndex}
                module={module}
                setMappedIndex={setMappedIndex}
                mappedValue={mappedValue}
                setMappedValue={setMappedValue}
                setNewAttribute={setNewAttribute}
                newAttribute={newAttribute}
                locale={locale}
                fromParent={toWordCase(importVia)}
              />
            </div>
          </React.Fragment>
        )}
        {popup && (
          <React.Fragment>
            <AddAttribute
              setPopup={setPopup}
              customAttribute={customAttribute}
              module={module}
              preDefinedColumnUpdate={preDefinedColumnUpdate}
              handleAlertMessage={handleAlertMessage}
              handleNewAttribute={handleNewAttribute}
              team_id={team_id}
              popupFor="import"
            />
          </React.Fragment>
        )}
      </Modal.Body>
      {!popup && !isConfirm && (
        <Modal.Footer>
          {step >= 2 && step <= 3 && hasPreviousStep(step) ? (
            <Button
              variant="outline-primary btn-w-icon text-uppercase me-auto"
              onClick={() => {
                setNewTagError(null);
                setStep(step > 0 ? step - 1 : 1);
              }}
            >
              <i className={"ri-arrow-left-line"}></i>{" "}
              <span>{locale("previous")}</span>
            </Button>
          ) : (
            step !== 1 &&
            step !== 4 && (
              <Button
                variant="outline-primary btn-w-icon text-uppercase me-auto"
                onClick={handleClose}
              >
                <i className={"ri-close-line"}></i>{" "}
                <span>{locale("cancel")}</span>
              </Button>
            )
          )}
          {step !== 4 ? (
            <Button
              variant="outline-primary btn-w-icon text-uppercase"
              onClick={handleStepChange}
              disabled={fileImporting || newTagError !== null}
            >
              <span>{locale("next")}</span>{" "}
              <i className={"ri-arrow-right-line"}></i>
            </Button>
          ) : (
            <Button
              variant="outline-primary btn-w-icon text-uppercase"
              onClick={() => {
                setStep(1);
                handleClose();
              }}
            >
              <span>{locale("finish")}</span>
            </Button>
          )}
        </Modal.Footer>
      )}
    </>
  );
};

export default ImportWizard;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

export const RemoveDuplicateAction = ({
  values = null,
  handleChange,
  handleClose,
}) => {
  const [checked, setChecked] = useState(1);
  const [radios] = useState([
    { name: "The one with mobile and email both", value: 1 },
    { name: "The one added first", value: 2 },
    { name: "The latest one", value: 3 },
  ]);

  const handleSaveData = () => {
    let str = [];
    str.push(<span key="1">Remove duplicate contacts</span>);
    str.push(
      <span key="2" className="text-highlighter-dark">
        And keep
      </span>
    );
    const keep = radios.filter(
      (item) => item.value.toString() === checked.toString()
    );
    str.push(
      <span key="3" className="text-primary">
        {" "}
        {keep?.length > 0 && keep[0].name}{" "}
      </span>
    );

    handleChange("action", {
      action: "remove_duplicate_contact",
      duplicate_contact_details: { keep: checked },
      string: str,
    });
    handleClose();
  };

  const handleKeep = (value) => {
    setChecked(value);
  };

  useEffect(() => {
    if (values) {
      setChecked(values?.duplicate_contact_details?.keep || 1);
    }
  }, [values]);

  const handelCancel = () => {
    handleClose();
  };
  /******/
  return (
    <React.Fragment>
      <Container>
        <Row className="mt-3">
          <Col>
            <Form.Group className="form-group">
              <Form.Label>Keep Contacts</Form.Label>
              <br />
              {radios.map((radio, idx) => (
                <Form.Check
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  label={radio.name}
                  name={`radio-${idx}`}
                  value={radio.value}
                  checked={checked.toString() === radio.value.toString()}
                  onChange={(e) => handleKeep(e.currentTarget.value)}
                />
              ))}
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <div className="bg-dark text-end sticky-footer">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={handleSaveData}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handelCancel}>
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

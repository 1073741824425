import { FinishComp } from "./FinishComp";
import { BusinessFlowBox } from "./BusinessFlowBox";
import { isObjectEmpty, toTitleCase } from "../../../../../Utils/utils";

const extraProps = {
  FormSubmission: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  ChatBot: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  Call: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  OnlineBooking: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  Whatsapp: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  ManuallyAdd: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  DelayTimer: {
    noString: "Add Wait Time",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  UpdateContact: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  RequestDocument: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  SendAgreement: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  SendEstimate: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  RequestAppointment: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  SendForm: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  SendBookingLink: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  NotifyTeamMember: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  AssignTeamMember: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  CreateDeal: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  ApplyTag: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  UpdateLifeCycle: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  SendMarketingEmail: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  SendMarketingEmail: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  SendPersonalEmail: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  SendTransactionalEmail: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  SendSms: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  SendWhatsappMessage: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  UpdateDealStage: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  AddLeadScore: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  AssignDealOwner: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  CreateNote: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  EnableCustomerPortal: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  RemoveDuplicateContact: {
    noString: "",
    icons: <i className="ri-price-tag-3-line ri-3x"></i>,
  },
  /*******/
};

export const Component = (props) => {
  const getExtraProps = (name) => {
    let otherProps = {};
    if (!isObjectEmpty(extraProps[name])) {
      otherProps = extraProps[name];
    }
    return otherProps;
  };

  const otherProps = getExtraProps(toTitleCase(props.name));

  return <BusinessFlowBox key={props.itemId} {...props} {...otherProps} />;
};
/****/
export const availableComponent = {
  Finish: FinishComp,
  FormSubmission: Component,
  Call: Component,
  Whatsapp: Component,
  OnlineBooking: Component,
  ChatBot: Component,
  ManuallyAdd: Component,
  DelayTimer: Component,
  UpdateContact: Component,
  RequestDocument: Component,
  SendAgreement: Component,
  SendEstimate: Component,
  RequestAppointment: Component,
  SendForm: Component,
  SendBookingLink: Component,
  NotifyTeamMember: Component,
  AssignTeamMember: Component,
  CreateDeal: Component,
  ApplyTag: Component,
  UpdateLifeCycle: Component,
  CreateTask: Component,
  SendMarketingEmail: Component,
  SendPersonalEmail: Component,
  SendTransactionalEmail: Component,
  SendSms: Component,
  SendWhatsappMessage: Component,
  UpdateDealStage: Component,
  RemoveDuplicateContact: Component,
  EnableCustomerPortal: Component,
  CreateNote: Component,
  AssignDealOwner: Component,
  AddLeadScore: Component,
};

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
/* Retrieve Data From Server */
import { useMutation, useLazyQuery } from '@apollo/client';
// or you can use `import gql from 'graphql-tag';` instead
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  GET_ALL_CONTACT_SOURCE,
  CREATE_CONTACT_SOURCE,
} from '../../../GraphApi/LeadOwner';
import { nonGroupColourStyles } from '../../Common/DropDown/Style';
import { currentTeamId, getValidationMessage } from '../../../Utils/utils';
import PipelineDropdown from '../Pipeline/PipelineDropdown';

const validationSchema = Yup.object({});

const ImportActions = ({
  leadOwners,
  actionOnDuplicate,
  setActionOnDuplicate,
  setContactSource,
  contactSource,
  setLeadOwner,
  leadOwner,
  handleAlertMessage,
  tagsList,
  contactTags,
  setContactTags,
  newTags,
  setNewTags,
  module,
  locale,
  newTagError,
  setNewTagError,
  moduleProps = null,
}) => {
  const [showNewField, setShowNewField] = useState(false);
  const [contactSources, setContactSources] = useState();
  const [dealStages, setDealStage] = useState(null);
  /** Contact Source listing **/
  const [getAllSource, { data: contactSourceList }] = useLazyQuery(
    GET_ALL_CONTACT_SOURCE,
    {
      variables: {
        team_id: currentTeamId,
      },
      fetchPolicy: 'network-only',
    }
  );
  /** Contact Source Create **/
  const [addNewSource] = useMutation(CREATE_CONTACT_SOURCE);

  useEffect(() => {
    if (
      moduleProps &&
      moduleProps.pipelineValue &&
      moduleProps.pipelineValue.stages
    ) {
      setDealStage(moduleProps.pipelineValue.stages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleProps]);

  useEffect(() => {
    refetchSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchSource = () => {
    /** This methods Update cache for backend and cause for refetch contact list. **/
    getAllSource({
      variables: {
        team_id: currentTeamId,
      },
      fetchPolicy: 'network-only',
    });
  };

  useEffect(() => {
    if (contactSourceList) {
      let sourceList = [];
      contactSourceList.allSource.forEach((item) => {
        sourceList.push({ value: item.id, label: item.name });
      });
      setContactSources(sourceList);
    }

    /**Tags List Called ***/

    if (leadOwners) {
      setLeadOwner(
        leadOwner ? leadOwner : leadOwners.length > 0 ? leadOwners[0] : ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactSourceList, leadOwners]);

  const formik = useFormik({
    initialValues: {
      contact_source: '',
    },
    validationSchema,
    onSubmit(values) {
      processData(values);
    },
  });

  /** process Data on Submit **/
  const processData = async (data) => {
    addNewSource({
      variables: { name: data.contact_source, team_id: currentTeamId },
      update: refetchSource,
    })
      .then((result) => {
        if (result.data.createSource.id) {
          setContactSource({
            value: result.data.createSource.id,
            label: result.data.createSource.name,
          });
          handleAlertMessage(
            'success',
            locale('messages:common.added', { fieldName: locale('New Source') })
          );
          setShowNewField(false);
        }
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        handleAlertMessage(
          'error',
          locale('messages:backend.' + getValidationMessage(graphQLErrors))
        );
      });
  };

  /** Duplication **/
  const handleDuplication = (type) => {
    if (type === 'ignore') {
      setActionOnDuplicate({ overwrite: false, ignore: true });
    } else {
      setActionOnDuplicate({ ignore: false, overwrite: true });
    }
  };

  /** Assign Tag to Import data **/
  const handleTags = (e) => {
    let newTagList = [];
    let tagList = [];
    e &&
      e.forEach((tag) => {
        if (tag.__isNew__) {
          newTagList.push({ name: tag.label });
        } else {
          tagList.push({ id: tag.value });
        }
      });

    if (
      newTagList.filter((item) => item.name && item.name.length < 3).length > 0
    ) {
      setNewTagError(locale('messages:Tag.tag_length_error'));
    } else {
      setNewTagError(null);
      setContactTags(tagList);
      setNewTags(newTagList);
    }
  };

  /** RENDER HTML **/
  return (
    <React.Fragment>
      <h4 className={'font-weight-semibold mb-2'}>
        {module === 'contacts'
          ? locale('import criteria and select tag, owner and contact source')
          : locale('Import Criteria')}
      </h4>
      <Form.Group className='form-group'>
        <Form.Label
          className={'d-block mb-2'}
          visuallyHidden
        >
          {locale('owner and contact source')}
        </Form.Label>
        <Form.Check
          type='radio'
          label={locale('ignore duplicates')}
          name='duplicateAction'
          inline
          checked={actionOnDuplicate.ignore}
          onChange={() => {
            handleDuplication('ignore');
          }}
        />
        <Form.Check
          type='radio'
          label={locale('overwrite duplicates')}
          name='duplicateAction'
          inline
          checked={actionOnDuplicate.overwrite}
          onChange={() => {
            handleDuplication('overwrite');
          }}
        />
      </Form.Group>
      {module === 'deals' && moduleProps !== null && (
        <Row>
          <Col>
            <Form.Group className='form-group'>
              <Form.Label>{locale('Select Pipeline')}</Form.Label>
              <PipelineDropdown
                pipelineValue={moduleProps.pipelineValue}
                setPipelineValue={moduleProps.setPipelineValue}
                isDefaultRequired={moduleProps.isDefaultRequired}
                getPipelines={moduleProps.setPipelines}
                isPipelineUpdated={moduleProps.isPipelineUpdated}
                setIsPipelineUpdated={moduleProps.setIsPipelineUpdated}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='form-group'
              id='deal_stage'
            >
              <Form.Label>{locale('pages:deal.deal stage')}</Form.Label>
              <Select
                isClearable={false}
                isSearchable={false}
                placeholder={locale('pages:deal.deal stage')}
                noOptionsMessage={() => locale('pages:deal.no deal stage')}
                options={dealStages}
                styles={nonGroupColourStyles}
                value={moduleProps.dealStage}
                onChange={(e) => {
                  moduleProps.setDealStage(e);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {['contacts', 'deals'].indexOf(module) > -1 && (
        <Row>
          <Col sm='10'>
            <Form.Group className='form-group'>
              <Form.Label>{locale('lead owner')}</Form.Label>
              <Select
                placeholder={locale('lead owner')}
                options={leadOwners}
                value={
                  leadOwner
                    ? leadOwner
                    : leadOwners.length > 0
                      ? leadOwners[0]
                      : ''
                }
                onChange={(e) => {
                  setLeadOwner(e);
                }}
                styles={nonGroupColourStyles}
                noOptionsMessage={() => locale('no lead owner')}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {['contacts', 'deals'].indexOf(module) > -1 && (
        <React.Fragment>
          <Row>
            <Col sm='10'>
              <Row>
                <Col>
                  <Form.Group className='form-group'>
                    <Form.Label>{locale('contact source')}</Form.Label>
                    <Row>
                      <Col>
                        <Select
                          placeholder={locale('contact source')}
                          options={contactSources}
                          value={contactSource && contactSource}
                          onChange={(e) => {
                            setContactSource(e);
                          }}
                          styles={nonGroupColourStyles}
                          noOptionsMessage={() => locale('no contact source')}
                        />
                      </Col>
                      <Col xs='auto'>
                        <Button
                          variant='outline-primary btn-w-icon h-100'
                          disabled={showNewField}
                          onClick={() => setShowNewField(true)}
                        >
                          <i className={'ri-add-line'}></i>{' '}
                          <span>{locale('add new source')}</span>
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          {showNewField && (
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col sm='10'>
                  <Row>
                    <Col>
                      <Form.Group className='form-group'>
                        <Form.Label>{locale('source name')}</Form.Label>
                        <Row>
                          <Col>
                            <Form.Control
                              type='text'
                              name='contact_source'
                              placeholder={locale('enter source name')}
                              autoFocus={true}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                          <Col xs='auto'>
                            <Button
                              variant='outline-primary btn-w-icon h-100'
                              type='submit'
                              disabled={!showNewField}
                            >
                              <span>{locale('save')}</span>
                            </Button>
                          </Col>
                        </Row>
                        {formik.touched.contact_source &&
                        formik.errors.contact_source ? (
                          <Form.Text className='text-muted text-danger'>
                            {formik.errors.contact_source}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          )}
          <Row>
            <Col sm='10'>
              <Form.Group className='form-group'>
                <Form.Label>{locale('select tag')}</Form.Label>
                <CreatableSelect
                  placeholder={locale('create or select tag')}
                  options={tagsList}
                  isMulti
                  onChange={handleTags}
                  styles={nonGroupColourStyles}
                  noOptionsMessage={() => locale('create or select tag')}
                />
                {newTagError !== null && (
                  <Form.Text className='text-muted text-danger'>
                    {locale(newTagError)}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ImportActions;

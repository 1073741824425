import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { ConditionDivider } from "../../../Common/Helper/CommonHelper";
import { toWordCase } from "../../../../Utils/utils";

export const TriggerRow = ({
  currentCompanySetting,
  handleAlertMessage = () => {},
  automationData,
  setAutomationData = () => {},
  automationStep,
}) => {
  const getButtonClass = (idx) => {
    if (automationStep > idx) return "success";
    else if (automationStep === idx) return "primary";
    else return "outline-primary";
  };
  /*****/
  return (
    <Container>
      <Row className="mt-2">
        {automationData?.triggers.map((item, idx) => (
          <React.Fragment key={idx}>
            <Col xs="auto">
              <Button variant={getButtonClass(idx)} className="mt-2">
                {toWordCase(item)}
              </Button>
            </Col>
            {automationData?.triggers?.length - 1 !== idx && (
              <Col>
                <ConditionDivider text="" />
              </Col>
            )}
          </React.Fragment>
        ))}
      </Row>
    </Container>
  );
};
